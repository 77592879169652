import { Inject, Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@phase-ii/auth';
import { API, LoadTranslationService } from '@phase-ii/common';
import { HttpRoutingService } from '@phase-ii/common';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getSuperadminSettings } from '../models/common-service';
import { CommonConstants } from '../../../../../libs/shared/src/lib/constants/shared-constant';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  /**
  * Variable which is used to reset theme.
  * @type {Subject<any>}
  */
  changeStoreDetails = new BehaviorSubject<any>(false);
  /**
  * Variable which is used to store current payment details.
  */
  currentPaymentDetails = new BehaviorSubject<any>(null);
  /**
  * Variable which is used to store  notification count.
  */
  notifyCount = new BehaviorSubject<any>({});
  /**
  * Variable which is used to store preorder plugin.
  */
  preOrderPluginEnable = new BehaviorSubject<Boolean>(null);
  /**
* Variable which is used to store user query count.
*/
  userQueryCount = new BehaviorSubject<any>(0);
  /**
* Variable which is used to store total count(new order notification count + user query count).
*/
  totalAmount = new BehaviorSubject<any>(0);
  /**
  * Variable which is used to find the position while draging the spots in image hotspots.
  */
  pointPosition: Subject<any> = new Subject();

  fileExtension  = {
    GIF_FORMAT: 'gif',
    WEPB_FORMAT: 'webp',
    AVIF_FORMAT: 'webp'
   };

  /**
   * searchSubject used search the subject.
   * @type {Subject<any>}
   */
  searchSubject: Subject<any> = new Subject();
  searchField = new UntypedFormControl('');
  /**
   * Variable which is used to reset theme.
   * @type {Subject<any>}
   */
  setDefaultSetup = new BehaviorSubject<any>(false);
  /**
   *  Variable which is used to store image in file format.
   * @type {Subject<any>}
   */
  imageContent = new BehaviorSubject<any>(null);
  /**
   *  Variable which is used to store image and section details.
   * @type {Subject<any>}
   */
  documentContent = new BehaviorSubject<any>(null);
  /**
   *  Variable which is used to store error section index.
   * @type {Subject<any>}
   */
  errorSectionsIndex = new BehaviorSubject<any>(null);
  // searchField = new FormControl('');
  /**
   * Variable used to store the environment variable.
   */
  environment: any;
  topNavbarDetails = new BehaviorSubject<any>(null);
  loaderDetails = new BehaviorSubject<any>(null);
  footerDetails = new BehaviorSubject<any>(null);
  /**
   * behavior subjct variable which is used to store the feature limits
   */
  featureLimit: any = new BehaviorSubject<any>(null);
  /**
 * A common variable used to store and proccess menus
 */
  menuList;
  /**
   * Store the display to access from other components
   */
  displayData: any[];
      /**
   * common constatnt
   */
      commonConstant: CommonConstants;
  constructor(
    @Inject('environment') environment,
    private httpRoutingService: HttpRoutingService,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private translatService: LoadTranslationService
  ) {
    this.commonConstant = new CommonConstants();
    this.environment = environment;
  }
  updatePaymentType(paymentType) {
    return this.httpRoutingService.postMethod(API.UPDATE_PAYMENT_TYPE, paymentType);
  }
  getPaymentType() {
    return this.httpRoutingService.getMethod(API.GET_PAYMENT_TYPE);
  }
  getPaymentOptions() {
    return this.httpRoutingService.getMethod(API.GET_PAYMENT_OPTIONS);
  }
  getSuperadminSettings(): Observable<getSuperadminSettings> {
    return this.httpRoutingService.getMethod(API.GET_ALL_SUPER_ADMIN_SETTINGS) as Observable<getSuperadminSettings>;
  }
  getAndSetThemes(storeId, themeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.SELECTED_THEMES, { storeId: storeId });
    return this.httpRoutingService.postMethod(url, { themeId: themeId });
  }
  phoneNumber(data) {
    let number = '';
    if (data?.userCountryCode) {
      number = '+' + data.userCountryCode + ' ';
    }
    return number.concat(data?.userPhone || '-');

  }
  /**
  * Method which is used to get the country details.
  */
  getCountries(data?) {
    return this.httpRoutingService.getMethod(API.COUNTRIES, { currency: data ? true : false });
  }
  /**
  * Method which is used to get the state value.
  */
  getStates(id) {
    const url = this.httpRoutingService.replaceUrlParam(API.STATES, { id: id });
    return this.httpRoutingService.getMethod(url);
  }
  /**
  * Method which is used to get the state value.
  */
  getStatesWithCountryName(name) {
    const url = this.httpRoutingService.replaceUrlParam(API.STATESWITHCOUNTRY, { name: name });
    return this.httpRoutingService.getMethod(url);
  }
  getAllNotification(storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ALL_NOTIFICATION, { storeId: storeId });
    return this.httpRoutingService.getMethod(url);
  }
  getExpiredOrderNotification(storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.EXPIRED_ORDER_NOTIFICATION, { storeId: storeId });
    return this.httpRoutingService.getMethod(url);
  }
  getNewPreorderCount(storeId: any) {
    const url = this.httpRoutingService.replaceUrlParam(API.NEW_PREORDER_COUNT, { storeId: storeId });
    return this.httpRoutingService.getMethod(url);
  }
  getUserQueriesCount(storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.USER_QUERY_COUNT, { storeId: storeId });
    return this.httpRoutingService.getMethod(url);
  }

  /**
   * this method used to get wallet details of store
   * @param storeId store id
   * @returns 
   */
  getOneStoreWalletDetails(storeId) {
    return this.httpRoutingService.getMethod('stores/' + storeId + '/wallet');
  }
  updateRenewalData(data) {
    return this.httpRoutingService.putMethod('subscription/autoRenewal', data);
  }
  updateSuperadminSettings(data: any, id: number) {
    return this.httpRoutingService.putMethod('superadminsettings/' + id, data);
  }

  suspendStore(id: number, data: any) {
    return this.httpRoutingService.putMethod('stores/' + id + '/suspendstore', data);
  }
  checkRecurrence(recurrenceDetails, startDate, endDate) {
    const today = new Date();
    if (today >= new Date(startDate) && today <= new Date(endDate)) {
      // console.log('in if 1');
      if (recurrenceDetails) {
        // console.log('in if 2');
        if (recurrenceDetails.recurrenceType) {
          // console.log('in if 3');
          if (recurrenceDetails.recurrenceType === 1) {
            // console.log('in if 4');
            for (let i = 0; i < recurrenceDetails.recurrenceDetails.length; i++) {
              //  recurrenceDetails.recurrenceDetails.forEach(t => {
              const start = new Date();
              const end = new Date();
              const time = recurrenceDetails.recurrenceDetails[i].startTime.split(":").map((item) => {
                return Number(item);
              });
              const endTime = recurrenceDetails.recurrenceDetails[i].endTime.split(":").map((item) => {
                return Number(item);
              });
              start.setHours(time[0]);
              start.setMinutes(time[1]);
              end.setHours(endTime[0]);
              end.setMinutes(endTime[1]);
              if (start.getTime() <= today.getTime() &&
                end.getTime() <= today.getTime()) {
                return true;
              }
              // });
            }
          } else {
            // console.log('in else 1');
            for (let i = 0; i < recurrenceDetails.recurrenceDetails.length; i++) {
              // recurrenceDetails.recurrenceDetails.forEach(t => {
              // console.log(recurrenceDetails.recurrenceDetails[i], today.getDay());
              if (recurrenceDetails.recurrenceDetails[i] === today.getDay()) {
                // console.log('in else if',recurrenceDetails.recurrenceDetails[i]);
                return true;
              }
              // });
            }
          }
        }
      }
      else {
        return true;
      }
    }

    return false;
  }

  dateDifference(date2, date1) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    // Discard the time and time-zone information.
    const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  /**
   * Method used to get Ribbon details
   * @param storeId id of the store.
   * @param data contains limit and offset
   * @returns the response
   */
  getAllRibbon(storeId, data?) {
    const url = this.httpRoutingService.replaceUrlParam(API.RIBBIONS, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);

  }
  /**
   * method used to get position types
   * @param storeId id of the store.
   * @param propertyName name of the property
   * @returns the response
   */
  getPosition(storeId, propertyName) {
    const url = this.httpRoutingService.replaceUrlParam(API.PROPERTY_VALUE, {});
    return this.httpRoutingService.getMethod(url, { propertyName: JSON.stringify(propertyName) });

  }
  /**
  * method to get data from backend based on store id and property name.
  * @param code which holds the property code.
  * @returns returns the response .
  */
  getPropertyValuesByCode(code) {
    const url = this.httpRoutingService.replaceUrlParam(API.PROPERTY_VALUES_BY_CODE, {});
    return this.httpRoutingService.getMethod(url, { code: code });
  }
  /**
 * this method is used to create role 
 * @param data role data
 * @param storeId store id
 * @returns response
 */
  createRole(data, storeId) {
    return this.httpRoutingService.postMethod('stores/' + storeId + '/roles', data);
  }
  /**
* Method used to update order details
* @param orderId used to get the order details.
* @param storeId is the id of the store.
* @param data is the data to update order details.
*/
  // updateOrderDetails(storeId, orderId, data) {
  //   return this.httpRoutingService.putMethod('stores/' + storeId + '/updateorderdetails/' + orderId, data);
  // }
  // updateOrderDetails(storeId, orderId, data) {
  //   const url = this.httpRoutingService.replaceUrlParam(API.UPDATE_ORDER_DETAIL, {
  //     storeId: storeId, orderId: orderId
  //   });
  //   return this.httpRoutingService.putMethod(url, data);
  // }
  /**
   * this method is used to update existing role
   * @param data role data
   * @param id role id
   * @returns 
   */
  updateRole(data, id, storeId) {
    return this.httpRoutingService.putMethod('stores/' + storeId + '/roles/' + id, data);
  }
  /**
 * this method is used to fetch all privilages
 * @returns 
 */
  getAllPrivileges() {
    return this.httpRoutingService.getMethod('privileges');
  }
  /**
  * this method is used to get one role data
  * @param roleId role id
  * @returns fetched role data
  */
  getOneRole(roleId, storeId) {
    return this.httpRoutingService.getMethod('stores/' + storeId + '/roles/' + roleId);
  }
  /**
 * this method used to get all roles
 * @param storeId storeId
 * @param data filter data
 * @returns fetched data
 */
  getAllRoles(storeId, data) {
    return this.httpRoutingService.getMethod('stores/' + storeId + '/roles', data);
  }
  /**
* this method used to get all roles
* @param storeId storeId
* @param data filter data
* @returns fetched data
*/
  getAllTransactions(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.TRANSACTION, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);

  }
  /**
   * this method is used to delete a brand
   * @param id role id
   * @returns deletion response
   */
  deleteRole(id, storeId) {
    return this.httpRoutingService.deleteMethod('stores/' + storeId + '/roles/' + id);
  }
  /**
   * This method used to get all stores
   * @returns 
   */
  getAllStores() {
    return this.httpRoutingService.getMethod('stores/');
  }
  //ASYNC VALIDATORS

  /**
 * Method used to check the duplicate.
 * @param data is the data to check duplicates
 * @param storeId is the id of the store.
 */
  checkDuplicate(data, storeId, isGuest?) {
    return this.httpRoutingService.postMethod('stores/' + storeId + (isGuest ? '/checkduplicatesforguest' : '/common/checkduplicates'), data);
  }
  /**
* Method which is used to fetch all users history.
* @returns Returns response.
*/
  getUserHistory(id, storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.FETCH_USER_HISTORY, { storeId: storeId, id: id });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
  * Method which is used to save users history.
  * @returns Returns response.
  */
  saveUsrLogs(data) {
    return this.httpRoutingService.postMethod('/users/logs', data);
  }
  /**
   * This method used to get all stores
   * @returns 
   */
  getAllStoresName() {
    return this.httpRoutingService.getMethod('storenames');
  }
  /**
   * Method which is used to get all the customer of store
   * @param data To fetch customer based on this
    * @param storeId id of the store.
   * @returns 
   */
  getAllCustomers(data: any, storeId: number) {
    // return this.httpRoutingService.getMethod('stores/' + storeId + '/customers/v1', data);
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ALL_CUSTOMER_BY_STORE, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
   * Method which is used to display the chip in table.
   * @param tabName To define the selected tab.
   * 
   */
  tableChip(chipText, bgcolor?, color?) {
    bgcolor = bgcolor ? bgcolor : 'green';
    color = color ? color : 'white';
    return this.sanitizer.bypassSecurityTrustHtml(
      `<span style="font-size:12px;padding:3px 10px 3px 10px; border-radius:50px; color:${color}; background-color:${bgcolor}">${chipText}</span>`);
  }
  /**
  * Method which is used for payment process
  * @param data To fetch payment based on this
  * @param storeId has the storeId
  * @returns 
  */
  paymentProcess(data, storeId) {
    const url = data.isUrlCheckout ? this.httpRoutingService.replaceUrlParam(API.URL_AUTHORIZE_PAYMENT, { storeId: storeId }) : this.httpRoutingService.replaceUrlParam(API.AUTHORIZE_PAYMENT, { storeId: storeId });
    return this.httpRoutingService.postMethod(url, data);
  }

  /**
    * Method which is used to delete existing stripe card of customer
    * @param id id of the customer card.
    * @returns 
    */
  deleteStripeCustomerCard(id) {
    return this.httpRoutingService.deleteMethod('/deletestripecard/' + id);
  }

  /**
  * Method which is used to get client name
  * @returns 
  */
  getClientName() {
    return this.httpRoutingService.getMethod('storenames', {});
  }

  /**
  * Method which is used to get company details
  * @param data to fetch company details based on this.
  * @returns 
  */
  getCompanyDetails(data) {
    return this.httpRoutingService.postMethod('/companydetails', data);
  }


  updatePlanDetailsForCustomer(data) {
    return this.httpRoutingService.postMethod('subscription', data);
  }

  // Wallet
  createNewWallet(data, id) {
    return this.httpRoutingService.postMethod('wallet/' + id, data);
  }
  updateWallet(data, id) {
    return this.httpRoutingService.putMethod('wallet/' + id, data);
  }

  // setHeadersForSubscriptionLambda(url) {
  //   if (localStorage.getItem('jwt_token')) {
  //     // console.log('inside');
  //     this.headerService.setHeaders(this.environment.apiUrlDb + url, 'Authorization', localStorage.getItem('jwt_token'));
  //   }
  // this.headerService.setHeaders(this.environment.apiUrlDb + url, 'lambdaauthorization', localStorage.getItem('lambda_jwt_token'));
  // this.headerService.setHeaders(this.environment.apiUrlDb + url, 'x-api-key', this.environment.lambdaKey);
  // }
  planPayment(data) {
    // this.setHeadersForSubscriptionLambda('planPayment');
    return this.httpRoutingService.postMethod('planPayment', data);
  }
  /**
   * Method used to create the payment for square
   * @param data defines the data for square payment
   * @param storeId defines the storeid
   * @returns response
   */
  createSquarePayment(data, storeId) {
    const url = data.isUrlCheckout ? this.httpRoutingService.replaceUrlParam(API.URL_SQUARE_PAYMENT, { storeId: storeId }) : this.httpRoutingService.replaceUrlParam(API.SQUARE_PAYMENT, { storeId: storeId });
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
   * Method used to delete the saved card for stripe and square
   * @param id defines the card id
   * @param data data need for deletion
   * @param storeId defines the store id
   * @returns deleted response
   */
  deleteSavedCard(id, data, storeId) {
    const url = this.httpRoutingService.replaceUrlParam(data.isUrlCheckout ? API.URL_DELETE_CARD : API.DELETE_CARD, { id: id, storeId: storeId });
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
   * Method used to create the order for razorpay
   * @param data defines the data for order creation
   * @param storeId defines the storeid
   * @returns response
   */
  createRazorpayOrder(data, storeId) {
    const url = data.userId ? this.httpRoutingService.replaceUrlParam(API.URL_RAZORPAY_ORDER, { storeId: storeId }) : this.httpRoutingService.replaceUrlParam(API.RAZORPAY_ORDER, { storeId: storeId });
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
    * Method used to create the order for Authorize.net
    * @param data defines the data for order creation
    * @param storeId defines the storeid
    * @returns response
    */
  createAuthorizeDotNet(data, storeId) {
    const url = data.isUrlCheckout ? this.httpRoutingService.replaceUrlParam(API.URL_AUTHORIZEDOTNET_PAYMENT, { storeId: storeId }) : this.httpRoutingService.replaceUrlParam(API.AUTHORIZEDOTNET_PAYMENT, { storeId: storeId });
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
* this method is used to get one role data
* @param roleCode role code
* @returns fetched role data
*/
  getRoleName(storeId, roleCode) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ROLE_NAME, { storeId: storeId });
    return this.httpRoutingService.postMethod(url, roleCode);
  }
  checkStoreNameExists(data, industryId) {
    return this.httpRoutingService.getMethod('stores/' + industryId + '/checkStoreNameExists', data);
  }

  /**
  * Method which is used to get invoice details
  * @param data To fetch invoice based on this
  * @param storeId id of the store.
  * @returns 
  */
  getInvoice(data, storeId) {
    return this.httpRoutingService.getMethod(API.INVOICE, data);
  }
  generateSubscriptionDetails(data) {
    return this.httpRoutingService.postMethod(API.GENERATE_SUBSCRIPTION, data);
  }

  /**
   * Method which is used to purchase mobile app subscription
   * @param data for subscription
   * @returns 
   */
  generateMobileSubscriptionDetails(data) {
    return this.httpRoutingService.postMethod(API.MOBILE_SUBSCRIPTION, data);
  }

  updateMobileTheme(storeId: number, data: any) {
    const url = this.httpRoutingService.replaceUrlParam(API.UPDATE_MOBILE_THEME, { storeId: storeId });
    return this.httpRoutingService.postMethod(url, data);
  }

  generateThemePlanDetails(data) {
    return this.httpRoutingService.postMethod(API.GENERATE_THEME_PLAN, data);
  }
  getAllPlans(data?) {
    return this.httpRoutingService.getMethod(API.PLAN, data);
  }
  getAllFeatures(params?) {
    if (params && params.history) {
      return this.httpRoutingService.getMethod(API.FEATURE, { history: true });
    } else {
      return this.httpRoutingService.getMethod(API.FEATURE);
    }
  }
  getAllPluginFeatures() {
    return this.httpRoutingService.getMethod(API.FEATURE_PLUGIN);
  }
  // Subscription Member
  suspendUser(data, id) {
    const url = this.httpRoutingService.replaceUrlParam(API.SUSPEND_USER, { storeId: id });
    return this.httpRoutingService.putMethod(url, data);
  }
  getActivePlan(id) {
    const url = this.httpRoutingService.replaceUrlParam(API.ACTIVE_SUBSCRIPTION, { customerId: id });
    return this.httpRoutingService.getMethod(url);
  }
  getPlanDetailsOfUser(id: any) {
    const url = this.httpRoutingService.replaceUrlParam(API.UPGRADE_SUBSCRIPTION_PLAN, { customerId: id });
    return this.httpRoutingService.getMethod(url);
  }
  printInvoice(invoiceTemplate) {
    // this.setHeadersForSubscriptionLambda('generateInvoice');
    const url = this.httpRoutingService.replaceUrlParam(API.CREATE_SUBSCRIPTION_INVOICE_PDF, invoiceTemplate);
    return this.httpRoutingService.getMethod(url);

  }
  printMobileInvoice(invoiceTemplate) {
    // this.setHeadersForSubscriptionLambda('generateInvoice');
    const url = this.httpRoutingService.replaceUrlParam(API.CREATE_MOBILE_SUBSCRIPTION_INVOICE_PDF, invoiceTemplate);
    return this.httpRoutingService.getMethod(url);

  }
  sendInvoice(data) {
    return this.httpRoutingService.postMethod(API.SEND_SUBSCRIPTION_INVOICE_PDF, data);
  }
  calculateTaxForSubscription(data) {
    return this.httpRoutingService.postMethod(API.TAX, data);
  }
  //Subscription Payment
  /**
   * Method used to get the saved card details for subscription payment
   * @param clientId 
   * @returns response
   */
  getSavedCardDetails(clientId, isTestStore?: boolean) {
    let query;
    if (isTestStore)
      query = { isTestStore: isTestStore };
    const url = this.httpRoutingService.replaceUrlParam(API.SUBSCRIPTION_SAVEDCARD, { clientId: clientId });
    return this.httpRoutingService.getMethod(url, query);
  }

  /**
    * Method used to get the saved card details for subscription payment
    * @param clientId 
    * @returns response
    */
  getSripeCardDetails(clientId, isTestStore?: boolean) {
    let query;
    if (isTestStore)
      query = { isTestStore: isTestStore };
    const url = this.httpRoutingService.replaceUrlParam(API.SRIPE_SAVEDCARD, { clientId: clientId });
    return this.httpRoutingService.getMethod(url, query);
  }
  updateCard(data) {
    return this.httpRoutingService.postMethod(API.UPDATE_CARD, data);
  }
  /**
   * Method used to create the razorpay order
   * @param data defines body data
   * @returns response
   */
  createOrderInRazorpay(data) {
    return this.httpRoutingService.postMethod(API.SUBSCRIPTION_RAZORPAY, data);
  }
  /**
   * Method used to get the subscription payment transaction logs
   * @param data defines query param data
   * @returns response
   */
  getSubscriptionTransactionLogs(data) {
    return this.httpRoutingService.getMethod(API.SUBSCRIPTION_TRANSACTION_LOG, data);
  }
  /**
   * Method which is used to create order invoice as pdf
   * @param data , invoice details
   * @returns
   */
  createOrderInvoicePdf(data) {
    return this.httpRoutingService.postMethod(
      API.CREATE_ORDER_INVOICE_PDF,
      data
    );
  }
  /**
 * Method which is used for payment process
 * @param data To fetch payment based on this
 * @param storeId id of the store.
 * @returns 
 */
  authorizePaymentOrder(data, storeId) {
    const url = data.isUrlCheckout ? this.httpRoutingService.replaceUrlParam(API.URL_AUTHORIZE_PAYMENT_ORDER, { storeId: storeId }) : this.httpRoutingService.replaceUrlParam(API.AUTHORIZE_PAYMENT_ORDER, { storeId: storeId });
    return this.httpRoutingService.postMethod(url, data);
  }
  /**
   * Method which is used for payment process
   * @param data To fetch payment based on this
   * @param storeId id of the store.
   * @returns 
   */
  checkoutDotComOrder(data, storeId) {
    const url = data.isUrlCheckout ? this.httpRoutingService.replaceUrlParam(API.URL_AUTHORIZE_CHECKOUTDOTCOM_ORDER, { storeId: storeId }) : this.httpRoutingService.replaceUrlParam(API.AUTHORIZE_CHECKOUTDOTCOM_ORDER, { storeId: storeId });
    return this.httpRoutingService.postMethod(url, data);
  }

  /**
  * Method which is used for failed payment process
  * @param data To fetch payment based on this
  * @param storeId id of the store.
  * @returns 
  */
  createFailureTransactionLog(data, storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.CREATE_FAILURE_TRANSACTION_LOG, { storeId: storeId });
    return this.httpRoutingService.postMethod(url, data).subscribe(res => {
      console.log("Payment Failed");
    });
  }


  /**
   * Method used to update store page setupdetails.
   * @param storePageSetupId To the store page setupId id.
   * @param StoreId To hold store id
   * @param data To store page details.
   */
  updateStorePageSetup(storePageSetupId: number, data: any) {
    if (storePageSetupId) {
      const url = this.httpRoutingService.replaceUrlParam(API.UPDATE_STORE_PAGE_SETUP, { storeId: data.storeId, storePageSetupId: storePageSetupId });
      return this.httpRoutingService.putMethod(url, { data: data.encryptedData });
    }
  }
  /**
   * Method used to update store page setupdetails.
   * @param data To store page details.
   */
  getAllPagesOfTheme(data: any) {
    const url = this.httpRoutingService.replaceUrlParam(API.All_THEME_PAGES, { storeId: data.storeId, themeId: data.themeId });
    return this.httpRoutingService.getMethod(url, { getStorePageSetup: "true" });
  }
  /**
   * Method used to update store page setupdetails.
   * @param data To store page details.
   */
  updatePagesForSingleStore(storeId, data: any) {
    const url = this.httpRoutingService.replaceUrlParam(API.UPDATE_PAGE_FOR_SINGLE_STORE, { storeId: storeId });
    return this.httpRoutingService.putMethod(url, data);
  }
  /**
   * Method used to update store page setupdetails.
   * @param data To store page details.
   */
  updatePagesInStorePageSetup(storeId: number, data: any) {
    const url = this.httpRoutingService.replaceUrlParam(API.UPDATE_ALL_PAGES_IN_STORE_PAGE_SETUP, { storeId: storeId });
    return this.httpRoutingService.putMethod(url, { data: data });
  }

  getAllMailLogs(data, storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_MAIL_LOGS, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
   * Method used to get store page details.
   * @param data which holds storeId and themeId.
   * @param pageId which holds pageId
   */
  getStorePages(data: any, pageId?: number) {
    return this.httpRoutingService.putMethod(API.GET_ALL_STORE_PAGES, { data: data, pageId: pageId });
  }
  /**
   * Method used to get store page details.
   * @param data which holds storeId and themeId.
   * @param pageId which holds pageId
   */
  formatAndUpdateThemePages(data: any, pageId?: number) {
    return this.httpRoutingService.putMethod(API.FORMAT_AND_UPDATE_THEME_PAGES, pageId ? { data: data, pageId: pageId } : { data: data });
  }
  /**
   * Method used to update store page setup details
   */
  updateIsChanged() {
    return this.httpRoutingService.putMethod(API.GET_IS_CHANGED_VALUE, {});
  }
  /**
   * Method used to update store page setupdetails.
   * @param data To store page details.
   */
  updateStorePages(data: any) {
    return this.httpRoutingService.putMethod(API.UPDATE_ALL_STORE_PAGES, { data: data });
  }
  /**
   * Method used to get payment logs.
   * @param isMobile to get the page is mobile or not.
   */
  getPaymentLog(isMobile?: boolean) {
    return this.httpRoutingService.getMethod(API.GET_COUNT, isMobile ? { isMobile: true } : {});
  }
  getCheckFeatureLimit(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.limit, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, { planId: data },null,this.commonConstant.versioningRoute);
  }
  getPaymentData(data, isupgrade) {
    return this.httpRoutingService.postMethod(API.GET_PAYMENT_DATA, data, { isupgrade: isupgrade });
  }
  updateUpcomingPlan(data) {
    return this.httpRoutingService.putMethod(API.UPDATE_UPCOMING_PLAN, data);
  }
  storePlanCount(storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.STORE_PLANS, { id: storeId });
    return this.httpRoutingService.getMethod(url);
  }
  deleteUpcominPlanRequest(id) {
    return this.httpRoutingService.deleteMethod(API.DELETE_REQUEST, { id: id });
  }
  /**
   * Method checkSignupDiscountExists used to check if the given code is exists or not.
   * @param data is the promocode.
   * @returns data.
   */
  checkSignupDiscountExists(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.CHECK_DISCOUNT_DATA, {});
    return this.httpRoutingService.getMethod(url, data);
  }
  /**
    * Method used to remove Special Character .
    */
  removeSpecialCharacterForFile(file: any) {
    let ext = file.slice(file.lastIndexOf('.'));
    let Name = file.slice(0, file.lastIndexOf('.'));
    Name = Name.replace(/[^a-zA-Z^1-9^_ ]/g, "");
    Name = Name.replace(/\s/g, '_');
    let image = Name + ext;
    return image;
  }

  /**
   * Method used to add email template.
   * @param id has notification type id.
   */
  AddEmailTemplate(data: any): any {
    return this.httpRoutingService.postMethod(API.ADDDEFAULTMAILTEMPLATE, data);
  }
  /**
   * Method used to get email template.
   * @param storeId has notification type storeId.
   * @param data has notification type data.
   */
  getOneEmailTemplate(templateId: number, storeId: number): any {
    const url = this.httpRoutingService.replaceUrlParam(API.GETONEDEFAULTMAILTEMPLATESTOREID, {
      // storeId: 5, id: 10
      storeId: storeId, id: templateId
    });
    return this.httpRoutingService.getMethod(url);
  }
  /**
     * Method used to update email template.
     * @param id has notification type id.
     */
  updateEmailTemplate(templateId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.UPDATEMAILDEFAULTTEMPLATE, {
      id: templateId
    });
    return this.httpRoutingService.putMethod(url, data);
  }
    /**
     * Method used to updatEmail.
     * @param id has notification type id.
     */
  updateEmailTemplateDetails(templateId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.UPDATEMAILDEFAULTTEMPLATE, {
      id: templateId
    });
    return this.httpRoutingService.putMethod(url, data);
  }
  /**
     * Method used to add new email template.
     * @param id has notification type id.
     */
  sentTestMail(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.SENTTESTMAIL, {
      storeId: storeId
    });
    return this.httpRoutingService.postMethod(url, data);
  }
  getAllStoreNames() {
    return this.httpRoutingService.getMethod(API.STORENAMES);
  }
  getAllDefaultTemplates() {
    return this.httpRoutingService.getMethod(API.GET_ALL_TEMPLATES);
  }
  /**
   * Method used to get store details for profileMenu.
   * @param storeId has store Id.
   */
  getStoreDetails(storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.FETCH_STORE_DETAILS, { storeId: storeId });
    return this.httpRoutingService.getMethod(url);
  }
  /**
   * Method used to update store details from profileMenu.
   * @param storeId has store Id.
   * @param data has to be updated.
   */
  updateStoreDetails(storeId, data) {
    const url = this.httpRoutingService.replaceUrlParam(API.FETCH_STORE_DETAILS, { storeId: storeId });
    return this.httpRoutingService.putMethod(url, data);
  }
  /**
 * this method used to check weather the navigating url can be accessed by the current user.
 * @param rawUrl currently navigating url
 * @param params paramters in current url
 * @param querParams query parameters in current url
 * @returns 
 */
  checkPermissions(rawUrl, params, querParams) {
    let permission = false;
    // console.log("paramsssssssss", querParams, params, Object.keys(querParams).length);
    // if (url.indexOf('%') !== -1) {

    //   const sub = String(url.substring(url.indexOf('%'), url.indexOf('%') + 3));

    //   url = url &&url.toString() && url.toString().replaceAll(sub, ' ');
    // }
    // console.log("url..........b......",url,typeof url);

    let url = decodeURIComponent(rawUrl);
    // console.log("url.............a...",url,typeof url);
    if (this.menuList && this.menuList.menus) {
      // console.log("menu........", this.menuList);
      this.menuList.menus.forEach(element => {
        let both = true, isParams = true,
          changedLink = element.subMenu && element.subMenu.length === 0 ? element.pageCustomLink : null;
        if (Object.keys(params).length > 0 || Object.keys(querParams).length > 0) {
          for (const proms in params) {
            if (proms && element.subMenu && element.subMenu.length === 0) {
              if (isParams) {
                isParams = false;
                changedLink = element.pageCustomLink && element.pageCustomLink.replace(':' + proms, params[proms]);
                // console.log("paramsssssssss....1", changedLink);
              }
              else {
                changedLink = changedLink && changedLink.replace(':' + proms, params[proms]);

              }

            }
          }
          // } 
          // else if (Object.keys(querParams).length > 0) {
          // console.log("paramsssssssss..................");
          for (const qparams in querParams) {
            if (qparams && element.subMenu && element.subMenu.length === 0) {
              if (both) {
                both = false;
                changedLink = changedLink + '?' + qparams + '=' + querParams[qparams];
                // console.log("paramsssssssss...2", changedLink);
              }
              else {
                changedLink = changedLink + '&' + qparams + '=' + querParams[qparams];
              }
            }
          }
        }
        else {
          if (element.pageCustomLink && (element.pageCustomLink.includes(':') ||
            url.includes('?'))) {
            const urlSplitData = url.split('/');
            const dbUrlSplit = element.pageCustomLink.split('/');
            if (urlSplitData.length === dbUrlSplit.length) {
              if (element.pageCustomLink.includes(':')) {

                dbUrlSplit.find((data, index) => {
                  if (data.includes(':')) {
                    dbUrlSplit[index] = urlSplitData[index];
                  }
                });
                changedLink = dbUrlSplit.join('/');
              } else if (url.includes('?')) {
                changedLink = url.split('?')[0];
              }

            }
          }
        }
        if (element.subMenu && element.subMenu.length) {
          for (let i = 0; i < element.subMenu.length; i++) {
            if (element.subMenu[i].pageCustomLink === url) {
              // console.log("in submenu...");
              if (element.subMenu[i].isReadOnly)
                this.authService.isReadOnly.next(element.subMenu[i].isReadOnly);
              else
                this.authService.isReadOnly.next(false);
              permission = true;
            }
          }
        }
        else if (changedLink === url) {
          // console.log("not submenu...");
          if (element.isReadOnly)
            this.authService.isReadOnly.next(element.isReadOnly);
          else
            this.authService.isReadOnly.next(false);


          permission = true;
        }
        // console.log("changedLink...",changedLink,"url,,,,,,,,,,,,,,,,,",url);

      });
      // this.authService.isReadOnly.subscribe((res) => {
      //    console.log("isReadonly.........1....", res,permission);
      // })
      // console.log("isReadonly..........2...", this.isReadOnly);

      return permission;
    }
  }
  /**
 * Method used to get referal code details.
 * @param code has referal code to get its details.
 */
  getReferalCodeDetails(code) {
    return this.httpRoutingService.getMethod(API.REFERRALCODE, { code: code, exist: true });
  }
  /**
* Method used to get global settings details.
*/
  getGlobalSettings() {
    return this.httpRoutingService.getMethod(API.GLOBAL_SETTINGS);
  }
  /**
   * method used to get all all shopapp user
   * @param data holds the data about filter search etc..
   * @param storeId holds the storeid
   * @returns the response
   */
  getAllShopappCustomers(data, storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.SHOPAPP_USERLIST, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, data)
  }
  /**
 * Method used to get user email id.
 * @param email holds the email id.
 * @param storeId holds the store id.
 * @param role holds the role.
 * @returns the response.
 */
  getUserEmail(email: any) {
    return this.httpRoutingService.postMethod('customer/shopapp/mail', email);
  }
  /*
   * Method which is used to check plugin enable or disable.
   * @param storeId To get store id.
   * @param data To get plugin code
   * @param pluginTitle To get sub plugin title
   * @returns loyalty program plugin and sub plugins enable or disable value.
   */
  getLoyaltyPlugin(storeId, data, pluginTitle) {
    let pluginEnable;
    return new Promise((resolve, reject) => {
      this.authService.getLoyaltyPlugin(storeId).subscribe((res: any) => {
        if (res && res.result.plugin && res.result.plugin.storePluginMappings && res.result.plugin.storePluginMappings.length) {
          if (res.result.plugin.customfields && res.result.plugin.customfields.length) {
            res.result.plugin.customfields.forEach(element => {
              if (element && element.fieldTitle && element.fieldTitle == pluginTitle) {
                if (element.customFieldsAnswerMappings && element.customFieldsAnswerMappings.length && element.customFieldsAnswerMappings[0].value) {
                  pluginEnable = element.customFieldsAnswerMappings[0].value;
                  resolve(pluginEnable);
                }
                else {
                  pluginEnable = false;
                  resolve(pluginEnable);
                }
              }
            });
          }
        }
        else {
          pluginEnable = false;
          resolve(pluginEnable);
        }
      }, (err) => {
        reject(err);
      });
    })
  }

  getAllCounts(storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ALL_COUNTS, { storeId: storeId });
    return this.httpRoutingService.getMethod(url)
  }
  /**
   * Method used to get  detail of mobile Application Plugin.
   * @param storeId has store Id.
   */
  getStoreadminSettings(data) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ONE_SUPER_ADMIN_SETTINGS, { code: data });
    return this.httpRoutingService.getMethod(url);
  }
  /**
   * Method is used to create store secrets
   */
  createStoreSecret(data: any) {
    return this.httpRoutingService.postMethod(API.GET_ALL_SUPER_ADMIN_SETTINGS, data);
  }

  /**
   * Mehtod is used to delete store secrets
   * @param id holds secrets id
   * @returns delete response
   */
  deleteSuperadminSettings(id: number) {
    return this.httpRoutingService.deleteMethod('superadminsettings/' + id);
  }

  /**
   * method is used get one getOneStoreSecret
   * @param data sends the data to be find
   * @returns url for the request
   */
  getOneStoreSecret(data: any, isSuperadmin?: boolean) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ONE_SUPER_ADMIN_SETTINGS, data);
    return this.httpRoutingService.getMethod(url, { isSuperadmin });
  }

  /**
  * Method used to update JSON for mobile application details.
  * @param data which holds storeId and themeId.
  * @param pageId which holds pageId
  */
  formatAndUpdateThemePagesForMobile(data: any, pageId?: number) {
    return this.httpRoutingService.putMethod(API.FORMAT_AND_UPDATE_MOBILE_PAGES, { storeData: data, pageId: pageId });
  }
  /**
   * Method used to fetch data of a plugin plan
   * @param id unique plugin id
   * @param storeId unique store id
   * @returns 
   */
  getExpiredPlugin(pluginId, planId, storeId) {
    const url = this.httpRoutingService.replaceUrlParam(API.PLUGIN_EXPIRE, {
      storeId: storeId, pluginId: pluginId, planId: planId
    });
    return this.httpRoutingService.getMethod(url);
  }

  /**
   * Method used to get all languages.
   * @param data has storeId.
   */
  getAllLanguages() {
    return this.httpRoutingService.getMethod(API.GET_LANGUAGES);
  }
  /**
   * Method used to get languges based on store.
   * @param data has storeId.
   */
  getAllStoreLanguages(storeId: number, isPublished?: boolean) {
    let query;
    if (isPublished) {
      query = { isPublished: isPublished };
    }
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ALL_STORELANGUAGE, { storeId: storeId });
    return this.httpRoutingService.getMethod(url, query);
  }
  /**
   * 
   * @param id log id
   * @returns the log data for the particular log
   */
  getOneMailLogs(id) {
    const url = this.httpRoutingService.replaceUrlParam(API.GET_ONE_MAIL_LOG, { id: id }); 
    return this.httpRoutingService.getMethod(url);
  }
  /**
   * Function used to get all plugin subscription details.
   * @param storeId 
   * @returns response
   */
  getPluginSubscriptionDetails(storeId: number) {
    const url = this.httpRoutingService.replaceUrlParam(API.PLUGIN_SUBSCRIPTION, { storeId: storeId });
    return this.httpRoutingService.getMethod(url);
  }
  /**
   * Function used for calculate plugin subscription.
   * @param data
   * @returns response
   */
  calculatePluginSubscription(data: { id: number, storeId: number, isRenewal: boolean }) {
    const url = this.httpRoutingService.replaceUrlParam(API.UPDATE_PLUGIN_SUBSCRIPTION, { storeId: data.storeId, id: data.id });
    delete data.id;
    delete data.storeId;
    return this.httpRoutingService.putMethod(url, data);
  }

  imageOptimization(originalImage: any, imageExtension: any, imageWidth?: any) {
    let formattedFileName!: string;
    if (originalImage && imageExtension) {
      originalImage = originalImage.replaceAll(" ","%20");
      if (!originalImage?.includes('base64') && !originalImage?.includes(',') && !originalImage?.includes('assets/')) {
        let path = originalImage.slice(0, originalImage.lastIndexOf('/') + 1);
        let fileName = originalImage.slice(originalImage.lastIndexOf('/') + 1, originalImage.lastIndexOf('.'));
        let originalExtension = originalImage.slice(originalImage.lastIndexOf('.') + 1);
        imageExtension = originalExtension === this.fileExtension.WEPB_FORMAT || originalExtension === this.fileExtension.AVIF_FORMAT ? originalExtension : (originalExtension === this.fileExtension.GIF_FORMAT && imageExtension === this.fileExtension.AVIF_FORMAT ?  this.fileExtension.WEPB_FORMAT : imageExtension);
        if (fileName && imageWidth && imageExtension) {
          formattedFileName = path + fileName + '-' + imageWidth + '.' + imageExtension;
        }
        else if (fileName && imageExtension) {
          formattedFileName = path + fileName + '.' + imageExtension;
        }
        return formattedFileName;
      }
      else {
        return originalImage;
      }
    }
  }


  /**
  * Method which is used to get all theme pages.
  * @returns Returns theme pages.
  */
  getAllTheme(searchText?: string) {
    let query = {};
    if (searchText)
      query = { searchText: searchText };
    query['isMobile'] = false;
    query['isExport'] = true;
    return this.httpRoutingService.getMethod('stores/themes', query);
  }

   /**
   * Method which is used to replace the translated date.
   * @param dateString it holds the date in string format.
   * @returns response
   */
   translateDate(dateString:string) {
    if(dateString){
      const monthPattern = /January|February|March|April|May|June|July|August|September|October|November|December|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec/i; 
      const monthFound = dateString.match(monthPattern);
      const getTranslationData = monthFound && monthFound.length ? this.translatService.getTranslation('COMMON.' + monthFound[0].toUpperCase()) : dateString;
      const translateDate = getTranslationData && monthFound ? dateString.replace(monthFound[0], getTranslationData): dateString;
      return translateDate;
    }
    return dateString;
  }

}
