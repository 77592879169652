import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'libs/themes/shared-theme/src/lib/services/shared.service';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { of, Subscription } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { ThemeConstants } from '../constants/theme.constant';
import { FbtProductsModel, FbtResolveModel, FbtResponseModel, NonVariantProductValuesModel, StorefrontFunctionNames } from '../models/storefront-function';
import { CommonDataService } from './common-data.service';
import { ProductDetailsService } from './product-details.service';
import { ThemesService } from './themes.service';
import { BulkOperationService } from './bulk-operation.service';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedConstant } from 'libs/common/src/lib/constants/shared-constant';
import { LoadTranslationService } from '@phase-ii/common';
import { HeaderService } from '@phase-ii/auth';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CommonPageService extends ThemeConstants implements StorefrontFunctionNames {

  /**
   * Variable used to subscribe and unsubscribe.
   * @type {Subscription}
   */
  subscriptionObj = new Subscription();
  /**
   * Variable used to check city or location filter.
   * @type {boolean}
   */
  isCity = true;
  /**
   * Variable which is used to search text.
   * @type {string}
   */
  searchText = '';
  /**
   * Variable used to store video extentions in array.
   * @type {Array}
   */
  videoExtensions = ['mp4', 'mov', 'wmv', 'webm'];
  /**
   * Variable used to store overlap heigth.
   */
  isHeaderOverlap: boolean;
  /**
   * Variable used to access methods inside CommonDataService.
   * @type {CommonDataService}
   */
  commonDataService: CommonDataService;
  /**
   * Variable used to get the url.
   * @type {Router}
   */
  router: Router;
  /**
* Variable used to set the featured products param.
* @type {any}
*/
  displayfeaturedProducts: any;
  sharedService: SharedService;
  productDetailsService: ProductDetailsService;
  themeService: ThemesService;
  dialogService: DialogService;
  snackBar: MatSnackBar;

  /**
   * Variable used to access methods inside BulkOperationService.
   * @type {BulkOperationService}
   */
  bulkOperationService: BulkOperationService;
  /**
   * Variable used to store environment details
   */
  environment: any;

  /**
   * variable used to hold the category list
   * @type {any}
   */
  productService: any;
  /**
   * Variable used to access the constant values.
   */
  commonConstant: SharedConstant;
  /**
   * Variable used to access methods inside HeaderService.
   * @type {HeaderService}
   */
  headerService: HeaderService;
  /**
   * Variable used to access livenotification feature availability.
   * @type {any}
   */
  liveNotification: any;
  /**
   * Variable used to access methods inside ActivatedRoute.
   * @type {ActivatedRoute}
   */
  route: ActivatedRoute;

  /**
   * Component constructor which is used to inject the required services.
   * @param sharedService To access methods inside SharedService.
   * @param productDetailsService To access methods inside ProductDetailsService.
   * @param themeService To access methods inside ThemesService.
   * @param dialogService To access methods inside DialogService.
   * @param snackBar To access methods inside MatSnackBar.
   * @param router To navigate to another component/page.
   */
  constructor(
    private injector: Injector,
    private translationService: LoadTranslationService,
    @Inject('environment') environment,
    @Inject(PLATFORM_ID) private platformId: object,
    private sanitizer: DomSanitizer
  ) {
    super();
    this.environment = environment;
    this.commonDataService = injector.get<CommonDataService>(CommonDataService);
    this.router = injector.get<Router>(Router);
    this.sharedService = injector.get<SharedService>(SharedService);
    this.productDetailsService = injector.get<ProductDetailsService>(ProductDetailsService);
    this.themeService = injector.get<ThemesService>(ThemesService);
    this.dialogService = injector.get<DialogService>(DialogService);
    this.bulkOperationService = injector.get<BulkOperationService>(BulkOperationService);
    this.snackBar = injector.get<MatSnackBar>(MatSnackBar);
    this.commonConstant = new SharedConstant();
    this.headerService = injector.get<HeaderService>(HeaderService);
    this.route = injector.get<ActivatedRoute>(ActivatedRoute);
  }

  /**
   * Method used to retrieve data dynamically to display details in storefront.
   * @param functionName To store the function name which is to be called.
   * @param data To store the component's values.
   * @param details To store the save page details.
   */
  getRequiredData(functionName: string, data: any, details?: any, sectionId?): Promise<any> {
    return details ? this[functionName](data, details, sectionId) : this[functionName](data);
  }
  /**
   * Method used to call functions based on data emitted from child components.
   * @param functionName To store the function name which is to be called.
   * @param event To store the event emitted fromt child component to parent component.
   * @param data To store the component's values.
   * @param details To store the save page details.
   */
  emittedData(functionName: string, event: any, data: any, details: any): Promise<any> {
    if (functionName != 'onDataEmit')
      data.isLoading = true;
    return this[functionName](event, data, details);
  }
  /**
   * Method used to retrieve featured categories basde on selected location.
   * @param data To store the component's values.
   */
  getAllFeaturedCategories(data: any): Promise<any> {
    const queryParam = {
      locationId: data.locationId
    }
    return new Promise((resolve, reject) => {
      this.subscriptionObj.add(this.themeService.getAllFeaturedCategories(data.sharedService.storeId, queryParam).subscribe((res: any) => {
        if (res && res.getCategories) {
          resolve(res.getCategories.length ? res.getCategories : []);
        } else {
          resolve([]);
        }
      }, (err) => {
        if (err) {
          // this.commonDialogBox(this.dialogMessages && this.dialogMessages.featuredCategoryFailure);
          this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.FEATURED_CATEGORY_FAILURE'));
          reject(err);
        }
      }))
    });
  }

  /**
   * Method used to retrieve available active locations of a store.
   * @param data To store the component's values.
   */
  getAllLocation(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptionObj.add(this.sharedService.getAllLocation(data.sharedService.storeId, this.searchText, this.isCity, data.sharedService.industryName).subscribe((res: any) => {
        if (res && res.storeLocation) {
          if (res.storeLocation.length) {
            res.storeLocation.map((item) => {
              item.latitude = Number(item.latitude);
              item.longitude = Number(item.longitude);
            });
            resolve(res.storeLocation);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      }, (err) => {
        if (err) {
          // this.commonDialogBox(this.dialogMessages && this.dialogMessages.locationFailure);
          this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.LOCATION_FAILURE'));
          reject(err);
        }
      }));
    });
  }
  /**
   * Method used to get testimonial data.
   * @param data To store the component's values.
   */
  getTestimonials(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptionObj.add(this.sharedService.getTestimonialDetails(data.sharedService.storeId).subscribe((res: any) => {
        if (res && res.testimonial) {
          resolve(res.testimonial);
        } else {
          resolve(true);
        }
      }, (err) => {
        if (err) {
          // this.commonDialogBox(this.dialogMessages && this.dialogMessages.testimonialFailure);
          this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.TESTIMONIAL_FAILURE'));
          reject(err);
        }
      }));
    });
  }
  /**
* Method used to get blog data.
* @param data To store the component's values.
*/
  getAllBlog(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptionObj.add(this.sharedService.getAllBlogDetails(data.sharedService.storeId).subscribe((res: any) => {
        if (res?.response?.rows?.length) {
          resolve(res.response.rows);
        } else {
          resolve(true);
        }
      }, (err) => {
        if (err) {
          this.dialogService.openSnackBar(this.dialogMessages && this.dialogMessages.blogFailure);
          reject(err);
        }
      }));
    });
  }
  /**
   * Method which is used to navigate to the pages.
   * @param event which holds the page name and id to navigate.
   */
  pageToNavigate(event: any): Promise<any> {
    return new Promise((resolve) => {
      if (event) {
        if (event.queryParam) {
          event.queryParam.filterDetails = event.queryParam.filterDetails ? this.commonDataService.setParam(JSON.stringify(event.queryParam.filterDetails)) : null;
        }
        if (event.productId) {
          this.sharedService.navigateTo(event.page, event.productId, event.queryParam);
        } else if (event.param) {
          this.sharedService.navigateTo(event.page, event.param, event.queryParam);
        } else {
          this.sharedService.navigateTo(event.page, null, event.queryParam);
        }
        resolve(true);
      } else {
        resolve(true);
      }
    });
  }
  checkStorefrontDomain() {
    if (isPlatformBrowser(this.platformId)) {
      return this.environment.storefrontUrl !== (window.location.origin + '/' + (this.environment.RegionUrl ? (this.environment.RegionUrl + '/') : ''));
    }
  }

  /**
   * Method which is used to display working hours and day.
   * @param data contains working time value.
   */
  setWorkingDays(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAllLocation(data).then((res) => {
        if (res && res.length > 0) {
          for (let i = 0; i < res.length; i++) {
            res[i]['workingHoursData'] = [];
            res[i]['workingHours'] = [];
            res[i]['closingHoursData'] = [];
            if (res[i].storeWorkingHours && res[i].storeWorkingHours.length > 0) {
              const value1 = this.formatWorkingDays(res[i].storeWorkingHours, data.weekDays);
              res[i].workingHoursData = value1.filter(item => item.isStoreActive);
              res[i].closingHoursData = value1.filter(item => !item.isStoreActive);
              if (i === res.length - 1) {
                resolve(res);
              }
            } else if (i === res.length - 1) {
              resolve(res);
            }
          }
        } else {
          resolve([]);
        }
      }).catch((err) => {
        this.commonDialogBox(this.translationService.getTranslation('SHARED.LOCATION_FAILURE'));
        reject(err);
      });
    });
  }
  /**
   * Method which is used to display working hours and day.
   * @param workingTime contains working time value.
   */
  formatWorkingDays(workingTime: any, weekDays: any) {
    if (workingTime) {
      const myarray = [];
      let skipIndex;
      let myarrayLength;
      let time;
      let start;
      const translatedWeekDays = weekDays.map(day => this.translationService.getTranslation('COMMON.' + day.toUpperCase()));
      for (let i = 0; i < workingTime.length; i++) {
        for (let j = 0; j < weekDays.length; j++) {
          if (workingTime[i][weekDays[j].toLowerCase()]) {
            time = workingTime[i].startTime + ' - ' + workingTime[i].endTime;
            if (j - 1 === skipIndex &&
              time === myarray[myarrayLength - 1].time
            ) {
              start = start + ' ' + translatedWeekDays[j];
              const dayArray = start.split(' ');
              start = dayArray[0] + ' - ' + dayArray[dayArray.length - 1];
              skipIndex = j;
              myarray[myarrayLength - 1] = { days: start, time: time, isStoreActive: workingTime[i].isStoreActive };
            } else {
              start = translatedWeekDays[j];
              skipIndex = j;
              myarray.push({ days: start, time: time, isStoreActive: workingTime[i].isStoreActive });
            }
            myarrayLength = myarray.length;
            if (j === weekDays.length - 1) {
              break;
            }
          }
        }
        if (i === workingTime.length - 1) {
          return myarray;
        }
      }
    }
  }
  /**
   * Method which is used get locations based on search value.
   * @param event which holds the search value to get locations.
   * @param data which holds the data values to search.
   */
  searchValue(event: any, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.searchText = event.target ? event.target.value : '';
      this.getAllLocation(data).then((res) => {
        resolve({ locationDetails: res });
      }).catch((err) => {
        reject(err);
      });
    });
  }
  /**
   * Method which is used search based on city or location.
   * @param event has the event to display city or location.
   */
  searchBy(event: any): Promise<any> {
    return new Promise((resolve) => {
      this.isCity = event;
      resolve(true);
    });
  }
  /**
   * Method used to retrieve featured products based on selected location.
   * @param data To store the component's values.
   */
  getAllFeaturedProducts(data: any, details?: any): Promise<any> {
    const productData = {
      offset: 0,
      limit: details && details.productCount ? details.productCount : 4,
      isFeatured: true,
      locationId: data.locationId
    };
    return new Promise((resolve, reject) => {
      this.getRetailHomeProducts('themeService', 'getAllFeaturedProducts', data.sharedService.storeId, productData).then((response: any) => {
        if (response) {
          resolve(response);
        }
      }, (err) => {
        if (err) {
          reject(err);
        }
      });
    });

  }
  /**
   * Method used to get top rated products.
   * @param data holds the store id and location id.
   */
  getTopRatedProducts(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getRetailHomeProducts('themeService', 'getTopRatedProducts', data.sharedService.storeId, data.locationId).then((topRatedResponse: any) => {
        if (topRatedResponse) {
          resolve(topRatedResponse);
        }
      }, (topRatedErr) => {
        if (topRatedErr) {
          reject(topRatedErr);
        }
      });
    });
  }

  getRelatedProducts(data: any): Promise<any> {
    const relatedProducts = { crosssellProducts: [], upsellProducts: [] };
    return new Promise((resolve, reject) => {
      if (data && data.locationId) {
        this.subscriptionObj.add(this.themeService.getRelatedProducts(data?.sharedService?.storeId, data?.productId, data?.locationId, data && data.userData && data.userData?.customerId ? data.userData.customerId : null).subscribe((response: any) => {
          if (response) {
            relatedProducts['crosssellProducts'] = response && response.relatedProducts && response.relatedProducts.crosssellProducts && response.relatedProducts.crosssellProducts.length ? response.relatedProducts.crosssellProducts : [],
              relatedProducts['upsellProducts'] = response && response.relatedProducts && response.relatedProducts.upsellProducts && response.relatedProducts.upsellProducts.length ? response.relatedProducts.upsellProducts : []
          }
          resolve({ relatedProducts: relatedProducts });
        }, (err) => {
          if (err) {
            reject(err);
          }
        }));
      }
    });
  }
  getData(data: any, value?) {
    const storeId = this.sharedService && this.sharedService.storeId ? this.sharedService.storeId : value;
    let isSingleSubscriptionValue: any, subscriptionFrequency: any, isSubscription = false;
    const frequencyArray = [];
    return new Promise((resolve, reject) => {
      if (storeId) {
        this.subscriptionObj.add(this.sharedService.getPluginDetails(storeId).pipe(mergeMap((res: any) => {
          if (res && res.Status && res.Status.featureAvailability) {
            isSubscription = isSingleSubscriptionValue = res.Status.featureAvailability;
            if (data.assignData && data.assignData.productDetails && data.assignData.productDetails.isSubscription) {
              return this.sharedService.getSubscriptionSetting(storeId);
            }
          }
          return of(null);
        }), mergeMap((res: any) => {
          if (res && res['subscriptionSettings']) {
            subscriptionFrequency = res['subscriptionSettings'].frequency ? res['subscriptionSettings'].frequency : [];
            return this.sharedService.getFrequency(storeId);
          }
          return of(null);
        })).subscribe((res: any) => {
          if (res && res.result && res.result.rows) {
            const frequencyValue = res.result && res.result.rows;
            for (let i = 0; i < frequencyValue.length; i++) {
              for (let j = 0; j < subscriptionFrequency.length; j++) {
                if (subscriptionFrequency[j] === frequencyValue[i].id)
                  frequencyArray.push({ id: frequencyValue[i].id, deliver_frequency: frequencyValue[i].deliver_frequency })
              }
            }
            resolve({ isSingleSubscriptionValue: isSingleSubscriptionValue, frequencyArray: frequencyArray, isSubscription: isSubscription });
          } else {
            resolve({ isSingleSubscriptionValue: isSingleSubscriptionValue, frequencyArray: [], isSubscription: isSubscription });
          }
        }, (err) => {
          if (err) {
            reject(err);
          }
        }));
      }
    });
  }

  /**
   * Method used to get best seller products.
   * @param data holds the store id and location id.
   */
  getBestSellerProducts(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getRetailHomeProducts('themeService', 'getBestSellerProducts', data.sharedService.storeId, data.locationId).then((bestSellerResponse: any) => {
        if (bestSellerResponse) {
          resolve(bestSellerResponse);
        }
      }, (bestSellerErr) => {
        if (bestSellerErr) {
          reject(bestSellerErr);
        }
      });
    });
  }
  /**
   * Method used to retrieve featured products based on selected location.
   * @param data To store the component's values.
   */
  getFeaturedProducts(data: any): Promise<any> {
    const productData = {
      offset: 0,
      limit: 6,
      isFeatured: true,
      locationId: data.locationId
    };
    return new Promise((resolve, reject) => {
      this.getRetailHomeProducts('themeService', 'getAllFeaturedProducts', data.sharedService.storeId, productData).then((featuredResponse: any) => {
        resolve(featuredResponse);
      }, (featuredErr) => {
        if (featuredErr) {
          reject(featuredErr);
        }
      });
    });
  }
  /**
   * Method used to get product response by dynamic function names.
   * @param serviceName which holds service name.
   * @param functionName which holds function name.
   * @param storeId which holds store id.
   * @param productData which holds product data.
   * @returns api response.
   */
  getRetailHomeProducts(serviceName: string, functionName: string, storeId: number, productData: any) {
    return new Promise(async (resolve, reject) => {
      if (serviceName && functionName) {
        await this.subscriptionObj.add(this[serviceName][functionName] && this[serviceName][functionName](storeId, productData) && this[serviceName][functionName](storeId, productData).subscribe((res: any) => {
          if (res && res.getProducts) {
            resolve(res.getProducts.length ? res.getProducts : []);
          } else {
            resolve(false);
          }
        }, (err) => {
          if (err) {
            // this.commonDialogBox(this.dialogMessages && this.dialogMessages.featuredProductFailure);
            this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.FAILED_LOAD_FEATURED_PRODUCTS'));
            resolve(false);
          }
        }))
      }

    });
  }
  /**
   * Method used to retrieve products based on selected tag index.
   * @param data To store the component's values.
   * @param details To store the tag details.
   */
  async getTagProductDetails(data: any, details: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const tagProductDetails = { featured: [], topRated: [], bestSeller: [] };
      for (let i = 0; i < details.tags.length; i++) {
        let methodName;
        if (i === 0) {
          methodName = this.productType.featuredProduct
        } else {
          methodName = (i === 1 ? this.productType.topRated : this.productType.bestSeller);
        }
        const arrayTagValue = [];
        arrayTagValue.push(this[methodName](data));
        await Promise.all(arrayTagValue).then((response) => {
          tagProductDetails[details.tags[i].propertyName] = response[0];
        }).catch((err) => {
          reject(err);
        });
      }
      resolve({ tagProductDetails, details });
    });
  }
  /**
   * Method used to get products based on categories for coffee theme.
   * @param data To store the component's values.
   * @param details To store the published page details.
   */
  getCategoryforCoffee(data: any, details: any): Promise<any> {
    let categoryId, categoryIdArray;
    return new Promise((resolve, reject) => {
      this.sharedService.productCategoryId.pipe(filter((productCategoryResponse) => {
        if (productCategoryResponse && productCategoryResponse['categoryId'] && productCategoryResponse['categoryId'].length > 0 && data.locationId) {
          categoryId = productCategoryResponse['categoryId'][0];
          categoryIdArray = productCategoryResponse['categoryId'];
        }
        return true;
      }), mergeMap(() => {
        return this.sharedService.getAllCategories(data['sharedService'].storeId, data.locationId, data.timeZone);
      })).subscribe((res) => {
        categoryId = categoryId ? categoryId : 0;
        if (res && res['category']) {
          let categoryList = res['category'];
          const categoryIds = [];
          categoryList.forEach((item) => {
            categoryIds.push(item.id);
            if (item.subCategory && item.subCategory.length) {
              item.subCategory.forEach((subCategory) => {
                categoryIds.push(subCategory.id);
                if (subCategory.subCategory && subCategory.subCategory.length) {
                  subCategory.subCategory.forEach((innerSubCategory) => {
                    categoryIds.push(innerSubCategory.id);
                  });
                }
              });
            }
          });
          if (categoryId === 0) {
            categoryIdArray = categoryIds;
          }
          categoryList = [...[{ id: 0, categoryName: "All Categories", categoryIdArray: categoryIds }], ...categoryList];
          let categoryName;
          categoryList.find((category) => {
            if (categoryId === category.id) {
              categoryName = category.categoryName;
              return true;
            } else {
              if (category.subCategory && category.subCategory.length > 0) {
                category.subCategory.find((subCategory) => {
                  if (categoryId === subCategory.id) {
                    categoryName = subCategory.categoryName;
                    return true;
                  }
                });
              }
            }
          });
          const selectedDetails = {
            categoryDetails: data.assignData.selectedDetails && data.assignData.selectedDetails.categoryDetails ? data.assignData.selectedDetails.categoryDetails : categoryList[0],
            searchText: data.assignData.selectedDetails && data.assignData.selectedDetails.searchText ? data.assignData.selectedDetails.searchText : null,
            filterDetails: data.assignData.selectedDetails && data.assignData.selectedDetails.filterDetails ? data.assignData.selectedDetails.filterDetails : {}
          };
          const assignData = {
            selectedDetails: selectedDetails,
            foodSelectedCategoryId: 0,
            paginationDetails: { offset: 0, limit: details.productCount }
          };
          data.assignData = { ...assignData, ...data.assignData };
          this.getProductsByCategory(details, data).then((productResponse: any) => {
            resolve({
              categoryList: categoryList,
              selectedDetails: selectedDetails,
              menuCategoryAvailability: res['menuCategoryAvailability'],
              productArray: productResponse.productArray,
              paginationDetails: productResponse.paginationDetails,
              foodSelectedCategoryId: productResponse.foodSelectedCategoryId
            });
          });
        }
      }, (err) => {
        if (err) {
          this.commonDialogBox(this.translationService.getTranslation('SHARED.FAILED_TO_GET_COFFEE'));
          reject(err);
        }
      })
    });
  }
  /**
   * Method used to get products based on categories except coffee theme.
   * @param data To store the component's values.
   * @param details To store the published page details.
   */
  getCategories(data: any, details: any): Promise<any> {
    let selectedCategory, categoryIndex;
    return new Promise((resolve, reject) => {
      this.productDetailsService.getCategories(data.locationId, data.timeZone, data.sharedService && data.sharedService.storeId).then((response: any) => {
        let categoryList = [{ id: 0, categoryImageUrl: "themes/retail2/allProductsImage.png", categoryName: this.translationService.getTranslation('SHARED.ALL_PRODUCTS'), categoryIdArray: response.categoryIds, category: { id: 0, name: this.translationService.getTranslation('SHARED.ALL_PRODUCTS') } }];
        if (response) {
          categoryList = [...categoryList, ...response.categoryList];
          if (data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.categoryId >= 0) {
            categoryIndex = this.sharedService.findCategoryIndexForAllLevels(categoryList, data.assignData.selectedDetails.categoryId);
            if (categoryIndex) {
              selectedCategory = this.sharedService.categoryIndexFind(categoryList, categoryIndex.i, categoryIndex.j, categoryIndex.k);
            } else {
              selectedCategory = this.sharedService.categoryIndexFind(categoryList, 0, null, null);
            }
          } else if (data.isFeaturedProductsPage) {
            selectedCategory = categoryList[0];
          } else {
            selectedCategory = this.sharedService.categoryIndexFind(categoryList, 0, null, null);
          }
          const selectedDetails = {
            categoryDetails: selectedCategory,
            searchText: data.assignData.selectedDetails && data.assignData.selectedDetails.searchText ? data.assignData.selectedDetails.searchText : null,
            filterDetails: data.assignData.selectedDetails && data.assignData.selectedDetails.filterDetails ? data.assignData.selectedDetails.filterDetails : {},
            categoryId: data.assignData.selectedDetails && data.assignData.selectedDetails?.categoryId >= 0 ? data.assignData.selectedDetails.categoryId : null,
            discountId: data.assignData.selectedDetails && data.assignData.selectedDetails?.discountId ? data.assignData.selectedDetails.discountId : null
          };
          if (data.isFeaturedProductsPage) {
            selectedDetails['categoryId'] = 0;
          } else {
            selectedDetails['categoryId'] = data.assignData.selectedDetails && data.assignData.selectedDetails?.categoryId >= 0 ? data.assignData.selectedDetails.categoryId : null;
          }
          const assignData = {
            selectedDetails: selectedDetails,
            foodSelectedCategoryId: 0,
            paginationDetails: {
              offset: 0,
              limit: details.productCount ? details.productCount : 20
            }
          }
          data.assignData = { ...data.assignData, ...assignData };
          this.getProductsByCategory(details, data).then((productResponse: any) => {
            if (productResponse) {
              resolve({
                categoryList: categoryList,
                selectedDetails: selectedDetails,
                menuCategoryAvailability: response.menuCategoryAvailability,
                productArray: productResponse.productArray,
                paginationDetails: productResponse.paginationDetails,
                foodSelectedCategoryId: productResponse.foodSelectedCategoryId
              });
            }
          }).catch((err1) => {
            this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
            if (err1) {
              // this.commonDialogBox(this.dialogMessages && this.dialogMessages.productByCategoryFailure);
              this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.FAILED_PRODUCTS_CATEGORY'));
              reject(err1);
            }
          });
        } else {
          resolve(true);
        }
      }).catch((err) => {
        if (err) {
          // this.commonDialogBox(this.dialogMessages && this.dialogMessages.categoryFailure);
          this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.CATEGORY_FAILURE'));
          reject(err);
        }
      });
    });
  }
  /**
   * Method used to set assign data.
   * @param data To define the data from the parent.
   * @returns the query param object.
   */
  setAssignData(data: any): object {
    return {
      offset: data && data.assignData && data.assignData.paginationDetails && data.assignData.paginationDetails.offset ? data.assignData.paginationDetails.offset : 0,
      limit: data && data.assignData && data.assignData.paginationDetails && data.assignData.paginationDetails.limit ? data.assignData.paginationDetails.limit : 20,
      filterData: data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.filterDetails && data.assignData.selectedDetails.filterDetails !== null ? JSON.stringify(data.assignData.selectedDetails.filterDetails) : {},
      customerId: data && data.userData && data.userData.customerId ? data.userData.customerId : null,
      searchText: data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.searchText ? data.assignData.selectedDetails.searchText : "",
      locationId: data && data.locationId ? data.locationId : null,
      categoryId: data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.categoryDetails && data.assignData.selectedDetails.categoryDetails.categoryIdArray ? JSON.stringify(data.assignData.selectedDetails.categoryDetails.categoryIdArray) : null,
      isFeaturedProduct: data && data.isFeaturedProductsPage ? data.isFeaturedProductsPage : false,
      isOrder: data && data.orderId ? true : false,
      isDiscount: data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.discountId ? true : false
    };
  }
  /**
   * Method used to get the products based on category.
   * @param data To store the component's values.
   * @param details To store the published page details.
   */
  getProductsByCategory(details: any, data: any): Promise<any> {
    this.sharedService.commonLoader && this.sharedService.commonLoader.next(true);
    if (sessionStorage.getItem('featuredProductPage')) {
      this.displayfeaturedProducts = JSON.parse(sessionStorage.getItem('featuredProductPage'));
      data['isFeaturedProductsPage'] = this.displayfeaturedProducts;
    }
    const queryParams = this.setAssignData(data);
    if (data.assignData.foodSelectedCategoryId > 0) {
      queryParams['foodType'] = JSON.stringify({ isVeg: data.assignData.foodSelectedCategoryId === 1 ? true : false });
    }
    if (data && data.assignData && data.assignData.selectedDetails && (data.assignData.selectedDetails.categoryId == 0 || data.assignData.selectedDetails.categoryId === null)) {
      delete queryParams['categoryId'];
    }
    return new Promise((resolve, reject) => {
      if (queryParams && queryParams['locationId'] != null) {
        this.productDetailsService.getProductsByCategory(data.sharedService.storeId, queryParams, false).subscribe(async (response: any) => {
          if (response) {
            const paginationDetails: any = {};
            if (response.productData && response.productData.length) {
              if (response.productData.length === (data?.assignData?.paginationDetails?.limit ? Number(data?.assignData?.paginationDetails?.limit) : Number(details?.productCount))) {
                paginationDetails.itemsCount = true;
              } else {
                paginationDetails.itemsCount = false;
              }
              paginationDetails['listDataSource'] = data.assignData && data.assignData.paginationDetails && data.assignData.paginationDetails.listDataSource && data.assignData.paginationDetails.listDataSource.length > 0 ? data.assignData.paginationDetails.listDataSource.concat(response.productData) : response.productData;
            } else {
              paginationDetails.itemsCount = false;
              if (data.assignData && data.assignData.paginationDetails && data.assignData.paginationDetails.listDataSource && data.assignData.paginationDetails.listDataSource.length === 0) {
                paginationDetails['listDataSource'] = [];
              } else {
                paginationDetails['listDataSource'] = data?.assignData?.paginationDetails?.listDataSource;
              }
            }
            if (data?.assignData?.paginationDetails?.limit) {
              paginationDetails.limit = data.assignData.paginationDetails.limit;
            } else {
              paginationDetails.limit = details && details.productCount ? details.productCount : 20
            }
            paginationDetails.offset = data.assignData && data.assignData.paginationDetails && data.assignData.paginationDetails.offset ? data.assignData.paginationDetails.offset : 0;
            this.productTaxCalculation(paginationDetails?.listDataSource, response.zones, data).then((res) => {
              resolve({ paginationDetails, productArray: res && res.length ? res : [], selectedCategory: data.assignData.selectedDetails.categoryDetails, searchText: data.assignData.selectedDetails.searchText, filterDetails: data.assignData.selectedDetails.filterDetails, foodSelectedCategoryId: data.assignData.foodSelectedCategoryId });
            });
          } else {
            this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
            resolve(false);
          }
        }, (productCategoryErr) => {
          if (productCategoryErr) {
            this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
            reject(productCategoryErr);
          }
        });
      } else {
        this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
        resolve(false);
      }
    });
  }
  /**
    * Method used to get products based on specific category.
    * @param data To store the component's values.
    * @param details To store the published page details.
    */
  getProductCategories(data: any, details: any) {
    this.sharedService.categoryList.subscribe((response: any) => {
      if (response) {
        this.productService = response;
      }
    })
    const queryParams = {
      offset: data && data.assignData && data.assignData.paginationDetails && data.assignData.paginationDetails.offset ? data.assignData.paginationDetails.offset : 0,
      limit: data && data.assignData && data.assignData.paginationDetails && data.assignData.paginationDetails.limit ? data.assignData.paginationDetails.limit : 10,
      customerId: data && data.userData && data.userData.customerId ? data.userData.customerId : null,
      locationId: data && data.locationId ? data.locationId : null,
      categoryId: details.templateSettings?.displaySpecificProduct?.id ? details.templateSettings.displaySpecificProduct.id : null
    };
    return new Promise((resolve, reject) => {
      if (queryParams && queryParams['locationId'] != null && queryParams['categoryId'] !== null) {
        if (!data.isStoreEditor == true)
          queryParams.categoryId = JSON.stringify([queryParams.categoryId]);
        this.productDetailsService.getProductsByCategory(data.sharedService.storeId, queryParams, data.isStoreEditor).toPromise().then((response: any) => {
          if (response) {
            resolve(response)
          }
          else {
            resolve([])
          }
        }).catch((err) => {
          if (err)
            reject(err);
        });
      } else {
        resolve([])
      }
    })
  }
  /**
   * Method used to get products and filter details.
   * @param data To store the component's values.
   * @param details To store the published page details.
   */
  getProductAndFilterDetails(data: any, details: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getProductsByCategory(details, data).then((productResponse) => {
        if (productResponse) {
          this.getFilterDetails(data, data.assignData.productPageDetails).then((filterResult) => {
            resolve({
              productArray: productResponse.productArray,
              paginationDetails: productResponse.paginationDetails,
              foodSelectedCategoryId: productResponse.foodSelectedCategoryId,
              details: filterResult.details,
              productPageDetails: filterResult.productPageDetails
            });
          });
        } else {
          resolve(false);
        }
      }).catch((filterErr) => {
        if (filterErr) {
          reject(filterErr);
        }
      });
    });
  }
  /**
   * Method used to set search text and get products based on search text.
   * @param event has the value of search string.
   * @param data To store the component's values.
   * @param details To store the published page details.
   */
  searchTextEmit(event: string, data: any, details: any): Promise<any> {
    if (data?.assignData) {
      data.assignData['paginationDetails']['listDataSource'] = [];
      data.assignData['selectedDetails'] = event;
      data.assignData['foodSelectedCategoryId'] = 0;
      data.assignData['paginationDetails']['offset'] = 0;
    }
    return new Promise((resolve, reject) => {
      this.getProductsByCategory(details, data).then((res) => {
        if (res) {
          res = { ...res, ...{ selectedDetails: event, productPageDetails: data.assignData.productPageDetails } };
          resolve(res);
        } else {
          resolve(false);
        }
      }).catch((err) => {
        if (err) {
          this.commonDialogBox(this.translationService.getTranslation('SHARED.SEARCH_FAILED'));
          reject(err);
        }
      });
    });
  }
  /**
   * Method which is emitted from child component to give search results.
   * @param event defines the searched values.
   * @param data To store the component's values.
   * @param details To store the published page details.
   */
  searchTextEmitRetail(event: string, data: any, details: any): Promise<any> {
    if (data?.assignData) {
      data.assignData['paginationDetails']['listDataSource'] = [];
      data.assignData['selectedDetails'] = event;
      data.assignData['foodSelectedCategoryId'] = 0;
      data.assignData['paginationDetails']['offset'] = 0;
    }
    return new Promise((resolve, reject) => {
      return this.getProductAndFilterDetails(data, details).then((response) => {
        response = { ...response, ...{ selectedDetails: event } };
        resolve(response);
      }).catch((err1) => {
        if (err1) {
          // this.commonDialogBox(this.dialogMessages && this.dialogMessages.searchTextFailure);
          this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.SEARCH_FAILED'));
          reject(err1);
        }
      });
    });
  }
  /**
   * Method which is emitted from child component to give filtered array.
   * @param event defines the filtered values of filter array.
   * @param data To store the component's values.
   * @param details To store the published page details.
   */
  filterAction(event: any, data: any, details: any): Promise<any> {
    let selectedCategory, categoryIndex;
    if (data?.assignData) {
      data.assignData['paginationDetails']['listDataSource'] = [];
      data.assignData['selectedDetails'] = event;
      data.assignData['paginationDetails']['offset'] = 0;
    }
    if (data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.categoryId >= 0 && data.assignData.selectedDetails.categoryId !== null) {
      categoryIndex = this.sharedService.findCategoryIndexForAllLevels(data.assignData.categoryList, data.assignData.selectedDetails.categoryId);
      if (categoryIndex) {
        selectedCategory = this.sharedService.categoryIndexFind(data.assignData.categoryList, categoryIndex.i, categoryIndex.j, categoryIndex.k);
      } else {
        selectedCategory = this.sharedService.categoryIndexFind(data.assignData.categoryList, 0, null, null);
      }
    } else {
      selectedCategory = this.sharedService.categoryIndexFind(data.assignData.categoryList, 0, null, null);
    }
    event.categoryDetails = selectedCategory;
    return new Promise((resolve, reject) => {
      return this.getProductsByCategory(details, data).then((response) => {
        resolve({ ...response, ...{ selectedDetails: event } });
      }).catch((err) => {
        if (err) {
          this.commonDialogBox(this.translationService.getTranslation('SHARED.FAILED_GET_FILTERED_PRPODUCTS'));
          reject(err);
        }
      });
    });
  }
  /**
   * Method used to load more product list.
   * @param event defines the pagination details.
   * @param data To store the component's values.
   * @param details To store the published page details.
   */
  onDataEmit(event: any, data: any, details: any): Promise<any> {
    if (event && data && data.assignData && data.assignData.paginationDetails && data.assignData.paginationDetails.itemsCount) {
      data.assignData.paginationDetails.offset = Number(data.assignData.paginationDetails.offset) + Number(data.assignData.paginationDetails.limit);
      const selectedDetails = {
        categoryDetails: data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.categoryDetails,
        searchText: data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.searchText,
        filterDetails: data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.filterDetails ? data.assignData.selectedDetails.filterDetails : {},
      }
      selectedDetails['categoryId'] = data.assignData.selectedDetails.categoryId;
      data.assignData.selectedDetails = selectedDetails;
      return new Promise((resolve, reject) => {
        return this.getProductsByCategory(details, data).then((dataResponse) => {
          this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
          resolve({ ...dataResponse, ...{ selectedDetails: selectedDetails } });
        }).catch((err) => {
          if (err) {
            this.commonDialogBox(this.translationService.getTranslation('SHARED.FAILED_LOAD_PRODUCTS'));
            reject(err);
          }
        });
      });
    } else {
      return Promise.resolve(false);
    }
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to add product to cart from product list page.
   * @param event defines the cart product details.
   * @param data To store the component's values.
   */
  addToCartProduct(event: any, data: any): Promise<any> {
    let locationDetails;
    if (data && data.locationId && data.timeZone) {
      locationDetails = { locationId: data.locationId, timeZone: data.timeZone };
    }
    return new Promise((resolve, reject) => {
      if (event && event.product) {
        let countValue;
        this.sharedService.cartCountValue.subscribe((res) => {
          countValue = res;
        });
        if (data.userData && data.userData.customerId && !data.userData.shopappUser) {
          let cartSubscription;
          if (!event.update) {
            cartSubscription = this.themeService.addToCart(event.cartDetails, this.sharedService.storeId, data.userData.customerId)
          } else {
            cartSubscription = event.cartDetails.quantity === 0 ? this.themeService.removeCartProducts(this.sharedService.storeId, event.cartDetails.cartProductId) :
            this.themeService.updateCart(event.cartDetails, this.sharedService.storeId, event.cartDetails.cartProductId);
          }
          if (cartSubscription) {
            cartSubscription.subscribe((response: any) => {
              if (response) {
                if (response.cartData && response.cartData.isAdded) {
                  this.openCommonSnackbar(this.translationService.getTranslation('SHARED.ADD_TO_CART'), 'success');
                  if (event.cartDetails) {
                    if (!event.cartDetails.weightUnit || (event.cartDetails.weightUnit && (event.cartDetails.weightUnit === 'count' || event.cartDetails.weightUnit === 'item'))) {
                      this.sharedService.cartCountValue.next(countValue + (event.cartDetails.quantity ? event.cartDetails.quantity : 0));
                    }
                    else {
                      this.sharedService.cartCountValue.next(countValue + 1);
                    }
                  }
                  event.product['cartProductId'] = response.cartData.cartProductId;
                  if (event.isChange) {
                    if (!(Array.isArray(event.cartProducts)))
                      event.cartProducts = [];
                    event.cartProducts.push({
                      productId: event.cartDetails && event.cartDetails.productId ? event.cartDetails.productId : null,
                      variantId: event.cartDetails && event.cartDetails.variantId ? event.cartDetails.variantId : null,
                      combinationName: event.cartDetails && event.cartDetails.combinationName ? event.cartDetails.combinationName : null,
                      id: response.cartData.cartProductId ? response.cartData.cartProductId : null,
                      quantity: event.cartDetails && event.cartDetails.quantity ? event.cartDetails.quantity : 0
                    });
                  }
                  resolve(event.isChange ? { cartProducts: event.cartProducts } : true);
                }
                else if (response.cartData && response.cartData.isDeleted) {
                  event.product.cartProductId = null;
                  this.openCommonSnackbar(this.translationService.getTranslation('SHARED.REMOVE_FROM_CART'), 'success');
                  if (event.isChange && event.cartProducts) {
                    const index = event.cartProducts.findIndex((cartValues) => {
                      return cartValues && event.product.product && event.product.product.id === cartValues.productId && (cartValues.variantId === event.product.id) && (event.product.variantName === cartValues.combinationName);
                    });
                    if (index >= 0) {
                      event.cartProducts.splice(index, 1);
                    }
                  }
                  if (event.cartDetails) {
                    if ((!event.cartDetails.weightUnit || (event.cartDetails.weightUnit && (event.cartDetails.weightUnit === 'count' || event.cartDetails.weightUnit === 'item'))) && event.cartDetails.weightCount) {
                      this.sharedService.cartCountValue.next(countValue - event.cartDetails.weightCount);
                    }
                    else {
                      this.sharedService.cartCountValue.next(countValue - 1);
                    }
                  }
                  resolve(event.isChange ? { cartProducts: event.cartProducts } : true);
                }
                else {
                  this.openCommonSnackbar(this.translationService.getTranslation('SHARED.UPDATED_CART'), 'success');
                  if (event.isChange && event.cartProducts) {
                    const index = event.cartProducts.findIndex((cartValues) => {
                      return cartValues && event.product.product && event.product.product.id === cartValues.productId && cartValues.variantId === event.product.id && event.product.variantName === cartValues.combinationName;
                    });
                    if (index >= 0) {
                      event.cartProducts[index].quantity = event.cartDetails && event.cartDetails.quantity;
                    }
                  }
                  if (event.cartDetails) {
                    if ((!event.cartDetails.weightUnit || (event.cartDetails.weightUnit && (event.cartDetails.weightUnit === 'count' || event.cartDetails.weightUnit === 'item'))) && event.cartDetails.weightCount) {
                      event.cartDetails.actionType === '+' ? this.sharedService.cartCountValue.next(countValue + event.cartDetails.weightCount) : this.sharedService.cartCountValue.next(countValue - event.cartDetails.weightCount);
                    }
                  }
                }
                event.product.quantity = event.cartDetails && event.cartDetails.quantity;
                resolve(event.isChange ? { cartProducts: event.cartProducts } : true);
              }
            }, (err: any) => {
              if (err) {
                this.commonDialogBox(this.translationService.getTranslation('SHARED.ERROR_PLEASE_TRY_AGAIN'));
                reject(err);
              }
            });
          }
        } else {
          this.sharedService.updateGuestUserCart(event.cartDetails, locationDetails).then((res: any) => {
            event.cartProducts = res ?? null;
            event.product.quantity = event.cartDetails && event.cartDetails.quantity;
            resolve(event.isChange ? { cartProducts: event.cartProducts } : true);
          });
        }
      }
    });
  }
  /**
   * Method used to return default food categories.
   */
  getFoodCategories(): Promise<any> {
    return Promise.resolve(this.restaurantFoodCategories);
  }
  /**
   * Method used to retrieve food category.
   * @param event defines the selected food category.
   * @param data To store the component's values.
   * @param details To store the published page details.
   */
  getFoodCategory(event: any, data: any, details: any): Promise<any> {
    if (data?.assignData) {
      data.assignData['paginationDetails']['listDataSource'] = [];
      data.assignData['foodSelectedCategoryId'] = event && event.selectedCategoryId ? event.selectedCategoryId : 0;
      data.assignData['paginationDetails']['offset'] = 0;
    }
    return new Promise((resolve, reject) => {
      return this.getProductsByCategory(details, data).then((foodCategory) => {
        resolve(foodCategory);
      }).catch((err) => {
        if (err) {
          this.commonDialogBox(this.translationService.getTranslation('SHARED.FAILED_PRODUCTS_CATEGORY'));
          reject(err);
        }
      });
    });
  }
  /**
   * Method used to calculate the product inclusive tax.
   * @param productArray To store the product list.
   * @param zones To store the zone details
   * @param data To store the component's values.
   */
  productTaxCalculation(productArray: any, zones: any, data: any): Promise<any> {
    return new Promise((resolve) => {
      if (productArray && productArray.length) {
        productArray.forEach(val => {
          val.productWithOptionPrice = val.price;
        });
      }
      const taxResponse = this.sharedService.taxCalculation(productArray, zones, data.userData && data.userData.address && data.userData.address[0]);
      resolve(taxResponse && taxResponse.products);
    });
  }
  /**
   * Method used to get the store settings details.
   */
  getWebsiteSettings(): Promise<any> {
    return new Promise((resolve) => {
      this.subscriptionObj.add(this.sharedService.commonWebsiteDetails.subscribe(res => {
        if (res) {
          resolve({
            websiteSettings: { ...res, ...{ currencyCode: res.currency && res.currency.currencyCode } }
          });
        } else {
          resolve(true);
        }
      }));
    });

  }
  /**
   * Method used to get the ordered product list.
   */
  getProductOrders(): Promise<any> {
    return new Promise((resolve) => {
      this.subscriptionObj.add(this.sharedService.changedNewOrders.subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          resolve(localStorage.getItem('orderedProducts') && JSON.parse(localStorage.getItem('orderedProducts')));
        }
      }));
    });
  }
  /**
   * Method used to add product in waitlist.
   * @param event defines the product detail.
   * @param data To store the component's values.
   */
  addToWaitList(event: any, data: any): Promise<any> {
    return new Promise((resolve) => {
      if (event && event.productId) {
        const waitListDetails = { customerId: data.userData && data.userData.customerId ? data.userData.customerId : null, locationId: data.locationId, productId: event.productId, variantId: event.variantId };
        resolve(this.productDetailsService.addToWaitList(waitListDetails));
      } else {
        resolve(true);
      }
    });
  }
  /**
   * Method used to add/remove product to/from customer's wishlist.
   * @param event defines the product detail.
   * @param data To store the component's values.
   */
  addRemoveFromWishlist(event: any, data: any): Promise<any> {
    return new Promise((resolve) => {
      if (data.userData && data.userData.customerId && data.userData.storeId && data.assignData) {
        if (!event?.isAddedToFav) {
          data.assignData['isWishlist'] = false;
        } else {
          data.assignData['isWishlist'] = true;
        }
        resolve({ wishlist: this.productDetailsService.addToWishlist({ customerId: data.userData.customerId, storeId: data.userData.storeId }, event), isWishlist: data.assignData['isWishlist'] });
      } else {
        resolve(true);
      }
    });
  }
  /**
   * Method used to get brand and variant filter data based on selected category.
   * @param data To store the component's values.
   * @param details To store the published page details.
   */
  getFilterDetails(data: any, details: any): Promise<any> {
    this.sharedService && this.sharedService.commonLoader && this.sharedService.commonLoader.next(true);
    return new Promise(async (resolve, reject) => {
      let productDetails = details && details.hasOwnProperty('productListDetails') ? details && details.productListDetails : details;
      if (productDetails && productDetails.showFilterAndSort && productDetails.filterData && productDetails.filterData.showFilterOption) {
        await this.getBrands(data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.categoryDetails, data).then(brand => {
          const brandFilterIndex = productDetails && productDetails.filterData && productDetails.filterData.filterArray && productDetails.filterData.filterArray.findIndex((item) => item && item.fieldName === 'brandId');
          productDetails = this.subGetFilterDetails(brand, brandFilterIndex, productDetails);
        }).catch((err) => {
          reject(err);
        });
        if (data && data.assignData && data.assignData.websiteSettings && data.assignData.websiteSettings.isOptionsVariantsEnabled) {
          await this.getVariantOptions(data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.categoryDetails, data).then(variant => {
            productDetails.filterData.filterArray = productDetails && productDetails.filterData && productDetails.filterData.filterArray && productDetails.filterData.filterArray.filter(val => val?.optionType !== 'Variant Options');
            if (variant && variant.length > 0) {
              variant.forEach((filterData) => {
                productDetails && productDetails.filterData && productDetails.filterData.filterArray && productDetails.filterData.filterArray.push(filterData);
              });
            }
          }).catch((err) => {
            reject(err);
          });
        }
        this.sharedService && this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
        resolve({ details, productPageDetails: details });
      } else {
        this.sharedService && this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
        resolve({ productPageDetails: details });
      }
    })
  }
  /**
   * Method used to get brand filter details.
   * @param brand To store brand details.
   * @param brandFilterIndex To store particular index of the brand.
   * @param details To store the published page details.
   * @returns
   */
  subGetFilterDetails(brand: any, brandFilterIndex: number, details: any) {
    if (brand && brand != null) {
      if (brandFilterIndex >= 0) {
        details.filterData.filterArray[brandFilterIndex] = brand;
      } else {
        details.filterData.filterArray.push(brand);
      }
    } else if (brand == null && brandFilterIndex >= 0) {
      details.filterData.filterArray.splice(brandFilterIndex, 1);
    }
    return details;
  }
  /**
   * Method used to get the selected category products from parent component except retail theme.
   * @param event defines the value of the selected category details.
   * @param data To store the component's values.
   * @param details To store the published page details.
   */
  selectedCategoryDetails(event: any, data: any, details: any): Promise<any> {
    let categoryIndex, selectedCategory;
    return new Promise((resolve, reject) => {
      if (data.assignData) {
        data.assignData['selectedDetails'] = event;
        data.assignData['paginationDetails']['listDataSource'] = [];
        data.assignData['paginationDetails']['offset'] = 0;
      }
      if (data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.categoryId >= 0) {
        categoryIndex = this.sharedService.findCategoryIndexForAllLevels(data.assignData.categoryList, data.assignData.selectedDetails.categoryId);
        if (categoryIndex) {
          selectedCategory = this.sharedService.categoryIndexFind(data.assignData.categoryList, categoryIndex.i, categoryIndex.j, categoryIndex.k);
        } else {
          selectedCategory = this.sharedService.categoryIndexFind(data.assignData.categoryList, 1, null, null);
        }
      } else {
        selectedCategory = this.sharedService.categoryIndexFind(data.assignData.categoryList, 1, null, null);
      }
      event.categoryDetails = selectedCategory;
      if (event && event.categoryDetails && event.categoryDetails['category']) {
        data.assignData.isAllCategory = event.categoryDetails['category'].id > 0 ? false : true;
      } else {
        data.assignData.isAllCategory = event && event.categoryDetails && event.categoryDetails.id > 0 ? false : true;
      }
      this.getProductsByCategory(details, data).then((selectedCategory) => {
        if (selectedCategory) {
          selectedCategory = { ...selectedCategory, ...{ selectedDetails: event, productPageDetails: data.assignData.productPageDetails } };
          resolve(selectedCategory);
        } else {
          resolve(false);
        }
      }).catch((err) => {
        if (err) {
          this.commonDialogBox(this.translationService.getTranslation('SHARED.FAILED_SELECTED_CATEGORY'));
          reject(err);
        }
      });
    });
  }
  /**
   * Method used to change category details.
   * @param event defines the value of the selected category details.
   * @param data To store the component's values.
   * @param details To store the published page details.
   * @returns
   */
  selectedCategoryEmit(event: any, data: any, details: any): Promise<any> {
    if (data.assignData) {
      data.assignData['selectedDetails'] = event;
      data.assignData['paginationDetails']['listDataSource'] = [];
      data.assignData['paginationDetails']['offset'] = 0;
    }
    return new Promise((resolve, reject) => {
      return this.getProductAndFilterDetails(data, details).then((response1) => {
        resolve({ ...response1, ...{ selectedDetails: event } });
      }).catch((err1) => {
        if (err1) {
          this.commonDialogBox(this.translationService.getTranslation('SHARED.FAILED_SELECTED_CATEGORY'));
          reject(err1);
        }
      });
    });
  }
  /**
   * Method used to get all the options for filter.
   * @param selectedCategory To store the selected category.
   */
  getVariantOptions(selectedCategory: any, data?): Promise<any> {
    return new Promise((resolve, reject) => {
      this.themeService.getCategoryBasedVariantOptions(this.sharedService.storeId ? this.sharedService.storeId : data.sharedService.storeId).subscribe(res => {
        if (res['optionList'].length) {
          const optionFilter: any = [];
          for (let i = 0; i < res['optionList'].length; i++) {
            optionFilter.push({
              fieldName: res['optionList'][i].name, filterName: res['optionList'][i].name, isVisible: true, optionType: 'Variant Options', type: 'check-box',
              showColors: res['optionList'][i].showColors, options: []
            });
            if (res['optionList'][i].optionValues?.length) {
              for (let j = 0; j < res['optionList'][i].optionValues.length; j++) {
                if (res['optionList'][i].optionValues[j]) {
                  optionFilter[i].options.push({ display: res['optionList'][i].optionValues[j].optionValue, value: res['optionList'][i].optionValues[j].id, color: res['optionList'][i].optionValues[j].color, });
                }
                if (i === res['optionList'].length - 1 && j === res['optionList'][i].optionValues.length - 1) {
                  resolve(optionFilter);
                }
              }
            } else if (i === res['optionList'].length - 1) {
              resolve(optionFilter);
            }
          }
        }
        else {
          resolve([]);
        }
      }, (err) => {
        if (err) {
          this.commonDialogBox(this.translationService.getTranslation('SHARED.VARIANT_FAILURE'));
          reject(err);
        }
      });
    });
  }
  /**
   * Method used to get all the brands for filter.
   *  @param selectedCategory To store the selected category.
   */
  getBrands(selectedCategory: any, data?): Promise<any> {
    const query = data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.categoryId !== 0 ? {
      categoryIdArray: selectedCategory && selectedCategory['categoryIdArray'] && JSON.stringify(selectedCategory['categoryIdArray'])
    } : null;
    return new Promise((resolve, reject) => {
      this.themeService.getCategoryBasedBrand(this.sharedService.storeId ? this.sharedService.storeId : data.sharedService.storeId, query).subscribe(res => {
        if (res && res['brandList'] && res['brandList'].length) {
          const brandFilter = {
            fieldName: 'brandId', filterName: 'Brand', isVisible: true, optionType: 'Simple', type: 'check-box',
            options: []
          };
          for (const list of res['brandList']) {
            if (list) {
              brandFilter.options.push({ display: list.brandName, value: list.id });
            }
          }
          resolve(brandFilter);
        } else {
          resolve(null);
        }
      }, (err) => {
        if (err) {
          this.commonDialogBox(this.translationService.getTranslation('SHARED.BRAND_FAILURE'));
          reject(err);
        }
      });
    });
  }
  /**
   * Method used to get the product details.
   * @param data holds the component details.
   */
  getOneProductDetails(data: any, parentData?): Promise<any> {
    let paramData;
    // eslint-disable-next-line prefer-const
    let productInformation: any = {}, tabSectionDetails: any = {}, productsSet, productVideos = [], automaticDiscountDetails = [], salesCountdownTimerOffers = [], isNoProduct = false, buyxGetyDiscountDetails = [], orderCount: any = {}, isBasicCustomization = false;
    return new Promise((resolve, reject) => {
      if (data?.clearProductDetails && parentData?.assignData && parentData?.assignData?.productDetails) {
        delete parentData?.assignData.productDetails;
        this.sharedService.commonLoader.next(false);
        resolve(true);
      }
      else {
        paramData = { isApproved: true, storeId: data.sharedService.storeId };
        if (data && data.locationId) {
          paramData.locationId = data.locationId;
        }
        if (data && data.timeZone) {
          paramData.timeZone = data.timeZone;
        }
        if (data?.categoryId && data?.isQuickView) {
          paramData['categoryId'] = data?.categoryId;
        }
        this.subscriptionObj.add(this.themeService.getOneProductDetails(data.productId, data.sharedService.storeId, paramData).pipe(mergeMap((response: any) => {
          if (response && response['success'] && response['productData']) {
            productInformation = response['productData'];
          } else {
            isNoProduct = true;
          }
          if (data?.userData?.customerId && data?.userData?.storeId) {
            if (!data?.restrictRecentlyViewed)
              this.createRecentlyViewedProducts({ customerId: data?.userData?.customerId, productId: data.productId }, data).then((res: any) => { });
            if (data && !data.isReview) {
              if (productInformation && productInformation.productOptionMappings && !productInformation.productOptionMappings.length) {
                if (productInformation && productInformation.variantCombinationDetails && productInformation.variantCombinationDetails.length) {
                  return this.themeService.getWishlistDetails(data.userData.storeId, Number(data.userData.customerId), Number(data?.productId), { variantId: productInformation?.variantCombinationDetails[0]?.id });
                } else {
                  return of(true);
                }
              } else {
                return of(true);
              }
            } else {
              return of(true);
            }
          } else {
            return of(true);
          }
        }), mergeMap((response: any) => {
          productInformation['wishlistItem'] = response && response['wishList'] ? true : false;
          if (productInformation && productInformation.productOptionMappings && !productInformation.productOptionMappings.length) {
            if (productInformation && productInformation.variantCombinationDetails && productInformation.variantCombinationDetails.length) {
              return this.themeService.getAutomaticDiscountForSingleProduct({ storeId: data.sharedService.storeId, productId: data?.productId, variantId: productInformation?.variantCombinationDetails[0]?.id, customerId: data && data.userData && data.userData.customerId ? data.userData.customerId : null, roleId: data && data.userData && data.userData.roleId ? data.userData.roleId : null });
            }
            return of(true);
          } else {
            return of(true);
          }
        }), mergeMap((response: any) => {
          if (response && response.discount && response.discount.length) {
            automaticDiscountDetails = response.discount;
          }
          if (productInformation && productInformation.productOptionMappings && !productInformation.productOptionMappings.length) {
            if (productInformation && productInformation.variantCombinationDetails && productInformation.variantCombinationDetails.length) {
              return this.themeService.getBuyxGetyDetailsForSingleProduct({ storeId: data.sharedService.storeId, productId: data?.productId, variantId: productInformation?.variantCombinationDetails[0]?.id, customerId: data && data.userData && data.userData.customerId ? data.userData.customerId : null, roleId: data && data.userData && data.userData.roleId ? data.userData.roleId : null, locationId: data?.locationId });
            }
            return of(true);
          } else {
            return of(true);
          }
        }), mergeMap((response: any) => {
          if (response && response.discount && response.discount.length) {
            buyxGetyDiscountDetails = response.discount;
          }
          if (data?.productId && data?.sharedService?.storeId) {
            return this.sharedService.checkPreorderLimit(data?.sharedService?.storeId, data?.productId, { customerId: data?.customerId, langCode: this.translationService.languageCode } ?? null);
          }
          return of(true);

        }), mergeMap((response: any) => {
          if (response && response.orderCount) {
            orderCount = response.orderCount;
          }
          if (data?.userData?.customerId && data?.userData?.storeId) {
            return this.themeService.getOrderedProducts(data.userData.storeId, data.userData.customerId, data.productId);
          } else {
            return of(true);
          }
        }), mergeMap((response: any) => {
          if (response?.orderedProduct) {
            tabSectionDetails['isOrderedProduct'] = true;
          } else {
            tabSectionDetails['isOrderedProduct'] = false;
          }
          if (productInformation && productInformation.productOptionMappings && !productInformation.productOptionMappings.length) {
            if (productInformation.variantCombinationDetails && productInformation.variantCombinationDetails.length) {
              const currentDate = new Date();
              const storeId = data && data.sharedService && data.sharedService.storeId;
              const productId = data && data.productId;
              return this.sharedService.getOneProductTimeBasedOffers(storeId, productId, productInformation.variantCombinationDetails[0].id, currentDate);
            }
            return of(true);
          } else {
            return of(true);
          }
        }), mergeMap((response: any) => {
          let offers = [];
          if (response.discountDetails) {
            if (response.discountDetails.allProducts && response.discountDetails.allProducts.length > 0) {
              offers = response.discountDetails.allProducts;
            }
            if (response.discountDetails.productOrCategoryLevel && response.discountDetails.productOrCategoryLevel.length > 0) {
              for (const offer of response.discountDetails.productOrCategoryLevel) {
                offers[offers.length] = offer.timerDiscount;
              }
            }
            salesCountdownTimerOffers = offers;
            isBasicCustomization = response.discountDetails.isBasicCustomization ? response.discountDetails.isBasicCustomization : false;
          }
          return this.themeService.getDetailsOfProduct(data.productId, data.sharedService.storeId, { isApproved: true, storeId: data.sharedService.storeId });
        })).subscribe((response: any) => {
          if (response && response['success'] && response['productData']) {
            tabSectionDetails = Object.assign(tabSectionDetails, response['productData']);
          }
          tabSectionDetails['averageRatings'] = productInformation?.averageRatings ? productInformation.averageRatings : null;
          tabSectionDetails['featuredImage'] = productInformation?.featuredImage ? productInformation.featuredImage : null;
          tabSectionDetails['name'] = productInformation?.name ? productInformation.name : null;
          tabSectionDetails['isDigitalProduct'] = productInformation?.isDigitalProduct ? productInformation.isDigitalProduct : null;
          tabSectionDetails['userId'] = data?.userData?.id ? data.userData.id : null;
          this.basicSetup(tabSectionDetails).then((res) => {
            tabSectionDetails = Object.assign(tabSectionDetails, res);
            productInformation.ratingCount = tabSectionDetails.productReviews?.length;
            this.getFrequentlyBoughtTogether(data).then((fbtResponse: FbtProductsModel) => {
              this.sharedService.commonLoader.next(false);
              resolve({
                customerPreorderLimit: orderCount,
                productDetails: productInformation,
                productVideos: productVideos,
                tabSectionDetails: tabSectionDetails,
                productsSet: productsSet,
                automaticDiscountDetails: automaticDiscountDetails,
                buyxGetyDiscountDetails: buyxGetyDiscountDetails,
                isNoProduct: isNoProduct,
                salesCountdownTimerOffers: salesCountdownTimerOffers,
                getFrequentlyBoughtTogether: fbtResponse,
                isBasicCustomization: isBasicCustomization
              });
            });
          });
        }, (err) => {
          if (err) {
            this.sharedService.commonLoader.next(false);
            this.commonDialogBox(this.translationService.getTranslation('SHARED.ERROR_PLEASE_TRY_AGAIN'));
          }
          reject(err);
        }));
      }
    });
  }
  /**
   * Method used to get the short and full name of the user.
   * @param tabSectionDetails holds the product review details.
   */
  basicSetup(tabSectionDetails: any): Promise<any> {
    return new Promise((resolve) => {
      if (tabSectionDetails && tabSectionDetails.productReviews && tabSectionDetails.productReviews.length) {
        if (tabSectionDetails.users) {
          tabSectionDetails.productReviews.map(element => {
            const foundUser = tabSectionDetails.users.find(user => user && user.id === element.createdBy);
            if (foundUser) {
              element.fullName = (foundUser.firstName ? (foundUser.firstName + ' ') : '') + (foundUser.lastName ? foundUser.lastName : '');
              if (foundUser.firstName && foundUser.lastName) {
                element.shortName = foundUser.firstName[0] + foundUser.lastName[0];
              }
              else if (foundUser.firstName) {
                element.shortName = foundUser.firstName.substring(0, 2);
              }
            }
          });
          resolve(tabSectionDetails);
        } else {
          resolve(tabSectionDetails);
        }
      } else {
        resolve(tabSectionDetails);
      }
    });
  }
  
  /**
   * Method used to open snackbar.
   * @param message holds message to display in the snackbar.
   * @param actionType holds action type.
   */
  openCommonSnackbar(message: string, actionType: string) {
    this.dialogService.openDialog({
      message,
      actionType,
      button: { right: this.translationService.getTranslation('SHARED.DIALOG_OKAY') },
    });
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to add product to cart from product details page.
   * @param event contains the product details to be added to cart.
   * @param data holds the component details.
   */
  addToCart(event: any, data: any): Promise<any> {
    if (event && event.cartItem && event.cartItem.id && event.cartItem.quantity && event.cartDetails && data.userData && data.userData.customerId && !data.userData.shopappUser) {
      this.themeService.removeCartProducts(data?.sharedService?.storeId, event.cartItem.id).subscribe(() => this.sharedService.cartCountValue.next(event.cartDetails.cartCount - event.cartItem.quantity));
    }
    let locationDetails;
    if (data && data.locationId && data.timeZone) {
      locationDetails = { locationId: data.locationId, timeZone: data.timeZone };
    }
    return new Promise((resolve, reject) => {
      if (event) {
        let countValue;
        this.subscriptionObj.add(this.sharedService.cartCountValue.subscribe((res) => {
          countValue = res;
        }));
        if (data.userData && data.userData.customerId && !data.userData.shopappUser) {
          const cartSubscription = !event.update ?
            this.bulkOperationService.importData('stores/' + data?.sharedService?.storeId + '/cart/' + data.userData.customerId, event.imageData ? event.imageData : null, 'postMethod', event.cartDetails) :
            this.bulkOperationService.importData('stores/' + data?.sharedService?.storeId + '/cart/products/' + event?.cartDetails?.cartProductId, event.imageData ? event.imageData : null, 'putMethod', event.cartDetails);
          if (cartSubscription) {
            this.subscriptionObj.add(cartSubscription.subscribe((response: any) => {
              if (response) {
                if (response.cartData && response.cartData.isAdded) {
                  this.openCommonSnackbar(this.translationService.getTranslation('SHARED.ADD_TO_CART'), 'success');
                  event.cartProductArray = [...event.cartProductArray, ...[{
                    productId: event.cartDetails.productId,
                    variantId: event.cartDetails.variantId,
                    combinationName: event.cartDetails.combinationName,
                    id: response.cartData.cartProductId,
                    quantity: event.cartDetails.quantity,
                    instruction: event.cartDetails.instruction,
                    modifierIds: event.cartDetails.modifierIds,
                    addonImageData: response.cartData && response.cartData.addonImageData && response.cartData.addonImageData.length ? response.cartData.addonImageData : [],
                    customizeProductData: response.cartData && response.cartData.customizeProductData && response.cartData.customizeProductData.length ? response.cartData.customizeProductData : []
                  }]];
                  if (event.cartDetails) {
                    if (!event.cartDetails.weightUnit || (event.cartDetails.weightUnit && (event.cartDetails.weightUnit === 'count' || event.cartDetails.weightUnit === 'item'))) {
                      this.sharedService.cartCountValue.next(countValue + (event.cartDetails.quantity ? event.cartDetails.quantity : 0));
                    }
                    else {
                      this.sharedService.cartCountValue.next(countValue + 1);
                    }
                  }
                  const resolveData = { productDetails: event.productDetails, cartProductArray: event.cartProductArray };
                  resolve(resolveData);
                } else if (response.updateCart && response.updateCart.isUpdated) {
                  this.openCommonSnackbar(this.translationService.getTranslation('SHARED.UPDATED_CART'), 'success');
                  const findItemIndex = event.cartProductArray.findIndex(val => val && val.id === event.cartDetails.cartProductId);
                  if (findItemIndex >= 0) {
                    event.cartProductArray[findItemIndex] = Object.assign(event.cartProductArray[findItemIndex], {
                      quantity: event.cartDetails.quantity,
                      instruction: event.cartDetails.instruction,
                      modifierIds: event.cartDetails.modifierIds
                    });
                    event.cartDetails.selectedAddons && event.cartDetails.selectedAddons.length && event.cartDetails.selectedAddons.forEach(selected => {
                      let findIndex;
                      if (event.cartProductArray[findItemIndex].addonImageData && event.cartProductArray[findItemIndex].addonImageData.length > 0)
                        findIndex = event.cartProductArray[findItemIndex].addonImageData.findIndex(addon => addon.imageType === selected.type);
                      else
                        event.cartProductArray[findItemIndex].addonImageData = [];
                      const responseFile = response.updateCart.addonImageData && response.updateCart.addonImageData.length && response.updateCart.addonImageData.find(imageResponse => imageResponse.imageType === selected.type);
                      if (findIndex > -1)
                        event.cartProductArray[findItemIndex].addonImageData[findIndex].imageUrl = responseFile.imageUrl;
                      else
                        event.cartProductArray[findItemIndex].addonImageData.push(responseFile);
                    })
                    event.cartDetails.removedImages && event.cartDetails.removedImages.length && event.cartDetails.removedImages.forEach(removedFile => {
                      const removedIndex = event.cartProductArray[findItemIndex].addonImageData && event.cartProductArray[findItemIndex].addonImageData.length && event.cartProductArray[findItemIndex].addonImageData.findIndex(addon => addon.id === removedFile.id && addon.imageUrl === removedFile.imageUrl);
                      if (removedIndex > -1)
                        event.cartProductArray[findItemIndex].addonImageData.splice(removedIndex, 1);
                    })
                  }
                  if (event.cartDetails) {
                    if (!event.cartDetails.weightUnit || (event.cartDetails.weightUnit && (event.cartDetails.weightUnit === 'count' || event.cartDetails.weightUnit === 'item'))) {
                      this.sharedService.cartCountValue.next(countValue + ((event.cartDetails.quantity ? event.cartDetails.quantity : 0) - (event.cartDetails.cartQty ? event.cartDetails.cartQty : 0)));
                    }
                  }
                  if (response.updateCart && response.updateCart.customizeProductData && response.updateCart.customizeProductData.length >= 0) {
                    event.cartProductArray[findItemIndex].customizeProductData = response.updateCart.customizeProductData;
                  }
                  const resolveData = { productDetails: event.productDetails, cartProductArray: event.cartProductArray };
                  resolve(resolveData);
                }
              }
            }, (err: any) => {
              if (err) {
                this.openCommonSnackbar(this.translationService.getTranslation('SHARED.CART_FAILURE'), 'failure');
                reject(err);
              }
            }));
          }
        } else {
          this.sharedService.updateGuestUserCart(event.cartDetails, locationDetails, event.imageData, event.cartItem).then((res: any) => {
            resolve({ productDetails: event.productDetails, cartProductArray: res ?? null });
          });
        }
      }
    });
  }
  /**
   * Method which is used to create a review.
   * @param event To store the review data to be added.
   * @param data holds the component details.
   */
  createReview(event: any, data: any): Promise<any> {
    let tabSectionDetails;
    return new Promise((resolve, reject) => {
      this.getWebsiteSettings().then((websiteResponse: any) => {
        if (websiteResponse) {
          event['isApproved'] = websiteResponse?.websiteSettings?.autoReviewApproval ? websiteResponse.websiteSettings.autoReviewApproval : false;
          if (data && data.customerId && data.customer != null) {
            event['customerId'] = data.customerId;
          } else if (data && data.userData && data.userData.customerId && data.userData.customerId != null) {
            event['customerId'] = data.userData.customerId;
          }
          this.productDetailsService.createReview(this.sharedService.storeId, event, data.productId).then((createReviewResponse: any) => {
            if (createReviewResponse) {
              if (createReviewResponse.actionEarnedPoints) {
                this.dialogService.loyaltyPointsDialog(this.translationService.getTranslation('SHARED.REVIEW_SUCCESS'), this.commonConstant.dialogType.earningPoints, createReviewResponse.actionEarnedPoints, this.dialogMessages.okButtonText, true);
              } else {
                this.dialogService.dialogMethod(this.translationService.getTranslation('SHARED.REVIEW_SUCCESS'), this.commonConstant.dialogType.success, true);
              }
              data['restrictRecentlyViewed'] = true;
              this.getOneProductDetails(data).then((response: any) => {
                if (response && response.tabSectionDetails) {
                  tabSectionDetails = response.tabSectionDetails;
                  resolve({ tabSectionDetails });
                }
              }).catch((err) => {
                reject(err);
              });
            }
          }).catch((reviewErr) => {
            reject(reviewErr);
          });
        }
      })
    })
  }
  /**
   * Method used to get like, dislike details for a product.
   * @param reviewId holds the review id.
   * @param data holds the component details.
   */
  changeLikeDislike(reviewId: number, data: any, isLike: any): Promise<any> {
    let tabSectionDetails;
    return new Promise((resolve, reject) => {
      data['restrictRecentlyViewed'] = true;
      this.getOneProductDetails(data).then((response: any) => {
        if (response && response.tabSectionDetails) {
          tabSectionDetails = response.tabSectionDetails;
          this.productDetailsService[isLike ? 'likeClicked' : 'dislikeClicked'](tabSectionDetails, reviewId).then(res => {
            if (res) {
              tabSectionDetails = res;
              resolve({ tabSectionDetails });
            } else {
              resolve({ tabSectionDetails });
            }
          }).catch((err) => {
            if (err) {
              this.commonDialogBox(isLike ? this.translationService.getTranslation('SHARED.ATTEMPT_TO_LIKE_FAILED') : this.translationService.getTranslation('SHARED.ATTEMP_TO_DISLIKE_FAILED'));
              reject(err);
            }
          })
        } else {
          resolve({ tabSectionDetails: tabSectionDetails });
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }
  /**
   * Method used to like some review.
   * @param reviewId holds the review id.
   * @param data holds the component details.
   */
  likeClicked(reviewId: number, data: any): Promise<any> {
    return this.changeLikeDislike(reviewId, data, true);
  }
  /**
   * Method used to dislike some review.
   * @param reviewId defines the review id.
   * @param data holds the component value.
   */
  dislikeClicked(reviewId: number, data: any): Promise<any> {
    return this.changeLikeDislike(reviewId, data, false);
  }
  /**
   * Method used to get the cart products.
   * @param data holds the component details.
   */
  getCartProducts(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (data && data.userData && data.userData.customerId) {
        this.subscriptionObj.add(this.themeService.getVerifiedCartProducts(data.userData.customerId, { isProductDetails: true, locationId: data.locationId, isCart: true }, this.sharedService.storeId).subscribe((response: any) => {
          if (response && response.cartProducts && response.cartProducts.cartProducts) {
            resolve({ cartProductArray: response.cartProducts.cartProducts });
          } else {
            resolve({ cartProductArray: [] });
          }
        }, (cartProductErr) => {
          if (cartProductErr) {
            reject(cartProductErr);
          }
        }));
      } else {
        if (JSON.parse(localStorage.getItem('cart'))) {
          const cartProduct = JSON.parse(localStorage.getItem('cart'));
          if (cartProduct && cartProduct.cartProduct && cartProduct.cartProduct.products) {
            resolve({ cartProductArray: cartProduct.cartProduct.products });
          } else {
            resolve({ cartProductArray: [] });
          }
        } else {
          resolve({ cartProductArray: [] });
        }
      }
    });
  }
  /**
   * Method used to set data for coffee product summary.
   * @param data which holds the component details.
   * @param details To store the published page details.
   * @returns product category response.
   */
  getAllSubCategories(data: any, details: any): Promise<any> {
    let subCategorys, cartId, selectedSubCategory;
    return new Promise(async (resolve, reject) => {
      await this.themeService.getSubCategories(this.sharedService.storeId, data.locationId, this.searchText ? this.searchText : null, data.customerId ? data.customerId : null, data.timeZone, this.sharedService.industryName).pipe(filter((categories) => {
        subCategorys = categories && categories['category'] ? categories['category'] : [];
        if (subCategorys) {
          return subCategorys;
        }
      }), mergeMap(() => {
        if (data.customerId) {
          return this.themeService.getVerifiedCartProducts(data.customerId, { isProductDetails: true, locationId: data.locationId }, this.sharedService.storeId);
        } else {
          const localStorageProducts = JSON.parse(localStorage.getItem('cart'));
          if (localStorageProducts && localStorageProducts.cartProduct) {
            return of(localStorageProducts);
          } else {
            return of(true);
          }
        }
      })).subscribe((res) => {
        if (res) {
          let cartProductData;
          if (data.customerId) {
            cartProductData = res.cartProducts && res.cartProducts.cartProducts && res.cartProducts.cartProducts.length ? res.cartProducts.cartProducts : [];
          } else {
            cartProductData = res.cartProduct && res.cartProduct.products && res.cartProduct.products.length ? res.cartProduct.products : [];
          }
          const categoryIds = [];
          if (data.customerId && res.cartProducts && res.cartProducts && res.cartProducts.id) {
            cartId = res.cartProducts.id;
          }
          subCategorys.forEach((item) => {
            if (item.products && item.products.length > 0) {
              item.products.map(value => value.quantity = 0);
              if (cartProductData && cartProductData.length) {
                cartProductData.map((product) => {
                  if (product && !product.discountProductId && !product.fbtId) {
                    item.products.map(value => {
                      value.quantity = value.id === product.productId ? product.quantity : 0;
                    });
                  }
                });
              }
            }
            if (item.subCategory && item.subCategory.length) {
              item.subCategory.forEach((subCategory) => {
                if (subCategory.products && subCategory.products.length > 0) {
                  subCategory.products.map(value => value.quantity = 0);
                  if (cartProductData && cartProductData.length) {
                    cartProductData.map((product) => {
                      if (product && !product.discountProductId && !product.fbtId) {
                        subCategory.products.map(value => {
                          value.quantity = value.id === product.productId ? product.quantity : 0;
                        });
                      }
                    });
                  }
                }
                if (subCategory.subCategory && subCategory.subCategory.length) {
                  subCategory.subCategory.forEach((innerSubCategory) => {
                    if (innerSubCategory.products && innerSubCategory.products.length > 0) {
                      innerSubCategory.products.map(value => value.quantity = 0);
                      if (cartProductData && cartProductData.length) {
                        cartProductData.map((product) => {
                          if (product && !product.discountProductId && !product.fbtId) {
                            innerSubCategory.products.map(value => {
                              value.quantity = value.id === product.productId ? product.quantity : 0;
                            });
                          }
                        });
                      }
                    }
                  });
                }
              });
            }
          });
          selectedSubCategory = { id: subCategorys[0].id, categoryName: subCategorys[0].categoryName };
          if (isPlatformBrowser(this.platformId)) {
            window.addEventListener('scroll', this.scrollEvent);
          }
          if (subCategorys) {
            resolve({
              currentCategory: selectedSubCategory,
              cartId: cartId,
              categoryData: subCategorys,
              isHeaderOverlap: this.isHeaderOverlap,
              showBreadcrumbs: false,
              isSummary: true,
            })
          }
        }
      }, (err) => {
        if (err) {
          this.commonDialogBox(this.translationService.getTranslation('SHARED.DIALOG_FAILURE_MESSAGE'));
          reject(err);
        }
      });
    });
  }
  /**
  * Method which is used to get the scroll height dynamically inorder to change the stick-background UI
  */
  scrollEvent = (event: any): void => {
    this.isHeaderOverlap = event && event.srcElement && event.srcElement.scrollingElement && event.srcElement.scrollingElement.scrollTop && event.srcElement.scrollingElement.scrollTop >= 250 ? true : false;
  };
  /**
 * Method used to get the selected category id.
 * @param data To store the component's values.
 */
  getSelectedSubCategoryId(data: any): Promise<any> {
    return new Promise((resolve) => {
      if (data && data.assignData && data.assignData.currentCategory && data.assignData.currentCategory.hasOwnProperty('id') && (data.assignData.currentCategory.id >= 0)) {
        resolve(data.assignData.currentCategory.id);
      } else {
        resolve(null);
      }
    });
  }
  /**
   * Method used to add products to cart for coffee summary.
   * @param event holds quantity details
   * @param data holds the component details.
   * @param details To store the published page details.
   * @returns response of quantity changes
   */
  addToCartCoffee(event: any, data: any, details: any): Promise<any> {
    const indexData = event.indexData;
    let countValue;
    return new Promise((resolve, reject) => {
      this.subscriptionObj.add(this.sharedService.cartCountValue.subscribe((res) => {
        countValue = res;
      }));
      const cartData = {
        customerId: data.customerId,
        storeId: this.sharedService.storeId,
        cartDetails: event,
        countValue: countValue,
        cartId: data.assignData.cartId
      };
      let quantityData = 0, cartResponse;
      this.productDetailsService.addToCartProductList(cartData).then((response: any) => {
        if (response) {
          cartResponse = response;
        }
        if (indexData.hasOwnProperty('subCategoryListIndex') && indexData.hasOwnProperty('innerSubCategoryListIndex')) {
          data.assignData.categoryData[indexData.listIndex].subCategory[indexData.subCategoryListIndex].subCategory[indexData.innerSubCategoryListIndex].products[event.index].quantity = event.operation === '-' ? data.assignData.categoryData[indexData.listIndex].subCategory[indexData.subCategoryListIndex].subCategory[indexData.innerSubCategoryListIndex].products[event.index].quantity - 1 : data.assignData.categoryData[indexData.listIndex].subCategory[indexData.subCategoryListIndex].subCategory[indexData.innerSubCategoryListIndex].products[event.index].quantity + 1;
          quantityData = data.assignData.categoryData[indexData.listIndex].subCategory[indexData.subCategoryListIndex].subCategory[indexData.innerSubCategoryListIndex].products[event.index].quantity;
        } else if (indexData.hasOwnProperty('subCategoryListIndex')) {
          data.assignData.categoryData[indexData.listIndex].subCategory[indexData.subCategoryListIndex].products[event.index].quantity = event.operation === '-' ? data.assignData.categoryData[indexData.listIndex].subCategory[indexData.subCategoryListIndex].products[event.index].quantity - 1 : data.assignData.categoryData[indexData.listIndex].subCategory[indexData.subCategoryListIndex].products[event.index].quantity + 1;
          quantityData = data.assignData.categoryData[indexData.listIndex].subCategory[indexData.subCategoryListIndex].products[event.index].quantity;
        } else {
          data.assignData.categoryData[indexData.listIndex].products[event.index].quantity = event.operation === '-' ? data.assignData.categoryData[indexData.listIndex].products[event.index].quantity - 1 : data.assignData.categoryData[indexData.listIndex].products[event.index].quantity + 1;
          quantityData = data.assignData.categoryData[indexData.listIndex].products[event.index].quantity;
        }
        if (cartResponse && cartResponse.cartProduct) {
          if ((event.operation === '-') && (quantityData === 0)) {
            this.openSnackBar(this.cartMessages.deleteCart);
          } else if ((event.operation === '+') && (quantityData === 1)) {
            this.openSnackBar(this.cartMessages.insertCart);
          }
          resolve(true);
        } else {
          this.openSnackBar(this.cartMessages.cartFailure);
          resolve(true);
        }
      }).catch((cartErr) => {
        if (cartErr) {
          this.commonDialogBox(this.translationService.getTranslation('SHARED.DIALOG_FAILURE_MESSAGE'));
          reject(cartErr);
        }
      });
    });
  }
  /**
   * Method used to display dialog.
   * @param message To store message to be displayed in dialog.
   */
  commonDialogBox(message: string): void {
    this.dialogService.openDialog({
      header: this.translationService.getTranslation('SHARED.DIALOG_FAILURE_HEADER'),
      message: message,
      actionType: this.dialogType.failure,
      button: { right: this.translationService.getTranslation('SHARED.DIALOG_OK_BTN') },
      disableClose: true
    });
  }
  /**
 * Method used to Open snack Bar.
 * @param message message to display in snack bar.
 */
  openSnackBar(message: string): void {
    this.snackBar.open(message, 'X', { duration: 3000 });
  }
  /**
   * Method used to return selected category details.
   * @param event which holds category details
   * @param data holds the component details.
   * @returns current category response.
   */
  selectCategory(event: any, data: any): Promise<any> {
    return Promise.resolve({ currentCategory: event });
  }
  /**
   * Method used to get filter array.
   * @param data holds the component details.
   * @param details To store the published page details.
   * @returns filter array response.
   */
  getFilterArray(data: any, details: any): Promise<any> {
    return Promise.resolve({ productPageDetails: details.filterData });
  }
  /**
 * Method used to get whether the category should display or not.
 * @param data holds the component details.
 * @param details To store the published page details.
 * @returns categoryDisplay response.
 */
  getCategoryDisplay(data: any, details: any): Promise<any> {
    return Promise.resolve({ categoryDisplay: true });
  }
  /**
   * Method used to get width of the window.
   * @returns window width.
   */
  getInnerWidth() {
    if (isPlatformBrowser(this.platformId)) {
      return window.innerWidth;
    }
  }
  /**
   * Method used to get categories with products.
   * @param data holds the component details.
   * @returns the category list with produsct.
   */
  getProductsWithCategories(data) {
    this.sharedService.commonLoader && this.sharedService.commonLoader.next(true);
    return new Promise(async (resolve, reject) => {
      data.assignData['productDetails'] = null;
      if (data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.categoryId && !data.assignData.selectedDetails.searchText) {
        await this.getAllProductsForCategory(data.assignData.selectedDetails, data).then((response) => {
          if (response) {
            resolve(response);
          } else {
            resolve(true);
          }
        }).catch((err) => {
          reject(err);
        });
      } else if (data && data.assignData && data.assignData.selectedDetails && (data.assignData.selectedDetails.categoryId || data.assignData.selectedDetails.searchText)) {
        await this.getProductsBySearchedText(data.assignData.selectedDetails, data).then((searchResponse) => {
          if (searchResponse) {
            resolve(searchResponse);
          } else {
            resolve(true);
          }
        }).catch((err) => {
          reject(err);
        });
      } else {
        let categoryList = [], menuCategoryAvailability = [], cartId;
        const queryParams = {
          locationId: data?.locationId,
          timeZone: data.timeZone,
          customerId: data && data.userData && data.userData.customerId ? data.userData.customerId : null,
          limit: 9,
          isOrder: data && data.orderId ? true : false,
          isDiscount: data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.discountId ? true : false
        };
        this.themeService.getProductsWithCategories(data.sharedService.storeId, queryParams).pipe(filter((response: any) => {
          if (response && response.categories) {
            if (response.categories.categoryDetails && response.categories.categoryDetails.length) {
              categoryList = response.categories.categoryDetails;
              for (const category of categoryList) {
                if (category && category.productList && category.productList.length) {
                  for (const categoryProducts of category.productList) {
                    categoryProducts.quantity = 0;
                  }
                }
              }
              const localStorageProducts = JSON.parse(localStorage.getItem('cart'));
              if (localStorageProducts && localStorageProducts.cartProduct && localStorageProducts.cartProduct.products && localStorageProducts.cartProduct.products.length) {
                localStorageProducts.cartProduct.products.map((localStorageProduct) => {
                  if (localStorageProduct && localStorageProduct.productId) {
                    for (const category of categoryList) {
                      if (category && category.productList && category.productList.length) {
                        const index = category.productList.findIndex((product) => product && product.id === localStorageProduct.productId);
                        if (index >= 0) category.productList[index].quantity = localStorageProduct.quantity;
                      }
                    }
                  }
                });
              }
            }
            if (response.categories.menuCategoryAvailability && response.categories.menuCategoryAvailability.length) {
              menuCategoryAvailability = response.categories.menuCategoryAvailability;
            }
            return true;
          } else {
            return true;
          }
        }), mergeMap(() => {
          if (data && data.userData && data.userData.customerId) {
            return this.themeService.getVerifiedCartProducts(data.userData.customerId, { isProductDetails: true, locationId: data.locationId }, this.sharedService.storeId);
          } else {
            return of(true);
          }
        })).subscribe((response) => {
          if (response) {
            if (response['cartProducts'] && response['cartProducts'].cartProducts && response['cartProducts'].cartProducts.length) {
              const cartProducts = response['cartProducts'].cartProducts;
              cartId = cartProducts[0].cartId;
              cartProducts.map((cart) => {
                if (cart && !cart.discountProductId && !cart.fbtId) {
                  for (const category of categoryList) {
                    if (category && category.productList && category.productList.length) {
                      category.productList.find((product) => {
                        if (product && cart.productId === product.id) {
                          product.quantity = cart.quantity;
                          product.cartProductId = cart.id;
                          return true;
                        }
                      });
                    }
                  }
                }
              });
            }
            if (!categoryList?.length) {
              this.sharedService && this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
            }
            resolve({
              selectedDetails: data.assignData.selectedDetails,
              categoryList: categoryList,
              menuCategoryAvailability: menuCategoryAvailability,
              limit: 9
            });
          }
        }, (err) => {
          if (err) {
            this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
            this.dialogService.openDialog({
              header: this.translationService.getTranslation('SHARED.DIALOG_FAILURE_HEADER'),
              message: this.translationService.getTranslation('SHARED.DIALOG_FAILURE_MESSAGE'),
              actionType: this.dialogType.failure,
              button: { right: this.translationService.getTranslation('SHARED.DIALOG_OK_BTN') }
            });
            reject(err);
          }
        })
      }
    })
  }
  /**
   * Method used to get products based on selected category.
   * @param event holds the selected category id.
   * @param data holds the component details.
   * @returns the products based on selected category id.
   */
  getAllProductsForCategory(event, data) {
    this.sharedService && this.sharedService.commonLoader && this.sharedService.commonLoader.next(true);
    let categoryList = [], productList = [], menuCategoryAvailability = [];
    return new Promise((resolve, reject) => {
      const queryParams = {
        locationId: data.locationId,
        timeZone: data.timeZone,
        customerId: data && data.userData && data.userData.customerId,
        categoryId: event && event.categoryId ? event.categoryId : null,
        isDiscount: data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.discountId ? true : false
      };
      this.themeService.getAllProductsForCategory(data.sharedService.storeId, queryParams).pipe(filter((response: any) => {
        if (response && response.categoryDetails) {
          categoryList = response.categoryDetails;
          const localStorageProducts = JSON.parse(localStorage.getItem('cart'));
          if (localStorageProducts && localStorageProducts.cartProduct && localStorageProducts.cartProduct.products.length) {
            localStorageProducts.cartProduct.products.map((localStorageProduct) => {
              if (localStorageProduct && localStorageProduct.productId) {
                if (response && response.productList && response.productList.length) {
                  const index = response.productList.findIndex((product) => product && product.id === localStorageProduct?.productId);
                  if (index >= 0) response.productList[index].quantity = localStorageProduct?.quantity;
                }
              }
            });
          }
        }
        if (response && response.productList) {
          productList = response.productList;
        }
        if (response.menuCategoryAvailability && response.menuCategoryAvailability.length) {
          menuCategoryAvailability = response.menuCategoryAvailability;
        }
        return true;
      }), mergeMap(() => {
        if (data && data.userData && data.userData.customerId) {
          return this.themeService.getVerifiedCartProducts(data.userData.customerId, { isProductDetails: true, locationId: data.locationId }, this.sharedService.storeId);
        } else {
          return of(true);
        }
      })).subscribe((response) => {
        if (response) {
          if (response && response['cartProducts'] && response['cartProducts'].cartProducts.length) {
            const cartProducts = response['cartProducts'].cartProducts;
            cartProducts.map((cart) => {
              if (cart && !cart.discountProductId && !cart.fbtId) {
                productList.forEach((product) => {
                  if (product && cart.productId === product.id) {
                    product.quantity = cart.quantity;
                    product.cartProductId = cart.id;
                  }
                });
              }
            });
          }
          const selectedCategoryIndex = categoryList.findIndex((item) => item.id === event.categoryId);
          if (selectedCategoryIndex != -1) {
            categoryList[selectedCategoryIndex] = Object.assign(categoryList[selectedCategoryIndex], { productList: productList });
          }
          if (!categoryList?.length) {
            this.sharedService && this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
          }
          resolve({
            selectedDetails: event,
            categoryList: categoryList,
            isSelectedCategory: true,
            menuCategoryAvailability: menuCategoryAvailability
          });
        }
      }, categoryErr => {
        this.sharedService && this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
        if (categoryErr) {
          this.dialogService.openDialog({
            header: this.translationService.getTranslation('SHARED.DIALOG_FAILURE_HEADER'),
            message: this.translationService.getTranslation('SHARED.DIALOG_FAILURE_MESSAGE'),
            actionType: this.dialogType.failure,
            button: { right: this.translationService.getTranslation('SHARED.DIALOG_OK_BTN') }
          });
          reject(categoryErr);
        }
      });
    });
  }
  /**
   * Method used to get products with categories.
   * @param event holds the whether category is selected.
   * @param data holds the component details.
   */
  getProductsBasedOnCategories(event, data) {
    data.assignData.selectedDetails = { categoryId: null, searchText: null };
    return new Promise((resolve, reject) => {
      this.getProductsWithCategories(data).then((response: any) => {
        if (response && response.categoryList) {
          resolve({
            categoryList: response.categoryList,
            isSelectedCategory: event.isSelectedCategory,
            limit: response.limit,
            selectedDetails: data.assignData.selectedDetails
          });
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }
  /**
   * Method used to reset search text and get products.
   * @param event holds the search text.
   * @param data holds the component details.
   */
  getClearSearchProducts(event: any, data: any) {
    return new Promise((resolve, reject) => {
      if (event) {
        data.assignData.selectedDetails = { categoryId: null, searchText: null };
        this.getProductsWithCategories(data).then((response: any) => {
          if (response && response.categoryList) {
            resolve(response);
          } else {
            resolve(false);
          }
        }).catch((err) => {
          reject(err);
        });
      } else {
        resolve(false);
      }
    });
  }
  /**
   * Method used to get the products based on search text.
   * @param event holds the search text.
   * @param data holds the component details.
   */
  getProductsBySearchedText(event, data) {
    this.sharedService && this.sharedService.commonLoader && this.sharedService.commonLoader.next(true);
    const queryParams = {
      searchText: event?.searchText?.length > 0 ? event?.searchText : '',
      locationId: data?.locationId,
      isAllCategory: true,
      timeZone: data?.timeZone
    };
    const categoryList = [];
    let menuCategoryAvailability = [];
    return new Promise(async (resolve, reject) => {
      await this.themeService.getAllProductsBySearchText(data.sharedService.storeId, queryParams).pipe(filter((response: any) => {
        const localStorageProducts = JSON.parse(localStorage.getItem('cart'));
        if (localStorageProducts && localStorageProducts.cartProduct) {
          localStorageProducts.cartProduct.products.map((localStorageProduct) => {
            if (localStorageProduct && localStorageProduct.productId) {
              if (response && response.categories && response.categories.products && response.categories.products.length) {
                const index = response.categories.products.findIndex((product) => product && product.id === localStorageProduct.productId);
                if (index >= 0) response.categories.products[index].quantity = localStorageProduct.quantity;
              }
            }
          });
        }
        if (response && response.categories && response.categories.products && response.categories.products.length) {
          categoryList[0] = { productList: response.categories.products };
        }
        if (response && response.categories && response.categories.menuCategoryAvailability && response.categories.menuCategoryAvailability.length) {
          menuCategoryAvailability = response.categories.menuCategoryAvailability;
        }
        return true;
      }), mergeMap(() => {
        if (data && data.userData && data.userData.customerId) {
          return this.themeService.getVerifiedCartProducts(data.userData.customerId, { isProductDetails: true, locationId: data.locationId }, this.sharedService.storeId);
        } else {
          return of(true);
        }
      })).subscribe((response) => {
        if (response) {
          if (response['cartProducts'] && response['cartProducts'].cartProducts && response['cartProducts'].cartProducts.length) {
            const cartProducts = response['cartProducts'].cartProducts;
            cartProducts.map((cart) => {
              if (cart && categoryList && categoryList[0] && categoryList[0].productList && !cart.discountProductId && !cart.fbtId) {
                for (const item of categoryList[0].productList) {
                  if (item && cart.productId === item.id) {
                    item.quantity = cart.quantity;
                    item.cartProductId = cart.id;
                  }
                }
              }
            });
          }
          if (!categoryList?.length) {
            this.sharedService && this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
          }
          resolve({
            selectedDetails: data.assignData.selectedDetails,
            categoryList: categoryList,
            menuCategoryAvailability: menuCategoryAvailability
          });
        }
      }, searchTextErr => {
        if (searchTextErr) {
          this.dialogService.openDialog({
            header: this.translationService.getTranslation('SHARED.DIALOG_FAILURE_HEADER'),
            message: this.translationService.getTranslation('SHARED.DIALOG_FAILURE_MESSAGE'),
            actionType: this.dialogType.failure,
            button: { right: this.translationService.getTranslation('SHARED.DIALOG_OK_BTN') }
          });
          reject(searchTextErr);
        }
      });
    });
  }
  /**
   * Method used to get store details based on display name.
   * @param data To define the condition based on which data is to retrieved.
   * @param storeDisplayName To define the store display name in the route.
   */
  getStoreDetails(data: any, storeDisplayName?: string, languageCode?: string, currentUrl?: string, queryParam?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.themeService.getStoreInfo(data).subscribe((res: any) => {
        if (res && res.storeInfo && res.storeInfo.storeId && !res.storeInfo.isDeleted && res.storeInfo.isActive) {
          this.headerService.setHeaders('default', 'Plan-Id', res.storeInfo.planId ?? null);
          this.sharedService.industryName = res.storeInfo.industryName;
          this.sharedService.storeName = res.storeInfo.storeName;
          this.sharedService.storeId = res.storeInfo.storeId;
          this.translationService.languageArray = res.storeInfo.storeLanguages;
          this.sharedService.emitCustomEvent('sharedService?.storeId', res.storeInfo.storeId);
          if (storeDisplayName !== undefined) {
            this.sharedService.storeDisplayName = storeDisplayName;
            this.sharedService.emitCustomEvent('sharedService?.storeDisplayName', storeDisplayName);
          }
          // languageCode = res?.storeInfo?.storeLanguages.length > 0 ? (languageCode ?? localStorage.getItem('language')) : languageCode;
          if (languageCode !== undefined && languageCode !== null) {
            const languageCodeFromParam = res?.storeInfo?.storeLanguages.find(res => res.langCode === languageCode);
            if (languageCodeFromParam) {
              this.translationService.isNavigateWithLangCode = true;
              this.setlanguageCode(languageCodeFromParam.langCode, false);
            }
            else {
              if (res.storeInfo.languageCode != null && res.storeInfo.languageCode !== undefined) {
                this.setlanguageCode(res.storeInfo.languageCode, true);
              }
              this.navigate(currentUrl, queryParam, languageCode);
              resolve(res.storeInfo.storeId)
            }
          }
          else {
            this.translationService.isNavigateWithLangCode = false;
            if (res.storeInfo.languageCode != null && res.storeInfo.languageCode !== undefined) {
              this.setlanguageCode(res.storeInfo.languageCode, true);
            }
            else {
              if (localStorage.getItem('language')) {
                localStorage.removeItem('language');
              }
              this.translationService.changeTranslationObj();
            }
          }
          this.translationService.multiLoadTranslation(["shared"]);
          resolve(res.storeInfo.storeId);
        }
        else if (res && res.storeInfo) {
          if ((res.storeInfo.notFound || res.storeInfo.isDeleted)) {
            // this.router.navigate(['/page/customer-not-found']);
            this.router.navigate(['shopapp/home']);
            resolve(0);
          } else if (!res.storeInfo.isActive) {
            this.router.navigate(['/page/not-active']);
            resolve(0);
          }
        }
        this.translationService.multiLoadTranslation(["shared"]);
      }, (err) => {
        if (err) {
          // this.dialogService.openDialog({
          //   header: this.dialogMessages.failureHeader,
          //   message: this.dialogMessages.storefrontPathFailure,
          //   actionType: this.dialogType.failure,
          //   button: { right: this.dialogMessages.okButtonText },
          // });
          this.dialogService.openSnackBar(this.translationService.getTranslation('COMMON.STOREPATH_FAILURE'));
          reject(err);
        }
      });
    });
  }
  /**
   * Method used to navigate if the language code is not correct
   * @param url current path
   * @param queryParams queryParam
   */
  navigate(url, queryParams, languageCode) {
    const urlSplit = url.split('/');
    const codeIndex = urlSplit.indexOf(languageCode);
    const path = urlSplit.slice(codeIndex + 1);
    const finalPath = path.join('/').split('?')[0];
    this.sharedService.pageNavigation(finalPath, '', queryParams);
  }
  /**
   * Method to set the Language Code
   * @param code language Code to be displayed
   */
  setlanguageCode(code: string, isNew: boolean) {
    const language = localStorage.getItem('language');
    if (language && isNew) {
      this.translationService.isNavigateWithLangCode = true;
      this.translationService.languageCode = language;
    } else {
      localStorage.setItem('language', code);
      this.translationService.languageCode = code;
    }
  }
  /**
   * Method used to return current location details.
   * @param data holds the component details.
   * @param details To store the published page details.
   */
  getCurrentLocation(data: any, details: any): Promise<any> {
    if (data && data.locationDetails && data.locationDetails.address) {
      return Promise.resolve(data.locationDetails.address);
    } else {
      return Promise.resolve(null);
    }
  }
  /**
   * Method is used to get the featured categories with sub categories.
   * @param data holds the component details.
   */
  getFeaturedCategoriesWithSubCategories(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptionObj.add(this.themeService.getFeaturedCategoriesWithSubCategories(data.sharedService.storeId, data.locationId).subscribe((res: any) => {
        if (res && res.getFeaturedSubCategories && res.getFeaturedSubCategories.length) {
          resolve(res.getFeaturedSubCategories);
        } else {
          resolve([]);
        }
      }, (err) => {
        if (err) {
          this.commonDialogBox(this.translationService.getTranslation('SHARED.FEATURED_CATEGORY_FAILURE'));
          reject(err);
        }
      }))
    });
  }
  /**
 * Method used to change filter data currency symbol.
 * @param data holds the component details.
 * @param details To store the published page details.
 */
  getCurrentFilterData(data: any, details: any): Promise<any> {
    return new Promise((resolve) => {
      const productDetails = details && details.hasOwnProperty('productListDetails') ? details.productListDetails : details;
      if (data.assignData && data.assignData.websiteSettings && data.assignData.websiteSettings && data.assignData.websiteSettings.currency && data.assignData.websiteSettings.currency.currencySymbol && productDetails && productDetails.filterData && productDetails.filterData.filterArray && productDetails.filterData.filterArray.length) {
        const filterIndex = productDetails.filterData.filterArray.findIndex((item) => item.fieldName === "price");
        if (productDetails.filterData.filterArray[filterIndex] && productDetails.filterData.filterArray[filterIndex].options && productDetails.filterData.filterArray[filterIndex].options.length) {
          productDetails.filterData.filterArray[filterIndex].options.map((filterPrice) => {
            if (filterPrice && filterPrice.display) {
              filterPrice.display = filterPrice.display.replaceAll('$', data.assignData.websiteSettings.currency.currencySymbol);
            }
          });
        }
        resolve({ details: details });
      } else {
        resolve(false);
      }
    });
  }
  /**
   * Method used to set assign data.
   * @param data To define the data from the parent.
   * @returns the query param object.
   */
  setAssignDataForQuickOrderProduct(data: any): object {
    return {
      customerId: data && data.userData && data.userData.customerId ? data.userData.customerId : null,
      searchText: data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.searchText ? data.assignData.selectedDetails.searchText : "",
      locationId: data && data.locationId ? data.locationId : null,
      categoryId: data && data.assignData && data.assignData.selectedDetails && data.assignData.selectedDetails.categoryId ? JSON.stringify(data.assignData.selectedDetails.categoryId) : null
    };
  }
  /**
* Method used to retrieve category details in quick order product list.
* @param data To store the component's values.
*/
  getAllQuickOrderCategories(event: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let selectedCategory, categoryList;
      this.themeService.getAllCategoryDetails(event.sharedService.storeId, event.locationId, event.timeZone).subscribe((res: any) => {
        if (res && res.category && res.category.length) {
          categoryList = res.category;
          selectedCategory = categoryList.find((item) => event.categoryId === item.id) ? event.categoryId : categoryList[0].id;
          const selectedDetails = {
            categoryDetails: categoryList,
            categoryIds: res.categoryIds ? res.categoryIds : [],
            searchText: event.assignData.selectedDetails && event.assignData.selectedDetails.searchText ? event.assignData.selectedDetails.searchText : null,
            categoryId: selectedCategory ? selectedCategory : null
          };
          const assignData = {
            selectedDetails: selectedDetails,
          }
          event.assignData = { ...event.assignData, ...assignData };
          this.getAllProductsDetail(event).then((productResponse: any) => {
            if (productResponse) {
              resolve({
                categoryList: res.category,
                categoryIds: res.categoryIds,
                selectedDetails: selectedDetails,
                menuCategoryAvailability: res.menuCategoryAvailability ? res.menuCategoryAvailability : [],
                productArray: productResponse.productArray,
                cartProducts: productResponse.cartProducts
              });
            }
            else {
              resolve({
                categoryList: res.category,
                categoryIds: res.categoryIds,
                selectedDetails: selectedDetails,
                menuCategoryAvailability: res.menuCategoryAvailability ? res.menuCategoryAvailability : [],
                productArray: []
              });
            }
          })
        } else {
          resolve(false);
        }
      }, (err) => {
        if (err) {
          this.commonDialogBox(this.translationService.getTranslation('SHARED.CATEGORY_FAILURE'));
          reject(err);
        }
      })
    });
  }
  /**
   * Method used to load more product list.
   * @param event defines the pagination details.
   * @param data To store the component's values.
   * @param details To store the published page details.
   */
  onDataEmitQuick(event: boolean, data: any): Promise<any> {
    data.assignData.paginationDetails.offset = Number(data.assignData.paginationDetails.offset) + Number(data.assignData.paginationDetails.limit);
    return new Promise((resolve, reject) => {
      return this.getAllProductsDetail(data).then((dataResponse) => {
        resolve(dataResponse);
      }).catch((err) => {
        if (err) {
          this.commonDialogBox(this.dialogMessages && this.dialogMessages.dataEmitFailure);
          reject(err);
        }
      });
    });
  }
  /**
   * Method used to get products based on selected category.
   * @param event holds the selected category id.
   * @param data holds the component details.
   * @returns the products based on selected category id.
   */
  getAllProductsDetail(event: any): Promise<any> {
    const queryParams = this.setAssignDataForQuickOrderProduct(event);
    if (event.assignData.selectedDetails && event.assignData.selectedDetails.searchText != null && event.assignData.selectedDetails.searchText != '' && event.assignData.selectedDetails.categoryIds) {
      queryParams['categoryId'] = JSON.stringify(event.assignData.selectedDetails.categoryIds);
    } else if (event.assignData.selectedDetails && event.assignData.selectedDetails.searchText != null && event.assignData.selectedDetails.searchText != '' && event.assignData.categoryIds) {
      queryParams['categoryId'] = JSON.stringify(event.assignData.categoryIds);
    }
    return new Promise((resolve, reject) => {
      if (queryParams && queryParams['locationId'] != null) {
        this.quickOrderProductsQuantity(event.sharedService.storeId, queryParams, event).subscribe(async (response: any) => {
          if (response && response.productData && response.productData.length) {
            const productList = response.productData;
            resolve({
              selectedCategory: event.assignData.selectedDetails.categoryDetails,
              searchText: event.assignData.selectedDetails.searchText,
              productArray: productList,
              cartProducts: response.cartProducts
            });
          } else {
            resolve(false);
          }
        }, categoryErr => {
          if (categoryErr) {
            this.dialogService.openDialog({
              header: this.translationService.getTranslation('SHARED.DIALOG_FAILURE_HEADER'),
              message: this.translationService.getTranslation('SHARED.DIALOG_FAILURE_MESSAGE'),
              actionType: this.dialogType.failure,
              button: { right: this.translationService.getTranslation('SHARED.DIALOG_OK_BTN') }
            });
            reject(categoryErr);
          }
        });
      } else {
        resolve(false);
      }
    });
  }
  /**
   * Method used to get products based on selected category.
   * @param event holds the selected category id.
   * @param data holds the component details.
   * @returns the products based on selected category id.
   */
  getAllCategoryProducts(event, data): Promise<any> {
    return new Promise((resolve, reject) => {
      const queryParams = this.setAssignDataForQuickOrderProduct(data);
      queryParams['categoryId'] = event && event.categoryId ? JSON.stringify([event.categoryId]) : null;
      const selectedCategory = data.assignData.categoryList.find((item) => item && item.id === (event && event.categoryId));
      data.assignData = {
        ...data.assignData,
        paginationDetails: {
          offset: 0,
          limit: 30,
          listDataSource: []
        }
      }
      this.quickOrderProductsQuantity(data.sharedService.storeId, queryParams, data).subscribe(async (response: any) => {
        if (response) {
          const productList = response.productData;
          resolve({
            selectedDetails: { categoryDetails: selectedCategory, categoryId: event.categoryId },
            productArray: productList ? productList : [],
            cartProducts: response.cartProducts
          }
          );
        }
        else {
          resolve({
            selectedDetails: { categoryDetails: selectedCategory, categoryId: event.categoryId },
            productArray: []
          });
        }
      }, categoryErr => {
        if (categoryErr) {
          this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
          this.dialogService.openDialog({
            header: this.translationService.getTranslation('SHARED.DIALOG_FAILURE_HEADER'),
            message: this.translationService.getTranslation('SHARED.DIALOG_FAILURE_MESSAGE'),
            actionType: this.dialogType.failure,
            button: { right: this.translationService.getTranslation('SHARED.DIALOG_OK_BTN') }
          });
          reject(categoryErr);
        }
      });
    }
    );
  }
  /**
  * Method used to get products based on search text.
  * @param event holds the selected search text.
  * @param data holds the component details.
  * @returns the products based on search text.
  */
  getAllProductsBySearchText(event, data): Promise<any> {
    const queryParams = this.setAssignDataForQuickOrderProduct(data);
    queryParams['categoryId'] = data.assignData.categoryIds ? JSON.stringify(data.assignData.categoryIds) : null;
    queryParams['searchText'] = event.searchText;
    data.assignData = {
      ...data.assignData,
      paginationDetails: {
        offset: 0,
        limit: 30,
        listDataSource: []
      }
    };
    return new Promise(async (resolve, reject) => {
      this.quickOrderProductsQuantity(data.sharedService.storeId, queryParams, data).subscribe(async (response: any) => {
        if (response) {
          const productList = response.productData;
          resolve({
            selectedDetails: data.assignData.selectedDetails,
            categoryList: data.assignData.categoryList,
            productArray: productList ? productList : [],
            cartProducts: response.cartProducts
          });
        } else {
          resolve({
            selectedDetails: data.assignData.selectedDetails,
            categoryList: data.assignData.categoryList,
            productArray: [],
          });
        }
      }, categoryErr => {
        if (categoryErr) {
          this.dialogService.openDialog({
            header: this.translationService.getTranslation('SHARED.DIALOG_FAILURE_HEADER'),
            message: this.translationService.getTranslation('SHARED.DIALOG_FAILURE_MESSAGE'),
            actionType: this.dialogType.failure,
            button: { right: this.translationService.getTranslation('SHARED.DIALOG_OK_BTN') }
          });
          reject(categoryErr);
        }
      });
    });
  }
  /**
   * Method used to get products after cearing the search text.
   * @param event holds the search text.
   * @param data holds the component details.
   * @param details holds the json data.
   * @returns the products based on search text.
   */
  clearSearchForQuickOrder(event: any, data: any, details: any) {
    return new Promise((resolve, reject) => {
      if (event) {
        data.assignData.selectedDetails = { categoryId: null, searchText: null };
        Object.defineProperty(data.assignData, 'paginationDetails', { value: { offset: 0, limit: 30 } });
        this.getAllQuickOrderCategories(data).then((response: any) => {
          if (response && response.categoryList) {
            resolve(response);
          } else {
            resolve(false);
          }
        }).catch((err) => {
          reject(err);
        });
      } else {
        resolve(false);
      }
    });
  }
  /**
   * Method used to get products after cearing the search text.
   * @param storeId holds the store id.
   * @param queryParams holds the customerId,CategoryId,LocationId,SearchText
   * @returns the product quantity based on cart
   */
  quickOrderProductsQuantity(storeId: any, queryParams: any, event?: any): any {
    event.assignData = {
      ...event.assignData,
      paginationDetails: {
        ...event.assignData.paginationDetails,
        offset: event && event.assignData && event.assignData.paginationDetails && event.assignData.paginationDetails.offset ? event.assignData.paginationDetails.offset : 0,
        limit: event && event.assignData && event.assignData.paginationDetails && event.assignData.paginationDetails.limit ? event.assignData.paginationDetails.limit : 30
      }
    };
    if (event && event.assignData && event.assignData.paginationDetails)
      event.assignData.paginationDetails['listDataSource'] = event.assignData.paginationDetails.offset === 0 ? [] : event.assignData.paginationDetails.listDataSource;
    queryParams['limit'] = event.assignData.paginationDetails.limit;
    queryParams['offset'] = event.assignData.paginationDetails.offset;
    let productData, cartId, localStorageProducts, cartProducts;
    return this.themeService.getAllQuickOrderProducts(storeId, queryParams).pipe(filter((res: any) => {
      if (res && res['productData'] && res['productData'].length) {
        productData = res['productData'];
        productData.map(value => value.quantity = 0);
        if (localStorage.getItem('cart')) {
          localStorageProducts = JSON.parse(localStorage.getItem('cart'));
          if (localStorageProducts && localStorageProducts.cartProduct && localStorageProducts.cartProduct.products) {
            localStorageProducts.cartProduct.products.map((localStorageProduct) => {
              if (localStorageProduct && localStorageProduct.productId) {
                const index = productData.findIndex((product) => product && product.product && product.product.id === localStorageProduct.productId && localStorageProduct.variantId === product.id
                  && ((product.variantName === localStorageProduct.combinationName) || (!product.variantName && !localStorageProduct.combinationName))
                );
                if (index >= 0) {
                  productData[index].quantity = localStorageProduct.quantity;
                  productData[index]['cartProductId'] = localStorageProduct.id ?? null;
                }
              }
            });
          }
        }
      }
      return true;
    }), mergeMap(() => {
      if (queryParams && queryParams.customerId)
        return this.themeService.getVerifiedCartProducts(queryParams.customerId, { locationId: queryParams.locationId, isProductDetails: true }, storeId);
      else
        return of(true);
    }), mergeMap((res) => {
      if (res && res['cartProducts'] && res['cartProducts'].cartProducts && res['cartProducts'].cartProducts.length) {
        cartProducts = res['cartProducts'].cartProducts;
        cartId = cartProducts[0].cartId;
        cartProducts.forEach((cart) => {
          if (cart && productData && !cart.discountProductId && !cart.fbtId) {
            productData.find((product) => {
              if (product && product.product && cart.productId === product.product.id && cart.variantId === product.id && ((product.variantName === cart.combinationName) || (!product.variantName && !cart.combinationName))) {
                product.quantity = Number(cart.quantity);
                product.cartProductId = cart.id;
              }
            });
          }
        });
      }
      if (productData) {
        event.assignData.paginationDetails['listDataSource'] = event.assignData && event.assignData.paginationDetails && event.assignData.paginationDetails.listDataSource.length && event.assignData.paginationDetails.listDataSource.length > 0 ? event.assignData.paginationDetails.listDataSource.concat(productData) : productData;
        return of({
          productData: event.assignData.paginationDetails.listDataSource,
          cartId: cartId ? cartId : null,
          cartProducts: cartProducts ? cartProducts : localStorageProducts && localStorageProducts.cartProduct && localStorageProducts.cartProduct.products ? localStorageProducts.cartProduct.products : null
        });
      }
      else
        return of(false);
    }));
  }
  /**
   * Method getAllBrands used to fetch all the brands.
   * @param data To store the component's values.
   */
  getAllBrands(data?) {

    return new Promise((resolve, reject) => {
      this.sharedService.getAllBrands(this.sharedService.storeId, data).subscribe((res: any) => {
        if (res && res.result && res.result.rows) {
          resolve({
            brandList: res.result.rows.length ? res.result.rows : []
          });
        }
      }, (err) => {
        if (err) {
          reject(err);
        }
      })
    });
  }
  /**
   * Method getAllBrands used to fetch featured brands.
   * @param data To store the component's values.
   */
  getFeaturedBrands(data) {
    return new Promise((resolve, reject) => {
      const queryParams = {
        limit: 5,
        offset: 0, sort: true
      };
      this.sharedService.getAllBrands(data.sharedService.storeId, queryParams).subscribe((res: any) => {
        if (res && res.result && res.result.rows) {
          resolve({
            featuredBrands: res.result.rows.length ? res.result.rows : []
          });
        }
      }, (err) => {
        if (err) {
          reject(err);
        }
      })
    });
  }
  /**
 * Method topBrands used to fetch top brands.
 * @param data To store the component's values.
 */
  topBrands(data) {
    return new Promise((resolve, reject) => {
      const queryParams = {
        limit: 10,
        offset: 0, sort: true
      };
      this.sharedService.getAllBrands(data.sharedService.storeId, queryParams).subscribe((res: any) => {
        if (res && res.result && res.result.rows) {
          resolve({
            featuredBrands: res.result.rows.length ? res.result.rows : []
          });
        }
      }, (err) => {
        if (err) {
          reject(err);
        }
      })
    });
  }
  /**
   * Method used to get discount details for a products.
   * @param event holds the variant id.
   * @param data holds the data.
   */
  getDiscountDetailsForSingleProduct(event: any, data: any): Promise<any> {
    let automaticDiscountDetails, buyxGetyDiscountDetails = [], salesCountdownTimerOffers = [], isCombinationAvailable = true, isWishlist = false, isBasicCustomization = false;
    if (event && event.hasOwnProperty('isCombinationAvailable')) {
      isCombinationAvailable = event.isCombinationAvailable;
    }
    const stockCondition = event && event.selectedVariant && event.selectedVariant.stockCount ?
      event.selectedVariant.stockCount.find((location: { inventoryLocationId: any; }) => location && location.inventoryLocationId === data.locationDetails.id) : {};
    return new Promise((resolve, reject) => {
      if (event && event.selectedVariant && event.selectedVariant.variantId) {
        this.themeService.getAutomaticDiscountForSingleProduct({ storeId: data.sharedService.storeId, productId: data?.productId, variantId: event.selectedVariant.variantId, customerId: data?.userData?.customerId, roleId: data?.userData?.roleId }).pipe(filter((response: any) => {
          if (response && response.discount && response.discount.length) {
            automaticDiscountDetails = response.discount;
          } else {
            automaticDiscountDetails = [];
          }
          return true;
        }), mergeMap((response: any) => {
          if (response) {
            return this.themeService.getBuyxGetyDetailsForSingleProduct({ storeId: data.sharedService.storeId, productId: data?.productId, variantId: event.selectedVariant.variantId, customerId: data?.userData?.customerId, roleId: data?.userData?.roleId, locationId: data?.locationId });
          } else {
            return of(true);
          }
        }), mergeMap((response: any) => {
          if (response && response.discount && response.discount.length) {
            buyxGetyDiscountDetails = stockCondition && stockCondition.isPreorder && stockCondition.stock == 0 ? [] : response.discount;
          } else {
            buyxGetyDiscountDetails = [];
          }
          const currentDate = new Date();
          const storeId = data && data.sharedService && data.sharedService.storeId;
          const productId = data && data.productId;
          return this.sharedService.getOneProductTimeBasedOffers(storeId, productId, event.selectedVariant.variantId, currentDate);
        }), mergeMap((response: any) => {
          let offers = [];
          if (response.discountDetails) {
            if (response.discountDetails.allProducts && response.discountDetails.allProducts.length > 0) {
              offers = response.discountDetails.allProducts;
            }
            if (response.discountDetails.productOrCategoryLevel && response.discountDetails.productOrCategoryLevel.length > 0) {
              for (const offer of response.discountDetails.productOrCategoryLevel) {
                offers[offers.length] = offer.timerDiscount;
              }
            }
            salesCountdownTimerOffers = offers;
            isBasicCustomization = response.discountDetails.isBasicCustomization ? response.discountDetails.isBasicCustomization : false;
          }
          if (data?.userData?.customerId && data?.userData?.storeId) {
            return this.themeService.getWishlistDetails(data.userData.storeId, Number(data.userData.customerId), Number(data?.productId), { variantId: event.selectedVariant.variantId });
          } else {
            return of(true);
          }
        })).subscribe((response: any) => {
          if (response) {
            isWishlist = response && response['wishList'] ? true : false;
            resolve({
              automaticDiscountDetails: automaticDiscountDetails,
              buyxGetyDiscountDetails: buyxGetyDiscountDetails,
              isCombinationAvailable: isCombinationAvailable,
              isWishlist: isWishlist,
              salesCountdownTimerOffers: salesCountdownTimerOffers,
              isBasicCustomization: isBasicCustomization
            });
          }
        }, err => {
          if (err) {
            // this.dialogService.openDialog({
            //   header: this.dialogMessages.failureHeader,
            //   message: this.dialogMessages && this.dialogMessages.failureMessage,
            //   actionType: this.dialogType.failure,
            //   button: { right: this.dialogMessages.okButtonText }
            // });
            this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.DIALOG_FAILURE_MESSAGE'))
            reject(err);
          }
        });
      } else {
        resolve({
          automaticDiscountDetails: [],
          buyxGetyDiscountDetails: [],
          isCombinationAvailable: isCombinationAvailable
        });
      }
    });
  }
  /**
   * Method used to get fbt products with main product.
   * @param event to get event emitted from component.
   * @param data to get data from services.
   * @returns {FbtResolveModel}
   */
  setFbtMainProduct(event, data): Promise<FbtResolveModel | boolean> {
    return new Promise((resolve) => {
      if (data.productId && data.assignData && data.assignData.productDetails && !data.assignData.productDetails.isProductAddOns && !data.assignData.productDetails.isProductCustomizable && !data.assignData.productDetails.isDigitalProduct && ((event.selectedVariant && event.selectedVariant.stockCount.findIndex(location=> location.inventoryLocationId == data.locationId && location.stock !== 0) > -1) || (!data.assignData.productDetails.isVariants && data.assignData.productDetails.variantCombinationDetails && data.assignData.productDetails.variantCombinationDetails.length > 0 && data.assignData.productDetails.variantCombinationDetails[0].multiLocationVariantStocks.findIndex(location=> location.inventoryLocationId == data.locationId && location.stock !== 0) > -1 && event.emitFlag == 0)) && !data.assignData.productDetails.menuNotAvailable && data.assignData.productDetails.productAvailability && !data.assignData.productDetails.outOfStock && (!data.assignData.productDetails.productModifiers || (data.assignData.productDetails.productModifiers && data.assignData.productDetails.productModifiers.length === 0)) && data.assignData.fbtProducts) {
        if (!data.assignData.productDetails.isVariants) {
          event.selectedVariant = this.setValuesForNonVariant(data);
        }
        const image = this.imageForProduct(data, event.selectedVariant.variantId);
        const { productId, assignData: { productDetails: { name, weightUnit = null } } } = data;
        const { selectedVariant: { variantId, combinationName, costComparePrice, price, stockCount: [{ stock }] }, discountPrice, discountPercentage } = event;
        const mainProduct = {
          productId: Number(productId), variantId, combinationName,
          costComparePrice: costComparePrice || price,
          image, name,
          price: discountPrice || price,
          discountPercentage, isFbtDefaultProduct: true, stock,
          weightUnit: weightUnit
        };
        const fbtProducts = this.assignMainProductToFbt(data, mainProduct);
        resolve({ getFrequentlyBoughtTogether: fbtProducts });
      }
      else if ((event.emitFlag && event.emitFlag == 0) || !event.emitFlag)
        resolve({ getFrequentlyBoughtTogether: { isActive: false } });
      else resolve(true)
    });
  }
  /**
   * Method used to get non variant product basic information.
   * @param {any} data to get data from services.
   * @returns {NonVariantProductValuesModel}
   */
  setValuesForNonVariant(data: any): NonVariantProductValuesModel {
    return {
      variantId: data.assignData.productDetails.variantCombinationDetails[0].id,
      costComparePrice: data.assignData.productDetails.variantCombinationDetails[0].costComparePrice,
      price: data.assignData.productDetails.variantCombinationDetails[0].price,
      stockCount: [{
        stock: data.assignData.productDetails.variantCombinationDetails[0].multiLocationVariantStocks && data.assignData.productDetails.variantCombinationDetails[0].multiLocationVariantStocks.length > 0 && data.assignData.productDetails.variantCombinationDetails[0].multiLocationVariantStocks[0].stock
      }]
    };
  }
  /**
   * Method is used to get image for selected variant.
   * @param {any} data to get data from services.
   * @returns {string} image url
   */
  imageForProduct(data, selectedVariantId: number): string {
    let image: string;
    if (!data.assignData.productDetails.isVariants && data.assignData.productDetails.featuredImage) {
      image = data.assignData.productDetails.featuredImage;
    } else if (data.assignData.productDetails.variantImages && data.assignData.productDetails.variantImages.length > 0) {
      const variantImage = data.assignData.productDetails.variantImages.find(image => image.itemId === selectedVariantId);
      if (variantImage && variantImage.attachmentCategoryTypesMappings && variantImage.attachmentCategoryTypesMappings.length > 0)
        image = variantImage.attachmentCategoryTypesMappings[0].fileUrl;
      else
        image = data.assignData.productDetails.featuredImage;
    } else
      image = data.assignData.productDetails.featuredImage;
    return image;
  }
  /**
   * Method used to merge main product and fbt products.
   * @param {any} data to get data from services.
   * @param {object} mainProduct to get main product for fbt.
   * @returns {FbtProductsModel} fbt products with main product.
   */
  assignMainProductToFbt(data, mainProduct): FbtProductsModel {
    let fbtProducts;
    if (data.assignData.fbtProducts && data.assignData.fbtProducts.isActive && data.assignData.fbtProducts.fbtProductMappings && data.assignData.fbtProducts.fbtProductMappings.length > 0) {
      fbtProducts = _.cloneDeep(data.assignData.fbtProducts);
      mainProduct['fbtId'] = data.assignData.fbtProducts.id;
      fbtProducts.fbtProductMappings[0] = mainProduct;
    }
    return fbtProducts;
  }
  /*
   * Method used to get buyx gety discount details for a particular product.
   * @param event holds the selected variant id.
   * @param data holds the component details.
   */
  getBuyxGetyDetailsForSingleProduct(data: any, event: any): Promise<any> {
    const discountDetails = {
      storeId: data?.sharedService?.storeId,
      productId: data?.productId,
      customerId: data?.userData?.customerId,
      roleId: data?.userData?.roleId,
      locationId: data?.locationId
    };
    if (event && event.selectedVariant && event.selectedVariant.variantId) {
      discountDetails['variantId'] = event.selectedVariant.variantId;
    } else if (data && data.variantId) {
      discountDetails['variantId'] = data.variantId;
    }
    return new Promise((resolve, reject) => {
      if (discountDetails && discountDetails['variantId']) {
        this.themeService.getBuyxGetyDetailsForSingleProduct(discountDetails).subscribe((response: any) => {
          if (response && response.discount && response.discount.length) {
            resolve({
              buyxGetyDiscountDetails: response.discount
            });
          } else {
            resolve({
              buyxGetyDiscountDetails: []
            })
          }
        }, (err) => {
          if (err) {
            reject(err);
          }
        });
      } else {
        resolve({
          buyxGetyDiscountDetails: []
        });
      }
    });
  }
  /**
   * Method used to get category name for product based on category id.
   * @param data holds the component details.
   */
  getCategoryNameForProduct(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (data && data.categoryId) {
        this.themeService.getCategoryNameForProduct(data && data.sharedService && data.sharedService.storeId, data.categoryId).subscribe((response: any) => {
          if (response && response.categoryDetails) {
            resolve({
              categoryDetails: response.categoryDetails
            });
          }
        }, err => {
          if (err) {
            reject(err);
          }
        });
      } else {
        resolve({
          categoryDetails: {}
        });
      }
    });
  }

  createRecentlyViewedProducts(event: any, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (event && event.customerId) {
        const bodyData = { customerId: event?.customerId, productId: event?.productId };
        this.themeService.createRecentlyViewedProducts(data && data.sharedService && data.sharedService.storeId, bodyData).subscribe((response: any) => {
          if (response) {
            resolve(true);
          }
        }, err => {
          if (err) {
            reject(err);
          }
        });
      } else {
        resolve(true);
      }
    });
  }
  getRecentlyViewedProducts(data: any, details): Promise<any> {
    return new Promise((resolve, reject) => {
      if (data && data.customerId) {
        const queryData = { customerId: data?.customerId };
        this.themeService.getRecentlyViewedProducts(data && data.sharedService && data.sharedService.storeId, queryData).subscribe((response: any) => {
          if (response) {
            resolve(response);
          }
        }, err => {
          if (err) {
            reject(err);
          }
        });
      } else {
        resolve(true);
      }
    });
  }
  /**
   * Method used to set OGS details.
   * @param data holds storeId and productId.
   */
  setOgsDetails(data: any): Promise<any> {
    const ogsDetails = { ogsImage: '', ogsTitle: '', ogsDescription: '' };
    return new Promise((resolve, reject) => {
      this.subscriptionObj.add(this.sharedService.getOgsDetails(data?.sharedService?.storeId, data?.productId).subscribe((response: any) => {
        if (response && response.ogsDetails && response.ogsDetails.productOgs) {
          ogsDetails.ogsImage = (response.ogsDetails.productOgs.og && response.ogsDetails.productOgs.og.ogsImage) || (response.ogsDetails.productOgs.featuredImage);
          ogsDetails.ogsDescription = (response.ogsDetails.productOgs.og && response.ogsDetails.productOgs.og.ogsMetaDescription) || (response.ogsDetails.productOgs.og && response.ogsDetails.productOgs.og.ogsDescription) || (response.ogsDetails.productOgs.description);
          ogsDetails.ogsTitle = response.ogsDetails.productOgs.og && response.ogsDetails.productOgs.og.ogsTitle ? response.ogsDetails.productOgs.og.ogsTitle : response.ogsDetails.productOgs.name;
        }
        resolve({ ogsDetails: ogsDetails });
      }, (err) => {
        if (err) {
          reject(err);
        }
      }));
    });
  }

  /**
 * Method used to get preorder product count
 * @param data holds storeId, productId and customerId.
 */
  checkCustomerLimit(data: any): Promise<any> {
    this.sharedService.commonLoader && this.sharedService.commonLoader.next(true);
    return new Promise((resolve, reject) => {
      const paramData = {
        customerId: data?.customerId,
        langCode: this.translationService.languageCode
      }
      this.subscriptionObj.add(this.sharedService.checkPreorderLimit(data?.sharedService?.storeId, data?.productId, paramData).subscribe((response: any) => {
        if (response) {
          this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
          resolve({ orderCount: response.orderCount });
        }
      }, (err) => {
        this.sharedService.commonLoader && this.sharedService.commonLoader.next(false);
        if (err) {
          reject(err);
        }
      }));
    });
  }
  /**
     * Method used to GET CATEGORIES for navbar.
     * @param data holds storeId and productId.
     */
  getCategoriesForNavBar(data: any) {
    return new Promise((resolve, reject) => {
      this.productDetailsService.getCategories(data.locationId, data.timeZone, data.storeId).then((res: any) => {
        let categoryList = [{ id: 0, categoryImageUrl: "themes/retail2/allProductsImage.png", categoryName: this.translationService.getTranslation('SHARED.ALL_PRODUCTS'), categoryIdArray: res.categoryIds, category: { id: 0, name: this.translationService.getTranslation('SHARED.ALL_PRODUCTS') } }];
        if (res) {
          categoryList = [...categoryList, ...res.categoryList];
          resolve({
            categoryList: categoryList
          })
        }
      }, (err) => {
        if (err) {
          // this.dialogService.openDialog({
          //   header: this.dialogMessages.failureHeader,
          //   message: this.dialogMessages.errorMessage,
          //   actionType: this.dialogType.failure,
          //   button: { right: this.dialogMessages.okButtonText }
          // });
          reject(err);
        }
      })
    })
  }
  /**
   * Method used to get category list with product count.
   * @param data holds the component details.
   */
  getCategoriesWithProductCount(data: any): Promise<any> {
    let categoryList = [], categoryId;
    return new Promise(async (resolve, reject) => {
      this.subscriptionObj.add(this.themeService.getCategoriesWithProductCount(data?.sharedService?.storeId, data?.locationId, data?.timeZone).subscribe((response: any) => {
        if (response) {
          categoryList = response && response.categoryListWithProductCount && response.categoryListWithProductCount.length ? response.categoryListWithProductCount : [];
          data.assignData['categoryList'] = categoryList;
          const offset = 0, limit = 3;
          categoryId = this.sharedService.getCategoryId(categoryList, offset, limit);
          const details = {
            categoryId: categoryId && categoryId.length ? categoryId : [],
            offset: 0,
            limit: 3
          };
          this.getProductsBasedOnCategoriesForHomePage(details, data).then((res: any) => {
            if (res && res.categoryList) {
              const selectedDetails = {
                discountId: data.assignData.selectedDetails && data.assignData.selectedDetails?.discountId ? data.assignData.selectedDetails.discountId : null
              };
              resolve({
                categoryList: res.categoryList,
                selectedDetails: selectedDetails,
                menuCategoryAvailability: response && response.menuCategoryAvailability && response.menuCategoryAvailability.length ? response.menuCategoryAvailability : []
              });
            }
          });
        }
      }, (err) => {
        if (err) {
          reject(err);
        }
      }
      ));
    });
  }
  /**
   * Method used to get products based on category.
   * @param categoryDetails  holds the categoryId,offset and limit value.
   * @param data holds the component details.
   */
  getProductsBasedOnCategoriesForHomePage(categoryDetails, data) {
    const queryParams = {
      offset: 0,
      customerId: data && data.userData && data.userData.customerId ? data.userData.customerId : null,
      locationId: data && data.locationId ? data.locationId : null,
      categoryId: categoryDetails && categoryDetails.categoryId && categoryDetails.categoryId.length ? JSON.stringify(categoryDetails.categoryId) : []
    };
    let categoryList;
    return new Promise((resolve, reject) => {
      this.productDetailsService.getProductsByCategory(data?.sharedService?.storeId, queryParams).subscribe((response: any) => {
        if (response && response.productData) {
          categoryList = this.sharedService.setProductsForCategory(data, response.productData, categoryDetails);
          resolve({
            categoryList: categoryList
          });
        } else {
          resolve({
            categoryList: data && data.assignData && data.assignData.categoryList && data.assignData.categoryList.length ? data.assignData.categoryList : []
          });
        }
      }, (err) => {
        if (err) {
          reject(err);
        }
      });
    });
  }
  /**
   * Method used to get wishlist details and sales discount timer details of a product.
   * @param event holds variant id and product id.
   * @param data holds component details.
   */
  getWishlistDetails(event: any, data: any): Promise<any> {
    let isWishlist = false, salesCountdownTimerOffers = [];
    return new Promise((resolve, reject) => {
      if (data?.userData?.storeId && event?.productId && event?.variantId) {
        this.themeService.getWishlistDetails(data.userData.storeId, Number(data.userData.customerId), Number(event.productId), { variantId: event.variantId }).pipe(filter((response: any) => {
          if (response && response.wishList) {
            isWishlist = true;
          } else {
            isWishlist = false;
          }
          return true;
        }), mergeMap((response: any) => {
          if (response) {
            const currentDate = new Date();
            return this.sharedService.getOneProductTimeBasedOffers(data.userData.storeId, event.productId, event.variantId, currentDate);
          } else {
            return of(true);
          }
        })).subscribe((response: any) => {
          if (response && response.discountDetails) {
            let offers = [];
            if (response.discountDetails.allProducts && response.discountDetails.allProducts.length > 0) {
              offers = response.discountDetails.allProducts;
            }
            if (response.discountDetails.productOrCategoryLevel && response.discountDetails.productOrCategoryLevel.length > 0) {
              for (const offer of response.discountDetails.productOrCategoryLevel) {
                offers[offers.length] = offer.timerDiscount;
              }
            }
            salesCountdownTimerOffers = offers;
          }
          resolve({
            isWishlist: isWishlist,
            salesCountdownTimerOffers: salesCountdownTimerOffers
          });

        }, err => {
          if (err) {
            this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.DIALOG_FAILURE_MESSAGE'))
            reject(err);
          }
        });
      } else {
        resolve({
          isWishlist: isWishlist,
          salesCountdownTimerOffers: salesCountdownTimerOffers
        });
      }
    });
  }
  /**
   * Method which is used to get the all discount details.
   * @param data To store the component's values.
   */
  getDiscountDetails(data: any): Promise<any> {
    let routeName;
    if (data && data.sharedService && data.sharedService.storeId) {
      routeName = data.userData && data.userData.customerId ? 'stores/' + data.sharedService.storeId + '/discounts/user' : 'stores/' + data.sharedService.storeId + '/discounts/deals';
    }
    return new Promise((resolve, reject) => {
      this.subscriptionObj.add(this.sharedService.getDealsDetails(routeName, 'Manual', data.sharedService.storeId >= 0 ? data.sharedService.storeId : null).subscribe((res: any) => {
        if (res && res.discount && res.discount.rows && res.discount.rows.length) {
          resolve(res.discount.rows);
        }
        else {
          resolve([]);
        }
      }, (err) => {
        if (err) {
          this.dialogService.openSnackBar(this.dialogMessages && this.dialogMessages.offers);
          reject(err);
        }
      }))
    })
  }
  /**
   * Method which is used to get all Featured products for the particular categories.
   * @param data To store the component's values.
   */
  getFeaturedCategoriesProducts(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptionObj.add(this.sharedService.getCategoriesProducts(data.sharedService.storeId, data?.userData?.customerId ? data?.userData?.customerId : null, data.locationDetails.id).subscribe((res: any) => {
        if (res && res.featuredCategoryProducts) {
          resolve(res.featuredCategoryProducts);
        }
        else {
          resolve([]);
        }
      }, (err) => {
        if (err) {
          this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.OFFERS_FAILED'));
          reject(err);
        }
      }))
    })
  }
  /**
   * Method used to get featuered products or categories based on the selection.
   * @param data To store the component's values.
   * @param details To store the published page details.
   * @returns Array of featured products or category
   */
  getCommonSliderData(data: any, details: any, sectionId: number) {
    return new Promise((resolve, reject) => {
      if (details && data) {
        if (details?.templateSettings?.displayProduct === "recentlyViewedItems")
          this.getRecentlyViewedProducts({ customerId: data.currentUser && data.currentUser.customerId, sharedService: { storeId: data.sharedService && data.sharedService.storeId } }, null).then((res) => {
            if (res && res.recentlyViewedProducts && res.recentlyViewedProducts.length)
              resolve(res.recentlyViewedProducts);
            else
              resolve([]);
          }, (err) => {
            reject(err)
          });
        else if (details?.templateSettings?.displayProduct === "featuredProducts")
          this.getAllFeaturedProducts({ sharedService: { storeId: data.sharedService && data.sharedService.storeId }, locationId: data.locationDetails && data.locationDetails.id }, { productCount: 10 }).then((res) => {
            if (res)
              resolve({ selectedDetail: details?.templateSettings?.displayProduct, data: res.length ? res : [], sectionId: sectionId });
            else
              resolve([]);
          }, (err) => {
            reject(err)
          });
        else if (details?.templateSettings?.displayProduct === "getBrands")
          this.sharedService.getAllBrands(data?.sharedService?.storeId, { limit: 10, offset: 0, sort: true }).subscribe((res: any) => {
            if (res && res.result && res.result.rows) {
              resolve({ selectedDetail: details?.templateSettings?.displayProduct, data: res.result.rows.length ? res.result.rows : [], sectionId: sectionId });
            } else {
              resolve([]);
            }
          }, (err) => {
            if (err) {
              reject(err);
            }
          })
        else if (details?.templateSettings?.displayProduct === "specificCategory")
          this.getProductCategories(data, details).then((res: any) => {
            if (res) {
              resolve({ specificCategoryId: details?.templateSettings?.displaySpecificProduct?.id, selectedDetail: details?.templateSettings?.displayProduct, data: res.productData ? res.productData : [], sectionId: sectionId });
            } else
              resolve([]);
          }, (err) => {
            reject(err)
          });
        else if (details?.templateSettings?.displayProduct === "topProducts")
          this.getTopRatedProducts(data).then((res: any) => {
            if (res) {
              resolve({ selectedDetail: details?.templateSettings?.displayProduct, data: res ? res : [], sectionId: sectionId });
            } else
              resolve([]);
          }, (err) => {
            reject(err)
          });
        else
          this.getAllFeaturedCategories({ sharedService: { storeId: data.sharedService && data.sharedService.storeId }, locationId: data.locationDetails && data.locationDetails.id }).then((res) => {
            if (res)
              resolve({ selectedDetail: details?.templateSettings?.displayProduct, data: res.length ? res : [], sectionId: sectionId })
            else
              resolve([]);
          }, (err) => {
            reject(err)
          });
      }
    })
  }
  /**
   * Method is used to get frequently bought together
   * @param data  To store the component's values.
   * @returns Promise
   */
  getFrequentlyBoughtTogether(data): Promise<FbtProductsModel> {
    return new Promise((resolve, reject) => {
      if (data.routeValue && data.routeValue.some((route: string) => route.includes("productdetails"))) {
        let cartProductId, variantId;
        this.subscriptionObj.add(this.route.queryParams.subscribe(queryParam => {
          if (!data.customerId || (data.userData && data.userData.shopappUser)) {
            const filterFromStroage = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : '';
            if (filterFromStroage && cartProductId) {
              variantId = filterFromStroage?.cartProduct?.products?.find((val) => val.productId === Number(queryParam.productId))?.variantId;
            }
          }
          if (queryParam && queryParam.cartProductId && data.customerId && data.userData && !data.userData.shopappUser)
            cartProductId = queryParam.cartProductId;
          if (queryParam && queryParam.variantId)
            variantId = queryParam.variantId;
        }));
        if (data) {
          this.subscriptionObj.add(this.sharedService.getFrequentlyBoughtTogether(data.sharedService.storeId, data.productId, { locationId: data.locationId, customerId: data.customerId, timeZone: data.timeZone, ...(cartProductId ? { cartProductId } : {}), ...(variantId ? { variantId } : {}) }).subscribe({
            next: (res: FbtResponseModel) => {
              if (res) {
                data['assignData']['fbtProducts'] = res.fbtResponse;
                resolve(res.fbtResponse);
              }
            }, error: (err) => {
              reject(err);
            }
          }));
        }
      } else {
        resolve({ isActive: false });
      }
    });
  }

  /* Method which is used to get the all discount details.
  * @param data To store the component's values.
  */
  getAllSalesCountdownTimerDiscounts(data: any): Promise<any> {
    const storeId = data && data.sharedService && data.sharedService.storeId;
    const date = {
      currentDate: new Date()
    }
    return new Promise((resolve, reject) => {
      if (storeId && date && date.currentDate) {
        this.subscriptionObj.add(this.sharedService.getAllSalesCountdownTimerDiscountDetails(date, storeId).subscribe((res: any) => {
          if (res && res.timerDiscountDetails && res.timerDiscountDetails.length) {
            resolve(res.timerDiscountDetails);
          }
          else {
            resolve([]);
          }
        }, (err) => {
          if (err) {
            this.dialogService.openDialog({
              header: this.dialogMessages.failureHeader,
              message: this.dialogMessages && this.dialogMessages.failureMessage,
              actionType: this.dialogType.failure,
              button: { right: this.dialogMessages.okButtonText }
            });
            reject(err);
          }
        }))
      }
      else {
        this.dialogService.openDialog({
          header: this.dialogMessages.failureHeader,
          message: this.dialogMessages && this.dialogMessages.storedIdMissing,
          actionType: this.dialogType.failure,
          button: { right: this.dialogMessages.okButtonText }
        });
        reject(this.dialogMessages && this.dialogMessages.storedIdMissing);
      }
    })
  }
  /**
   * Method used to get the details of selected single product.
   * @param data To store the component's values.
   * @param details To store the published page details.
   */
  getSingleProduct(data: any, details: any): Promise<any> {
    let productPrice;
    return new Promise((resolve, reject) => {
      this.themeService.getSingleProductOptions(this.sharedService.storeId, { locationId: data?.locationId, timeZone: data.timeZone }).pipe(mergeMap((res: any) => {
        if (details?.singleProductSettings?.singleProduct) {
          data['productId'] = details?.singleProductSettings?.singleProduct;
        } else {
          if (res['product']?.length) {
            data['productId'] = res['product'][0]?.id;
          }
        }
        if (res['product']?.length && data?.productId) {
          return this.themeService.getSingleProductDetails(data?.productId, data?.sharedService?.storeId, { locationId: data?.locationId });
        }
      })).subscribe((res: any) => {
        if (res) {
          if (res?.['productSummary']?.productDatas?.variantCombinationDetails?.length) {
            productPrice = res?.['productSummary']?.productDatas?.variantCombinationDetails[0];
          } else {
            if (res?.['productSummary']?.productDatas?.productOptionMappings?.length)
              productPrice = res?.['productSummary']?.productDatas?.productOptionMappings[0]?.option?.optionValues[0]?.productVariantMappings[0]?.variantCombinationDetail;
          }
          resolve({
            productDetail: res?.['productSummary'],
            productPrice: productPrice
          });
        }
      }, (err) => {
        if (err) {
          // this.dialogService.openSnackBar(this.dialogMessages && this.dialogMessages.singleProductDetailFailure);
          reject(err);
        }
      });
    })
  }
  /**
   * Method used to get the details of product notification.
   * @param data To store the component's values.
   */
  getProductNotification(data) {
    let message: []; let count: [], cart, isCart;
    return new Promise((resolve, reject) => {
      if (data && data.sharedService && data.sharedService.storeId && data.productId) {
        this.sharedService.getOneCartAndWishlistNotificationMessg(data.sharedService.storeId, { isCart: true }).pipe(filter((response: any) => {
          if (response) {
            message = response.data
          }
          return true;
        }), mergeMap((response: any) => {
          if (response) {
            return this.sharedService.getOneProductNotification(data.sharedService.storeId, data.productId);
          }
        }),
          mergeMap((response1: any) => {
            if (response1) {
              count = response1.count
              return this.sharedService.checkLiveNotification(data.sharedService.storeId);
            }
          })).subscribe({
            next: (res: any) => {
              if (res) {
                this.liveNotification = res.liveNotification;
                cart = res.liveNotification.cart
                if (this.liveNotification && this.liveNotification.storefrontFeature && this.liveNotification.storefrontFeature.hasOwnProperty('ADD_TO_CART_NOTIFICATION')) {
                  if (!this.liveNotification.storefrontFeature.ADD_TO_CART_NOTIFICATION) {
                    isCart = true;
                  } else {
                    isCart = false;
                  }
                }
                else {
                  isCart = false;
                }
              }
              resolve({
                messageNotification: message,
                countNotification: count,
                cart: cart,
                isCart: isCart
              });
            },
            error: err => {
              if (err) {
                this.dialogService.openSnackBar(this.dialogMessages && this.dialogMessages.failureMessage)
                reject(err);
              }
            }
          });
      }
    });
  }
  /**
   * Method used to get the details of product wishlist notification.
   * @param data To store the component's values.
   */
  getOneProductWishlistNotification(data): Promise<any> {
    let message: []; let count: [], wishlistCount, isWishlistCount;
    return new Promise((resolve, reject) => {
      // if (data && data.sharedService && data.sharedService.storeId && data.productId) {
      //   this.sharedService.checkLiveNotification(data.sharedService.storeId).pipe(filter((response: any) => {
      //     if (response) {
      //       this.liveNotification = response.liveNotification;
      //       isWishlistCount = response.liveNotification.isWishlistCount
      //       if (this.liveNotification && this.liveNotification.storefrontFeature && this.liveNotification.storefrontFeature.hasOwnProperty('WISHLIST_NOTIFICATION')) {
      //         if (!this.liveNotification.storefrontFeature.WISHLIST_NOTIFICATION) {
      //           wishlistCount = true;
      //         } else {
      //           wishlistCount = false;
      //         }
      //       }
      //       else {
      //         wishlistCount = true;
      //       }
      //     }
      //     return isWishlistCount
      //   }), mergeMap((res: any) => {
      //     if (res) {
      //       return this.sharedService.getOneCartAndWishlistNotificationMessg(data.sharedService.storeId, { isCart: false })
      //     }
      //   }), mergeMap((response1: any) => {
      //     if (response1) {
      //       message = response1.data;
      //     }
      //     return this.sharedService.getOneProductWishlistNotification(data.sharedService.storeId, data.productId);
      //   })).subscribe({
      //     next: (res: any) => {
      //       if (res) {
      //         count = res.count
      //       }
      //       resolve({
      //         message: message,
      //         count: count,
      //         wishlistCount: isWishlistCount,
      //         isWishlistCount: wishlistCount
      //       });
      //     },
      //     error: err => {
      //       if (err) {
      //         this.dialogService.openSnackBar(this.dialogMessages && this.dialogMessages.failureMessage)
      //         reject(err);
      //       }
      //     }
      //   })
      // }
      if (data?.sharedService?.storeId && data?.productId) {
        this.sharedService.getOneCartAndWishlistNotificationMessg(data.sharedService.storeId, { isCart: false }).pipe(filter((response: any) => {
          if (response) {
            message = response.data;
          }
          return true;
        }), mergeMap((response: any) => {
          if (response) {
            return this.sharedService.getOneProductWishlistNotification(data.sharedService.storeId, data.productId);
          }
        }), mergeMap((response1: any) => {
          if (response1) {
            count = response1.count
            return this.sharedService.checkLiveNotification(data.sharedService.storeId);
          }
        })).subscribe({
          next: (res: any) => {
            if (res) {
              this.liveNotification = res.liveNotification;
              isWishlistCount = res.liveNotification.isWishlistCount
              if (this.liveNotification && this.liveNotification.storefrontFeature && this.liveNotification.storefrontFeature.hasOwnProperty('WISHLIST_NOTIFICATION')) {
                if (!this.liveNotification.storefrontFeature.WISHLIST_NOTIFICATION) {
                  wishlistCount = true;
                } else {
                  wishlistCount = false;
                }
              }
              else {
                wishlistCount = false;
              }
            }
            resolve({
              message: message,
              count: count,
              wishlistCount: wishlistCount,
              isWishlistCount: isWishlistCount
            });
          },
          error: err => {
            if (err) {
              this.dialogService.openSnackBar(this.dialogMessages && this.dialogMessages.failureMessage)
              reject(err);
            }
          }
        });
      }
    });
  }

  /**
   * Method used to get the product size chart details.
   * @param data holds the product id and store id.
   * @returns response.
   */
  getProductSizeChartDetails(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (data && data.sharedService && data.sharedService.storeId && data.productId) {
        this.subscriptionObj.add(this.themeService.getProductSizeChartDetails({ storeId: Number(data.sharedService.storeId), productId: Number(data.productId) }).subscribe((response: any) => {
          if (response && response.sizeChartData) {
            resolve({
              sizeChartDetails: response.sizeChartData
            });
          } else {
            resolve(true);
          }
        }, err => {
          if (err) {
            this.dialogService.openSnackBar(this.translationService.getTranslation('SHARED.DIALOG_FAILURE_MESSAGE'))
            reject(err);
          }
        }));
      } else {
        resolve(true);
      }
    });
  }
  /**
   * Method is used to add bulk products in cart.
   * @param event holds event value emitted from fbt component.
   * @param data holds application service values.
   */
  addBulkProductsInCart(event: any, data: any): Promise<any> {
    return new Promise((resolve) => {
      if (data.authService && data.authService.user && data.authService.user.value && data.authService.user.value.customerId && data.authService.user.value.storeId) {
        this.subscriptionObj.add(this.sharedService.addBulkProductsInCart({ cartProducts: event.cartProducts }, data.authService.user.value.storeId, data.authService.user.value.customerId).subscribe((res: any) => {
          if (res && res.cartData && res.cartData.isAdded && res.cartData.cartCount) {
            this.sharedService.cartCountValue.next((event.countValue ? event.countValue : 0) + res.cartData.cartCount);
            const staticAddedProduct = this.translationService.getTranslation('SHARED.ADDED_PRODUCT');
            this.dialogService.openDialog({ message: (staticAddedProduct !== 'SHARED.ADDED_PRODUCT' ? staticAddedProduct : 'Product added to the cart.'), actionType: this.dialogType.success });
            resolve(true);
          } else {
            const staticCartProduct = this.translationService.getTranslation('SHARED.CART_PRODUCT');
            this.dialogService.openDialog({ message: (staticCartProduct !== 'SHARED.CART_PRODUCT' ? staticCartProduct : 'Product is already in the cart.'), actionType: this.dialogType.alert });
            resolve(true);
          }
        }));
      } else {
        this.sharedService.bulkUpdateGuestUserCart(event.cartProducts);
        resolve(true);
      }
    });
  }

  /**
   * Method used to load more product list.
   * @param event defines the params details.
   * @param data holds application service values.
   */
  getCategoryProduct(event: any, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptionObj.add(this.themeService.getCategoryProducts(data.sharedService.storeId, { locationId: data.locationId, limit: event.limit, offset: event.offset }).subscribe((res: any) => {
        if (res) {
          const eventData = { detail: res };
          const event = new CustomEvent('petalGetCategoryProduct', eventData);
          window.dispatchEvent(event);
          resolve(true);
        }
      }, err => {
        if (err) {
          reject(err);
        }
      }));
    });
  }
}