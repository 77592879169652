import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ThemesModuleConstants } from '../constants/shared-theme-constants';
import { catchError, map } from 'rxjs/operators';
import { CommonDataService } from 'libs/shared/src/lib/services/common-data.service';
import { HttpRoutingService } from 'libs/common/src/lib/services/httpRouting.service';
import { DialogService } from 'libs/common/src/lib/services/dialog.service';
import { HeaderService } from 'libs/auth/src/lib/services/header.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { API } from 'libs/common/src/lib/constants/api-routes';
import { HttpClient } from '@angular/common/http';
// import { BulkOperationService } from '@phase-ii/shared';
import { isPlatformBrowser } from '@angular/common';
// import { LoadTranslationService } from '@phase-ii/common';
import * as Model from '../models/cart.model';
import { Title } from '@angular/platform-browser';
import { BulkOperationService } from 'libs/shared/src/lib/services/bulk-operation.service';
import { LoadTranslationService } from 'libs/common/src/lib/services/load-translation.service';
/**
 * Service which is used to store all shared methods
 */
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  /**
   * Variable used to store imageData.
   */
  imageData: any = [];

  /**
   * Variable used to store the store id.
   * @type {number}
   */
  storeId: number;
  /**
   * Variable used to store the price details.
   */
  priceDetails = {
    taxAmount: 0,
    subTotal: 0,
    discountAmount: 0,
    orderBasedDiscount: []
  };
  /**
   * Variable used to store the zone id
   */
  zoneId: number;
  /**
   * Variable used to store the customized store details
   */
  customizedStoreDetails = new BehaviorSubject<any>(null);
  /**
   * Variable used to store store available policies.
   */
  storePolicies = new BehaviorSubject<any>(null);
  /**
   * Variable used to get whether the storepage details gets published or not
   */
  afterPublished = new BehaviorSubject<any>(null);
  /**
   * Variable used to store the menu changes.
   */
  menuChanged = new Subject();
  /**
   * BehaviorSubject used to get the current page details
   */
  currentPage = new BehaviorSubject<any>(null);
  /**
   * BehaviorSubject used to get the current deals details
   */
  currentDeals = new BehaviorSubject<any>(null);
  /**
   * BehaviorSubject used to get the product details while order update.
  */
  changedNewOrders = new BehaviorSubject<any>(null);
  /**
   * searchText used to search the product list
   */
  searchText = new BehaviorSubject<any>(null);
  /**
   * BehaviorSubject used to load the image
   */
  imageLoading = new BehaviorSubject<boolean>(false);
  /**
   * BehaviourSubject used to get product category id
   */
  productCategoryId = new BehaviorSubject<any>(null);
  /**
   * BehaviourSubject used to get product category id
   */
  cartDetails = new BehaviorSubject<any>(null);
  /**
 * Variable used to store the customized store details
 */
  updateCartDetails = new BehaviorSubject<any>(null);
  /**
   * Variable used to store modifier details.
   */
  modifier = [];
  /**
   * Variable used to store new price details.
   */
  newPrice = 0;
  /**
   * BehaviourSubject used to store the location details
   */
  locationDetails = new BehaviorSubject<any>(null);
  /**
  * Variable used to store the store name.
  */
  storeName: string;
  /**
   * BehaviourSubject used to get cart count
   */
  cartCountValue = new BehaviorSubject<any>(0);
  /**
   * BehaviourSubject used to get subscription cart count
   */
  subscriptionCartCountValue = new BehaviorSubject<any>(0);
  /**
   * Variable used to store the industry name.
   */
  industryName: string;
  /**
   * Variable which is used to store the display name of store from router
   */
  storeDisplayName: string;
  /**
   * Variable used to store the common website settings.
   */
  commonWebsiteDetails = new BehaviorSubject<any>(null);
  /**
   * Variable used to store contact details..
   */
  storeContactDetails = new BehaviorSubject<any>(null);
  /**
   * Variable to initiate or terminate the loader
   */
  commonLoader = new BehaviorSubject<boolean>(false);
  isFirstTime = new BehaviorSubject<boolean>(true);
  /**
    * Variable which is used to store all time zone data
    */
  timeZones: any;
  /**
  * Variable which is used to store all time zone data
  */
  allTimeZones: any;
  /**
   * Variable which is used to check menu timing availablity
   * @type {array}
   */
  menuTimingAvailablityCheckingIndustryList: string[];
  /**
   * Variable used to indicate whether the route is navigated from storefront or other module.
   * @type {boolean}
   */
  isStorefront = false;
  /**
   * Variable used to access methods from instance environment.
   */
  environment: any;
  /**
 * Variable used to store the customized store details
 */
  filterSearchDetails = new BehaviorSubject<any>(null);
  /**
   * Variable used to store bulk import status.
   */
  // bulkImportProducts = new BehaviorSubject<any>(true);
  /**
 * Variable used to store the order navigation history
 */
  orderNavigation = new BehaviorSubject<any>(false);
  /**
* Variable used to store the selected subscription id
*/
  subscriptionId = new BehaviorSubject<any>(null);
  /**
* Variable used to store the order navigation history
*/
  singleSubscription = new BehaviorSubject<any>(false);
  /**
* Variable used to store the selected single subscription id
*/
  singleProductId = new BehaviorSubject<any>(null);
  /**
   * Variable used to store categoryList.
   */
  categoryList = new BehaviorSubject<any>(null);

  /* Variable used to access methods inside BulkOperationService.
  * @type {BulkOperationService}
  */
  bulkOperationService: BulkOperationService;
  /**
   * Variable used to store route url.
   */
  url: any;
  /**
   * BehaviourSubject used to hold the store's active locations.
   */
  activeStoreLocation = new BehaviorSubject<any>([]);
  /**
   * Service constructor which is used to inject the required services.
   * @param commonDataService To get the functions and observables in the common data service.
   * @param meta to get access to the functions in meta.
   * @param router To navigate to the routes.
   * @param dialogService To access methods inside DialogService.
   * @param httpService To access methods inside HttpRoutingService.
   * @param snackBar To access methods inside MatSnackBar.
   * @param headerService To access methods inside HeaderService.
   * @param sanitizer To access methods inside DomSanitizer.
   */
  constructor(
    private commonDataService: CommonDataService,
    private meta: Meta,
    private router: Router,
    private dialogService: DialogService,
    private httpService: HttpRoutingService,
    private snackBar: MatSnackBar,
    private headerService: HeaderService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private injector: Injector,
    private translationService: LoadTranslationService,
    private titleService: Title,
    @Inject('environment') environment,
    @Inject(PLATFORM_ID) private platformId: object) {
    this.bulkOperationService = injector.get<BulkOperationService>(BulkOperationService);
    this.timeZones = new ThemesModuleConstants().TimeZones;
    this.allTimeZones = new ThemesModuleConstants().TimeZones;
    this.menuTimingAvailablityCheckingIndustryList = new ThemesModuleConstants().menuTimingAvailablityCheckingIndustryList;
    this.environment = environment;
  }
  /**
   * Method which is used to return the full / half star
   * @param id defines the index of the star
   * @param star defines the rating of the product
   */
  checkStarRating(id, star) {
    if (id < star - 1 && star % 1 === 0.5) {
      return true;
    }
    else if (id <= star - 1) {
      return true;
    }
    else {
      return false;
    }
  }
  /**
   * Method used for navigation purpose.
   * @param page which holds the page name to navigate.
   * @param id which holds the id to be encrypted.
   */
  navigateTo(page, id?, queryParam?) {
    if (page) {
      if (page.includes('https') || page.includes('http') || page.includes('www')) {
        window.open(page.includes('https') || page.includes('http') ? page : "https://" + page, "_blank");
      }
      // else if (page.includes('/')) {
      //   const path = page.split('/');
      //   if (path?.length > 2) {
      //     this.pageNavigation(page, id, queryParam);
      //   }
      //   else {
      //     this.pageNavigation(path?.length === 2 ? (path[0] + '/' + path[1]) : path[0], path[path?.length - 1], queryParam);
      //   }
      // }
      else {
        this.pageNavigation(page, id, queryParam);
      }
    }
  }
  /**
   * Method which is used to navigate to the pages.
   * @param route which holds the page name to navigate.
   * @param id which holds the id to be encrypted.
   * @param queryParam which holds search text,filter data,category details.
   */
  pageNavigation(route, id?, queryParam?) {
    let query = {};
    if (sessionStorage.getItem('isPreview')) {
      query = { ...query, ...{ isPreview: true } };
    }
    if (queryParam) {
      query = { ...query, ...queryParam };
    }
    const routeValue = !route.includes('shopapp') ? (this.storeDisplayName && this.storeDisplayName !== undefined ? (this.environment.RegionUrl + '/' + this.storeDisplayName + (this.translationService.languageCode && this.translationService.isNavigateWithLangCode ? '/' + this.translationService.languageCode + '/' : '/') + route) : (this.translationService.languageCode && this.translationService.isNavigateWithLangCode ? '/' + this.translationService.languageCode + '/' : '/') + route) : '/' + route;
    this.routeChange(routeValue, id, query);
  }

  /**
  * Method used to get categories details in quick order products page.
  * @param storeId id of the store.
  * @param data cart details to get quick order product data.
  * @returns the response.
  */
  getAllQuickOrderProducts(storeId, data) {
    const url = this.httpService.replaceUrlParam(API.QUICK_ORDER_PRODUCTS, { storeId: storeId });
    return this.httpService.getMethod(url, data);
  }
  /**
   * Method used to navigate to components based on store display name and other details.
   * @param routeValue which holds the page name to navigate.
   * @param id which holds the id to be encrypted.
   * @param query which holds search text,filter data,category details.
   */
  routeChange(routeValue, id?, query?) {
    if (id) {
      const value = typeof id === 'object' ? [routeValue, ...id] : [routeValue, id];
      this.router.navigate(value, { queryParams: query });
    } else {
      this.router.navigate([routeValue], { queryParams: query });

    }
  }
  /**
   * Method used to get the formcontrol errors
   * @param control formcontrol
   * @param propertyName name of the error property
   * @returns error object
   */
  getFormControlErrors(control, propertyName) {
    const errorObj = {};
    if (control && control.errors && Object.keys(control.errors).length > 0) {
      Object.keys(control.errors).forEach(error => {
        if (error && error !== propertyName) {
          errorObj[error] = control.errors[error];
        }
      });
    }
    return errorObj;
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to get the website settings
   * @param storeId holds storeId
   * @returns API success response or error response
   */
  getWebsiteSettings(storeId: any) {
    const url = this.httpService.replaceUrlParam(API.WEBSITE_SETTING, { storeId });
    return this.httpService.getMethod(url);
  }

  /**
   * AUTHOR: SUJITHA S (CEN405)
   * Method used to get product subscription 
   * @param storeId holds storeId
   * @returns product subscription details
   */
  getProductSubscription(storeId: number) {
    const url = this.httpService.replaceUrlParam(API.GET_PLUIGIN_DETAILS, { storeId });
    return this.httpService.getMethod(url);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to get current user details.
   * @param storeId holds storeId.
   * @returns current user details.
   */
  getCurrentUserDetails(storeId: any) {
    const url = this.httpService.replaceUrlParam(API.CURRENT_USER, { storeId });
    return this.httpService.getMethod(url);
  }

  /**
   * Method used to notify the email error.
   * @param storeId storeId
   * @param data data to send email notification
   * @returns API success response or error response
   */
  errorEmailNotification(storeId, data) {
    return this.httpService.postMethod('stores/' + storeId + '/checkout/errornotification', data).subscribe(res => { });
  }
  /**
   * Method used to get the selected category details
   * @param categoryList list categories with their respective sub categories
   * @param index index of the parent category
   * @param i index of the first step of sub category
   * @param j in dex of the second step of sub category
   */
  categoryIndexFind(categoryList, index, i, j) {
    let selectedCategory;
    if (categoryList && categoryList.length > 1) {
      if (j !== null && j >= 0) {
        selectedCategory = {
          category: { id: categoryList[index].id, name: categoryList[index].categoryName },
          subCategory: { id: categoryList[index].subCategory[i].id, name: categoryList[index].subCategory[i].categoryName },
          subSubCategory: { id: categoryList[index].subCategory[i].subCategory[j].id, name: categoryList[index].subCategory[i].subCategory[j].categoryName },
          categoryDescription: categoryList[index].subCategory[i].subCategory[j].description,
          image: categoryList[index].subCategory[i].subCategory[j].categoryImageUrl
        };
        selectedCategory.categoryIdArray = [categoryList[index].subCategory[i].subCategory[j].id];
      } else if (i !== null && i >= 0) {
        selectedCategory = {
          category: { id: categoryList[index].id, name: categoryList[index].categoryName },
          subCategory: { id: categoryList[index].subCategory[i].id, name: categoryList[index].subCategory[i].categoryName },
          subSubCategory: null,
          categoryDescription: categoryList[index].subCategory[i].description,
          image: categoryList[index].subCategory[i].categoryImageUrl
        };
        selectedCategory.categoryIdArray = [categoryList[index].subCategory[i].id];
        if (categoryList[index] && categoryList[index].subCategory && categoryList[index].subCategory[i].subCategory && categoryList[index].subCategory[i].subCategory.length) {
          categoryList[index].subCategory[i].subCategory.forEach(element => {
            selectedCategory.categoryIdArray.push(element.id);
          });
        }
      } else {
        selectedCategory = {
          category: { id: categoryList[index] && categoryList[index].id, name: categoryList[index] && categoryList[index].categoryName },
          subCategory: null,
          subSubCategory: null,
          categoryDescription: categoryList[index] && categoryList[index].description,
          image: categoryList[index] && categoryList[index].categoryImageUrl
        }
        if (categoryList[index] && categoryList[index].id === 0) {
          selectedCategory.categoryIdArray = categoryList[index].categoryIdArray;
        } else {
          selectedCategory.categoryIdArray = [categoryList[index] && categoryList[index].id];
        }
        if (categoryList[index] && categoryList[index].subCategory && categoryList[index].subCategory.length) {
          categoryList[index].subCategory.forEach(element => {
            if (element && element.id) {
              selectedCategory.categoryIdArray.push(element.id);
            }
            if (element && element.subCategory && element.subCategory.length) {
              element.subCategory.forEach(sub => {
                selectedCategory.categoryIdArray.push(sub.id);
              });
            }
          });
        }
      }
    } else {
      selectedCategory = {
        category: { id: 0, name: 'All Products' },
        subCategory: null,
        subSubCategory: null,
        categoryDescription: null,
        image: null
      }
    }
    return selectedCategory;
  }
  /**
   * Method used to selected category index of all sub categories levels
   * @param categoryList category list array
   * @param selectedCategoryId selected category id
   * @returns index of all sub categories levels
   */
  findCategoryIndexForAllLevels(categoryList, selectedCategoryId) {
    if (categoryList && categoryList.length) {
      for (let i = 0; i < categoryList.length; i++) {
        if (categoryList[i].id === Number(selectedCategoryId)) {
          return { i: i }
        }
        if (categoryList[i] && categoryList[i].subCategory && categoryList[i].subCategory.length) {
          for (let j = 0; j < categoryList[i].subCategory.length; j++) {
            if (categoryList[i].subCategory[j].id === Number(selectedCategoryId)) {
              return { i: i, j: j }
            }
            if (categoryList[i].subCategory[j] && categoryList[i].subCategory[j].subCategory && categoryList[i].subCategory[j].subCategory.length) {
              for (let k = 0; k < categoryList[i].subCategory[j].subCategory.length; k++) {
                if (categoryList[i].subCategory[j].subCategory[k].id === Number(selectedCategoryId)) {
                  return { i: i, j: j, k: k }
                }
              }
            }
          }
        }
      }
    } else {
      return null;
    }
  }
  /**
   * method used to get location details
   * @param companyId company id
   * @returns response of location
   */
  getAllLocation(storeId, data, isCity, industryName) {
    return this.httpService.getMethod('stores/' + storeId + '/locations/v2', isCity ? { city: data, industryName: industryName ? industryName : '' } : { searchText: data, industryName: industryName ? industryName : '' });
  }
  returnRequestForProduct(data, orderId, storeId) {
    const url = this.httpService.replaceUrlParam(API.RETURN_REQUEST_FOR_PRODUCT, { storeId: storeId, orderId: orderId, productId: data.productId });
    return this.httpService.postMethod(url, data);
    // return this.httpService.postMethod('order/' + orderId + '/returnRequestForProduct', data);
  }
  /**
   * method used to get blog categories
   * @param storeId store id
   * @returns blog categories details
   */
  getStoreBlogCategories(storeId) {
    return this.httpService.getMethod('/blogCategories/store/' + storeId);
  }
  /**
   * Method used to get the discount types.
   * @returns discount types or error response
   */
  getDiscountTypePropertyValues(storeId) {
    const url = this.httpService.replaceUrlParam(API.DISCOUNT_TYPE, { storeId });
    return this.httpService.getMethod(url);

    // return this.httpService.getMethod('stores/' + storeId + '/discounts/types/');
  }

  getBuyxGetyAutomaticDiscount(storeId, data) {
    return this.httpService.postMethod('stores/' + storeId + '/discounts/buyxgety/auto', data);
  }
  /**
   * Method used to get the last minute offers
   * @param storeId store id
   * @param data data to fetch the offers
   * @param customerId customer id
   * @param roleId role id
   * @returns last minute offers or error response
   */
  getLastMinuteOffer(storeId, data, customerId, roleId) {
    const reqData = { data: data, customerId: customerId, roleId: roleId };
    return this.httpService.postMethod("stores/" + storeId + "/lastminuteoffer", reqData);
  }
  /**
   * Method used to add the item in cart
   * @param customerId customer id
   * @param data data to add the cart
   * @returns product added to the cart or error response
   */
  addToCart(customerId, data) {
    return this.httpService.postMethod('/cart/' + customerId, data);
  }
  /**
   * Method use to call the api to get all custom questions of checkout page
   * @returns API success response or error response
   */
  getCustomCheckoutQuestion() {
    return this.httpService.getMethod('getAllCheckoutQuestions');
  }
  /**
   * Method used to fetch all products
   * @param query query data to fetch all product
   * @returns fetched products or error
   */
  getAllProducts(query) {
    return this.httpService.getMethod('store/' + query.storeId + '/products', query);
  }
  /**
   * Method used to fetch all data of brands
   * @param storeId store id
   * @param data data to fetch
   * @returns response
   */
  getAllBrands(storeId, data?) {
    const url = this.httpService.replaceUrlParam(API.BRANDSFORSTOREFRONT, { storeId: storeId })
    return this.httpService.getMethod(url, data);
  }

  /**
   * Method used to fetch all categories.
   * @param storeId store id.
   * @param locationId location id
   * @param timeZone time zone
   * @returns fetched all categories or error response
   */
  getAllCategories(storeId, locationId?, timeZone?) {
    const url = this.httpService.replaceUrlParam(API.CATEGORY_TREE, { storeId });
    return this.httpService.getMethod(url, { locationId: locationId, timeZone: timeZone });
  }
  /**
   * Method used to fetch the address of the customer
   * @param storeId store id
   * @param id customer id
   * @returns address of the customer or error response
   */
  getAddress(storeId, id) {
    const url = this.httpService.replaceUrlParam(API.ADDRESS, {
      storeId: storeId, userId: id
    });

    return this.httpService.getMethod(url);
  }
  /**
   * Method used to place the order through store.
   * @param data data to place the order
   * @param storeId store id
   * @returns success response of placed order or error response
   */
  placeOrder(data, storeId) {
    data.currentDate = new Date();
    const url = this.httpService.replaceUrlParam(API.PLACE_ORDER, { storeId });
    return this.httpService.postMethod(url, data);
  }
  subscriptionPlaceOrder(data, storeId) {
    data.currentDate = new Date();
    const url = this.httpService.replaceUrlParam(API.PLACE_ORDER1, { storeId });
    return this.httpService.postMethod(url, data);
  }
  urlSubscriptionPlaceOrder(data, storeId) {
    data.currentDate = new Date();
    const url = this.httpService.replaceUrlParam(API.PLACE_ORDER_URL_CHECKOUT, { storeId });
    return this.httpService.postMethod(url, data);
  }
  /**
   * Method used to remove a product from cart.
   * @param storeId have logged in user's store id
   * @param cartProductId holds cart product id.
   * @returns API success response or error response
   */
  removeCartProducts(storeId, cartProductId) {
    const url = this.httpService.replaceUrlParam(API.REMOVE_CART, { storeId, cartProductId });
    return this.httpService.deleteMethod(url);
  }
  /**
  * Function used to restore the stock
  * @param orderId defines the id of the order to be cancelled
  * @returns the response
  */
  restoreStock(storeId, orderId, data) {
    const url = this.httpService.replaceUrlParam(API.RESTORE_STOCK, {
      storeId: storeId, orderId: orderId
    });
    return this.httpService.putMethod(url, data);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to get remove the cart product modifier
   * @param storeId have logged in user's store id
   * @param cartProductId holds cart product id.
   * @param modifierId has the cart product modifier id
   * @returns
   */
  removeCartProductModifier(storeId, cartProductId, modifierId) {
    const url = this.httpService.replaceUrlParam(API.REMOVE_CART_MODIFIER, { modifierId, storeId, cartProductId });
    return this.httpService.deleteMethod(url);

  }
  /**
  * Method used to update return products status.
   * @param orderId id holds order id.
   * @param storeId holds store id.
   * @returns the response
   */
  updateReturnStatus(storeId, returnId, data) {
    const url = this.httpService.replaceUrlParam(API.UPDATE_RETURN_STATUS, { storeId, returnId, orderId: data.orderId });
    return this.httpService.putMethod(url, data);
    // return this.httpService.putMethod("stores/" + storeId + "/updateReturnStatus/" + returnId, data);
  }
  /**
   * Method used to create customer
   * @param data has the requested data.
   * @returns API success response or error response
   */
  createCustomer(data) {
    return this.httpService.postMethod('createcustomer', data);
  }
  /**
   * Method calculateProductModifierPrice is used to calculate the product's modifier price
   * @param {*} productModifiers To get the modifiers of product
   */
  modifierPriceForSingleProduct(productModifiers) {
    let modifierPrice = 0;
    if (productModifiers && Object.keys(productModifiers).length) {
      for (const k in productModifiers) {
        if (k && productModifiers[k] && productModifiers[k].modifierArray && productModifiers[k].modifierArray.length) {
          for (const item of productModifiers[k].modifierArray) {
            modifierPrice += (item && item.price ? (+item.price) : 0);
          }
        }
      };
    }
    return modifierPrice;
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to calculate the modifier price of given product list
   * @param products To get the products array
   * @returns
   */
  modifierPriceCalculation(products) {
    if (products && products.length) {
      products.forEach(item => {
        item['modifierPrice'] = this.modifierPriceForSingleProduct(item?.productModifiers);
      });
      return products;
    }
  }
  /**
   * Method used to calculate the option price of the product
   * @param cartProducts products details in the cart
   * @returns option price calculated product
   */
  productOptionPriceCalculation(cartProducts) {
    if (cartProducts && cartProducts.length) {
      for (let i = 0; i < cartProducts.length; i++) {
        if (cartProducts[i]) {
          const obj = { 'modifierPrice': 0, 'productPrice': 0, 'optionsPrice': 0, 'productWithOptionPrice': 0, 'optionQty': 0, 'productSubTotal': 0 };
          obj['productPrice'] = Number(cartProducts[i].productPrice);
          obj['modifierPrice'] = this.modifierPriceForSingleProduct(cartProducts[i]?.productModifiers);
          obj['productWithOptionPrice'] = cartProducts[i].productPrice ? Number(cartProducts[i].productPrice) : 0;
          if (cartProducts[i].options) {
            for (let j = 0; j < cartProducts[i].options.length; j++) {
              obj['optionsPrice'] += (cartProducts[i].options[j].priceModifier == '+' ? Number(cartProducts[i].options[j].priceValue) : (-(Number(cartProducts[i].options[j].priceValue))));
              obj['productWithOptionPrice'] += (cartProducts[i].options[j].priceModifier == '+' ? Number(cartProducts[i].options[j].priceValue) : (-(Number(cartProducts[i].options[j].priceValue))));
              obj['optionQty'] += 1;
            }
          }
          obj['productSubTotal'] = obj['productWithOptionPrice'] * cartProducts[i].quantity;
          cartProducts[i] = Object.assign(cartProducts[i], obj);
        }
      }
    }
    return cartProducts;
  }
  /**
   * Method which is used to fetch order settings data.
   * @param data has request data.
   * @param storeId has store id.
   * @returns API success response or error response
   */
  getOrderSettings(storeId) {
    return this.httpService.getMethod('stores/' + storeId + '/order/settings');
  }
  /**
   * Method which is used to get category details
   * @param storeId used to store store id
   * @returns category name and images
   */
  getCategoriesDetail(storeId) {
    return this.httpService.getMethod('/stores/' + storeId + '/categories');
  }
  /**
   * Method which is used to get testimonial details
   * @param storeId used to store store id
   * @returns testimonial details
   */
  getTestimonialDetails(storeId) {
    const url = this.httpService.replaceUrlParam(API.TESTIMONIAL_DETAILS, { storeId: storeId });
    return this.httpService.getMethod(url);
  }
  /**
* Method getAllblogDetails which is used to get all blog details.
*/
  getAllBlogDetails(storeId) {
    let enabledBlog = { availabilityStatus: 'Enabled' };
    const data = {
      filterData: JSON.stringify(enabledBlog),
      date: new Date(),
      isFromTheme: true
    };
    const url = this.httpService.replaceUrlParam(API.LIST_ALL_BLOG, { storeId: storeId })
    return this.httpService.getMethod(url, data);
  }
  /**
   * Method used to fetch the role id of the particular role
   * @param storeId store id
   * @param roleName role name
   * @returns fetched the role id of the particular role
   */
  getRoleId(storeId, roleName) {
    const url = this.httpService.replaceUrlParam(API.GET_ROLE_ID, { storeId: storeId, roleName: JSON.stringify(roleName) });
    return this.httpService.getMethod(url);

  }
  /**
   * Method used to update the user details
   * @param data data to update the user
   * @param id user id
   * @param storeId store id
   * @returns success response of user updation or error response
   */
  updateUser(data, id, storeId) {
    const url = this.httpService.replaceUrlParam(API.UPDATE_USER, { storeId: storeId, id: id });
    return this.httpService.putMethod(url, data);
  }
  /**
   * Method used to update the address of the user
   * @param data data to update the address
   * @param id user id
   * @param storeId store id
   * @returns success response of user address updation or error response
   */
  updateAddress(data, id, storeId) {
    const url = this.httpService.replaceUrlParam(API.UPDATE_ADDRESS, { storeId: storeId, id: id });
    return this.httpService.postMethod(url, data);
  }
  /**
   * Method used to fetch the store location
   * @param storeId store id
   * @param id location id
   * @returns details of the particular location or error response
   */
  getOneStoreLocation(storeId, id) {
    return this.httpService.getMethod("stores/" + storeId + "/locations/" + id + '/v2');
  }
  /**
   * Method used to fetch the store details.
   * @param storeId store id
   * @returns fetched store details or error response
   */
  getOneStoreDetails(storeId) {
    return this.httpService.getMethod("stores/" + storeId + "/locations/locationStoreDetails");
  }
  /**
 * Methods which is used to calculate the tax for the single product
 * @param productsList To get the products
 * @param zones To get all the zones of store
 * @param address To get customer address
 * @returns tax amount of that product
 */
  taxForSingleProduct(product, zones, address?) {
    let productTaxAmount = 0, productInclusiveTax = 0, taxPercentage = 0;
    if (product && product.productTaxMapping && product.productTaxMapping.length) {
      product.productTaxMapping.forEach(prodTaxMapping => {
        if (prodTaxMapping) {
          taxPercentage = prodTaxMapping.otherZoneRate ? prodTaxMapping.otherZoneRate : 0;
          if (address && prodTaxMapping.taxFor === 'Specific Zone' && prodTaxMapping.taxZonesMappings && prodTaxMapping.taxZonesMappings.length > 0) {
            prodTaxMapping.taxZonesMappings.find(zoneMapping => {
              if (zoneMapping) {
                const selectedZone = zones && zones.length ? zones.find(zone => zoneMapping && zone && zone.id === zoneMapping.zoneId) : [];
                address['country'] = address.country ? Number(address.country) : 0;
                address['state'] = address.state ? Number(address.state) : 0;
                address['zipCode'] = address.zipCode ? Number(address.zipCode) : 0;
                address['city'] = address.city ? address.city.toString().toLowerCase() : '';
                if (selectedZone && (selectedZone.countryId && (selectedZone.countryId.find(country => country && country === address.country)) ||
                  (selectedZone.stateId && selectedZone.stateId.find(state => state && state === address.state))
                  || (selectedZone.zipCode && selectedZone.zipCode.find(val => val && val === address.zipCode))
                  || (selectedZone.city && selectedZone.city.find(val => val && val.toString().toLowerCase() === address.city))
                )) {
                  taxPercentage = zoneMapping.taxPercent ? zoneMapping.taxPercent : 0;
                  return true;
                }
              }
            });
          } else if (address && prodTaxMapping.taxFor === 'Specific Postal Code' && prodTaxMapping.taxZonesMappings && prodTaxMapping.taxZonesMappings.length > 0) {
            prodTaxMapping.taxZonesMappings.find(zoneMapping => {
              if (zoneMapping && zoneMapping.zipCode === Number(address.zipCode)) {
                taxPercentage = zoneMapping.taxPercent ? zoneMapping.taxPercent : 0;
                return true;
              }
            });
          }
          const price = product['price'] ? Number(product['price']) : 0;
          if (prodTaxMapping.isIncludeTaxInProduct) {
            productInclusiveTax += Number((price * ((taxPercentage ? taxPercentage : 0) / 100)).toFixed(2));
          }
          else {
            productTaxAmount += Number((price * ((taxPercentage ? taxPercentage : 0) / 100)).toFixed(2));
          }
        }
      });
    }
    productTaxAmount += (productInclusiveTax ? productInclusiveTax : 0);
    return { productTaxAmount: productTaxAmount, productInclusiveTax: productInclusiveTax };
  }
  /**
   * Methods which is used to calculate the tax of all the products
   * @param productsList To get the products
   * @param zones To get all the zones of store
   * @param address To get customer address
   * @returns tax amount of product list
   */
  taxCalculation(productsList, zones?, address?) {
    let calculatedTaxAmount, subTotal = 0, overAllTaxAmount = 0;
    if (productsList && productsList.length) {
      productsList.forEach(product => {
        if (product) {
          product['price'] = ((product['productWithOptionPrice'] ? Number(product['productWithOptionPrice']) : 0) + (product['modifierPrice'] ? product['modifierPrice'] : 0))
            - (product['appliedDiscountValue'] ? Number(product['appliedDiscountValue'] / (product['quantity'] ? product['quantity'] : 1)) : 0);
          calculatedTaxAmount = this.taxForSingleProduct(product, zones, address);
          product['taxAmount'] = calculatedTaxAmount && calculatedTaxAmount.productTaxAmount ? Number(calculatedTaxAmount.productTaxAmount) : 0;
          product['productTax'] = calculatedTaxAmount && calculatedTaxAmount.productInclusiveTax ? Number(calculatedTaxAmount.productInclusiveTax) : 0;
          product['price'] += product['productTax'] ? product['productTax'] : 0;
          if (!product.isDisabled && !product.isMenuClosed && !product.isModifierNotAvailable) {
            subTotal += Number(((product.productWithOptionPrice ? product.productWithOptionPrice : 0) * (product.quantity ? product.quantity : 0)).toFixed(2));
            overAllTaxAmount += Number(((product.taxAmount ? product.taxAmount : 0) * (product.quantity ? product.quantity : 0)).toFixed(2));
          }
        }
      });
    }
    return {
      taxAmount: overAllTaxAmount ? Number(overAllTaxAmount) : 0,
      subTotal: subTotal ? Number(subTotal) : 0,
      products: productsList
    };
  }
  /**
   * Method used to create the variant combinations
   */
  variantChange(productCombinations, variantObject, optionIndex, optionValueIndex, selectedOptionName, isWholeSale, preorderDetails) {
    try {
      let isCombinationAvailable, selectedVariant;
      if (productCombinations && productCombinations[optionIndex] && productCombinations[optionIndex].option &&
        productCombinations[optionIndex].option.optionValues &&
        productCombinations[optionIndex].option.optionValues[optionValueIndex]) {
        variantObject[selectedOptionName] = productCombinations[optionIndex].option.optionValues[optionValueIndex];
      }
      let selectedVariantId;
      if (variantObject && variantObject[selectedOptionName] && variantObject[selectedOptionName].productVariantMappings && variantObject[selectedOptionName].productVariantMappings.length) {
        for (let i = 0; i < variantObject[selectedOptionName].productVariantMappings.length; i++) {
          if (variantObject[selectedOptionName].productVariantMappings[i]) {
            selectedVariantId = variantObject[selectedOptionName].productVariantMappings[i].skuId;
            const comStr = [];
            for (const option in variantObject) {
              if (variantObject[option].productVariantMappings && variantObject[option].productVariantMappings.length) {
                const variantFound = variantObject[option].productVariantMappings.find(val => val.skuId === selectedVariantId);
                if (variantFound) {
                  comStr.push(variantObject[option].optionValue);
                }
              }
            }
            if (comStr.length === productCombinations.length && variantObject[selectedOptionName].productVariantMappings[i].variantCombinationDetail) {
              isCombinationAvailable = true;
              selectedVariant = {
                variantId: variantObject[selectedOptionName].productVariantMappings[i].skuId,
                combinationName: comStr.sort().join(', '),
                price: isWholeSale && variantObject[selectedOptionName].productVariantMappings[i].variantCombinationDetail.wholeSalePrice ?
                  Number(variantObject[selectedOptionName].productVariantMappings[i].variantCombinationDetail.wholeSalePrice) :
                  Number(variantObject[selectedOptionName].productVariantMappings[i].variantCombinationDetail.price),
                stockCount: variantObject[selectedOptionName].productVariantMappings[i].variantCombinationDetail.multiLocationVariantStocks,
                description: variantObject[selectedOptionName].productVariantMappings[i].variantCombinationDetail.description,
                variantName: variantObject[selectedOptionName].productVariantMappings[i].variantCombinationDetail.variantName,
                costComparePrice: variantObject[selectedOptionName].productVariantMappings[i].variantCombinationDetail.costComparePrice,
                weightCount: variantObject[selectedOptionName].productVariantMappings[i].variantCombinationDetail.weightCount,
                sku: variantObject[selectedOptionName]?.productVariantMappings[i]?.variantCombinationDetail?.sku
              }
              selectedVariant['preorderPrice'] = this.changeProductPrice(selectedVariant.price, preorderDetails);
              break;
            } else {
              isCombinationAvailable = false;
              selectedVariant = null;
            }
          }
        }
      }
      return { isCombinationAvailable, variantObject, selectedVariant };
    } catch (err) {
      console.log('Error in variant change', err);
      // this.catchError({ message: 'Exception in variant change ' + (err && err.message) }, { isSendMail: true, category: 'Exception' });
    }
  }
  /**
  * Method which can be used to process the product variant options
  */
  productOptionChange(productOptions: any, optionObject: any, optionIndex: number, optionValueIndex: number, optionValueName: any) {
    try {
      let selectedOption;
      if (productOptions && productOptions[optionIndex] &&
        productOptions[optionIndex].optionValues &&
        productOptions[optionIndex].optionValues[optionValueIndex]) {
        const optionName = productOptions[optionIndex].name;
        if (productOptions[optionIndex].defaultValue >= 0) {
          optionObject[optionName] = [productOptions[optionIndex].optionValues[optionValueIndex]];
        } else {
          optionObject[optionName] = optionObject[optionName] && optionObject[optionName].length ? optionObject[optionName] : [];
          const findIndex = optionObject && optionObject[optionName] && optionObject[optionName].findIndex(s => s.optionValue == optionValueName);
          if (findIndex !== -1) {
            optionObject[optionName].splice(findIndex, 1);
          } else {
            if (productOptions[optionIndex].controlType !== "CHECKBOX") {
              optionObject[optionName] = [productOptions[optionIndex].optionValues[optionValueIndex]];
            } else {
              optionObject[optionName].push(productOptions[optionIndex].optionValues[optionValueIndex]);
            }
          }
        }
        const comStr = [];
        let price = 0;
        selectedOption = { optionIds: [], optionValueIds: [] };
        for (const option in optionObject) {
          if (option && optionObject[option] && optionObject[option].length) {
            for (let a = 0; a < optionObject[option].length; a++) {
              if (optionObject[option][a]) {
                comStr.push(optionObject[option][a].optionValue);
                if (optionObject[option][a].productOptionValueMappings && optionObject[option][a].productOptionValueMappings[0] && optionObject[option][a].productOptionValueMappings[0].priceValue) {
                  if (optionObject[option][a].productOptionValueMappings[0].priceModifier === '+') {
                    price += parseFloat(optionObject[option][a].productOptionValueMappings[0].priceValue);
                  } else if (optionObject[option][a].productOptionValueMappings[0].priceModifier === '-') {
                    price -= parseFloat(optionObject[option][a].productOptionValueMappings[0].priceValue);
                  }
                }
                selectedOption.optionValueIds.push(optionObject[option][a].id);
                selectedOption.optionIds.push(optionObject[option][a].productOptionValueMappings && optionObject[option][a].productOptionValueMappings[0] && optionObject[option][a].productOptionValueMappings[0].id);
              }
            }
          }
        }
        selectedOption.combinationName = comStr.sort().join(', ');
        selectedOption.price = price;
        return { optionObject, selectedOption };
      }
    } catch (err) {
      console.log('Error in product option change', err);
      // this.catchError({ message: 'Exception in product variant change ' + (err && err.message) }, { isSendMail: true, category: 'Exception' });
    }
  }
  /**
   * Method used to create the combination
   */
  combinationCreation(price, selectedVariant, selectedOption, costComparePrice?, selectedModifier?, selectedAddons?) {
    const combinationString = [];
    if (selectedVariant) {
      price = selectedVariant.price;
      costComparePrice = Number(selectedVariant.costComparePrice);
      combinationString.push(selectedVariant.combinationName);
    }
    if (selectedOption && selectedOption.combinationName) {
      price += selectedOption.price;
      if (costComparePrice) {
        costComparePrice += selectedOption.price;
      }
      combinationString.push(selectedOption.combinationName);
    }
    // if (selectedModifier && selectedModifier.modifierMappingIds && selectedModifier.modifierMappingIds.length) {
    //   price += selectedModifier.price;
    //   if (costComparePrice) {
    //     costComparePrice += selectedModifier.price;
    //   }
    // }
    // if (selectedAddons && selectedAddons.addonDetails && selectedAddons.addonDetails.length) {
    //   price += selectedAddons.price;
    //   if (costComparePrice) {
    //     costComparePrice += selectedAddons.price;
    //   }
    // }
    return { combinationString: combinationString.join(', '), price, costComparePrice };
  }
  /**
   * Function used to change the selected variant's image
   * @param variantId id of the selected variant
   */
  changeProductImage(variantId, productDetails) {
    const variantFound = productDetails && productDetails.variantImages && productDetails.variantImages.length && productDetails.variantImages.find(val => val && val.itemId === variantId);
    if (variantFound && variantFound.attachmentCategoryTypesMappings && variantFound.attachmentCategoryTypesMappings.length) {
      if (!productDetails.isVariants && productDetails.featuredImage) {
        productDetails.productAttachments = [...[{ fileUrl: productDetails.featuredImage, ext: "jpg" }], ...variantFound.attachmentCategoryTypesMappings];
      }
      else {
        productDetails.productAttachments = variantFound.attachmentCategoryTypesMappings;
      }
    } else if (productDetails && productDetails.featuredImage) {
      productDetails.productAttachments = [{ fileUrl: productDetails.featuredImage, ext: "jpg" }];
    } else {
      productDetails.productAttachments = [];
    }
    return productDetails.productAttachments;
  }
  /**
   * Function is used to calculate the overall price and taxamount
   * @param productList
   * @returns summary details
   */
  calculateSummary(productList) {
    let subTotal = 0, taxAmount = 0, deposit = 0, discount = 0;
    if (productList && productList.length) {
      for (const product of productList) {
        if (product && !product.isDisabled && !product.isMenuClosed && !product.isModifierNotAvailable) {
          subTotal += Number(((product.productWithOptionPrice ? product.productWithOptionPrice : 0) * (product.quantity ? product.quantity : 0)).toFixed(2));
          taxAmount += Number(((product.taxAmount ? product.taxAmount : 0) * (product.quantity ? product.quantity : 0)).toFixed(2));
          deposit += Number(((product.depositAmount ? product.depositAmount : 0) * (product.quantity ? product.quantity : 0)).toFixed(2));
          discount += Number(((product.appliedDiscountValue ? product.appliedDiscountValue : 0)).toFixed(2));
        }
      }
    }
    return { subTotal: subTotal, taxAmount: taxAmount, depositAmount: deposit, discountAmount: discount };
  }
  /**
    * Method used to add or remove product to wishlist.
    * @param productDetails holds the product details.
    */
  addOrRemoveWishlist(productDetails) {
    const dataToEmit = {
      isAddedToFav: productDetails.wishlistItem ? productDetails.wishlistItem : false,
      data: {
        wishlistItems: {
          variantSkuId: productDetails.selectedVariant && productDetails.selectedVariant.variantId ? productDetails.selectedVariant.variantId : productDetails.variantCombinationDetails && productDetails.variantCombinationDetails.length && productDetails.variantCombinationDetails[0] && productDetails.variantCombinationDetails[0].id,
          modifierSetMappingId: productDetails?.selectedModifier?.modifierMappingIds?.length ? productDetails?.selectedModifier?.modifierMappingIds : [],
          productId: productDetails.id,
          isDeleted: false
        }
      }
    };
    return dataToEmit;
  }
  /**
   * Method used to add product to a cart for product list page.
   * @param id holds the product index.
   * @param operation holds the operation type.
   * @param products holds the product details.
   * @returns returns the cart data.
   */
  productListAddToCart(id, operation, products) {
    const cartDetail = {
      operation: operation,
      index: id,
      isSavedForLater: false,
      cartProduct: {
        products: [{
          productId: products[id].id,
          name: products[id].name,
          featuredImage: products[id].featuredImage,
          salePrice: products[id].price
        }]
      }
    }
    if (products[id] && products[id].variantCombinationDetails && products[id].variantCombinationDetails.length) {
      const variantIndex = products[id].variantCombinationDetails.findIndex(item => item && !(item.productVariantMappings && item.productVariantMappings.length));
      cartDetail.cartProduct.products[0]['variantId'] = variantIndex >= 0 ? products[id].variantCombinationDetails[variantIndex].id : null;
      return cartDetail;
    } else {
      return cartDetail;
    }
  }
  /**
    * Method used to add the product in the order
    * @param product which holds product details.
    * @param productOrders which holds products.
    * @param customerId which holds customer id.
    * @param websiteSettings which holds website settings details.
    * @param discountData which holds discount details.
    * @returns promise of product
    */
  goToOrder(product, productOrders, storeId, customerId?, websiteSettings?, discountData?) {
    let optionData, variantId;
    let modifiersId = [];
    if (product && product.modifierObject) {
      const modifierSetName = Object.keys(product.modifierObject);
      modifierSetName.forEach(setName => {
        if (product.modifierObject[setName] && product.modifierObject[setName].modifierArray && product.modifierObject[setName].modifierArray.length) {
          product.modifierObject[setName].modifierArray.forEach(item => {
            let objData = {};
            objData['modifierId'] = item && item.modifierId;
            objData['modifierSetId'] = product.modifierObject[setName] && product.modifierObject[setName].modifierSetId;
            objData['optionValue'] = item && item.optionValueName;
            objData['price'] = item && item.price;
            modifiersId.push(objData);
          });
        }
      });
    }
    return new Promise((resolve) => {
      if (product && product.id) {
        if (product.optionObject) {
          optionData = [];
          for (const item in product.optionObject) {
            if (product.optionObject[item] && product.optionObject[item].length) {
              for (let i = 0; i < product.optionObject[item].length; i++) {
                if (product.optionObject[item][i]) {
                  if (product.optionObject[item][i].productOptionValueMappings && product.optionObject[item][i].productOptionValueMappings[0]) {
                    optionData.push({
                      priceModifier: product.optionObject[item][i].productOptionValueMappings[0].priceModifier ? product.optionObject[item][i].productOptionValueMappings[0].priceModifier : null,
                      priceValue: product.optionObject[item][i].productOptionValueMappings[0].priceValue ? product.optionObject[item][i].productOptionValueMappings[0].priceValue : null,
                      optionName: item,
                      optionValueId: product.optionObject[item][i].id ? product.optionObject[item][i].id : null,
                      optionValueName: product.optionObject[item][i].optionValue ? product.optionObject[item][i].optionValue : null
                    });
                  }
                }
              }
            }
          }
        }
        if (product.selectedVariant && product.selectedVariant.variantId) {
          variantId = product.selectedVariant.variantId;
        } else if (product.variantCombinationDetails && product.variantCombinationDetails[0] && product.variantCombinationDetails[0].id) {
          variantId = product.variantCombinationDetails[0].id;
        } else if (product.variantId) {
          variantId = product.variantId;
        } else { variantId = null; }
        this.getAddOrderProductDetails(product.id, variantId, customerId, storeId).subscribe((res: any) => {
          if (res && res['getAddOrderProductData']) {
            res['getAddOrderProductData'].quantity = product.displayQuantity ? (res['getAddOrderProductData'].orderQuantityMinimum > product.displayQuantity ? res['getAddOrderProductData'].orderQuantityMinimum : product.displayQuantity) : product.discountProductId ? 1 : (product.weightCount ? product.weightCount : 1);
            res['getAddOrderProductData'].minimumOrderQuantity = res['getAddOrderProductData'].orderQuantityMinimum ? Number(res['getAddOrderProductData'].orderQuantityMinimum) : null;
            res['getAddOrderProductData'].maximumOrderQuantity = res['getAddOrderProductData'].orderQuantityMaximum ? Number(res['getAddOrderProductData'].orderQuantityMaximum) : null;
            res['getAddOrderProductData'].variantId = variantId ? variantId : null;
            res['getAddOrderProductData'].depositAmount = (websiteSettings && websiteSettings.isDepositEnabled && res['getAddOrderProductData'].depositAmount) ? Number(res['getAddOrderProductData'].depositAmount) : null;
            res['getAddOrderProductData'].combinationName = (product && product.selectedCombination && product.selectedCombination.combinationName) ? product.selectedCombination.combinationName : null;
            res['getAddOrderProductData'].options = optionData ? optionData : []
            res['getAddOrderProductData'].selectedModifiers = modifiersId;
            res['getAddOrderProductData'].appliedSalesDiscountValue = product && product.appliedSalesDiscountValue ? product.appliedSalesDiscountValue : 0;
            if (discountData) {
              res['getAddOrderProductData']['discountData'] = discountData;
            }
            if (res['getAddOrderProductData'].variantCombinationDetails && res['getAddOrderProductData'].variantCombinationDetails[0]) {
              res['getAddOrderProductData'].productId = res['getAddOrderProductData'].variantCombinationDetails[0].productId;
              res['getAddOrderProductData'].width = res['getAddOrderProductData'].variantCombinationDetails[0].width ? Number(res['getAddOrderProductData'].variantCombinationDetails[0].width) : null;
              res['getAddOrderProductData'].weight = res['getAddOrderProductData'].variantCombinationDetails[0].weight ? Number(res['getAddOrderProductData'].variantCombinationDetails[0].weight) : null;
              res['getAddOrderProductData'].length = res['getAddOrderProductData'].variantCombinationDetails[0].length ? Number(res['getAddOrderProductData'].variantCombinationDetails[0].length) : null;
              res['getAddOrderProductData'].height = res['getAddOrderProductData'].variantCombinationDetails[0].height ? Number(res['getAddOrderProductData'].variantCombinationDetails[0].height) : null;
              res['getAddOrderProductData'].variantName = res['getAddOrderProductData'].variantCombinationDetails[0].variantName ? res['getAddOrderProductData'].variantCombinationDetails[0].variantName : null;
              res['getAddOrderProductData'].productPrice = (product.selectedVariant && product.selectedVariant.price) ? product.selectedVariant.price : (res['getAddOrderProductData'].variantCombinationDetails[0].price ? Number(res['getAddOrderProductData'].variantCombinationDetails[0].price) : null);
            }
            res['getAddOrderProductData'].selectedAddons = product && product.selectedAddons && product.selectedAddons.addonDetails &&
              product.selectedAddons.addonDetails.length ? product.selectedAddons.addonDetails : null;
            res['getAddOrderProductData'].removedImages = product && product.removedImages && product.removedImages.length ? product.removedImages : [];
            res['getAddOrderProductData'].imageType = product && product.imageType ? "carts" : null;
            res['getAddOrderProductData'].addonImageData = product && product.addonImageData && product.addonImageData.length ? product.addonImageData : [];
            productOrders.push(res['getAddOrderProductData']);
            this.changedNewOrders.next(productOrders);
          }
          resolve({
            productOrders: productOrders
          });
        }, (err) => {
          if (err) {
          }
        });
      }
    });
  }
  /**
   * Method used to decrease the quantity of products for orders
   * @param product which holds product information.
   * @param productOrders which holds products
   * @returns ordered product data
   */
  decreaseOrderItemQuantity(product, productOrders) {
    if (product) {
      const index = productOrders.findIndex(x => (x && x.productId) === product.id);
      const changedProductData = {
        quantity: (productOrders[index].quantity ? productOrders[index].quantity : 0) - 1,
        product: {
          multiLocationVariantStocks: product.multiLocationVariantStocks ? product.multiLocationVariantStocks : null,
          orderQuantityMaximum: product.orderQuantityMaximum ? product.orderQuantityMaximum : null,
          orderQuantityMinimum: product.orderQuantityMinimum ? product.orderQuantityMinimum : null,
        }
      }
      const checkMinMaxQty = this.checkMinimumAndMaximumQty(changedProductData);
      if (checkMinMaxQty && checkMinMaxQty.qtyUpdateAlert) {
        this.dialogService.openDialog({
          header: 'Message',
          message: checkMinMaxQty.qtyUpdateAlert,
          actionType: 'information',
          button: { right: 'Okay' },
          disableClose: true
        });
      } else {
        if (index >= 0) {
          productOrders[index].quantity -= 1;
          productOrders[index].event = 'Decrease Quantity';
          this.changedNewOrders.next(productOrders);
        }
        return productOrders;
      }
    }
    else {
      return false;
    }
  }
  /**
 * Method used to increase the quantity of products in order
 * @param product which holds product information.
 * @param productOrders which holds products details in the order
 * @returns ordered product data
 */
  increaseOrderItemQuantity(product, productOrders) {
    if (product) {
      const index = productOrders.findIndex(x => (x && x.productId) === product.id);
      const changedProductData = {
        quantity: (productOrders[index].quantity ? productOrders[index].quantity : 0) + 1,
        product: {
          multiLocationVariantStocks: product.multiLocationVariantStocks ? product.multiLocationVariantStocks : null,
          orderQuantityMaximum: product.orderQuantityMaximum ? product.orderQuantityMaximum : null,
          orderQuantityMinimum: product.orderQuantityMinimum ? product.orderQuantityMinimum : null,
        }
      }
      const checkMinMaxQty = this.checkMinimumAndMaximumQty(changedProductData);
      if (checkMinMaxQty && checkMinMaxQty.qtyUpdateAlert) {
        this.dialogService.openDialog({
          header: 'Message',
          message: checkMinMaxQty.qtyUpdateAlert,
          actionType: 'information',
          button: { right: 'Okay' },
          disableClose: true
        });
      } else {
        if (index >= 0) {
          productOrders[index].quantity += 1;
          productOrders[index].event = 'Increase Quantity';
          this.changedNewOrders.next(productOrders);
        }
        return productOrders;
      }
    } else {
      return false;
    }
  }
  /**
   * Method used to check the product quantity and order quantity.
   * @param productId which holds product id.
   * @param id which holds product index
   * @param productOrders which holds products.
   * @param productList which holds products.
   * @returns boolean
   */
  checkOrder(productId: any, id: number, productOrders, productList) {
    if (productOrders && productOrders.length) {
      const index = productOrders.find(x => x.productId === productId && !x.discountProductId && !x.isPayXProduct && !x.fbtId);
      if (index) {
        if (productList && productList[id] && !productList[id].actualPrice && !productList[id].isPayXProduct) {
          productList[id].orderQuantity = index.quantity;
          return true;
        }
      } else {
        return false;
      }
    }
  }
  /**
   * Method used to save the contact details
   * @param storeId store id
   * @param data data to save the contact details
   * @returns saved response or error response
   */
  saveContactUsDetails(storeId, data) {
    return this.httpService.postMethod('stores/' + storeId + '/userqueries', data);
  }
  /**
  * Method used to get analytics details.
  * @param storeId id of the store.
  * @params customerId
  * @returns API success response or error response.
  */
  getAnalyticsDetails(storeId, data) {
    const url = this.httpService.replaceUrlParam(API.ANALYTICS_DETAILS, { storeId: storeId });
    return this.httpService.getMethod(url, { name: data });
  }
  /**
  * Method which is used to fetch plugins.
  * @param storeId has store id.
  * @returns API success response or error response
  */
  getPlugins(storeId, categoryName) {
    return this.httpService.getMethod('stores/' + storeId + '/plugincategory/' + categoryName + '/plugins');
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to get the seo details.
   * @param storeId has storeId.
   * @param productId has productId.
   * @returns API success response or error response.
   */
  getOgsDetails(storeId, productId) {
    const url = this.httpService.replaceUrlParam(API.OGS_DETAILS, {
      storeId: storeId,
      productId: productId
    });
    return this.httpService.getMethod(url);
  }

  /**
   * Method used to get the seo details.
   * @param storeId has storeId.
   * @param productId has productId.
   * @returns API success response or error response.
   */
  checkPreorderLimit(storeId, productId, data) {
    const url = this.httpService.replaceUrlParam(API.PREORDER_LIMIT, {
      storeId: storeId,
      id: productId
    });
    return this.httpService.getMethod(url, data);
  }
  /**
   * Method used to get the seo details.
   * @param storeId id of the store.
   * @params customerId
   * @returns API success response or error response.
   */
  getSeoDetails(storeId, queryParams) {
    const url = this.httpService.replaceUrlParam(API.SEO_DETAILS, {
      storeId: storeId
    });
    return this.httpService.getMethod(url, queryParams);
  }

  /**
   * Method used to get store details.
   * @param storeId id of the store.
   * @returns API success response or error response.
   */
  getStoreDetails(storeId) {
    return this.httpService.getMethod('stores/' + storeId);
  }
  /**
   * Method which is used to get all menu details.
   * @param categoryId category id
   * @returns Returns menu details.
   */
  getAllMenu(categoryId, storeId) {
    const url = this.httpService.replaceUrlParam(API.MENU_DETAILS, { storeId: storeId });
    return this.httpService.getMethod(url, { categoryId: categoryId, isStorefront: true });
  }
  /**
  * Method to get from fetch menu categories
  * @param categoryArray Which holds category names.
  * @returns returns API success response or error response.
  */
  getMenuCategories(categoryArray, storeId) {
    const url = this.httpService.replaceUrlParam(API.MENU_CATEGORIES, { storeId: storeId });
    return this.httpService.getMethod(url, { categoryArray: JSON.stringify(categoryArray) });
  }
  /**
   * Method used to fetch the store FAQ
   * @param storeId store id
   * @param data data to fetch the store FAQ
   * @returns fetched data or error response
   */
  getStoreFaq(storeId, data?) {
    const url = this.httpService.replaceUrlParam(API.FAQ, { storeId: storeId });
    return this.httpService.getMethod(url, data);
  }
  /**
   * Method used to fetch the product details for add to order
   * @param productId product id
   * @param variantId variant id
   * @param customerId customer id
   * @returns fetched product details or error response
   */
  getAddOrderProductDetails(productId, variantId, customerId?, storeId?) {
    const url = this.httpService.replaceUrlParam(API.GET_ADD_ORDER_PRODUCT_DETAILS, { storeId: storeId, productId: productId });
    return this.httpService.getMethod(url, { variantId: variantId, customerId: customerId });
  }
  /**
   * Method used to send a mail for order
   * @param storeId store id
   * @param data data to send the order mail.
   * @returns mailed details of the order or error response
   */
  sendMailForOrder(storeId, data) {
    return this.httpService.postMethod('stores/' + storeId + '/mail/orderdetails/', data);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to add the products into cart
   * @param data it holds the product data into the cart
   * @param storeId have the store id
   * @param customerId have the logged in customer id
   * @returns API success response or error response
   */
  addBulkProductsInCart(data: any, storeId: any, customerId: any) {
    const url = this.httpService.replaceUrlParam(API.BULK_UPLOAD, { storeId, customerId });
    return this.httpService.postMethod(url, data);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to get verified cart product details.
   * @param storeId id of the store.
   * @param zoneId id of the zone.
   * @param customerId id of the customer.
   * @returns API success response or error response.
   */
  getVerifiedCartProducts(customerId: any, queryParams: any, storeId?: any) {
    queryParams.currentDate = new Date();
    const url = this.httpService.replaceUrlParam(API.VERIFIED_CART, { storeId, customerId });
    return this.httpService.getMethod(url, queryParams);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to get guest user cart product details.
   * @param storeId id of the store.
   * @param queryParams which hold the query param
   * @param data cart details to get cart product data.
   * @returns API success response or error response.
   */
  getGuestCartProducts(storeId: any, data: any, queryParams: any) {
    queryParams.currentDate = new Date();
    const url = this.httpService.replaceUrlParam(API.GUEST_USER_CART, { storeId });
    return this.httpService.postMethod(url, data, queryParams);
  }
  /**
   * Method used to get the product price.
   * @param productDetails details of the product to calculate the price.
   */
  getProductPrice(productDetails) {
    if (productDetails && productDetails.variantCombinationDetails && productDetails.variantCombinationDetails.length) {
      return productDetails.variantCombinationDetails.findIndex((item => item && !(item.productVariantMappings && item.productVariantMappings.length)));
    }
  }
  /**
   * Method used to get count of discount and announcement.
   * @param url Path
   * @param status Manual or not.
   * @param storeId id of the store.
   * @returns API success response or error response.
   */
  getDealsDetails(url, status, storeId) {
    return this.httpService.getMethod(url, { type: status ? status : 'Manual', storeId: storeId });
  }

  /**
   * Method used to get limited products of categories
   * @param {*} storeId
   * @param {*} customerId
   * @param {*} locationId
   * @returns {*}
   */
  getCategoriesProducts(storeId, customerId, locationId) {
    let query = { locationId: locationId };
    if (customerId) {
      query['customerId'] = customerId;
    }
    const url = this.httpService.replaceUrlParam(API.FEATURED_CATEGORIES_PRODUCTS, { storeId: storeId });
    return this.httpService.getMethod(url, query);
  }
  /**
   * Method used to update user and customer details.
   * @param storeId id of the store.
   * @param userId id of the user.
   * @param data which holds form data.
   * @returns
   */
  updateUserDetails(storeId, customerId, data) {
    const url = this.httpService.replaceUrlParam(API.UPDATE_USER_INFO, { storeId: storeId, customerId: customerId });
    return this.httpService.putMethod(url, data);
  }
  /**
   * Method used to create faq
   * @param storeId holds the store id.
   * @param data holds the faq details.
   * @returns API success response or error response
  */
  createFaq(storeId, data) {
    const url = this.httpService.replaceUrlParam(API.FAQ, { storeId: storeId });
    return this.httpService.postMethod(url, data);
  }
  /**
   * Method used to sort the FAQ for the list
   * @param storeId store id
   * @param data data to sort the FAQ
   * @returns API success response or error response
   */
  sortFaq(storeId, data) {
    const url = this.httpService.replaceUrlParam(API.SORT_FAQ, { storeId: storeId });
    return this.httpService.putMethod(url, data);
  }
  /**
   * Method used to get one store faq.
   * @param storeId holds the store id.
   * @param id holds the faq id.
   * @returns API success response or error response
   */
  getOneFaq(storeId, id, data) {
    const url = this.httpService.replaceUrlParam(API.FAQ_DETAILS, { storeId: storeId, id: id });
    return this.httpService.getMethod(url, data);
  }
  /**
   * Method used to update faq.
   * @param storeId holds the store id.
   * @param id holds the faq id.
   * @param data holds the faq details.
   * @returns API success response or error response
   */
  updateFaq(storeId, id, data) {
    const url = this.httpService.replaceUrlParam(API.FAQ_DETAILS, { storeId: storeId, id: id });
    return this.httpService.putMethod(url, data);
  }
  /**
   * Method used to delete store faq.
   * @param storeId holds the store id.
   * @param id holds the faq id.
   * @returns API success response or error response
   */
  deleteStoreFaq(storeId, id) {
    const url = this.httpService.replaceUrlParam(API.FAQ_DETAILS, { storeId: storeId, id: id });
    return this.httpService.deleteMethod(url);
  }
  /**
  * Method used to get coupon code details.
  * @param data holds data.
  * @returns API success response or error response
  */
  getCouponCodeDetails(storeId, data: any) {
    data.currentDate = new Date();
    const url = this.httpService.replaceUrlParam(API.COUPON_DETAIL, { storeId: storeId });
    return this.httpService.postMethod(url, data);
  }
  /**
  * Method used to get gift card details.
  * @param storeId holds the store id.
  * @param userId holds the customer id.
  * @param data holds data.
  * @returns API success response or error response
  */
  getAllGiftCardCheckout(storeId, userId, data) {
    const url = this.httpService.replaceUrlParam(API.getPurchasedGiftCardDetailsForUser, { storeId: storeId, userId: userId });
    return this.httpService.postMethod(url, data);
  }
  /**
  * Method used to get gift card details for url checkout.
  * @param storeId holds the store id.
  * @param userId holds the customer id.
  * @param data holds data.
  * @returns API success response or error response
  */
  getAllGiftCardUrlCheckout(storeId, userId, data) {
    const url = this.httpService.replaceUrlParam(API.GET_PURCHASED_GIFTCARD_DETAILS, { storeId: storeId, userId: userId });
    return this.httpService.postMethod(url, data);
  }
  /**
   * Method used to fetch a store policies
   * @param storeId store id
   * @param policyName policy name
   * @returns fetched data or error response
   */
  getAllStorePolicies(storeId, policyName) {
    return this.httpService.getMethod('stores/' + storeId + '/policy', { policyName });
  }
  /**
 * Method used to fetch a store policies
 * @param storeId store id
 * @param policyId policy id
 * @returns fetched data or error response
 */
  getOneStorePolicy(storeId, policyId, data?) {
    return this.httpService.getMethod('stores/' + storeId + '/policy/' + policyId, data);
  }
  /**
   * Method used to apply the coupon code
   * @param data data to apply the coupon code
   * @returns response of coupon code or error response
   */
  CouponCodeApply(data: any) {
    return this.httpService.postMethod('checkout/couponApply', data);
  }
  /**
   * Method used to get email tracking details.
   * @param customerId id of the customer.
   * @param campaignId id of the email campaign.
   * @param storeId id of the store.
   * @param data used to get email tracking details.
   * @returns API success response or error response.
   */
  emailTracking(storeId, campaignId, customerId, data) {
    const url = this.httpService.replaceUrlParam(API.EMAIL_TRACKING_DETAILS, { storeId: storeId, campaignId: campaignId, customerId: customerId });
    return this.httpService.putMethod(url, data);
  }
  /**
   * Method used to calculate the order summary
   * @param data data to calculate the order summary
   * @returns response of summary or error
   */
  calculateOrderSummary(data) {
    data.currentDate = new Date();
    const url = this.httpService.replaceUrlParam(API.CALCULATE_SUMMARY, { storeId: data.storeId });
    return this.httpService.postMethod(url, data);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to calculate the order summary
   * @param data data to calculate the order summary
   * @returns response of summary or error
   */
  calculateDigitalProductOrderSummary(data) {
    data.currentDate = new Date();
    const url = this.httpService.replaceUrlParam(API.CALCULATE_SUMMARY_FOR_DIGITAL_PRODUCT, { storeId: data.storeId });
    return this.httpService.postMethod(url, data);
  }

  /**
     * Method used to calculate the order summary
     * @param data data to calculate the order summary
     * @returns response of summary or error
     */
  calculateCheckout(data) {
    const url = this.httpService.replaceUrlParam(API.CALCULATE_SUMMARY1, { storeId: data.storeId, clientId: data?.customerId });
    return this.httpService.postMethod(url, data);
  }
  /**
     * Method used to calculate the order summary
     * @param data data to calculate the order summary
     * @returns response of summary or error
     */
  calculateCheckoutUrl(data) {
    const url = this.httpService.replaceUrlParam(API.URL_CALCULATE_SUMMARY, { storeId: data.storeId, clientId: data?.customerId });
    return this.httpService.postMethod(url, data);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to delete specified cart products.
   * @param productIds holds product ids.
   * @returns
   */
  bulkDeleteCartProducts(cartProductIds: any, storeId: any) {
    const url = this.httpService.replaceUrlParam(API.BULK_DELETE_CART_PRODUCTS, { storeId: storeId });
    return this.httpService.postMethod(url, { id: cartProductIds });
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to bulk delete guest user cart products.
   * @param ids holds product id and variant id.
   */
  bulkDeleteGuestUserCart(ids: any) {
    const guestUserCart = localStorage && localStorage.getItem('cart') ?
      JSON.parse(localStorage.getItem('cart')) : { cartProduct: { products: [] } };
    if (guestUserCart && guestUserCart.cartProduct && guestUserCart.cartProduct.products && guestUserCart.cartProduct.products && guestUserCart.cartProduct.products.length && ids && ids.length) {
      ids.forEach((response: any) => {
        if (response) {
          const index = guestUserCart.cartProduct.products.findIndex((res: any) => res && res.id == response);
          if (index !== -1) {
            guestUserCart.cartProduct.products.splice(index, 1);
          }
        }
      });
    }
    localStorage.setItem('cart', JSON.stringify(guestUserCart));
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to generate unique product code.
   * @param variantId holds variantId.
   * @returns unique product code.
   */
  generateUniqueCode(variantId: number): string {
    if (variantId) {
      const timestamp = new Date().getTime();
      const base36Timestamp = timestamp.toString(36);
      const uniqueCode = `${variantId}${base36Timestamp}`;
      return uniqueCode;
    }
    return null;
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to update guest user cart while crud operation.
   * @param cartDetails holds specific product details.
   * @param locationDetails holds location details.
   */
  updateGuestUserCart(cartDetails?: any, locationDetails?: any, imageData?: any, cartItem?: Model.CartProducts) {
    const guestUserCart = localStorage && localStorage.getItem('cart') ?
      JSON.parse(localStorage.getItem('cart')) : { cartProduct: { products: [] } };
    if (cartItem && cartItem.id && cartItem.quantity && guestUserCart && guestUserCart.cartProduct && guestUserCart.cartProduct.products && guestUserCart.cartProduct.products && guestUserCart.cartProduct.products.length) {
      const index = guestUserCart.cartProduct.products.findIndex((res: Model.GuestUserProduct) => res && res.id == cartItem.id);
      guestUserCart.cartProduct.products.splice(index, 1);
      this.cartCountValue.next(cartDetails?.cartCount - cartItem.quantity);
    }
    return new Promise((resolve, reject) => {
      if (cartDetails) {
        if (guestUserCart && guestUserCart.cartProduct && guestUserCart.cartProduct.products && guestUserCart.cartProduct.products) {
          const index = guestUserCart.cartProduct.products.findIndex((item: any) => item && (item.variantId && cartDetails.variantId && item.variantId === cartDetails.variantId) && (item.productId && cartDetails.productId && item.productId === cartDetails.productId) &&
            ((item.id && cartDetails.cartProductId && item.id == cartDetails.cartProductId) || (item.id && cartDetails.id && item.id == cartDetails.id)) &&
            ((item.discountProductId && cartDetails.discountProductId && item.discountProductId === cartDetails.discountProductId) || (!item.discountProductId && !cartDetails.discountProductId)) &&
            ((item.fbtId && cartDetails.fbtId && item.fbtId === cartDetails.fbtId) || (!item.fbtId && !cartDetails.fbtId)));
          let alertMessage: string;
          if (index !== -1) {
            if (!cartDetails.quantity) {
              let fbtId = guestUserCart.cartProduct.products[index].fbtId ?? null;
              guestUserCart.cartProduct.products.splice(index, 1);
              if (fbtId) {
                guestUserCart.cartProduct.products = this.removeFbtProduct(fbtId, guestUserCart);
              }
              alertMessage = this.translationService.getTranslation('SHARED.REMOVE_FROM_CART');
            } else {
              guestUserCart.cartProduct.products[index].quantity = cartDetails.quantity;
              guestUserCart.cartProduct.products[index].instruction = cartDetails.instruction;
              guestUserCart.cartProduct.products[index].isReplaceOrder = cartDetails.isReplaceOrder;
              guestUserCart.cartProduct.products[index].modifierIds = cartDetails.modifierIds;
              guestUserCart.cartProduct.products[index]['selectedAddons'] = cartDetails.selectedAddons ?? [];
              guestUserCart.cartProduct.products[index]['removedImages'] = cartDetails.removedImages ?? [];
              guestUserCart.cartProduct.products[index]['customizedContent'] = cartDetails.customizedContent ?? [];
              guestUserCart.cartProduct.products[index]['oldCustomizeContent'] = cartDetails.oldCustomizeContent ?? [];
              alertMessage = this.translationService.getTranslation('SHARED.UPDATED_CART');
            }
          }
          else {
            cartDetails['id'] = this.generateUniqueCode(cartDetails.variantId);
            guestUserCart.cartProduct.products.unshift(cartDetails);
            alertMessage = this.translationService.getTranslation('SHARED.ADD_TO_CART');
          }
          guestUserCart['imageType'] = 'carts';
          localStorage.setItem('cart', JSON.stringify(guestUserCart));
          if (locationDetails && locationDetails.locationId && locationDetails.timeZone) {
            this.bulkOperationService.importData('stores/' + (this.storeId ? this.storeId : cartDetails.storeId) + '/cart', imageData ? imageData : [], 'postMethod', guestUserCart, null, { locationId: locationDetails.locationId, timeZone: locationDetails.timeZone, isProductDetails: true, isCart: true }).subscribe((res: any) => {
              // this.getGuestCartProducts(this.storeId, guestUserCart, { locationId: locationDetails.locationId, timeZone: locationDetails.timeZone, isProductDetails: true, isCart: true }).subscribe((res: any) => {
              this.dialogService.openDialog({
                message: alertMessage,
                actionType: 'success',
                button: { right: this.translationService.getTranslation('SHARED.DIALOG_OKAY') },
              });
              if (res && res.cartProducts) {
                if (res.cartProducts && res.cartProducts.cartProducts && res.cartProducts.cartProducts.length) {
                  for (let product of res.cartProducts.cartProducts) {
                    if (product && (product.addonImageData || product.customizeProductData) && !product.isPayXProduct && !product.isFreeProduct && !product.discountProductId && guestUserCart && guestUserCart.cartProduct && guestUserCart.cartProduct.products && guestUserCart.cartProduct.products.length) {
                      guestUserCart.cartProduct.products.forEach((prod) => {
                        if (prod && (prod.productId === product.productId) && (prod.variantId === product.variantId) && (prod.id == product.id)) {
                          if (product.addonImageData) {
                            prod['addonImageData'] = product.addonImageData;
                            delete prod.selectedAddons;
                            delete prod.removedImages;
                          }
                          if (product.customizeProductData) {
                            prod['customizeProductData'] = product.customizeProductData;
                            delete prod.customizedContent;
                            delete prod.oldCustomizeContent;
                          }
                        }
                      });
                    }
                  }
                }
                localStorage.setItem('cart', JSON.stringify(guestUserCart));
                this.cartCountValue.next(res.cartProducts.cartCount ? res.cartProducts.cartCount : 0);
                resolve(guestUserCart.cartProduct.products);
              }
            }, (err: any) => {
              reject(err);
            });
          } else {
            this.dialogService.openDialog({
              message: alertMessage,
              actionType: 'success',
              button: { right: this.translationService.getTranslation('SHARED.DIALOG_OKAY') },
            });
            resolve(guestUserCart.cartProduct.products);
          }
        }
      }
    });
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to check fbt product.
   * @param fbtId holds fbt id.
   * @param guestUserCart holds values in local storage.
   */
  removeFbtProduct(fbtId: any, guestUserCart: any): any {
    if (guestUserCart && guestUserCart.cartProduct && guestUserCart.cartProduct.products && guestUserCart.cartProduct.products) {
      guestUserCart.cartProduct.products.forEach((guest: any) => {
        // console.log('outside guest', guest);
        if (guest && guest.fbtId && guest.fbtId === fbtId) {
          // console.log('inside guest', guest);
          const qtyIndex = guestUserCart.cartProduct.products.findIndex((res: any) => !res.fbtId && (res.productId === guest.productId) && (res.variantId === guest.variantId) && ((res.discountProductId === guest.discountProductId) || (!res.discountProductId && !guest.discountProductId)));
          if (qtyIndex !== -1) {
            guestUserCart.cartProduct.products[qtyIndex].quantity += guest.quantity ? Number(guest.quantity) : 0;
            // console.log('remove guest', guest);
            guest['remove'] = true;
          }
          guest['fbtId'] = null;
          guest['isFbtDefaultProduct'] = false;
          guest['isFbt'] = false;
        }
      });
      return guestUserCart.cartProduct.products.filter((deleteGuest: any) => !(deleteGuest && deleteGuest.remove));
    }
  }

  /**
   * Method used to filter the time zone
   * @param value value to filter
   * @returns label of the time
   */
  ontimeZonesSearchFilter(value: string) {
    this.timeZones = this.timeZonesSearch(value);
    return this.timeZones[0].label;
  }
  /**
   * Method used to search the time zone
   * @param value value to filter
   * @returns filtered time zone
   */
  timeZonesSearch(value: string) {
    const result = this.allTimeZones.filter(obj => obj.value === value);
    return result;
  }
  /**
   * Method used to fetch the time zone of the particular location
   * @param locationId location id
   * @param storeId store id
   * @returns time zone of the location or error response
   */
  getLocationTimeZone(locationId: number, storeId: number) {
    let timezone;
    return new Promise((resolve, reject) => {
      this.getOneStoreLocation(storeId, locationId).subscribe((res) => {
        if ((res && res['inventoryLocations'] && res['inventoryLocations'].timeZone)) {
          timezone = res['inventoryLocations'].timeZone;
          const locationTime = this.ontimeZonesSearchFilter(timezone);
          const hour = locationTime.substring(4, 7);
          const minute = locationTime.substring(8, 10);
          const locationTimeZone = hour.concat(minute);
          resolve(locationTimeZone);
        }
      }, (err) => {
        reject(err);
      });
    })
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to check minimum and maximum quantity of an item.
   * @param item holds product details.
   */
  checkMinimumAndMaximumQty(item: any) {
    if (item && item.product) {
      const productStock = item.product.stock ? item.product.stock : item.product.multiLocationVariantStocks && item.product.multiLocationVariantStocks[0] &&
        item.product.multiLocationVariantStocks[0].stock;
      if (item.quantity && !item.preorder && productStock !== -1 && (productStock < item.quantity)) {
        item['isDisabled'] = true;
        item['qtyUpdateAlert'] = this.translationService.dynamicMessageTranslation('COMMON.STOCK_ALERT', [productStock]);
      }
      else if (!item.preorder && item.product.orderQuantityMaximum && item.quantity && (item.product.orderQuantityMaximum < item.quantity)) {
        item['isDisabled'] = true;
        item['qtyUpdateAlert'] = this.translationService.dynamicMessageTranslation('COMMON.QUANTITY_ALERT', [Number(item.product.orderQuantityMaximum)]);
      }
      // else if (!item.preorder && item.product.orderQuantityMinimum && item.quantity && (item.product.orderQuantityMinimum > item.quantity)) {
      //   item['isDisabled'] = true;
      //   item['qtyUpdateAlert'] = `You cannot decrease the quantity further, since the minimum quantity limit is ${item.product.orderQuantityMinimum}.`;
      // }
      else if (item.quantity && item.preorder && productStock !== -1 && (productStock < item.quantity)) {
        item['isDisabled'] = true;
        item['qtyUpdateAlert'] = productStock > 0 ? this.translationService.dynamicMessageTranslation('COMMON.PREORDER_STOCK_ALERT', [productStock]) : this.translationService.getTranslation('COMMON.PREORDER_STOCK_ALERT2');
      }
      else if (item.preorder && item.product.orderQuantityMaximum && item.quantity && (item.product.orderQuantityMaximum < item.quantity)) {
        item['isDisabled'] = true;
        item['qtyUpdateAlert'] = this.translationService.getTranslation('COMMON.PREORDER_MAXIMUM_ORDER_QUANTITY');
      }
      else if (item.preorder && item.product.orderQuantityMaximum && item.quantity && item.customerOrderCount && (item.product.orderQuantityMaximum < item.quantity + item.customerOrderCount)) {
        item['isDisabled'] = true;
        item['qtyUpdateAlert'] = this.translationService.getTranslation('COMMON.PREORDER_MAXIMUM_ORDER_QUANTITY');
      }
      else {
        item['isDisabled'] = false;
      }
      //   if ((productStock <= item.product.orderQuantityMaximum || !item.product.orderQuantityMaximum) && productStock !== -1)
      //     item.product.orderQuantityMaximum = productStock;
      //   if (item.quantity && productStock && ((item.product.orderQuantityMinimum && item.product.orderQuantityMinimum > item.quantity) || (item.product.orderQuantityMaximum && item.product.orderQuantityMaximum < item.quantity))) {
      //     item['isDisabled'] = true;
      //     item['qtyUpdateAlert'] = (item.product.orderQuantityMaximum < item.quantity) ? `Apologies, we have only ${item.product.orderQuantityMaximum} units left.` : 'You should add further quantity to place order';
      //   } else
      //     item['isDisabled'] = false;
    }
    return { isDisabled: item['isDisabled'], qtyUpdateAlert: item['qtyUpdateAlert'] };
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Function used to check the modifier checking based on min, max modifiers
   * @param modifierObject holds the value of the selected modifiers object
   */
  checkModifiers(modifierObject) {
    const notSatisfiedModifiers = {};
    let errorMessage;
    for (const data in modifierObject) {
      if (data && modifierObject[data]) {
        if (modifierObject[data].minModifiers && modifierObject[data].modifierArray && modifierObject[data].modifierArray.length < modifierObject[data].minModifiers) {
          notSatisfiedModifiers[data] = { ...notSatisfiedModifiers[data], ...{ min: modifierObject[data].minModifiers } };
        }
        if (modifierObject[data].maxModifiers && modifierObject[data].modifierArray && modifierObject[data].modifierArray.length > modifierObject[data].maxModifiers) {
          notSatisfiedModifiers[data] = { ...notSatisfiedModifiers[data], ...{ max: modifierObject[data].maxModifiers } };
        }
      }
    }
    if (Object.keys(notSatisfiedModifiers).length) {
      // errorMessage = "You have to choose ";
      for (const key in notSatisfiedModifiers) {
        if (key && notSatisfiedModifiers[key]) {
          if (notSatisfiedModifiers[key].min) {
            errorMessage = this.translationService.dynamicMessageTranslation('COMMON.CHOOSE_AT_LEAST', [notSatisfiedModifiers[key].min, key]);
          }
          if (notSatisfiedModifiers[key].max) {
            errorMessage = this.translationService.dynamicMessageTranslation('COMMON.CHOOSE_AT_MOST', [notSatisfiedModifiers[key].max, key]);
          }
        }
      }
      errorMessage = errorMessage.slice(0, -2) + ".";
    }
    return (errorMessage ? { errorMessage: errorMessage, isModifierNotAvailable: true } : null);
  }
  /**
   * Function used to check the addon checking based on required images
   * @param addonObject holds the value of the selected addons object
   */
  checkAddons(addonObject: Array<any>): object {
    let errorMessage: Array<any> = [], changed: boolean = false, errorMessagefinal;
    for (const addon in addonObject) {
      if (addonObject && addonObject[addon] && (addonObject[addon].requiredStatus === true && !addonObject[addon].imageUrl)) {
        errorMessage.push(addonObject[addon].name);
      }
      if (addonObject[addon] && addonObject[addon].isChanged)
        changed = true;
    }
    if (errorMessage && errorMessage.length) {
      const combinedMessage = errorMessage.join(', ');
      errorMessagefinal = this.translationService.dynamicMessageTranslation('COMMON.FINAL_MESSAGE', [combinedMessage]);
    }
    return (errorMessagefinal ? { errorMessage: errorMessagefinal, isAddonNotAvailable: true } : { isChanged: changed });
  }
  /**
   * Function used to check the selected modifier is changed or not
   * @returns modifier changed or not
   */
  checkModifierChanged(cartProductModifiers, modifierMappingIds) {
    if (!(cartProductModifiers && cartProductModifiers.length) && !(modifierMappingIds && modifierMappingIds.length)) {
      return false;
    } else if (cartProductModifiers && cartProductModifiers.length && modifierMappingIds && modifierMappingIds.length) {
      if (cartProductModifiers.length !== modifierMappingIds.length) {
        return true;
      } else {
        let count = 0;
        for (let id of modifierMappingIds) {
          if (id && cartProductModifiers.find(mod => mod === id)) {
            count++;
          }
        }
        if (cartProductModifiers.length !== count) {
          return true;
        } else {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to check email provided by guest user
   * @param storeId has storeId of logged in user
   * @param userEmail holds the data to check user email entry
   * @returns
   */
  checkGuestUserEmail(storeId: any, queryParams: any) {
    const url = this.httpService.replaceUrlParam(API.CHECK_GUEST_EMAIL, { storeId: storeId })
    return this.httpService.getMethod(url, queryParams).pipe(map((response: any) => {
      if (response && response.userEmailCheck && response.userEmailCheck.accessToken && !('emailExist' in response.userEmailCheck)) {
        this.headerService.setHeaders('default', 'Authorization', response.userEmailCheck.accessToken);
      }
      return response.userEmailCheck
    }));
  }
  /**
   * Method used to update the orderdetails
   * @param storeId store id
   * @param data data to update the order
   * @returns response of the updated order or error response.
   */
  updateOrder(storeId, orderId, data) {
    const url = this.httpService.replaceUrlParam(API.UPDATE_ORDER, { storeId: storeId, orderId: orderId });
    return this.httpService.postMethod(url, data);
  }
  /**
   * Method used to set ribbon in the product image
   * @param ribbon holds information about ribbon.
   * @param productIndex holds product index value.
   * @returns html tag
   */
  ribbonSetUp(ribbon: any, productIndex: any): any {
    let html: any;
    if (ribbon && ribbon.productRibbons && ribbon.className) {
      html = ribbon && ribbon.productRibbons && ribbon.productRibbons.overlayRibbonPosition && ribbon.className[ribbon.productRibbons.overlayRibbonPosition].className[0] ? ribbon.ribbonTemplate.replace('className1', ribbon.className[ribbon.productRibbons.overlayRibbonPosition].className[0]) : html;
      html = ribbon.productRibbons.overlayRibbonPosition && ribbon.className[ribbon.productRibbons.overlayRibbonPosition].className[1] ? html.replace('className2', ribbon.className[ribbon.productRibbons.overlayRibbonPosition].className[1]) : html;
      html = ribbon.productRibbons.overlayRibbonPosition && ribbon.className[ribbon.productRibbons.overlayRibbonPosition].className[2] ? html.replace('className3', ribbon.className[ribbon.productRibbons.overlayRibbonPosition].className[2]) : html;
      html = ribbon.productRibbons.overlayRibbonPosition && ribbon.className[ribbon.productRibbons.overlayRibbonPosition].styleAttribute[0] ? html.replace('style1', ribbon.className[ribbon.productRibbons.overlayRibbonPosition].styleAttribute[0]) : html;
      html = ribbon.productRibbons.overlayRibbonPosition && ribbon.className[ribbon.productRibbons.overlayRibbonPosition].styleAttribute[1] ? html.replace('style2', ribbon.className[ribbon.productRibbons.overlayRibbonPosition].styleAttribute[1]) : html;
      html = html.replace('textName', ribbon.text);
      if (ribbon.textColor) {
        html = html.replace('var(--light-color)', ribbon.textColor);
      }
      if (ribbon.ribbonColor) {
        html = html.replace('var(--primary-color)', ribbon.ribbonColor);
        html = html.replace('var(--secondary-color)', ribbon.ribbonColor);
        if (ribbon.className.ribbonName == 'foldable' || ribbon.className.ribbonName == 'v-shapeRibbon') {
          const ribbonElement = document.getElementById(((productIndex ? productIndex : 0) + 1).toString()) && document.getElementById(((productIndex ? productIndex : 0) + 1).toString()).style;
          ribbonElement && ribbonElement.setProperty('--new-ribbon-color', ribbon.ribbonColor);
        }
      }
      html = this.sanitizer.bypassSecurityTrustHtml(html);
      return html;

    }
  }
  /**
   * Method used to add meta data dynamically
   * @param route 
   * @param storeid 
   * @param id 
   */
  addMetaTags(route, storeid?, id?) {
    if (isPlatformBrowser(this.platformId)) {
      this.url = window && window.location && window.location.href;
    }
    if (route && route.includes("productdetails") && id > 0) {
      this.getSeoDetails(this.storeId, { productId: id, ogs: true }).subscribe((res: any) => {
        if (res && res.seoDetails) {
          if (res.seoDetails.productSeo) {
            let image = res.seoDetails.productSeo.og && res.seoDetails.productSeo.og.ogsImage ? res.seoDetails.productSeo.og.ogsImage : res.seoDetails.productSeo.featuredImage;
            this.meta.addTags([
              { name: 'description', content: res.seoDetails.productSeo.seo && res.seoDetails.productSeo.seo.metaDescription ? res.seoDetails.productSeo.seo.metaDescription : res.seoDetails.productSeo.description ? res.seoDetails.productSeo.description : res.seoDetails.productSeo.name },
              { name: 'author', content: res.seoDetails.productSeo.seo && res.seoDetails.productSeo.seo.pageTitle ? res.seoDetails.productSeo.seo.pageTitle : res.seoDetails.productSeo.name },
              {
                name: 'keywords', content: res.seoDetails.productSeo.seo && res.seoDetails.productSeo.seo.searchKeywords ?
                  res.seoDetails.productSeo.seo.searchKeywords.toString().split(',').join(', ') + ', ' + res.seoDetails.productSeo.name : res.seoDetails.productSeo.name
              },
              { property: 'og:title', content: res.seoDetails.productSeo.seo && res.seoDetails.productSeo.seo.pageTitle ? res.seoDetails.productSeo.seo.pageTitle : res.seoDetails.productSeo.name },
              { property: 'og:url', content: this.url },
              { property: 'og:type', content: 'website' },
              { property: 'og:description', content: res.seoDetails.productSeo.og && res.seoDetails.productSeo.og.ogsDescription ? res.seoDetails.productSeo.og.ogsDescription : res.seoDetails.productSeo.description ? res.seoDetails.productSeo.description : res.seoDetails.productSeo.name },
              { property: 'og:image', content: `${this.environment.AWSImageUrl}${image}` },
            ]);
          }
        }
      });
    }
    else {
      this.getRestSeoDetails(storeid).subscribe((res: any) => {
        if (res && res.seoDetails)
          var name = res.seoDetails.find(config => route.includes(config.pageTitle) && !config.isDeleted);
        if (name) {
          this.titleService.setTitle(name?.title ? name?.title : `zenbasket - ${route}`);
          this.meta.addTags([
            { name: 'description', content: name?.description },
            { name: 'author', content: name?.author },
            {
              name: 'keywords', content: name?.keywords ? name.keywords.toString().split(',').join(', ') : ''
            },
            { property: 'og:title', content: name?.ogTitle },
            { property: 'og:url', content: this.url },
            { property: 'og:type', content: 'website' },
            { property: 'og:description', content: name?.ogDescription },
            // name.ogUrl ? { property: 'og:image', content: `${this.environment.AWSImageUrl}${name.ogUrl}` } : null
          ].filter(tag => tag.content));
          if (name.isTwitterCheck) {
            this.meta.addTags([
              { name: 'twitter:title', content: name?.twitterTitle },
              { name: 'twitter:description', content: name?.twitterDescription },
              { name: 'twitter:card', content: name?.twitterType || 'summary' },
              { name: 'twitter:image', content: name?.twitterImage },
              { name: 'twitter:url', content: this.url },
            ].filter(tag => tag.content));
          }
        }
      })
    }
  }
  /**
   * Method used to remove the meta tags
   */
  removeTags() {
    const metaTagAttributes = [
      { type: 'name', values: ['description', 'author', 'keywords', 'twitter:title', 'twitter:description', 'twitter:card', 'twitter:image', 'twitter:url'] },
      { type: 'property', values: ['og:title', 'og:url', 'og:type', 'og:description', 'og:image'] }
    ];
    metaTagAttributes.forEach(attrType => {
      attrType.values.forEach(value => {
        this.meta.removeTag(`${attrType.type}="${value}"`);
      });
    });
  }
  /**
   * Method used to get the payment details
   * @param storeId has the store Id
   * @param queryParams has the query params
   * @returns response
   */
  getPaymentDetails(storeId, queryParams) {
    const url = this.httpService.replaceUrlParam(API.GET_PAYMENT_DETAILS, { storeId });
    return this.httpService.getMethod(url, queryParams);
  }
  /**
   * Method used to get the payment details
   * @param storeId has the store Id
   * @param queryParams has the query params
   * @returns response
   */
  getPaymentDetailsSubscription(storeId: any, queryParams: any) {
    const url = this.httpService.replaceUrlParam(API.GET_PAYMENT_DETAILS_SUBSCRIPTION, { storeId });
    return this.httpService.getMethod(url, queryParams);
  }
  /**
   * Method used to get the product modifiers in order details
   * @param storeId has the store Id
   * @param productId has the selected product Id
   * @returns response
   */
  getProductModifiers(storeId, productId, variantId) {
    const url = this.httpService.replaceUrlParam(API.getProductModifiers, { storeId: storeId, productId: productId, variantId: variantId });
    return this.httpService.getMethod(url);
  }
  /**
   * Method used to update product modifiers in order details
   * @param storeId has the store Id
   * @param productId has the selected product Id
   * @param orderId has the selected orderId Id
   * @param data has the array of modifier Id
   * @returns response
   */
  // updateProductModifiers(storeId, orderId, productId, data) {
  //   const value = { selectedModifiers: data, productId: productId }
  //   const url = this.httpService.replaceUrlParam(API.ADMIN_UPDATE_ORDER, { storeId: storeId, orderId: orderId })
  //   return this.httpService.postMethod(url, value);
  // }
  updateProductModifiers(storeId, data) {
    data['currentDate'] = new Date();
    // data['orderId'] = data.orderId;
    const url = this.httpService.replaceUrlParam(API.ADMIN_UPDATE_ORDER, { storeId: storeId, orderId: data.orderId });
    return this.httpService.putMethod(url, data);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method which is used to remove discounts from session storage based on product availability
   * @param productList has the product list
   * @param sessionDiscounts has the discounts available in the session storage
   */
  listAfterDeletion(productList, sessionDiscounts) {
    // console.log('session discounts', sessionDiscounts);
    let tempArray = [], discounts = [], finalDiscounts = [], temporaryDiscountList = [], singleDiscount;
    productList && productList.map((res1: any) => {
      if (res1 && res1['discountDetails']) {
        tempArray.push(res1['discountDetails']);
      }
    });
    // console.log('discountDetails', tempArray);
    tempArray && tempArray.forEach(response => {
      if (temporaryDiscountList && response && response.appliesToValue && response.appliesToValue.code && response.appliesToValue.code === "PRODUCT") {
        temporaryDiscountList.push(response);
      }
      else {
        discounts = tempArray && tempArray.filter((value, index, total) =>
          total && index === total.findIndex((singleObject) => {
            if (value && value.id && singleObject && singleObject.id && value && value.appliesToValue && value.appliesToValue.code && value.appliesToValue.code !== "PRODUCT") {
              return singleObject.id === value.id && ((singleObject.categoryId && value.categoryId && singleObject.categoryId === value.categoryId) || (singleObject.brandId && value.brandId && singleObject.brandId === value.brandId));
            }
            else {
              return false;
            }
          }));
      }
    });
    // console.log('temporaryDiscountList', temporaryDiscountList);
    // console.log('discounts', discounts);
    discounts = [...discounts, ...temporaryDiscountList];
    temporaryDiscountList = [];
    // console.log('filtered discounts', discounts);

    discounts && discounts.forEach(discount => {
      if (discount) {
        if (discount.id && discount.productId && discount.appliesToValue && discount.appliesToValue.code && discount.appliesToValue.code === "PRODUCT") {
          singleDiscount = sessionDiscounts.find(sessionDiscount => sessionDiscount && sessionDiscount.discountId && sessionDiscount.productId && sessionDiscount.productId.length && sessionDiscount.discountId === discount.id && sessionDiscount.productId[0] === discount.productId);
          // console.log('single discount product', singleDiscount);
        }
        else {
          singleDiscount = sessionDiscounts.find(sessionDiscount => sessionDiscount && sessionDiscount.discountId && sessionDiscount.discountId === discount.id && ((sessionDiscount.categoryId && discount.categoryId && sessionDiscount.categoryId === discount.categoryId) || (sessionDiscount.brandId && discount.brandId && sessionDiscount.brandId === discount.brandId)));
          // console.log('single discount category', singleDiscount);
        }

        if (singleDiscount && (discount.productId && singleDiscount.productId && singleDiscount.productId.length && (discount.productId === singleDiscount.productId[0])) || (discount.categoryId || discount.brandId)) {
          finalDiscounts.push(singleDiscount);
        }
      }
    });
    // console.log('final discounts', finalDiscounts);
    sessionStorage.setItem('appliedDiscounts', JSON.stringify(finalDiscounts));
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to update guest user cart.
   * @param cartProduct holds values from response.
   */
  updateGuest(cartProduct) {
    // console.log('before', cartProduct);
    // console.log('cart', JSON.parse(localStorage.getItem('cart')));
    let cartProducts = [];
    const updatedCartProducts = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : null;
    cartProduct && cartProduct.forEach((res: any) => {
      if ((res && res.discountDetails && res.discountDetails.appliedDiscountType && res.discountDetails.appliedDiscountType !== 'PAY_X_GET_FREE') || !(res && res.discountDetails)) {
        cartProducts.push(res);
      }
      if (res && updatedCartProducts && updatedCartProducts.cartProduct && updatedCartProducts.cartProduct.products && updatedCartProducts.cartProduct.products.length) {
        const index = updatedCartProducts.cartProduct.products.findIndex((res2: any) => res2 && res.productId === res2.productId && res.variantId === res2.variantId && !res2.discountProductId && res2.id == res.id);
        if (index !== -1) {
          updatedCartProducts.cartProduct.products[index]['addonImageData'] = res.addonImageData ? res.addonImageData : null;
          updatedCartProducts.cartProduct.products[index]['isProductCustomizable'] = res.isProductCustomizable ?? false;
          updatedCartProducts.cartProduct.products[index]['salesDiscountId'] = res.salesDiscountId ? res.salesDiscountId : null;
          localStorage.setItem('cart', JSON.stringify(updatedCartProducts));
        }
      }
    });
    // console.log('after filter', cartProducts);

    this.updateFbtProducts(cartProducts, updatedCartProducts);

    if (updatedCartProducts && updatedCartProducts.cartProduct && updatedCartProducts.cartProduct.products && cartProducts && updatedCartProducts.cartProduct.products.length) {
      if (cartProducts.length) {
        if (updatedCartProducts.cartProduct.products.length < cartProducts.length) {

          cartProducts.forEach(res => {
            if (res && res.discountDetails && res.discountDetails.appliedDiscountType && res.discountDetails.appliedDiscountType === 'BuyXGetY') {
              const index = updatedCartProducts.cartProduct.products.findIndex(res2 => res.productId === res2.productId && res.variantId === res2.variantId && (res.discountProductId === res2.discountProductId));
              if (index === -1) {
                res && res.discountDetails && res.discountDetails.comboCondition && res.discountDetails.comboCondition.matchedProduct && res.discountDetails.comboCondition.matchedProduct.length && res.discountDetails.comboCondition.matchedProduct.forEach(res1 => {
                  if (res1) {
                    let matchedIndex = updatedCartProducts.cartProduct.products.findIndex(res2 => (res2 && !res2.discountProductId && res1.id === res2.variantId));
                    if (matchedIndex !== -1) {
                      updatedCartProducts.cartProduct.products[matchedIndex].discountId = res.discountProductId
                    }
                  }
                });
                const data = {
                  productId: res.productId,
                  variantId: res.variantId,
                  discountProductId: res.discountProductId,
                  quantity: 1,
                  discountId: null,
                  productWithOptionPrice: res.actualPrice,
                  product: res.product,
                  combinationName: res.combinationName,
                  id: res.id
                }
                updatedCartProducts.cartProduct.products.unshift(data);
              }
            }
          });
          localStorage.setItem('cart', JSON.stringify(updatedCartProducts));

          // let element = cartProducts[0];
          // if (element) {
          //   element.discountDetails && element.discountDetails.comboCondition && element.discountDetails.comboCondition.matchedProduct && element.discountDetails.comboCondition.matchedProduct.length && element.discountDetails.comboCondition.matchedProduct.forEach(res => {
          //     if (res) {
          //       let index = updatedCartProducts.cartProduct.products.findIndex(res2 => (res2 && res.id === res2.variantId && (res.combinationName === res2.combinationName || (!res.combinationName && !res2.combinationName))));
          //       if (index !== -1) {
          //         updatedCartProducts.cartProduct.products[index].discountId = element.discountProductId
          //       }
          //     }
          //   });
          //   const data = {
          //     productId: element.productId,
          //     variantId: element.variantId,
          //     discountProductId: element.discountProductId,
          //     quantity: 1,
          //     discountId: null,
          //     productWithOptionPrice: element.actualPrice,
          //     product: element.product,
          //     combinationName: element.combinationName
          //   }
          //   updatedCartProducts.cartProduct.products.unshift(data);
          //   // console.log(updatedCartProducts);
          //   localStorage.setItem('cart', JSON.stringify(updatedCartProducts));
          // }
        }
        else if (updatedCartProducts.cartProduct.products.length > cartProducts.length) {
          updatedCartProducts.cartProduct.products.forEach((element, i, all) => {
            if (element && element.discountProductId && all) {
              let index;
              index = cartProducts.findIndex(res => res.discountProductId === element.discountProductId);
              if (index === -1) {
                all.splice(i, 1);
                all.forEach(response => {
                  if (response && element.discountProductId === response.discountId) {
                    response.discountId = null;
                  }
                });
              }
            }
          });
          // for (let i = 0; i <= l; i++) {
          //   if (!cartProducts[i].discountId && updatedCartProducts.cartProduct.products[i].discountId) {
          //     updatedCartProducts.cartProduct.products[i].discountId = null;
          //   }
          //   if (!cartProducts[i].discountProductId && updatedCartProducts.cartProduct.products[i].discountProductId) {
          //     updatedCartProducts.cartProduct.products[i].discountProductId = null;
          //   }
          // }

          localStorage.setItem('cart', JSON.stringify(updatedCartProducts));
        }
      }
      else {
        localStorage.setItem('cart', JSON.stringify({ cartProduct: { products: [] } }));
      }

    }
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to update fbt product quantity.
   * @param cartProducts holds values from response.
   * @param updatedCartProducts holds values in local storage.
   */
  updateFbtProducts(cartProducts: any, updatedCartProducts: any) {
    if (cartProducts && cartProducts.length && updatedCartProducts && updatedCartProducts.cartProduct && updatedCartProducts.cartProduct.products && updatedCartProducts.cartProduct.products.length) {
      cartProducts.forEach((cart: any) => {
        const index = updatedCartProducts.cartProduct.products.findIndex((guest: any) => cart.productId === guest.productId && cart.variantId === guest.variantId && cart.id === guest.id && !cart.fbtId && guest.fbtId && cart.isFbt);
        if (index !== -1) {
          updatedCartProducts.cartProduct.products[index]['fbtId'] = null;
          updatedCartProducts.cartProduct.products[index]['isFbt'] = false;
          updatedCartProducts.cartProduct.products[index]['isFbtDefaultProduct'] = false;
        }
      });
      // console.log(updatedCartProducts.cartProduct.products);

      updatedCartProducts.cartProduct.products = updatedCartProducts.cartProduct.products.filter((value: any, index: any, total: any) => total && index === total.findIndex((singleObject: any) => singleObject && value && singleObject.productId === value.productId && singleObject.variantId === value.variantId && singleObject.id == value.id && ((singleObject.fbtId === value.fbtId) || (!singleObject.fbtId && !value.fbtId)) && ((singleObject.discountProductId === value.discountProductId) || (!singleObject.discountProductId && !value.discountProductId))));

      cartProducts.forEach((cart: any) => {
        if (cart.toUpdateQty) {
          const updateIndex = updatedCartProducts.cartProduct.products.findIndex((guest: any) => cart.productId === guest.productId && cart.variantId === guest.variantId && cart.id == guest.id);
          if (updateIndex !== -1) {
            updatedCartProducts.cartProduct.products[updateIndex].quantity = cart.quantity ?? 0;
          }
        }
      });
      // console.log(updatedCartProducts.cartProduct.products);
      localStorage.setItem('cart', JSON.stringify(updatedCartProducts));
    }
  }

  /**
   * Method used to get category details.
   * @param storeId has storeId.
   * @param categoryId has categoryId.
   * @returns
   */
  getCategoryDetails(storeId, categoryId) {
    const url = this.httpService.replaceUrlParam(API.GET_CATEGORY_FOR_GUEST_USER, {
      storeId: storeId,
      id: categoryId.categoryId
    });
    return this.httpService.getMethod(url);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to check order placing time of store.
   * @param orderPlacingTiming To store order placing hours of a store.
   * @param isOrderPlacingTiming To store working hours details of a store.
   * @param shopClosedDays To check store opend or not.
   * @param timeZone To store zone of store
   */
  checkOrderingHours(orderPlacingTiming, isOrderPlacingTiming, shopClosedDays, timeZone) {
    const currentDate = new Date().toLocaleString('en-US', {
      timeZone: timeZone
    });
    let isClosed = null;
    if (isOrderPlacingTiming) {
      isClosed = true;
      if (isClosed) {
        const splitedDate = currentDate.split(',');
        const currentTimeValue = splitedDate && splitedDate[1].split(':');
        let currentHour = Number(currentTimeValue && currentTimeValue[0].trim());
        if (splitedDate[1].indexOf('PM') >= 0 && currentHour !== 12) {
          currentHour += 12;
        } else if (currentHour === 12 && splitedDate && splitedDate[1] && splitedDate[1].indexOf('AM') >= 0) {
          currentHour = 0;
        }
        const weekDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
        const weekDaysFullForm = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


        if (orderPlacingTiming && orderPlacingTiming.length) {
          let openingHour, openingMin, openingTime, fromTime, toTime, fromMinutes, toMinutes;
          for (const time of orderPlacingTiming) {
            if (time && time[weekDays[new Date(currentDate).getDay()]]) {
              if (time.startTime) {
                fromTime = Number(time.startTime.split(":")[0]);
                if (time.startTime.indexOf('PM') >= 0 && fromTime !== 12)
                  fromTime += 12;
                else if (fromTime === 12 && time.startTime.indexOf('AM') >= 0)
                  fromTime = 0;
                fromMinutes = Number(time.startTime.slice(time.endTime.indexOf(':') + 1, time.startTime.length - 2));
              }
              if (time.endTime) {
                toTime = Number(time.endTime.split(":")[0]);
                if (time.endTime.indexOf('PM') >= 0 && toTime !== 12)
                  toTime += 12;
                else if (toTime === 12 && time.endTime.indexOf('AM') >= 0)
                  toTime = 0;
                toMinutes = Number(time.endTime.slice(time.endTime.indexOf(':') + 1, time.endTime.length - 2));
              }
              if ((currentHour > fromTime || (currentHour === fromTime && Number(currentTimeValue[1]) >= fromMinutes))
                && (currentHour < toTime || (currentHour === toTime && Number(currentTimeValue[1]) <= toMinutes))) {
                isClosed = false;
                break;
              }
              else {
                isClosed = true;
                if ((currentHour < fromTime || (currentHour === fromTime && Number(currentTimeValue[1]) <= fromMinutes))) {
                  if (!openingTime || (openingHour > fromTime || (openingHour === fromTime && openingMin > fromMinutes))) {
                    openingTime = time.startTime;
                    openingHour = fromTime;
                    openingMin = fromMinutes;
                  }
                }
              }
            }
          }
          if (!openingTime && isClosed) {
            const day = (new Date(currentDate).getDay()) + 1 >= weekDays.length ? 0 : (new Date(currentDate).getDay()) + 1;
            for (const time of orderPlacingTiming) {

              if (time && time[weekDays[day]]) {

                if (time.startTime) {
                  fromTime = Number(time.startTime.split(":")[0]);
                  if (time.startTime.indexOf('PM') >= 0 && fromTime !== 12)
                    fromTime += 12;
                  else if (fromTime === 12 && time.startTime.indexOf('AM') >= 0)
                    fromTime = 0;
                  fromMinutes = Number(time.startTime.split(':')[1]);
                }

                if (!openingTime || (openingHour > fromTime || (openingHour === fromTime && openingMin > fromMinutes))) {
                  openingTime = weekDaysFullForm[day] + ' ' + time.startTime;
                  openingHour = fromTime;
                  openingMin = fromMinutes;
                }
              }
            }
          }
          return { isClosed: isClosed, openingTime: openingTime ? openingTime : null };

        } else return { isClosed: isClosed, openingTime: null };

      }

    }
    return { isClosed: isClosed, openingTime: null };
  }

  getSubscriptioPlanList(storeId, locationId, isstorefront, filterDate) {
    const url = this.httpService.replaceUrlParam(API.SUBSCRIPTION_LIST, { storeId });
    return this.httpService.getMethod(url, { locationId: locationId, isstorefront: isstorefront, filterData: JSON.stringify(filterDate) });
  }
  /**
   * Method used to get cart subscription count.
   * @param storeId holds storeId.
   * @param customerId holds customerId.
   * @returns response.
   */
  // getCartSubscriptionCount(storeId, customerId) {
  //   const url = this.httpService.replaceUrlParam(API.GET_CART_SUBSCRIPTION_COUNT, { storeId: storeId, customerId: customerId });
  //   return this.httpService.getMethod(url);
  // }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to get cart subscription details.
   * @param storeId holds storeId.
   * @param customerId holds customerId.
   * @param data holds location details.
   * @returns response.
   */
  getCartSubscriptionDetails(storeId, customerId, data) {
    const url = this.httpService.replaceUrlParam(API.GET_CART_SUBSCRIPTION_DETAILS, { storeId: storeId, customerId: customerId });
    return this.httpService.getMethod(url, data);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to delete cart subscription details.
   * @param storeId holds storeId.
   * @param subscriptionId holds subscriptionId.
   * @param cartId holds cartId.
   * @returns response.
   */
  removeCartSubscriptionDetails(storeId, cartSubscriptionId, cartId) {
    const url = this.httpService.replaceUrlParam(API.UPDATE_CART_SUBSCRIPTION_DETAILS, { storeId: storeId, subscriptionId: cartSubscriptionId, cartId: cartId });
    return this.httpService.deleteMethod(url);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to update cart subscription details.
   * @param storeId holds storeId.
   * @param subscriptionId holds subscriptionId.
   * @param cartId holds cartId.
   * @param data holds the data to be updated.
   * @returns
   */
  updateCartSubscriptionDetails(storeId, cartSubscriptionId, cartId, data) {
    const url = this.httpService.replaceUrlParam(API.UPDATE_CART_SUBSCRIPTION_DETAILS, { storeId: storeId, subscriptionId: cartSubscriptionId, cartId: cartId });
    return this.httpService.putMethod(url, data);
  }
  getOneSubscription(storeId, id) {
    const url = this.httpService.replaceUrlParam(API.GET_ONE_SUBSCRIPTION, { storeId: storeId, id: id })
    return this.httpService.getMethod(url);
  }
  getOneSubscriptionDetail(storeId, subscriptionId, locationId) {
    const url = this.httpService.replaceUrlParam(API.GET_ONE_SUBSCRIPTION_DETAIL, { storeId: storeId, id: subscriptionId })
    return this.httpService.getMethod(url, { locationId: locationId });
  }

  goToSubscriptionsOrder(storeId, orderData, customerId) {
    const url = this.httpService.replaceUrlParam(API.ADD_TO_ORDER, { storeId: storeId, customerId: customerId });
    return this.httpService.postMethod(url, orderData);
  }
  getCartSubscriptions(storeId, customerId, productId, isBundle) {
    const url = this.httpService.replaceUrlParam(API.GET_CART_SUBSCRIPTION, { storeId: storeId, customerId: customerId, productId: productId });
    return this.httpService.getMethod(url, { isBundle: isBundle });
  }
  /**
* Method which is used to get property based on code
* @param code , code for property
* @returns
*/
  getSubscriptionSetting(storeId) {
    const url = this.httpService.replaceUrlParam(API.GET_SUBSCRIPTION_SETTING, { storeId: storeId });
    return this.httpService.getMethod(url);
  }

  /**
   * Method used to get category details with product count.
   * @param storeId holds the store id.
   * @param data holds the query param details.
   * @returns category details with product count.
   */
  getAllCategoriesProductCount(storeId, data) {
    const url = this.httpService.replaceUrlParam(API.CATEGORIES_PRODUCT_COUNT, { storeId: storeId });
    return this.httpService.getMethod(url, data);
  }
  /**
   * Method used to get category id.
   * @param categoryList holds the category list.
   * @param offset holds the offset value.
   * @param limit holds the limit value.
   * @returns array containing category id.
   */
  getCategoryId(categoryList, offset, limit) {
    let categoryId = [];
    if (categoryList && categoryList.length) {
      for (let i = offset; i < limit; i++) {
        if (categoryList[i]) {
          if (categoryList[i]?.productCount > 0) {
            if (categoryList[i]?.id) {
              categoryId.push(categoryList[i].id);
            }
            if (categoryList[i].subCategory && categoryList[i].subCategory.length) {
              categoryList[i].subCategory.forEach((subCategory) => {
                if (subCategory && subCategory.productCategories && subCategory.productCategories.length) {
                  if (subCategory?.id) {
                    categoryId.push(subCategory.id);
                  }
                }
                if (subCategory && subCategory.subCategory && subCategory.subCategory.length) {
                  subCategory.subCategory.forEach((subSubCategory) => {
                    if (subSubCategory && subSubCategory.productCategories && subSubCategory.productCategories.length) {
                      if (subSubCategory?.id) {
                        categoryId.push(subSubCategory.id);
                      }
                    }
                  });
                }
              });
            }
          }
        }
      }
    }
    return categoryId;
  }
  /**
   * Method used to assign products to respective categories.
   * @param data holds the category list.
   * @param productList holds the product list.
   * @param categoryDetails holds the offset and limit.
   * @returns array containing category with products.
   */
  setProductsForCategory(data, productList, categoryDetails) {
    let categoryList = [];
    const offset = categoryDetails && categoryDetails.offset ? categoryDetails.offset : 0;
    const limit = categoryDetails && categoryDetails.limit ? categoryDetails.limit : data.assignData.categoryList;
    categoryList = data && data.assignData && data.assignData.categoryList ? data.assignData.categoryList : [];
    if (categoryList && categoryList.length) {
      let productArray = [];
      for (let i = offset; i < limit; i++) {
        if (productList && productList.length) {
          productList.forEach((product) => {
            if (categoryList[i]) {
              if (product?.categoryId?.length) {
                if (product?.categoryId.find(id => id === categoryList[i]?.id)) {
                  productArray.push(product);
                } else if (categoryList[i]?.subCategory && categoryList[i]?.subCategory?.length) {
                  categoryList[i].subCategory.forEach((subCategory) => {
                    if (product?.categoryId.find(id => id === subCategory?.id)) {
                      productArray.push(product);
                    } else if (subCategory && subCategory.subCategory && subCategory.subCategory.length) {
                      subCategory.subCategory.forEach((subSubCategory) => {
                        if (product?.categoryId.find(id => id === subSubCategory?.id)) {
                          productArray.push(product);
                        }
                      });
                    }
                  });
                }
              }
            }
          });
        }
        if (categoryList[i]) {
          categoryList[i]['products'] = productArray;
          productArray = [];
        }
      }
    }
    return categoryList;
  }

  changeProductPrice(price, preorderDetails) {
    if (preorderDetails === false) {
      return false
    }
    if (preorderDetails && preorderDetails.preorderAmount != null) {
      let preOrderDiscountValue = 0;
      let preorderAmount;
      if (preorderDetails.preOrderDiscountType === 'FIXED') {
        preOrderDiscountValue = Number(preorderDetails.preorderAmount);
      }
      else if (preorderDetails.preOrderDiscountType === 'PERCENTAGE') {
        preOrderDiscountValue = ((Number(price) * Number(preorderDetails.preorderAmount / 100)));
      }
      if (preorderDetails.preorderDiff === 'INCREASE') {
        preorderAmount = (Number(price) + preOrderDiscountValue).toFixed(2);
      } else {
        preorderAmount = (Number(price) - preOrderDiscountValue).toFixed(2);
      }
      return Number(preorderAmount);
    }
    else
      return Number(price);
  }


  getFrequency(storeId) {
    const url = this.httpService.replaceUrlParam(API.GET_SUBSCRIPTION_FREQUENCY, { storeId: storeId });
    return this.httpService.getMethod(url);
  }
  /**
  * Function to convert time from 12 to 24 hours format
  * @param time12h has time value
  * @returns converted time value
  */
  convertTime12to24(time12h: any): any {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }
  /**
   * used to calculate estimated delivery date
   * @param res has shipping details value
   * @param locationDetails has location details
   */
  calculateEstimatedDate(res: any, locationDetails: any): any {
    let todayDate = new Date();
    let lastDate = 0, packing_to = this.convertTime12to24(res.packing_to), packing_from = this.convertTime12to24(res.packing_from);
    let packing_to_hours = packing_to.slice(0, -3), packing_to_mins = packing_to.slice(3), packing_from_hours = packing_from.slice(0, -3);
    if (Number(packing_to_hours) < todayDate.getHours())
      lastDate = 1;
    else if (Number(packing_to_hours) == todayDate.getHours()) {
      if (Number(packing_to_mins) <= todayDate.getMinutes())
        lastDate = 1;
    }
    let deliveryDate = res.order_preparation_time + res.transit_to, err = false;
    if (locationDetails?.shopClosedDays?.length >= 7) {
      err = true;
    } else if (locationDetails?.shopClosedDays?.length) {
      let closedDaysArr = locationDetails.shopClosedDays, days = 0, daysCounter = -1 + lastDate;
      if (deliveryDate) {
        do {
          daysCounter++;
          if (!closedDaysArr.includes((todayDate.getDay() + daysCounter) % 7))
            days++;
        } while (days <= res.order_preparation_time);
        deliveryDate = daysCounter + (res.transit_to ? res.transit_to - 1 : 0);
      } else {
        deliveryDate += lastDate;
      }
    } else {
      deliveryDate = deliveryDate + lastDate - (res.transit_to ? 1 : 0);
    }
    todayDate.setDate(todayDate.getDate() + deliveryDate)
    return err ? false : todayDate;
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to add products to saved for later from cart.
   * @param data holds product details.
   * @param storeId holds storeId.
   * @returns http response.
   */
  addToSavedForLater(data, storeId) {
    const url = this.httpService.replaceUrlParam(API.ADD_TO_SAVED_FOR_LATER, { storeId: storeId });
    return this.httpService.postMethod(url, data);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to get save for later products.
   * @param customerId holds customerId.
   * @param queryParams holds queryParams.
   * @param storeId holds storeId.
   * @returns http response.
   */
  getSaveForLaterProducts(customerId, queryParams, storeId) {
    const url = this.httpService.replaceUrlParam(API.GET_SAVE_FOR_LATER_PRODUCTS, { storeId, customerId });
    return this.httpService.getMethod(url, queryParams);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to remove save for later products.
   * @param cartId holds cartId.
   * @param variantId holds variantId.
   * @param storeId holds storeId.
   * @returns http response.
   */
  removeSaveForLaterProducts(cartId, variantId, storeId) {
    const url = this.httpService.replaceUrlParam(API.REMOVE_SAVE_FOR_LATER_PRODUCTS, { storeId, cartId, variantId });
    return this.httpService.deleteMethod(url);
  }

  /**
   * AUTHOR: SUJIN S S (CEN250)
   * Method used to move products from save for later to cart.
   * @param data holds product details.
   * @param storeId holds storeId.
   * @returns http response.
   */
  moveToCart(data, storeId) {
    const url = this.httpService.replaceUrlParam(API.MOVE_TO_CART, { storeId });
    return this.httpService.putMethod(url, data);
  }

  /**
   * Method which is used to download the digital product.
   * @param data holds the product details.
   * @returns downloaded product data.
   */
  downloadDigitalProduct(data) {
    const url = this.httpService.replaceUrlParam(API.DOWNLOAD_DIGITAL_PRODUCT_FILE, { orderId: data && data.orderId, storeId: data && data.storeId });
    return this.httpService.postMethod(url, data);
  }
  /**
   * Method which is used to update the product download count.
   * @param data holds the product details.
   * @returns updated product details.
   */
  updateDownloadCount(data) {
    const url = this.httpService.replaceUrlParam(API.UPDATE_FILE_DOWNLOAD_COUNT, { orderId: data && data.orderId, storeId: data && data.storeId });
    return this.httpService.postMethod(url, data);
  }
  /**
   * @param storeId holds store Id value
   * @param productId holds product Id value
   * @param query send query value
   * @returns frequentlyBoughtTogether response
   */
  getFrequentlyBoughtTogether(storeId: number, productId: number, query: object) {
    const url = this.httpService.replaceUrlParam(API.GET_FREQUENTLY_BOUGHT_TOGETHER, { productId: productId, storeId: storeId });
    return this.httpService.getMethod(url, query);
  }
  /**
   * Method is ued to add bulk products in cart for guest user.
   * @param cartDetails to get cartDetails to add.
   */
  bulkUpdateGuestUserCart(cartDetails?: Array<any>): void {
    if (cartDetails) {
      let filteredArray;
      const locationDetails = localStorage && localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')) : {};
      const guestUserCart = localStorage && localStorage.getItem('cart') ?
        JSON.parse(localStorage.getItem('cart')) : { cartProduct: { products: [] } };
      if (guestUserCart && guestUserCart.cartProduct && guestUserCart.cartProduct.products && guestUserCart.cartProduct.products) {
        if (cartDetails && cartDetails[0].isFbt && cartDetails[0].fbtId) {
          filteredArray = guestUserCart.cartProduct.products.filter(product => product.isFbt && cartDetails[0].fbtId === product.fbtId);
        }
        cartDetails.forEach(cart => {
          const index = guestUserCart.cartProduct.products.findIndex(item => item &&
            (item.variantId && cart.variantId && item.variantId === cart.variantId) && (item.productId && cart.productId && item.productId === cart.productId && (cart.fbtId === item.fbtId) && (cart.isFbt)));
          if (index !== -1) {
            if (!cart.quantity) {
              guestUserCart.cartProduct.products.splice(index, 1);
              const staticRemovedProduct = this.translationService.getTranslation('SHARED.REMOVED_PRODUCT');
              this.dialogService.openDialog({ message: (staticRemovedProduct !== 'SHARED.REMOVED_PRODUCT' ? staticRemovedProduct : 'Product removed from the cart.'), actionType: 'alert' });
            } else {
              guestUserCart.cartProduct.products[index].quantity = cart.quantity;
              guestUserCart.cartProduct.products[index].instruction = cart.instruction;
              guestUserCart.cartProduct.products[index].isReplaceOrder = cart.isReplaceOrder;
              guestUserCart.cartProduct.products[index].modifierIds = cart.modifierIds;
              const staticCartProduct = this.translationService.getTranslation('SHARED.CART_PRODUCT');
              const staticUpdatedProduct = this.translationService.getTranslation('SHARED.UPDATED_PRODUCT');
              this.dialogService.openDialog({ message: cartDetails.length === (filteredArray && filteredArray.length) ? (staticCartProduct !== 'SHARED.CART_PRODUCT' ? staticCartProduct : 'Product is already in the cart.') : (staticUpdatedProduct !== 'SHARED.UPDATED_PRODUCT' ? staticUpdatedProduct : 'Product updated in cart.'), actionType: cartDetails.length === (filteredArray && filteredArray.length) ? 'alert' : 'success' });
            }
          } else {
            if (!guestUserCart.cartProduct.products.find(guest => guest && ((guest.fbtId && guest.fbtId === cart.fbtId && guest.isFbt === cart.isFbt) ||
              (!guestUserCart.fbtId && !cart.fbtId && !guest.isFbt && !cart.isFbt)) && guest.productId === cart.productId && (guest.variantId === cart.variantId || cart.isFbtDefaultProduct))) {
              cart['id'] = this.generateUniqueCode(cart.variantId);
              guestUserCart.cartProduct.products.unshift(cart);
              const staticAddedProduct = this.translationService.getTranslation('SHARED.ADDED_PRODUCT');
              this.dialogService.openDialog({ message: (staticAddedProduct !== 'SHARED.ADDED_PRODUCT' ? staticAddedProduct : 'Product added to the cart.'), actionType: 'success' });
            } else {
              const staticCartProduct = this.translationService.getTranslation('SHARED.CART_PRODUCT');
              this.dialogService.openDialog({ message: (staticCartProduct !== 'SHARED.CART_PRODUCT' ? staticCartProduct : 'Product is already in the cart.'), actionType: 'alert' });
            }
          }
        });
        localStorage.setItem('cart', JSON.stringify(guestUserCart));
        if (locationDetails && locationDetails.id && locationDetails.timeZone) {
          this.getGuestCartProducts(this.storeId, guestUserCart, { locationId: locationDetails.id, timeZone: locationDetails.timeZone, isProductDetails: true, isCart: true }).subscribe((res: any) => {
            if (res && res.cartProducts) {
              this.cartCountValue.next(res.cartProducts.cartCount ? res.cartProducts.cartCount : 0);
            }
          });
        }
      }
    }
  }
  /**
 * Method which is used to get sales countdown timer offers for single product
 * @param storeId holds the store id.
 * @param productId holds the product id.
 * @param variantId holds the variant id.
 * @param currentDate holds the current Date value.
 * @returns
 */
  getOneProductTimeBasedOffers(storeId: number, productId: number, variantId: number, currentDate: Date) {
    const url = this.httpService.replaceUrlParam(API.GET_ONE_PRODUCT_TIME_BASED_OFFERS, { storeId: storeId, productId: productId, variantId: variantId });
    return this.httpService.getMethod(url, { currentDate: currentDate });
  }
  /**
  * Method which is used to get all sales countdown timer discount details.
  * @param data holds current Date.
  * @param storeId holds storeId.
  * @returns sales countdown timer discount details.
  */
  getAllSalesCountdownTimerDiscountDetails(data: any, storeId: Number) {
    const url = this.httpService.replaceUrlParam(API.GET_ALL_SALES_COUNTDOWN_TIMER_DISCOUNT, { storeId: storeId });
    return this.httpService.getMethod(url, data);
  }
  /**
   * Method which is used to map discount to that products
   * @param productData holds current Date.
   * @param discountDetails holds storeId.
   * @returns product details.
   */
  setDiscount(productData: Array<any>, discountDetails: Array<any>, isQuickOrder?: Boolean) {
    if (productData && productData.length > 0 && discountDetails && discountDetails.length > 0) {
      productData.forEach((product) => {
        let discounts = [];
        discountDetails.forEach((res) => {
          if (res) {
            if (res.isAllProducts) {
              discounts[discounts.length] = res;
            } else if (res.isCategoryLevel) {
              res.timerDiscountMappings.forEach((categoryLevelDiscount) => {
                if (isQuickOrder) {
                  if (product && product.product && product.product.productCategories[0] && (categoryLevelDiscount.categoryId === product.product.productCategories[0].categoryId)) {
                    discounts[discounts.length] = res;
                  }
                } else {
                  if (product.categoryId && (categoryLevelDiscount.categoryId === product.categoryId[0])) {
                    discounts[discounts.length] = res;
                  }
                }
              })
            } else {
              if (res.isProductLevel) {
                res.timerDiscountMappings.forEach((productLevelDiscount) => {
                  if (productLevelDiscount) {
                    if (isQuickOrder) {
                      if (product && product.product && product.product.id && product.id && productLevelDiscount.productId && productLevelDiscount.variantId && (productLevelDiscount.productId === product.product.id && productLevelDiscount.variantId === product.id)) {
                        discounts[discounts.length] = res;
                      }
                    } else {
                      if (product && product.variantId && product.id && productLevelDiscount.productId && productLevelDiscount.variantId && (productLevelDiscount.productId === product.id && productLevelDiscount.variantId === product.variantId)) {
                        discounts[discounts.length] = res;
                      }
                    }
                  }
                })
              }
            }
          }
        })
        product.salesDiscount = discounts;
        product.DiscountPrice = 0;
      });
    }
    return productData;
  }
  /**
  * Method which is used to get all sales countdown timer discount details.
  * @param productData holds current Date.
  * @returns sales countdown timer discount details.
  */
  calculateSalesTimerDiscount(productData: Array<any>) {
    productData.forEach((res) => {
      if (res && !(!res.multiLocationVariantStocks || res.multiLocationVariantStocks.length === 0 ||
        (res.multiLocationVariantStocks.length !== 0 && res.multiLocationVariantStocks[0] &&
          res.multiLocationVariantStocks[0].isPreorder && res.allowPreorder && !res.isSubscription && (res.multiLocationVariantStocks[0].stock === 0 ||
            res.multiLocationVariantStocks[0].stock === null))) && !res.isDigitalProduct) {
        res.DiscountPrice = 0;
        let activeDiscount = res && res.salesDiscount && res.salesDiscount.filter((discount) => {
          if (discount) {
            let today = new Date();
            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0)
            let time = today.toTimeString().slice(0, 8);
            let startDate = new Date(discount.startDate);
            startDate.setHours(0, 0, 0, 0);
            let endDate = new Date(discount.endDate);
            endDate.setHours(0, 0, 0, 0);
            return ((discount.startTime <= time || currentDate > startDate) && (discount.endTime >= time || currentDate < endDate));
          }
        });
        if (activeDiscount) {
          activeDiscount.forEach((data) => {
            if (data && data.discountValue) {
              let price = 0;
              if (data.discountType === "FIXED") {
                price = Number(data.discountValue);
              }
              if (data.discountType === "PERCENTAGE") {
                if (Number(data.discountValue) === 100) {
                  price = res.price
                }
                else {
                  price = Number(res.price / 100) * Number(data.discountValue);
                }
              }
              if (res && (res.DiscountPrice <= price) && (res.price >= price)) {
                res.DiscountPrice = price;
              }
            }
          });
        }
      }
    })
    return productData;
  }

  /**
  * Method used to delete bulk images from s3 bucket
  * @param storeId store id
  * @param data used to fetch details
  * @returns response
  */
  bulkRemoveImage(storeId, data) {
    const url = this.httpService.replaceUrlParam(API.S3_BUCKET_IMAGE_LIST_BULKDELETE, { storeId: storeId });
    return this.httpService.putMethod(url, data);
  }
  /* Method used to update the user details
  * @param data data to update the user
  * @param id user id
  * @param storeId store id
  * @returns success response of user updation or error response
  */
  updateZenUserDetails(storeId, customerId, data) {
    const url = this.httpService.replaceUrlParam(API.ZENUSER_UPDATE, { storeId: storeId, id: customerId });
    return this.httpService.putMethod(url, data);
  }



  /**
   * Method used to get live sale data
   * @param productIds holds product ids.
   * @returns 
   */
  getOneNotification(storeId: any, isStorefront?: boolean): Observable<object> {
    const url = this.httpService.replaceUrlParam(API.GET_ONE_LIVE_SALE_NOTIFICATION, { storeId: storeId });
    return this.httpService.getMethod(url, {isStorefront: isStorefront ?? false});
  }

  /**
   * Method used to order details for live sale notification.
   * @param data used to fetch data
   * @returns 
   */
  orderDetailLiveSaleNotification(storeId: any): Observable<object> {
    const url = this.httpService.replaceUrlParam(API.GET_ORDER_DETAILS_LIVE_SALE_NOTIFICATION, { storeId: storeId });
    return this.httpService.getMethod(url);
  }

  /**
   * Method used to check live notification.
   * @param data used to fetch data
   * @returns 
   */
  checkLiveNotification(storeId: any) {
    const url = this.httpService.replaceUrlParam(API.CHECK_LIVE_NOTIFICATION, { storeId: storeId });
    return this.httpService.getMethod(url);
  }

  getPoliciesForStorefront(storeId) {
    const url = this.httpService.replaceUrlParam(API.STORE_POLICIES_STOREFRONT, { storeId: storeId });
    return this.httpService.getMethod(url);
  }
  /**
* Method which is used to get one product notification.
* @param productId has product id.
* @param storeId has store id.
* @returns response.
*/
  getOneProductNotification(storeId, productId) {
    const url = this.httpService.replaceUrlParam(API.GET_ONE_PRODUCT_NOTIFICATION, { storeId: storeId, productId: productId });
    return this.httpService.getMethod(url);
  }
  /**
* Method which is used to get one product wishlist notification.
* @param productId has product id.
* @param storeId has store id.
* @returns response.
*/
  getOneProductWishlistNotification(storeId, productId) {
    const url = this.httpService.replaceUrlParam(API.GET_ONE_PRODUCT_WISHLIST_COUNT, { storeId: storeId, productId: productId });
    return this.httpService.getMethod(url);
  }
  /** 
   * Method used to fetch sold count notification details of a store.
   * @param storeId has store id.
   * @returns the sold count notification details.
   */
  getSoldCountNotificationDetails(storeId: number): Observable<object> {
    const url = this.httpService.replaceUrlParam(API.GET_ONE_SOLD_COUNT_FOR_STORE_FRONT, { storeId: storeId });
    return this.httpService.getMethod(url);
  }
  /**
   * Method used to fetch sold count of a store.
   * @param storeId has store id.
   * @param data used to fetch data.
   * @returns the sold count of a store.
   */
  getSoldCountForStoreFront(storeId: number, data: { lookBackTime: number, timeFormat: string }): Observable<Object> {
    const url = this.httpService.replaceUrlParam(API.GET_SOLD_COUNT_FOR_STORE_FRONT, { storeId: storeId });
    return this.httpService.getMethod(url, data);
  }
  /**
* Method which is used to get one cartandwishlist notification.
* @param isCart has request cart data.
* @param storeId has store id.
* @returns response.
*/
  getOneCartAndWishlistNotificationMessg(storeId, query: object) {
    const url = this.httpService.replaceUrlParam(API.GET_ONE_CARTANDWISHLIST_NOTIFICATION_MSG, { storeId: storeId });
    return this.httpService.getMethod(url, query);
  }
  /**
  * Method which is used to GET online visitors notification.
  * @param storeId has store id.
  * @returns response.
  */
  getOnlineVisitorsNotification(storeId: any) {
    const url = this.httpService.replaceUrlParam(API.GET_ONLINE_VISITORS, { storeId: storeId });
    return this.httpService.getMethod(url);
  }
  /**
   * Method used to fetch online visitors count of a store.
   * @param data used to fetch data
   * @returns 
   */
  getOnlineVisitorsCount(storeId: any, data: any) {
    const url = this.httpService.replaceUrlParam(API.GET_ONLINE_VISITORS_COUNT, { storeId: storeId, data: data });
    return this.httpService.getMethod(url, data);
  }
  emitCustomEvent(variableName: any, value) {
    const event = new CustomEvent(variableName, { detail: value });
    dispatchEvent(event);
  }

  isBehaviorSubject(obj: any): obj is BehaviorSubject<any> {
    return obj && typeof obj.next === 'function';
  }
  /**
  * Method which is used to create pre process order data.
  * @param data has data.
  * @param storeId has store id.
  * @returns response.
  */
  createPreProcessOrder(data, storeId) {
    const url = this.httpService.replaceUrlParam(API.CREATE_PREPROCESS_ORDER, { storeId: storeId });
    return this.httpService.postMethod(url, data);
  }
  /**
  * Method which is used to create pre process order data.
  * @param data has data.
  * @param storeId has store id.
  * @returns response.
  */
  createPreProcessOrderUrlCheckout(data, storeId) {
    const url = this.httpService.replaceUrlParam(API.CREATE_PREPROCESS_ORDER_URL_CHECKOUT, { storeId: storeId });
    return this.httpService.postMethod(url, data);
  }
  /**
  * Method which is used to get all unfulfilled order data.
  * @param data has data.
  * @param storeId has store id.
  * @returns response.
  */
  getAllUnfulfilledOrders(storeId, data) {
    const url = this.httpService.replaceUrlParam(API.UNFULFILLED_ORDERS, { storeId: storeId });
    return this.httpService.getMethod(url, data);
  }
  /**
  * Method which is used to delete unfulfilled order data.
  * @param data has data.
  * @param storeId has store id.
  * @returns response.
  */
  deleteUnfulfilledOrders(storeId, data) {
    const url = this.httpService.replaceUrlParam(API.DELETE_UNFULFILLED_ORDERS, { storeId: storeId, id: data });
    return this.httpService.deleteMethod(url);
  }
  /**
   * Method which is used to place order and pay.
   * @param data holds preProcessId and paymentOrderId.
   * @param storeId holds store id.
   * @returns response.
   */
  placeOrderV2(data: any, storeId: number) {
    const url = this.httpService.replaceUrlParam(API.PLACE_ORDER, { storeId: storeId });
    return this.httpService.postMethod(url, data);
  }
  /**
     * Method which is used to retry order.
     * @param data holds preProcessId and isRetryOrder.
     * @param storeId holds store id.
     * @returns response.
     */
  retryOrder(storeId, data) {
    const url = this.httpService.replaceUrlParam(API.RETRY_ORDERS, { storeId: storeId });
    return this.httpService.postMethod(url, data);
  }
  /**
     * Method which is used to verify payment order.
     * @param data holds data.
     * @param storeId holds store id.
     * @returns response.
     */
  verifyPaymentOrder(storeId, data){
    const url = this.httpService.replaceUrlParam(API.VERIFY_PAYMENT_ORDER, { storeId: storeId });
    return this.httpService.postMethod(url, data);
  }
  /**
    * Method which is used to get subscription plugin details
    * @param code , code for property
    * @returns
    */
  getPluginDetails(storeId) {
    const url = this.httpService.replaceUrlParam(API.GET_SUBSCRIPTION_PLUGIN_DETAILS, { storeId: storeId });
    return this.httpService.getMethod(url, { isStorefront: true });
  }
  /**
  * Method used to get SEO details
  * @param id 
  * @returns 
  */
  getRestSeoDetails(id: number) {
    const url = this.httpService.replaceUrlParam(API.SEO_UPDATION, { storeId: id });
    return this.httpService.getMethod(url);
  }
  /**
   * Method used to get review plugin details.
   * @param storeId holds store id.
   */
  getReviewPluginDetails(storeId) {
    const url = this.httpService.replaceUrlParam(API.REVIEW_PLUGIN, { storeId: storeId });
    return this.httpService.getMethod(url);
  }
  /*
  * Fucntion used to get plugin details with it features available
  * @param data holds storeId , plugin code
  * @returns response
  */
  getPluginFeatures(data): any {
    const url = this.httpService.replaceUrlParam(API.GET_ONE_PLUGIN_FEATURE, { storeId: data.storeId });
    return this.httpService.getMethod(url, {
      code: data.pluginCode
    })
  }
  /**
 * this method used to post image data
 * @returns
 */
  saveImageData(data) {
    return this.httpService.postMethod('plugin/imageupload', data);
  }
}
