/**
 * constant which serves api url and other common variables for dev
 */
export const environment = {
  // Variable which is used to decide this is a production config data or not.
  production: true,
  // Variable which is used to figured out what is the current environment of the application.
  app: "dev",
  // A server URL which is used to connect with the backend express server.
  apiUrlDb: '',
  backendServerConfigUrl: 'https://dev.useast1.api.getzenbasket.com/checkdomain-dev/v1/config',
  countryCode: "",
  rollbarKey : "ac300ebc67554194bc453ef9cff5922e"
};