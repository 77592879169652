/**
 * API used to store Api routes used inside all the components.
 */
export const API = {
  GET_FREQUENTLY_BOUGHT_TOGETHER: 'stores/{storeId}/products/fbtProducts/{productId}',
  GET_FREQUENCY: 'frequency',//getFrequency
  GET_ONE_FREQUENCY: 'frequency/{id}',//getOneFrequency ,updateFrequency
  GET_STORE_LIST: 'stores/list',
  MENU_SORT: 'menu/sort',
  PLAN_CATEGORY: 'menu/planCategory',
  GET_PLAN_CATEGORY: 'menu/planCategory/{planId}',
  DASHBOARD: 'stores/{storeId}/dashboard',
  USER_QUERIES: 'stores/{storeId}/userqueries', //getAllUserQueries
  USER_QUERY: 'stores/{storeId}/userqueries/{data}', //getOneUserQueries
  ORDER_LOG: 'stores/{storeId}/orders/{orderId}/logs', //getOrderLogs,
  INVENTORY_LOCATION:
    'stores/{storeId}/locations/v3' /*getInventoryLocation    //product.service.ts -> getShipToLocation,getInventoryLocation*/,
  CATEGORIES: 'stores/{storeId}/categories', //getAllCategories,getOneCategory,updateCategory,deleteCategory
  MENUTIMING: 'stores/{storeId}/menutiming/{data}', //getOneMenuTiming
  UPDATE_MENU_TIMING: 'stores/{storeId}/menutiming/{id}', //updateMenuTiming
  ADD_MENU_TIMING: 'stores/{storeId}/menutiming', //addMenuTiming
  ADD_CUSTOM_GIFT_CARD: 'stores/{storeId}/giftcards/custom', //customgiftcard
  GET_ALL_PURCHASED_GIFT_CARD: 'stores/{storeId}/purchasedgiftcards', //getAllPurchasedGiftCard
  ORDER_SETTINGS: 'stores/{storeId}/order/settings', //getOrderSettings
  CUSTOMER_REGISTRATION: 'stores/{storeId}/customregistration', //createCustomRegistration,getOneCustomRegistration
  UPDATE_CUSTOMER_REGISTRATION: 'stores/{storeId}/customregistration/{id}', //updateCustomRegistration
  STORE_DETAILS:
    'stores/{storeId}/settings' /*getStoreDetails,addStoreDetails,updateStoreDetails
                    auth.service.ts -> getStoreDetails*/,
  FETCH_STORE_DETAILS: 'stores/{storeId}/settings/store',
  CREATE_STORE_LOCATION: 'stores/{storeId}/locations', //createStoreLocation
  DEACTIVATE_ACCOUNT: 'stores/{storeId}/customers/{userId}/deactivate',//authService(deactivateAccount)
  STORE_LOCATIONS: 'stores/{storeId}/locations/v1'
  /*getStoreLocation    // AUTH->admin-auth.service.ts ->getStoreLocationDetails    //setting.service.ts -> fetchStoreLocation,getStoreLocationDetails,getStoreLocation
   */,
  FETCH_MULTI_LOCATION_VARIANT_STOCK:
    'stores/{storeId}/locations/{data}/usage',
  STORELOCATION: 'stores/{storeId}/locations/{data}/v1', //getOneStoreLocation
  DESTROY_STORELOCATION: 'stores/{storeId}/locations/{data}', //destroyStoreLocation

  UPDATE_STORE_LOCATION: 'stores/{storeId}/locations/{id}', //updateStoreLocation
  CURRENCIES: 'countries/currencies', //getCurrencies,
  GET_PLAN_LIMIT:
    'planFeature/{featureCode}/{clientId}' /*getplanLimit
    shipping.service.ts -> getplanLimit
    */,
  GET_ALL_NOTIFICATION: 'stores/{storeId}/common/getallnotifications',
  UPDATE_STORE_DATA: 'stores/{storeId}',
  EXPIRED_ORDER_NOTIFICATION: 'stores/{storeId}/orders/expired/count',//Get Expired Orders Count
  NEW_PREORDER_COUNT: 'stores/{storeId}/preorder/count',//Get Preorder customer count
  USER_QUERY_COUNT: 'stores/{storeId}/userqueries/count',

  GET_ONE_PLUGIN: 'stores/{storeId}/plugins/plugincode', //getOnePlugin
  PLUGIN_BY_CODE: 'stores/{storeId}/plugins/pluginbycode', //getOnePlugin
  GET_ALL_PLUGIN: 'stores/{storeId}/plugins/config', //getOnePlugin
  GET_PLUGIN_BY_CODE: 'stores/{storeId}/plugins/code', //getPluginsByCode
  GET_ADMIN_DETAILS: 'admin/{storeId}', //getAdminDetails
  GET_INDUSTRIES: 'industry', //getAllIndustryType
  FETCH_USER_HISTORY: 'stores/{storeId}/users/{id}/logs', //getUserHistory
  CHECK_PLUGIN_PLAN_EXCEEDS: 'stores/{storeId}/plugins/check/{pluginId}',
  GET_PLUGIN_STORE_FEATURES: 'stores/{storeId}/plugins/storeFeatures/',
  PLUGIN_SUBSCRIPTION: 'stores/{storeId}/pluginSubscriptionDetails',
  GET_PLUGIN_PAYMENT_HISTORY: 'stores/{storeId}/plugins/pluginPurchased',
  UPDATE_PLUGIN_SUBSCRIPTION: 'stores/{storeId}/pluginSubscriptionDetails/{id}',
  GET_PLUGIN_SUBSCRIPTION_DETAILS: 'stores/{storeId}/pluginSubscriptionDetails/{id}',
  GENERATE_PLUGIN_INVOICE: 'stores/{storeId}/plugins/purchase/{id}/invoice',
  MAIL_PLUGIN_INVOICE: 'stores/{storeId}/plugins/invoice',

  CHECK_GUEST_EMAIL: 'stores/{storeId}/customers/email/check', //Check guest user mail
  CHECK_RECIPIENT_EMAIL: 'stores/{storeId}/customers/checkRecipientEmail', //Check Gift Card Recipient  mail
  SAVE_PURCHASED_DIGITAL_PRODUCT_DETAILS: 'stores/{storeId}/orders/placedigitalproduct',//Save purchased digital product details

  GET_PREVIEW_PRODUCT_DETAILS: 'stores/{storeId}/products/{productId}/preview', //getPreviewProductDetails(productService)
  REMOVE_CART: 'stores/{storeId}/cart/{cartProductId}', //Delete cart
  BULK_UPLOAD: 'stores/{storeId}/cart/upload/{customerId}', //Add bulk product in cart
  VERIFIED_CART: 'stores/{storeId}/cart/{customerId}', //Verified cart
  GUEST_USER_CART: 'stores/{storeId}/cart', //guest user cart
  ADD_GET_CART: 'stores/{storeId}/cart/{customerId}', //Add to cart and verified cart
  SAVE_ORDER_NOTES: 'stores/{storeId}/ordernotes', //saveOrderNotes
  REFUND_DETAILS: 'stores/{storeId}/refund/{id}', //getAllRefundDetails
  REFUND_POINTS_DETAILS: 'stores/{storeId}/orders/{orderId}/loyalty/pointsLog', //getLoyaltyRefundDetails

  GIFT_CARD_REFUND_DETAILS: 'stores/{storeId}/purchasedgiftcards/logs/{orderId}',//getAllGiftCardRefundDetails
  OPTIONS_VARIANTS: 'stores/{storeId}/optionsvariants', //saveOptionsVariants,getAllOptionsVariants
  DELETE_OPTIONS_VARIANTS: 'stores/{storeId}/optionsvariants/{id}', //deleteOptionsVariants,getOneOptionsVariants,updateOptionsVariants
  RIBBIONS: 'stores/{storeId}/ribbons', //createRibbon,getAllRibbons
  OPTION_MAPPED_WITH_PRODUCT:
    'stores/{storeId}/optionsvariants/option/{id}/products', //getOptionsMappedWithProduct
  OPTION_VALUE_MAPPED_WITH_PRODUCT:
    'stores/{storeId}/optionsvariants/optionsvalue/{id}/products', //getOptionsValuesMappedWithProduct
  TRANSLATE_OPTIONS_VARIANTS: 'stores/{storeId}/optionsvariants/translation', //updateTranslatedOptionsVariants

  CREATE_REVIEW: 'stores/{storeId}/reviews/products/{productId}', //createReview
  Fetch_REVIEW: 'stores/{storeId}/reviews/products/{productId}', //updateReview
  ONE_PRODUCT_DETAIL: 'stores/{storeId}/products/{productId}/details', //getOneProductDetails
  ONE_PRODUCT: 'stores/{storeId}/products/{productId}/{variantId}', //getOneProduct
  //Dashboard Admin Reports
  REVENUE_REPORT: 'stores/{storeId}/report/revenue', // getSalesOverTime
  PRODUCT_REPORT: 'stores/{storeId}/report/product', // getProductReport
  CUSTOMER_REPORT: 'stores/{storeId}/report/customer', // getCustomerReport
  OFFER_REPORT: 'stores/{storeId}/report/offer', // getOfferReport
  REPORT_STORE_DATA: 'stores/{storeId}/report/', // getStoreData
  //Super admin reports
  OVERALL_REVENUE_REPORT: 'report/revenue', // getAllSalesOverTime
  OVERALL_PRODUCT_REPORT: 'report/product', // getAllProductReport
  OVERALL_CUSTOMER_REPORT: 'report/customer', // getAllCustomerReport
  OVERALL_OFFER_REPORT: 'report/offer', // getAllOfferReport
  //Custom Push Notification
  PUSH_NOTIFICATIONS: 'stores/{storeId}/notification/push', // getAllNotification
  PUSH_NOTIFICATION: 'stores/{storeId}/notification/push/user/{notificationId}', // getOneNotificationUsers
  GET_USER_EMAIL: 'stores/{storeId}/notification/push/user', // getUserDetails
  SEND_PUSH_NOTIFICATION: 'stores/{storeId}/notification/push/send', // sendPushNotification
  CUSTOM_PUSH_NOTIFICATION:
    'stores/{storeId}/notification/push/custom', // customPushNotification
  //Custom Email Notification
  EMAIL_NOTIFICATIONS: 'stores/{storeId}/notification/email', // getAllEmailNotification
  EMAIL_NOTIFICATION:
    'stores/{storeId}/notification/email/{notificationId}', // getOneEmailNotification
  GET_CUSTOMER_EMAIL: 'stores/{storeId}/notification/email/customerdetails', // getCustomerDetails
  CUSTOM_EMAIL_NOTIFICATION:
    'stores/{storeId}/notification/email', // customEmailNotification
  verificationMail: 'customer/{userId}/mail/verification',
  //Wholesale Request
  WHOLESALE_REQUEST: 'stores/{storeId}/wholesale/', //getAllWholesaleRequest, updateWholesaleRequest

  // Product details when add to order
  GET_ADD_ORDER_PRODUCT_DETAILS:
    'stores/{storeId}/products/{productId}/v3', //getAddOrderProductDetails

  // Auth service routes
  GET_USER_NOTIFICATION_DETAILS: 'stores/{storeId}/users/{userId}/notification', //getOneUserNotificationDetails
  // ADMIN_SIGNIN: 'storeadmin/auth/signin', //adminSignIn
  REFRESH_TOKEN: 'refreshToken', // refreshToken
  // GET_ONE_PLUGIN: 'stores/{storeId}/plugin', //getOnePlugin
  GET_PLUGINS: 'stores/{storeId}/pluginNames', //getPlugins
  GENERATE_CODE: 'stores/{storeId}/users/code', //codeGeneration
  CHECK_PASSWORD: 'customer/checkpassword', //checkPasswordMatch
  GET_CURRENT_USER: 'getCurrentUser', //getCurrentUser
  GET_CURRENT_USER_SUPERADMIN: 'master/getCurrentUser', //getCurrentUser
  REGISTER_ADMIN: 'registerAdmin', // registerAdmin
  GET_LABELS: '/industry/{id}', //getLabels
  REGISTER_CUSTOMER: 'customer/registeruser', // registeruser
  // GET_INDUSTRIES: 'industrytypes', //getAllIndustryType
  GET_ONE_CUSTOM_REGISTRATION: 'stores/{storeId}/customregistration', //getOneCustomregistration
  GET_SECURITY_QUESTIONS: 'stores/{storeId}/customfields/securityquestions/{id}', //getAllSequrityQuestions
  GET_USER_DETAILS: 'getuserdetails/{emailId}', //getUserDetails
  CHECK_ANSWERS: 'stores/{storeId}/customfields/checkanswers/{id}', //checkAnswers
  FORGOT_PASSWORD: 'customer/forgotpassword', //sendEmail
  SUPER_ADMIN_FORGOT_PASSWORD: 'master/forgotpassword', //sendEmailForSuperAdmin
  CHECK_EMAIL_EXIST: 'checkMailExist', //checkMailExist
  GET_ADMIN_USER_EMAIL: 'getUserEmailForAdmin', //getUserEmailForAdmin
  VERIFY_USER: 'stores/auth/user/verify',

  VERIFY_CUSTOMER: 'customer/verify', //verifyCustomer
  VERIFY_CUSTOMER_ADMIN: 'customer/verify/admin', //verifyCustomer
  VERIFY_TOKEN: 'customer/verifytoken', //verifyToken
  VERIFY_ADMIN_TOKEN: 'storeadmin/auth/token/verify', //verifyTokenForAdmin
  RESET_PASSWORD: 'customer/configurepassword', //resetPassword
  SET_PASSWORD: 'customer/setpassword', //setPassword
  CHANGE_PASSWORD: 'customer/changepassword', //changePassword
  GET_ADDITIONAL_FIELDS: 'stores/{storeId}/customregistration', //getAdditionalFields
  SEND_ADMIN_MAIL: 'storeadmin/auth/mail/registration', //sendMailForAdmin
  ADD_TOKEN: 'stores/{storeId}/notification/push/token', //addToken
  GET_STORE_DETAILS: 'stores/{storeId}/settings', //getStoreDetails
  GET_INVENTORY_LOCATIONS: 'stores/{storeId}/locations/v4', //getInventoryLocations

  GET_USER_DETAIL_NOTIFICATION:
    'stores/{storeId}/notification/push/userdetails', //getUserDetailsForNotification
  REGISTERSTOREDETAILS: 'registerStoreDetails', //registerStoreDetails,
  GET_STORE_CURRENCY_DETAILS: 'stores/{storeId}/storeSettings/currency', //getStoreCurrencyDetails
  SAVE_LOGOUT: 'customer/logout', //saveLogoutDetails
  DISPLAY_NAME_EXIST: 'storeadmin/auth/displayname', //displayNameExists
  GET_USER: 'stores/{storeId}/users/verifyuser/{emailId}', //getUser
  GET_USER_BASED_INVENTORY_LOCATIONS:
    'stores/{storeId}/locations/users', //getUserBasedInventoryLocations

  //STOREADMIN

  //CUSTOMERS -> customer.service.ts

  //   CUSTOMER_ROLE:
  //       'customers/roles' /*getCustomerRole
  // add-discount.component.ts -> changeType
  // */,
  CUSTOMERs:
    'stores/{storeId}/customers' /*createCustomer,getCustomers
  //ORDERS -> orders.service.ts(getAllCustomers)
  */,
  CUSTOMER: 'stores/{storeId}/customers/{customerId}', //getOneCustomer
  UPDATE_CUSTOMER: 'stores/{storeId}/customers/{userId}', //updateCustomer
  CREATE_CUSTOMER_GROUP: 'stores/{storeId}/customers/customergroup', //createCustomerGroup
  GET_ONE_CUSTOMER_GROUP: 'stores/{storeId}/customers/customergroup/{customerGroupId}', //getOneCustomerGroup,updateCustomerGroup
  CUSTOMER_STATUS_UPDATION: 'stores/{storeId}/customer/{customerId}', //customerStatusUpdation
  UPDATE_USER_INFO: 'stores/{storeId}/customers/{customerId}/customerdetails',//updateUserAndCustomerDetails
  UPDATE_PROFILE_IMAGE: 'stores/{storeId}/users/uploadLogoUrl',//uploadImageUrl
  //CUSTOMERS -> customer-queries.component.ts
  GET_ONE_PRODUCT_LOCATION_LIST: 'stores/{storeId}/products/restoreInventory',

  USER_QUERIES_IMPORT: '/bulkimport/userqueries', //importCall
  USER_QUERIES_EXPORT: 'bulkexport/userqueries/', //exportCall

  //DISCOUNTS -> discount.service.ts
  SAVE_DISCOUNT_PAY_X_GET_FREE: 'stores/{storeId}/discounts/payxgetfree/', //saveDiscount,getAllDiscount
  UPDATE_DISCOUNT_PAY_X_GET_FREE: 'stores/{storeId}/discounts/payxgetfree/{id}',

  SAVE_DISCOUNT: 'stores/{storeId}/discounts', //saveDiscount,getAllDiscount
  SAVE_DISCOUNT_BUY_X_GET_Y: 'stores/{storeId}/discounts/buyxgety/', //saveDiscount,getAllDiscount
  UPDATE_DISCOUNT: 'stores/{storeId}/discounts/{id}', //updateDiscount
  UPDATE_DISCOUNT_BUY_X_GET_Y: 'stores/{storeId}/discounts/buyxgety/{id}',
  UPDATE_DISCOUNT_STATUS: 'stores/{storeId}/discounts/{id}/status', //updateDiscountStatus
  GET_DISCOUNT: 'stores/{storeId}/discounts/{data}', //getDiscount
  GET_BUYXGETY_DISCOUNT: 'stores/{storeId}/discounts/buyxgety/{data}', //getDiscount
  GET_PAYXGETFREE_DISCOUNT: 'stores/{storeId}/discounts/payxgetfree/{discountId}', //getDiscount
  GET_ALL_DISCOUNT: 'stores/{storeId}/discounts',
  GET_ALL_BUYX_GETY_DISCOUNT: 'stores/{storeId}/discounts/buyxgety/',
  GET_ALL_PAYX_GETFREE_DISCOUNT: 'stores/{storeId}/discounts/payxgetfree',
  PRODUCT: 'stores/{storeId}/productnames', //getProducts
  USER: 'users/', //getUsers
  SAVE_AUTOMATIC_DISCOUNT: 'stores/{storeId}/discounts/automatic', //saveAutoDiscount
  GET_AUTOMATIC_DISCOUNT: 'stores/{storeId}/discounts/automatic/{discountId}', //getAutomaticDiscount
  UPDATE_AUTOMATIC_DISCOUNT: 'stores/{storeId}/discounts/automatic/{discountId}', //updateAutomaticDiscount
  //add-discount.component.ts -> changeType

  ORDER_STATUS: 'status/order/', //getOrderStatus
  DISCOUNT_HISTORY: 'stores/{storeId}/discounts/{discountId}/history', //getDiscountHistory
  DISCOUNT_HISTORY_LOGS: 'stores/{storeId}/discounts/{discountId}/history/logs', //getDiscountHistoryLogs
  DISCOUNT_PROPERTY_VALUE:
    'stores/{storeId}/propertyvalues' /*getDiscountPropertyValues
  SETTINGS-> setting-service.ts(getPaymentMethods)
  */,
  CREATE_ORDER: 'orders', //createOrder
  DISCOUNT_TYPE: 'stores/{storeId}/discounts/types', //getDiscountTypes
  SHIPPING_TYPE: 'stores/{storeId}/shippingMethod', //getShippingTypes
  CHECK_EXISTENCE_OF_PROMOCODE: 'stores/{storeId}/discounts/existence', //checkExistenceOfPromoCode
  CHECK_EXISTENCE_OF_DISCOUNT_TITLE: 'stores/{storeId}/discounts/automatic/existence', //checkExistenceOftitle
  CHECK_EXISTENCE_OF_BUYXGETY: 'stores/{storeId}/discounts/buyxgety/existence/{data}',
  CHECK_EXISTENCE_OF_PAYXGETFREE: 'stores/{storeId}/discounts/payxgetfree/existence/{data}',
  //DISCOUNTS -> add-discount.component.ts

  USER_CUSTOMER_GROUP: 'users/customer-groups/', //changeType

  //DISCOUNTS -> discount-list.component.ts

  IMPORT_DISCOUNT: 'stores/{storeId}/discounts/bulkimport', //cardActions
  IMPORT_DISCOUNT_SAMPLE: 'stores/{storeId}/discounts/bulkimport/sample', //cardActions
  EXPORT_DISCOUNT: 'bulkexport/discount/', //cardActions

  //AUTOMATIC DISCOUNTS -> discount.service.ts

  GET_ALL_AUTOMATIC_DISCOUNT: 'stores/{storeId}/discounts/automatic', //discountList

  //ORDERS -> orders.service.ts
  UPDATE_FEATURED_PRODUCT: 'stores/{storeId}/product/feature',//updateProductList
  ADDRESSES: 'stores/{storeId}/users/{data}/addresses', //getAllAddress
  COUPON_DETAIL: 'stores/{storeId}/discounts/orders', //getCouponCodeDetails
  PLACE_ORDER: 'stores/{storeId}/order-pre-process/place-order', //placeOrder
  CREATE_PREPROCESS_ORDER: 'stores/{storeId}/order-pre-process', //preProcessPlaceOrder
  CREATE_PREPROCESS_ORDER_URL_CHECKOUT: 'stores/{storeId}/order-pre-process/urlcheckout', //preProcessPlaceOrderUrlCheckout
  PLACE_ORDER_URL_CHECKOUT: 'stores/{storeId}/order-pre-process/urlcheckout/place-order', //placeOrder
  // PLACE_ORDER1: 'stores/{storeId}/orders/subscription/placeorder', //placeOrder
  PLACE_ORDER1: 'stores/{storeId}/productsubscription/order', //placeOrder

  // ORDERS: 'stores/{storeId}/orders/' /*getAllOrders AUTH->admin-auth.service.ts(getOrderDetails)    */,
  ORDERS: 'stores/{storeId}/orders/list' /*getAllOrders AUTH->admin-auth.service.ts(getOrderDetails)    */,
  ORDER_FOR_ROUTING: 'stores/{storeId}/getOrdersForRouting', //getOrdersForRouting
  SHIPPING_TYPE_DETAILS: 'stores/{storeId}/getShippingTypeDetails', //getShippingTypeDetails
  //   REFUND_ORDER_AMOUNT: 'stores/{storeId}/refundOrderAmount', //refundOrderAmount
  READY_FOR_PICKUP: 'stores/{storeId}/orders/notification/pickup', //readyForPickup
  ORDER_CONSTANT: '/stores/{storeId}/propertyvalues'
  /*getOrderConstants    //shipping.service.ts -> getPropertyValues    // STOREFRONT-EDITOR->storefront-editor.service.ts -> getPropertyValues   //email-campaign.service.ts -> getEmailMarketingConstants,Setting Setive-> getEmailMarketingConstants
   */,
  //   ORDER_DETAIL: 'stores/{storeId}/orderdetails/{id}', //getOrderDetails
  //   UPDATE_ORDER_DETAIL: 'stores/{storeId}/updateorderdetails/{orderId}', //updateOrderDetails
  //   UPDATE_NEW_SHIPPING: 'stores/{storeId}/orders/updateNewShipping', //updateNewShipping
  LOCATION_BASED_STOCK: 'stores/{storeId}/locationBasedStock/', //getLocationBasedStock
  //   CALCULATE_DELIVERY_METHOD_CHARGE:
  //     'stores/{storeId}/calculateDeliveryMethodCharge', //calculateDeliveryMethodCharge
  STORE_SHIPPING_METHOD: 'stores/{storeId}/shipping'
  /*getStoreShippingMethods    //SHIPPING -> shipping.service.ts -> getStoreShippingMethods
   */,
  SAVE_ORDER_LOG: 'stores/{storeId}/orderlogs', //saveOrderLogs
  RETURN_REQUESTS: 'stores/{storeId}/returnrequests', //getAllReturnRequests
  //   DELETE_ORDER: 'stores/{storeId}/orderdelete/{orderId}', //deleteOrder
  GET_ALL_ABANDONED_CART: 'stores/{storeId}/cart/abandoned', //getAllAbandonedCart
  SEND_REMAINDER_MAIL: 'stores/{storeId}/cart/abandoned/mail', //sendCartRemainderEmail
  GET_ONE_ABANDONED_CART: 'stores/{storeId}/cart/abandoned/{cartId}', //getOneAbandonedCartDetails
  SQUARE_POS_ORDERS: 'stores/{storeId}/orders/squarePOS', //getAllSquarePOSOrders
  RESTORE_ORDER: 'stores/{storeId}/orders/{data}/restore', //restoreOrder
  CREATE_ORDER_INVOICE_PDF: 'invoice/order', //createOrderInvoicePdf
  SEND_MAIL_FOR_ORDER: 'stores/{storeId}/mail/orderdetails/', //sendMailForOrder
  PAYMENTS: 'stores/{storeId}/payment', //getAllPayment
  REFUND_DETAIL: 'stores/{storeId}/getRefundDetails', //getRefundDetails
  WAIT_LISTS: 'stores/{storeId}/waitlist', //getAllWaitList
  PLUGINS_CATEGORY: 'stores/{storeId}/plugins/category/{categoryName}',
  PLUGINS_CATEGORY_BY_CODE: 'stores/{storeId}/plugins/categorycode/{categoryCode}'
  /*getPlugins    product.service.ts -> getPlugins    setting.service.ts -> getPlugins    auth.service.ts -> getPlugins
   */,
  PROPERTY_BY_CODE: 'properties/code', //getPropertyByCode
  PROPERTY_VALUES_BY_CODE: 'properties/valuebycode', //getPropertyByCode
  ORDER_ROUTING: 'orderRouting', //orderRouting
  //   PLUGIN_CATEGORY: 'stores/{storeId}/plugincategory/plugins/{categoryCode}', //getPlugin
  PRODUCT_BY_CATEGORY: 'getProductsByCategoryEnabledLocation', //getProductDetails
  ORDER_SETTING: 'stores/{storeId}/orders/setting', //getOrderSetting
  //   RESTORE_STOCK: 'stores/{storeId}/restoreStock/{orderId}', //restoreStock
  SEND_MAIL_FOR_WAITLIST: 'stores/{storeId}/waitlist/mailNotification', //sendMailForWaitlist
  ORDER_LOCATION: 'store/{storeId}/getUserBasedInventoryLocations'
  /*getOrderLocations    //SHARED -> shared.service.ts -> getStoreLocation    // PRODUCTS -> product.service.ts ->getUserBasedInventoryLocations,getOrderLocations,getStoreLocation
   */,
  UPDATE_LOCATION_STOCK: 'stores/{storeId}/waitlist', //updateLocationStock
  GET_PRODUCT_COUNT: 'stores/{storeId}/products/count',

  //ORDERS -> order-list.component.ts

  IMPORT_ORDERS: '/bulkimport/orders', //importFunction
  EXPORT_ORDERS: 'bulkexport/orders/', //exportCall

  //ORDERS -> payment-list.component.ts

  WISHLIST_DETAIL: 'stores/{storeId}/wishlist/customers/{customerId}/products/{productId}', //getWishlistDetails
  UPDATE_ORDER: 'stores/{storeId}/orders/{orderId}', //updateOrder
  DETAILS_OF_PRODUCTS: 'stores/{storeId}/products/{productId}/productdetails',//getDetailsOfProduct
  OGS_DETAILS: 'stores/{storeId}/products/{productId}/ogs',//getOgsDetails
  PREORDER_LIMIT: 'stores/{storeId}/preorder/limit/{id}',//checkPreorderLimit
  SEO_DETAILS: 'stores/{storeId}/products/seo',//getSeoDetails
  STATES: 'countries/{id}/states',
  STATESWITHCOUNTRY: 'countries/{name}',
  COUNTRIES: 'countries',
  GET_PAYMENT_DETAILS: 'stores/{storeId}/payment',
  SQUARE_PAYMENT: 'stores/{storeId}/payment/square',
  RAZORPAY_ORDER: 'stores/{storeId}/payment/razorpay',
  AUTHORIZEDOTNET_PAYMENT: 'stores/{storeId}/payment/authorizeDotNetPayment',
  DELETE_CARD: 'stores/{storeId}/payment/card/{id}',
  CAPTURE_PAYMENT: 'stores/{storeId}/payment/capture',
  CANCEL_ORDER: 'stores/{storeId}/payment/{orderId}',
  //   CANCEL_ORDER: 'stores/{storeId}/ordercancel/{orderId}', //cancelOrder
  GET_DIGITAL_PRODUCT_DETAILS: 'stores/{storeId}/products/{id}',

  SUBSCRIPTION_LIST: 'stores/{storeId}/productsubscription', //getsubscriptionplanlist
  REFUND_ORDER_AMOUNT: 'stores/{storeId}/payment/refund',
  REFUND_GIFTCARD: 'stores/{storeId}/purchasedgiftcards/orders',

  //subscription -> common.service.ts
  INVOICE: "invoice",
  GENERATE_SUBSCRIPTION: 'subscription/generate',
  GENERATE_THEME_PLAN: 'stores/generatethemeplan',

  // PLAN: 'plan',
  SUSPEND_USER: 'stores/{storeId}/suspend',
  ACTIVE_SUBSCRIPTION: 'subscription/{customerId}',
  UPGRADE_SUBSCRIPTION: 'subscription/upgrade/{customerId}',
  UPGRADE_SUBSCRIPTION_PLAN: 'subscription/plans/{customerId}',
  TAX: 'subscription/Tax',
  SUBSCRIPTION_SAVEDCARD: 'subscription/payment/savedcard/{clientId}',
  SRIPE_SAVEDCARD: 'stores/sripecarddetails/{clientId}',
  UPDATE_CARD: 'subscription/payment/card',
  SUBSCRIPTION_RAZORPAY: 'subscription/payment/razorpayorder',
  GET_COUNT: 'subscription/paymentlogs',
  SUBSCRIPTION_TRANSACTION_LOG: 'subscription/payment/transactionlogs',
  UPDATE_SUBSCRIPTION: 'subscription/{subscriptionMemberId}',
  CATEGORY_TREE: 'stores/{storeId}/categories/tree',
  CATEGORY_BASED_BRAND: 'stores/{storeId}/brands/category',

  IMPORT_REFUND: 'bulkimport/refund', //importCall
  IMPORT_PAYMENT: 'bulkimport/payment', //importCall
  EXPORT_REFUND: 'bulkexport/exportRefund', //exportCall
  EXPORT_PAYMENT: 'bulkexport/exportPayment', //exportCall

  //SHARED -> menu.service.ts

  MENUS: 'menucategory/menus', //getAllMenu,createMenu
  UPDATE_MENU: 'menucategory/menus/{id}', //updateMenu
  DELETE_STORE_MENU: 'menucategory/menus/{id}', //deleteStoreMenus

  //SHARED -> shared.service.ts


  USER_ACTIVITIES: 'stores/{storeId}/googleanalytics/useractivities', //getUserActivities
  ANALYTICS_DETAILS: 'stores/{storeId}/googleanalytics/analyticsdetails',//getAnalyticsDetails
  STORE_LOCATION: 'stores/{storeId}/inventoryLocation/{id}/v2', //getOneStoreLocation

  //SHIPPING -> shipping.service.ts
  SHIPPING_SETTING:
    'stores/{storeId}/shipping/settings' /*getShippingSettings
  //setting.service.ts -> getShippingSettings*/,
  STORE_DELIVERY: 'stores/{storeId}/shipping/delivery', //getStoreDelivery, addDeliveryMethod
  DELIVERY: 'stores/{storeId}/shipping/delivery/{id}', //getDelivery, deleteDeliveryMethod, updateDeliveryMethod
  WEIGHT_UNIT: 'stores/{storeId}/shipping/weightunit', //getWeightUnitForShipping
  PICKUP: 'stores/{storeId}/shipping/pickup/{id}', //getPickup, deletePickupMethod, updatePickupMethod
  ADD_PICKUP: 'stores/{storeId}/shipping/pickup', //addPickupMethod
  USER_STORE_LOCATIONS:
    'stores/{storeId}/locations/users', //getStoreLocation
  STORAGE_AVAILABILITY: 'stores/{storeId}/common/storageavailability',
  //PRODUCTS -> product.service.ts
  CREATE_BRAND: 'stores/{storeId}/brands', //createBrand
  CREATE_ANNOUNCEMENT: 'stores/{storeId}/announcement', //createAnnouncement
  ANNOUNCEMENT: 'stores/{storeId}/announcement/{id}', //updateAnnouncement,getOneAnnouncement,deleteAnnouncement
  ANNOUNCEMENTS: 'stores/{storeId}/announcement/', //getAllAnnouncement
  STOREFRONT_ANNOUNCEMENT_DETAILS: 'stores/{storeId}/storeSettings/storefrontannouncement',//getAllStorefrontAnnouncement
  BULK_DELETE_ANNOUNCEMENT: 'stores/{storeId}/announcement/bulkdelete',
  BRAND: 'stores/{storeId}/brands/{id}', //getOneBrand,updateBrand,eleteBrand
  FOR_UPDATE_BRAND_TRANSLATION: 'stores/{storeId}/brands/translate',//to update brand-translated data
  BRANDS: 'stores/{storeId}/brands', //getAllBrands
  BRANDSFORSTOREFRONT: 'stores/{storeId}/brands/v1', //getAllBrands
  GIFTCARDSFORSTOREFRONT: 'stores/{storeId}/giftcards/list', //getAllGiftCards
  FETCHONEGIFTCARDFORSTOREFRONT: 'stores/{storeId}/giftcards/{id}/details', //getOneGiftCards
  DELETE_BRAND_PRODUCTS: 'stores/{storeId}/brands/products/{id}', //deleteBrandProducts
  Bulk_Delete: 'stores/{storeId}/products/catelog/delete',//product bulk delete
  PRODUCTS: 'stores/{storeId}/products/catelog', //getAllProducts
  PRODUCTS_BASED_ON_BRANDS: 'stores/{storeId}/brands/products', //getAllProductsBasedOnBrands
  ADD_PRODUCT: 'stores/{storeId}/products/{id}', //deleteProduct,updateProduct,getOneProduct
  WOO_COMMERCE_PRODUCT_IMPORT: 'stores/{storeId}/imports/products/',//wooCommerce Product Import
  GET_WOOCOMMERCE_LOGS: 'stores/{storeId}/importexport/logs/code',//get woocommerce logs
  GET_WOOCOMMERCE_CRENDENTIALS: 'stores/{storeId}/woocommerce',// get woocommerce credentials
  MANUAL_TAXES:
    'stores/{storeId}/tax' /*getAllManualTax,getEnableManualTax
  tax.service.ts -> getAllManualTax*/,
  ADD_TAX_PRODUCTS: 'stores/{storeId}/taxes/v2',
  REMOVE_TAX_PRODUCTS: 'stores/{storeId}/tax/products',
  PRODUCTS_BY_LOCATION: '/store/getProductListByLocation', //getProductsByLocation
  ADD_QUANTITY: 'store/addProductQuantity', //addQuantity
  SET_QUANTITY: 'store/setProductQuantity', //setQuantity
  PRODUCT_TYPES: 'stores/{storeId}/producttypes', //getAllProductType,createProductType
  PRODUCT_TYPE: 'stores/{storeId}/producttypes/{id}', //getOneProductType,deleteProductType,updateProductType
  SORT_CATEGORY: 'stores/{storeId}/category/sort', //sortCategory
  CREATE_ADD_ONS: 'stores/{storeId}/modifiers/set', //createAddOns,getAllAddOns
  MODIFIERS: 'stores/{storeId}/modifiers', //createModifier,getAllModifiers
  MODIFIER: 'stores/{storeId}/modifiers/{id}', //updateModifier,deleteModifier
  CHECK_MODIFIERS_EXIST: 'stores/{storeId}/modifiers/{id}/exists', //checkModifierExistsInSet
  CHECK_PRODUCT_MODIFIER: 'stores/{storeId}/modifiers/{id}/products/exists', //checkModifierExistsInProductModifier
  VARIANT_ADD_ONS: 'stores/{storeId}/variantsaddons', //getAllVarinatsAddOns
  UPDATE_ADD_ONS: 'stores/{storeId}/modifiers/set/{id}', //updateAddOns,deleteAddOns
  GET_MODIFIER_OPTION: 'stores/{storeId}/modifiers/option/{id}', //getOneModifierData
  LOCATION_WISE_STOCK: 'store/{storeId}/locationwiseStock', //onAddLocationwiseStock
  INVENTORY_STOCK: 'products/{productId}/InventoryStock', //getInventoryStockDetails
  //   OPTION_MAPPED_WITH_PRODUCT: 'stores/{storeId}/productoptions/{id}', //getOptionsMappedWithProduct
  //   OPTION_VALUE_MAPPED_WITH_PRODUCT:
  //     'stores/{storeId}/productoptionsvalues/{id}', //getOptionsValuesMappedWithProduct
  DELETE_OPTION_VALUE: 'deleteOptionValue/{id}', //removeOptionValue
  ALL_CATEGORIES: 'stores/{storeId}/categories'
  /*addCategory,getAllCategory    AUTH->admin-auth.service.ts -> getCategoryDetails
   */,
  CATEGORIES_PRODUCT_COUNT: 'stores/{storeId}/categories/products/counts',
  SINGLE_RIBBON_DATA: 'stores/{storeId}/ribbons/{id}', //editRibbon,removeRibbon,getOneRibbon
  RIBBON_PRODUCT_DETAIL: 'stores/{storeId}/ribbons/{ribbonId}/products/', //getOneRibbonDetails
  UPDATE_CATEGORY: 'stores/{storeId}/categories/{id}', //getOneCategory,updateCategory
  UPDATE_CATEGORY_AVAILABILITY: 'stores/{storeId}/categories/availability/{id}', //updateCategoryAvailability
  GET_CATEGORY_FOR_GUEST_USER: 'stores/{storeId}/categories/{id}/seoDetails', //getonecategoryforstorefront
  DELETE_CATEGORY: 'stores/{storeId}/categories/{id}/{seoId}/{productId}', //deleteCategory
  BULK_DELETE_CATEGORY: 'stores/{storeId}/categories/delete',
  BULK_DELETE_BRANDS: 'stores/{storeId}/brands/delete',
  PROPERTY_VALUE: 'properties/getallproperties', //getPropertyValues,getProductShippingRate,getPosition
  PARENT_CATEGORY: 'stores/{storeId}/parentcategories', //getParentCategoryDetails
  // DELETE_PRODUCT_REVIEWS: 'productReviews/{id}',//deleteProductReview
  PRODUCT_REVIEWS: 'stores/{storeId}/reviews', //getAllProductReview
  VENDORS: 'stores/{storeId}/vendors'
  /*createVendor,getAllVendors    AUTH->admin-auth.service.ts -> getVendorsDetails
   */,
  VENDOR: 'stores/{storeId}/vendors/{id}', //getOneVendor,updateVendor,deleteVendor
  PRODUCT_CONSTANT: 'stores/{storeId}/properties/productconstants/{constantName}', //getConstantValues
  UPDATE_PRODUCT_REVIEW: 'stores/{storeId}/reviews/{id}', //updateProductReview
  COUNTRY: 'country', //getCountry
  CREATE_GIFT_CARD: 'stores/{storeId}/products/giftcard', //createGiftCard
  GIFT_CARD: 'stores/{storeId}/products/giftcard/', //getOneGiftCard,updateGiftCard
  PRODUCT_ATTACHMENT: 'productAttachment/{id}', //getOneAttachment
  PRODUCT_DETAILS: 'stores/{storeId}/products/{id}/variants', //getProductDetails
  ONE_PRODUCTDETAIL: '/stores/{storeId}/products/{id}', //getOneProductDetail
  UNIT_PROPERTY: 'stores/{storeId}/shipping/property/{propertyname}/fulfillment', //getDimensionUnit
  GET_ONE_PREORDER_DETAIL: 'stores/{storeId}/products/preorder/{id}', //getOnePreorderDetail
  ADD_HS_CODE: 'product/{productId}/hsCode', //addHsCode
  UPDATE_FULFILLMENT: 'product/{productId}/fulfillment', //updateFulfillment,
  PLUGINS_CATEGOR_CODE: 'stores/{storeId}/categorycodes', //getPluginsCategory
  SAVE_PRODUCT: 'stores/{storeId}/products',
  GETALL_MODIFIER_NAMES: 'stores/{storeId}/products/v2',
  /*saveProduct    AUTH->admin-auth.service.ts ->getProductDetails
   */
  VARIANT_COMBINATIONS: 'stores/{storeId}/variantcombination', //getOptions
  RESTORE_PRODUCT: 'stores/{storeId}/restoreproduct/{data}', //restoreProducts
  UPDATE_PRODUCT_LIST: 'stores/{storeId}/updateproductlist/', //updateProductList
  MENU_TIMING: 'stores/{storeId}/menutiming/', //getMenuTiming
  REMOVE_MENU_TIMING: 'stores/{storeId}/menutiming/{menutimingId}', //removeMenuTiming
  // ATTACHMENTS: '/attachments', //getAllDetails
  ALL_PRODUCT_BASED_ON_CATEGORY: 'stores/{storeId}/categories/product', //getAllProductsBasedOnCategory
  DELETE_PRODUCT_CATEGORY: 'stores/{storeId}/categories/product/{productId}/{categoryId}', //deleteProductCategory
  PRODUCT_SETTINGS:
    'stores/{storeId}/products/settings' /*getProductSettings,getProductSetting    SETTINGS-> setting.service.ts ->getProductSetting */,
  ALL_LOGS: '/getAllImportExportLogsByStore', //getAllLogs
  IMPORT_EXPORTS_ERROR: '/importExportErrors', //getErrorLogsForStore
  IMPORT_EXPORTS_ERROR_DETAILS: '/getImportExportErrorLogDetails', //getImportExportErrorDetails
  UPDATE_LOCATION_WISE_STOCK: '/updateLocationWiseStock', //updateLocationStock
  WISHLISTS: 'stores/{storeId}/wishlist', //getAllWishlist
  PRODUCT_OF_WISHLISTS: 'stores/{storeId}/wishlist/{id}', //getProductsOfWishlist
  UPDATE_RIBBON_PRODUCTS: 'stores/{storeId}/ribbons', //updateRibbonProductsMapping
  RIBBONS_PRODUCTS: 'stores/{storeId}/ribbons/products', //getAllNonMappedProducts
  CREATE_RIBBON_PRODUCTS: 'stores/{storeId}/ribbons/{ribbonId}/products', //createRibbonProducts
  FEATURED_CATEGORY: 'stores/{storeId}/featuredcategory/', //getFeaturedCategories, updateFeaturedCategoryList
  SORT_FEATURED_CATEGORY: 'stores/{storeId}/featuredcategory/sort', //sortFeaturedCategory

  //**SETTINGS-> SETTINGS_SERVICE
  PAKAGE_SLIP: 'stores/{storeId}/packingslip', //getPackingSlip
  MAIL_SETTINGS:
    'stores/{storeId}/emailmarketing/mailserverconfigs' /*getAllMailSettingsInfo,createMailSettingsForm
  EMAIL-CAMPAIGN->email.campaign.service.ts -> createMailSettingsForm*/,
  UPDATE_MAIL_SETTINGS:
    'stores/{storeId}/emailmarketing/mailserverconfigs/{id}' /*updateMailSettingsForm,getOneMailSettingsForm,deleteMailSettingsForm
  email-campaign-sevice.ts -> getOneMailSettingsForm,updateMailSettingsForm,deleteMailSettingsForm*/,
  PAYMENT_SETTING: 'stores/{storeId}/paymentsettings/', //addPaymentSettings,updatePaymentSettings
  GET_PAYMENT_SETTINGS:
    'stores/{storeId}/paymentsettings' /*getPaymentSettings
  auth.service.ts -> getPaymentSettings*/,
  EMAIL_MARKETING_SETTINGS: 'stores/{storeId}/emailmarketingsettings/', //addEmailMarketingSettings, updateEmailMarketingSettings
  EMAIL_MARKETING: 'stores/{storeId}/emailmarketingsettings', //getEmailMarketingSettings
  STORE_AD_DETAIL: 'stores/{storeId}/getStoreAdDetails', //getStoreAdDetails
  SUBSCRIPTION_PLANS:
    'stores/{storeId}/storesubscrptionplans' /*getStoreSubscriptionPlan
  auth.service.ts -> getPlanDetails,getStoreSubscriptionPlan*/,
  ZONES: 'stores/{storeId}/zone', //getAllZone,createZone
  UPDATE_ZONE: 'stores/{storeId}/zone/{id}', //updateZone,deleteZone
  ZONE_DETAILS: 'stores/{storeId}/zone/mappingzone/{zoneId}', //getMappingZoneDetails
  ZONE_LOCATION_DETAILS: 'stores/{storeId}/locations/locationname/{zoneId}',//getZoneLocationDetails
  ORDER_INVOICE_PDF: 'stores/{storeId}/orderinvoice/', //createOrderInvoicePdf
  GET_INVENTORY_LOCATION: 'store/{storeId}/v4', //getInventoryLocations
  VARIANT_STOCK: 'checkMultiLocationVariant/{data}', //getMultiLocationVariantStocks
  STORE_POLICY: 'stores/{storeId}/policy', //getStorePolicy,saveCompanyPolicy
  GET_ONE_STORE_POLICY: 'stores/{storeId}/policy/{id}',//To get one store policy for translation
  UPDATE_STORE_POLICY_TRANSLATE: 'stores/{storeId}/policy/translate',//To update the translated content od store policy
  ADD_PRODUCT_SETTINGS: 'stores/{storeId}/products/settings', //updateProductSettings,addProductSettings
  CUSTOM_MAIL: 'stores/{storeId}/customemail/', //sendCustomEmail
  CHECKSMSPLUGIN: 'stores/{storeId}/settings/verify/{currencyCode}/sms',
  // EMAIL-CAMPAIGN->EMAIL-CAMPAIGN-SERVICE

  CHECK_DUPLICATE: 'stores/{storeId}/common/checkduplicates', //checkDuplicate
  CHECK_DUPLICATE_WITHOUT_STORE: 'common/checkduplicates',
  SIGNATURES: 'stores/{storeId}/emailmarketing/signatures', //getAllSignature,createSignature
  SIGNATURE: 'stores/{storeId}/emailmarketing/signatures/{data}', //getOneSignature
  UPDATE_SIGNATURE: 'stores/{storeId}/emailmarketing/signatures/{signatureId}', //updateSignature
  DELETE_SIGNATURE: 'stores/{storeId}/emailmarketing/signatures/{id}', //deleteSignature
  CAMPAIGN_GROUPS: 'stores/{storeId}/emailmarketing/campaigngroup', //createCampaignGroup,getAllCampaignGroup
  CAMPAIGN_GROUP: 'stores/{storeId}/emailmarketing/campaigngroup/{id}', //getOneCampaignGroup,updateCampaignGroup,deletecampaignGroup
  // PROPERTY_VALUE_BY_CODE: '/propertyValuesByCode', //getPropertyValuesByCode,getAllMailSettingsInfo
  EMAIL_TEMPLATES: 'stores/{storeId}/emailmarketing/emailtemplate', //createEmailTemplate,getAllEmailTemplate
  EMAIL_TEMPLATE: 'stores/{storeId}/emailmarketing/emailtemplate/{data}', //getOneEmailTemplate,deleteEmailTemplate
  UPDATE_EMAIL_TEMPLATE: 'stores/{storeId}/emailmarketing/emailtemplate/{id}', //updataEmailTemplate
  SEND_MAIL: 'stores/{storeId}/emailmarketing/sendmail', //sendTestEmail
  CHECK_CONFIGURATION: 'stores/{storeId}/emailmarketing/checkconfiguration', //checkConfiguration
  EMAIL_CAMPAIGNS: 'stores/{storeId}/emailmarketing/emailcampaign', //createEmailCampaign,getAllEmailCampaign
  EMAIL_CAMPAIGN: 'stores/{storeId}/emailmarketing/emailcampaign/{id}', //getOneEmailCampaign,updateEmailCampaign
  DELETE_EMAIL_CAMPAIGNS:
    'stores/{storeId}/emailmarketing/emailcampaign/{data}', //deleteEmailCampaign
  ENABLE_NOTIFICATION: 'stores/{storeId }/emailmarketing/notification', //enableNotifications,fetchNotifications
  SMS_NOTIFICATION: 'stores/{storeId}/emailmarketing/notification/{id}', //updateSmsNotifications
  EMAILS: 'stores/{storeId}/emailmarketing/emails', //getAllMails
  UNSUBSCRIBE_CAMPAIGN: 'emailmarketing/unsubscribe/{userId}', //unsubscribeCampaign

  // AUTH->admin-auth.service.ts
  USER_DETAIL_FOR_ADMIN: 'stores/auth/currentuser', //getOneUserDetails for admin
  USER_DETAIL_FOR_SUPERADMIN: 'stores/auth/currentuser/{storeId}', //getOneUserDetails for superadmin
  WALLET_DETAILS: 'subscription/wallet', //getOneStoreWalletDetails
  CURRENT_MOBILE_PLAN: 'subscription/mobile/activePlan/{clientId}',
  USER_NOTIFICATION_DETAIL: 'stores/{storeId}/userdetail/{userId}/notification', //getOneUserNotificationDetails
  UPDATE_USER_DETAILS: 'updateUserForAdmin', //updateUserDetails
  UPDATE_USER_NOTIFICATION_DETAILS: 'stores/{storeId}/usernotification', //updateUserNotificationDetails
  OFFER_DETAILS: 'stores/{storeId}/offers', //getOffersDetails
  DASHBOARD_DETAILS: 'stores/{storeId}/dashboarddetails', //getDashboardDetails
  STORE_DETAIL: 'stores/{storeId}/details', //getOneStoreDetails
  SIGNIN: 'admin/signin', // signin for admin,
  CHECK_MAIL_ALREADY_EXIST: 'admin/check/mail',
  RESET_PASSWORD_FOR_ADMIN: 'admin/reset/password',
  VERIFY_TOKEN_FOR_ADMIN: 'admin/verify',
  SEND_MAIL_FOR_REGISTRATION: 'admin/signup/validation  ',
  FORGOT_PASSWORD_MAIL: 'admin/forgotpassword',
  CHECK_PASSWORD_EXISTS: 'admin/check/password',
  CHECK_SUPER_ADMIN_PASSWORD_EXISTS: 'master/password/check',
  CODE_GENERATION: 'stores/{storeId}/users/code',
  EMAIL_UPDATION: 'admin/credential',
  VERIFY_EMAIL: 'admin/credential/{id}',
  DEACTIVATE_UPDATION: 'stores/auth/deactivate',
  DEACTIVATE_VERIFY_EMAIL: 'stores/auth/deactivate/{uuid}',
  // STORE USER REGISTRATION - registration.service.ts
  SIGNUP: 'admin/signup', // signup for admin
  DISPLAY_NAME_EXISTS: 'admin/displayname',

  // AUTH->admin-profile-vii.components.ts
  CHANGE_PASSWORD_FOR_ADMIN: 'admin/password', //changePassword

  // PLUGINS->plugins.service.ts
  //   PLUGINS: 'stores/{storeId}/plugins', //getAllPlugins,updateToggle,getStorePlugins,savePluginsConfiguration
  //   GET_PLUGIN_BY_CODE: 'stores/{storeId}/pluginCodes',//getPlugins

  PRODUCT_PLUGINS: 'stores/{storeId}/products/getAllProductsPlugins', //getAllProductsPlugins
  PLUGIN: 'stores/{storeId}/plugins/{id}', //getOnePlugin
  // PLUGIN: 'stores/{storeId}/plugin',//getOnePlugin

  PAYMENT_ACCOUNT_DETAILS: 'stores/{storeId}/accountdetails', //storePaymentAccountDetails

  // USERS->users.service.ts
  ROLE: 'stores/{storeId}/roles/{roleId}', //getOneRole
  ROLES: 'stores/{storeId}/roles', //createRole,getAllRoles
  SUPERADMIN_STAFFS_LIST: 'master/staff', //getAllStaffs, createStaff
  SUPERADMIN_ROLES: 'master/role', //getAllRoles, createRole
  SUPERADMIN_UPDATE_ROLE: 'master/role/{id}', // deleteSuperadminRole , updateRole
  SUPERADMIN_STAFFS: 'master/staff/{id}', //getOneSuperAdminStaff, deleteSuperAdminStaff, updateSuperAdminStaff
  UPDATE_ROLE: 'stores/{storeId}/roles/{id}', //updateRole,deleteRole
  GET_ROLE_NAME: 'stores/{storeId}/roles/rolename',
  PRIVILEGES: 'privileges', //getAllPrivileges
  NOTIFICATIONS: 'stores/{storeId}/getNotificationList', //getAllNotification
  PRODUCT_BULK_UPDATE: 'stores/{storeId}/products/bulkedit',
  // TAX->tax.service.ts
  CREATE_TAX_CATEGORY: 'stores/{storeId}/tax/', //createTaxCategory
  UPDATE_TAX_CATEGORY: 'stores/{storeId}/tax/{id}/', //updateTaxCategory
  TAX_CATEGORY: 'stores/{storeId}/taxes/{id}', //getOneTaxCategory
  TAX_EXISTANCE_CHECK: 'stores/{storeId}/tax/check', //getTaxExistanceCheck
  CATEGORY_PRODUCT: 'stores/{storeId}/tax/{id}/products', //getAllCategoryProducts
  PRODUCT_LIST: 'stores/{storeId}/tax/products', //getAllProductList
  // TAX_CATEGORY_PRODUCTS: '/stores/{storeId}/taxes/taxCategoryProducts', //createTaxCategoryProducts

  // STOREFRONT-EDITOR->storefront-editor.service.ts
  THEME_PAGES_SELECTED: 'stores/{storeId}/pages/themes/{id}', //getThemePages
  THEME_PAGES: 'stores/{storeId}/pages/themes', //getThemePages
  All_THEME_PAGES: 'stores/{storeId}/pages/themes/{themeId}', //getAllPagesOfTheme
  UPDATE_ALL_PAGES_IN_STORE_PAGE_SETUP: 'stores/{storeId}/pages/storepagesetup', //updatePagesInStorePageSetup
  UPDATE_PAGE_FOR_SINGLE_STORE: 'stores/{storeId}/pages/singlestorepages', //updatePagesForSingleStore
  GET_ALL_STORE_PAGES: 'stores/storepages', //getStorePages
  FORMAT_AND_UPDATE_THEME_PAGES: 'stores/themepages', //formatAndUpdateThemePages
  GET_IS_CHANGED_VALUE: 'stores/change', //getIsChanged
  UPDATE_ALL_STORE_PAGES: 'stores/updateStorePages', //updateStorePages
  PRODUCTS_ALL: 'products/', //getProducts
  GET_ALL_COUNTS: 'stores/{storeId}/allcounts', //getAllCounts -> common.service.ts

  SINGLE_PAGE_STORE_SETUP: 'stores/{storeId}/pages/{pageId}', //getSinglePageStoreSetup
  CREATE_STORE_PAGE_SETUP: 'stores/{storeId}/pages', //createStorePageSetup
  UPDATE_STORE_PAGE_SETUP: 'stores/{storeId}/pages/{storePageSetupId}', //updateStorePageSetup
  GET_ALL_PAGES: 'stores/pages',//getAllPage details
  GET_SINGLE_PAGE: 'stores/page/{pageId}',//get single page details
  DELETE_PAGE_DETAILS: 'stores/page/{pageId}',//delete page details
  DELETE_PAGE_FOR_STORE: 'stores/{storeId}/page/{pageId}',//delete newly created page
  UPDATE_PAGE_DETAILS: 'stores/page/{pageId}',//update page details
  GET_ONE_SECTION_DETAILS: 'stores/section/{sectionId}',//get edit section pages
  GET_ALL_SECTION_LIST: 'stores/sections',// get section lists
  DELETE_SECTION_LIST_DETAILS: 'stores/section',//delete section list details
  UPDATE_SECTION_LIST_DETAILS: 'stores/section',//update section list details
  SAVE_PAGE_DETAILS: 'stores/pages',//save page details
  SAVE_SECTION_LIST_DETAILS: 'stores/sections',//save section list details
  INDUSTRY_WISE_THEMES: 'stores/{storeId}/industries', //getIndustryWiseThemes
  SELECTED_THEMES: 'stores/{storeId}/themes', //getSelectedTheme
  GET_PAYMENT_TYPE: 'config/payment',
  GET_PAYMENT_OPTIONS: 'config/payment/options',
  UPDATE_PAYMENT_TYPE: 'config/payment',
  STORE_BLOG_CATEGORY: '/blogCategories/store/{storeId}', //getStoreBlogCategories
  HEADER_FOOTER_MENUS: 'menus/banner', //getHeaderFooterMenus
  FORM_CONTROLS: 'formControl', //getAllFormControls
  CHECKOUT_QUESTIONS: 'stores/questions/createcheckoutquestions', //createCheckoutQuestions
  SINGLE_CHECKOUT_QUESTIONS: 'stores/questions/singlecheckoutquestions/{checkoutStep}', //getSingleCheckoutQuestions
  EDIT_CHECKOUT_QUESTIONS: 'stores/questions/editcheckoutquestions/{questionId}', //editCheckoutQuestions
  DELETE_CHECKOUT_QUESTIONS: 'stores/questions/deletecheckoutquestions/{questionId}', //deleteCheckoutQuestions
  MENU_CATEGORIES: 'stores/{storeId}/menus/menucategories', //getMenuCategories
  MENU_DETAILS: 'stores/{storeId}/menus',//getAllMenus
  BANNER_MENUS: 'stores/{storeId}/menus/banner',//getBannerMenus
  SECTION_LISTS: 'stores/{storeId}/pages/sectionlist', //getAllSectionList
  SINGLE_PAGE_STORE_SETUP_BY_PAGE_NAME: 'stores/{storeId}/pages/pagename/{pageName}', //getSinglePageStoreSetupByPageName
  CURRENT_THEME_DETAILS: 'stores/{storeId}/pages/pagenames/{pageName}', //getPageDetails
  GET_SINGLE_PRODUCT_OPTIONS: 'stores/{storeId}/products/productsname', //getSingleProductDetails
  // STOREFRONT->storefront-preview.component.ts
  TESTIMONIAL_DETAILS: 'stores/{storeId}/testimonials', //getTestimonialDetails
  TESTIMONIAL_LIST_DETAILS: 'stores/{storeId}/testimonials/list', //getAllTestimonial
  TESTIMONIAL: 'stores/{storeId}/testimonials/{id}', //deleteTestimonials,editTestimonial,getonetestimonial,updateToggle
  CREATE_TESTIMONIAL: 'stores/{storeId}/testimonials', //createTestimonial,bulkdeleteTestimonial
  ZENBASKET_IMAGE_UPLOAD: 'stores/{storeId}/pages/zenbasketimages', // uploadZenbasketImages
  UPDATE_CURRENT_PAGE: 'stores/{storeId}/pages/currentpage', // uploadZenbasketImages
  TRANSLATE_THEME_PAGES: 'stores/{storeId}/pages/translatePage', //translateSeletectedThemePages

  // PRODUCTS->products-list.components.ts
  PRODUCT_IMPORT: '/bulkimport/product', //cardActions
  PRODUCT_EXPORT: 'bulkexport/product/', //cardActions

  // PRODUCTS->options-variants-list.components.ts
  OPTIONS_VARIANTS_IMPORT: '/bulkimport/optionsVariants', //importCall
  OPTIONS_VARIANTS_EXPORT: 'bulkexport/optionsVariants/', //exportCall

  // PRODUCTS->menu-timings.components.ts
  MENU_TIMING_IMPORT: '/bulkimport/menuTiming', //importCall
  MENU_TIMING_EXPORT: 'bulkexport/menutiming/', //exportCall

  // PRODUCTS->list-modifier-set.components.ts
  LIST_MODIFIER_SET_IMPORT: '/bulkimport/modifierset', //importFunction
  LIST_MODIFIER_SET_EXPORT: 'bulkexport/modifierset/', //exportCall

  // PRODUCTS->list-modifier.components.ts
  LIST_MODIFIER_IMPORT: '/bulkimport/modifiercontrols', //importFunction
  LIST_MODIFIER_EXPORT: 'bulkexport/modifiercontrols/', //exportCall

  // PRODUCTS->category-list.components.ts
  CATEGORY_IMPORT: 'bulkimport/stores/{storeId}/category', //importFunction
  CATEGORY_EXPORT: 'bulkexport/stores/{storeId}/category', //exportCall

  // PRODUCTS->brand-list.components.ts
  BRAND_IMPORT: 'bulkimport/stores/{storeId}/brands', //importCall
  BRAND_EXPORT: 'bulkexport/stores/{storeId}/brands', //exportCall

  // PRODUCTS->announcement-list.components.ts
  ANNOUNCEMENT_IMPORT: 'bulkimport/stores/{storeId}/announcement', //importCall
  ANNOUNCEMENT_EXPORT: 'bulkexport/stores/{storeId}/announcement', //exportCall

  // STOREFRONT
  // CHECKOUT->checkout.service.ts
  VERIFIED_CART_PRODUCT: 'customer/{customerId}/verifiedcart', //getVerifiedCartProducts
  SHIPPING_METHODS: 'stores/{storeId}/shipping', //getStoreShippingMethods

  // CUSTOMER-PROFILE->customer-profile.service.ts
  CUSTOMER_WISHLISTS: 'stores/{storeId}/wishlist/customers/{customerId}', //getAllWishListItems
  UPDATE_REVIEW: 'stores/{storeId}/reviews/reviewId/{reviewId}', //updateReview
  UPDATE_REVIEW_AVAILABILITY: 'stores/{storeId}/reviews/{reviewId}/status', //updateReviewAvailability
  ADD_TO_CART_WISHLISTITEM: 'stores/{storeId}/wishlist/products/{productId}',
  CUSTOMER_PRODUCT_REVIEWS: 'stores/{storeId}/reviews/customer/{userId}', //getAllProductReviews
  DELETE_WISHLIST: 'stores/{storeId}/wishlist/products/{id}', //deleteItemInWishList
  DELETE_REVIEW: 'stores/{storeId}/reviews/{id}', //deleteReview
  ADDRESS: 'stores/{storeId}/user/{userId}/address', //getAddress
  CREATE_UPDATE_ADDRESS: 'stores/{storeId}/user/{userId}/address', //createAddress, updateAddress
  DEFAULT_ADDRESS: 'stores/{storeId}/user/{userId}/address/{addressId}/default',//defaultAddress
  DELETE_ADDRESS: 'stores/{storeId}/user/{userId}/address/{addressId}', //deleteAddress,updateAddress,createAddress
  CUSTOMER_SUBSCRIPTIONS: 'stores/{storeId}/subscription/v1/{customerId}', //getAllCustomerSubscriptions
  CUSTOMER_SUBSCRIPTION_ORDERS: 'stores/{storeId}/subscription/v2/{id}', //getOneCustomerSubscriptionOrders
  // CUSTOMER_SUBSCRIPTION_UPCOMING_ORDER: 'stores/{storeId}/subscription/upcomingOrder/{subscriptionId}', //getCustomerUpcomingOrder
  CUSTOMER_CANCEL_SUBSCRIPTION: 'stores/{storeId}/subscription/{id}', //cancelSubscription
  CUSTOMER_SUBSCRIPTION_UPCOMING_ORDER: 'stores/{subscriptionId}/productsubscription/orders/{storeId}', //getCustomerUpcomingOrder
  CUSTOMER_SINGLE_SUBSCRIPTIONS: 'stores/{storeId}/productsubscription/orders/single/products', //getAllCustomerSingleSubscriptions
  PRODUCT_STATUS_SUBSCRIPTION: 'stores/{storeId}/subscription/status/{id}',
  CUSTOMER_SNGLE_SUBSCRIPTION_ORDERS: 'stores/{storeId}/subscription/v4/{id}/{productId}', //getOneCustomerSubscriptionOrders
  UPDATE_SHIPPING_METHODS: 'stores/{storeId}/subscription/shipping/{id}/{shippingMethodId}/{shippingRate}', //getShippingMethod






  // SHARED->shared.service.ts
  WEBSITE_SETTING: 'stores/{storeId}/websitesettings', //getWebsiteSettings
  CURRENT_USER: 'stores/{storeId}/contactdetails', //getCurrentUserDetails
  UPSELL_CROSS_SELL_DATA: 'stores/{storeId}/products/{productId}/upsellCrosssellData', //getUpsellCrosssellData
  CART_PRODUCTS: 'store/{storeId}/zone/{zoneId}/cartdiscounts', //getCartProducts
  FEATURED_CATEGORIES: 'stores/{storeId}/product/featuredCategories', //getAllFeaturedCategories
  FEATURED_PRODUCTS: 'stores/{storeId}/product/featuredProducts', //getAllFeaturedProducts
  FEATURED_CATEGORIES_PRODUCTS: 'stores/{storeId}/product/featuredcategoriesproducts', //featuredcategoriesproducts
  CUSTOMER_DETAILS: 'stores/{storeId}/customers/{customerId}', //getCustomerDetails
  CUSTOMER_ORDERS: 'stores/{storeId}/orders/users/{customerId}', //getOrders
  SINGLE_ORDER: 'stores/{storeId}/orders/{orderId}', //getSingleOrder
  ADD_TO_BASKET_FROM_ORDER: 'stores/{storeId}/cart/order', //addToBasketFromOrder
  CREATE_ORDER_INVOICE: 'stores/{storeId}/orderinvoice/', //createOrderInvoicePdf

  // RETURN_REQUEST: 'order/{orderId}/returnRequest', //returnSave
  // RETURN_REQUEST_FOR_PRODUCT: 'order/{orderId}/returnRequestForProduct', //returnRequestForProduct
  // ORDER_MAILL: 'stores/{storeId}/orders/mail',
  SMS_VERIFICATION: 'stores/{storeId}/users/{countryCode}/SmsVerification/{phone}',
  OTP_VERIFICATION: 'customer/{storeId}/{countryCode}/otp/{phone}',
  SIGNIN_OTP_VERIFY: 'customer/signin/verifyotp',
  SIGNUP_OTP_VERIFY: 'customer/signUp/verifyotp',

  RETURN_REQUEST: 'stores/{storeId}/orders/{orderId}/returnRequest', //returnSave
  RETURN_REQUEST_FOR_PRODUCT: 'stores/{storeId}/orders/{orderId}/returnRequest/product/{productId}', //returnRequestForProduct
  UPDATE_RETURN_STATUS: 'stores/{storeId}/orders/{orderId}/returnRequest/{returnId}',
  SUB_FEATURED_CATEGORIES: 'stores/{storeId}/product/featuredSubCategories',//getFeaturedCategoriesWithSubCategories
  // RETURN_REQUEST: 'order/{orderId}/returnRequest', //returnSave
  // RETURN_REQUEST_FOR_PRODUCT: 'order/{orderId}/returnRequestForProduct', //returnRequestForProduct
  GETSHIPYAARIDETAILS: 'stores/{storeId}/shipyaari/orders',
  GETAFTERSHIPDETAILS: 'stores/{storeId}/aftership/orders/',
  GETAFTERSHIPTRACKING: 'stores/{storeId}/aftership/orders/trackings/{slug}/{tracking_number}/{id}',
  GETSHIPPINGMETHOD: 'stores/{storeId}/shipyaari/orders/{orderId}/services',
  SHIPROCKETSERVICE: 'stores/{storeId}/shiprocket/orders/{orderId}/services',
  SHIPROCKETORDERS: 'stores/{storeId}/shiprocket/orders',
  CANCELSHIPROCKETORDERS: 'stores/{storeId}/shiprocket/orders/cancel',
  SHIPROCKETLABEL: 'stores/{storeId}/shiprocket/orders/{orderId}/label',
  SHIPROCKETMANIFEST: 'stores/{storeId}/shiprocket/orders/{orderId}/printmanifest',
  GETSHIPROCKETLOCATION: 'stores/{storeId}/shiprocket/orders/pickuplocation',
  GETSHIPROCKETTRACKING: 'stores/{storeId}/shiprocket/orders/{orderId}/trackstatus',
  GETSHIPYAARITRACKING: 'stores/{storeId}/shipyaari/orders/{orderId}/track',
  DELETESHIPYAARIORDERS: 'stores/{storeId}/shipyaari/orders/cancel',
  // CREATESHIPYAARIORDERS: 'stores/{storeId}/shipyaari/orders',
  DELHIVERYDETAILS: 'stores/{storeId}/delhivery/orders',
  CHECKDELHIVERYPINCODE: 'stores/{storeId}/delhivery/orders/{orderId}/pincode',
  CHECKDELHIVERYINVOICE: 'stores/{storeId}/delhivery/orders/{orderId}/invoice',
  // CREATEDELHIVERYORDER: 'stores/{storeId}/delhivery/orders',
  CREATEDELHIVERYREVERSEORDER: 'stores/{storeId}/delhivery/orders/reverse',
  TRACKDELHIVERYORDER: 'stores/{storeId}/delhivery/orders/{orderId}/track',
  DELETEDELHIVERYORDER: 'stores/{storeId}/delhivery/orders/cancel',
  DELHIVERYSLIP: 'stores/{storeId}/delhivery/orders/{orderId}/shipment',
  DELHIVERYPICKUP: 'stores/{storeId}/delhivery/orders/pickup',
  FETCHDELHIVERYWAREHOUSE: 'stores/{storeId}/delhivery/orders/warehouse',
  GETREADYFOREASYSHIPORDERS: 'stores/{storeId}/easyship/orders',
  GETEASYSHIPORDERS: 'stores/{storeId}/easyship',
  REFRESHEASYSHIPSTATUS: 'stores/{storeId}/easyship/tracking/{id}',
  CANCELEASYSHIPSHIPMENT: 'stores/{storeId}/easyship/{id}',
  GETEASYSHIPCATEGORIES: 'stores/{storeId}/easyship/categories',
  GETCOUNTRYSTATECODE: 'stores/{storeId}/easyship/code/{id}',
  REQUESTEASYSHIPRATES: 'stores/{storeId}/easyship/rate',
  CREATEEASYSHIPSHIPMENT: 'stores/{storeId}/easyship',
  CREATEEASYSHIPLABEL: 'stores/{storeId}/easyship/label',
  MARKASHANDOVERED: 'stores/{storeId}/easyship/status',
  // SHARED->themes.service.ts
  STORE_PAGES: 'stores/{storeId}/theme', //getStorePages
  SINGLE_STOREPAGESETUP: 'stores/{storeId}/singlestorepagesetup',
  SIGNED_IMAGE: 'page/signedImages', //getSignedImages
  CATEGORY_PRODUCTS: 'stores/{storeId}/products/category', //getCategoryProducts
  CATEGORY_BASED_PRODUCT: 'stores/{storeId}/getCategoryBasedBrand', //getCategoryBasedBrand
  CATEGORY_BASED_VARIANTS_OPTIONS: 'stores/{storeId}/optionsvariants/category', //getCategoryBasedVariantOptions
  ADD_TO_CART: 'stores/{storeId}/cart/{customerId}', //addToCart,addToCartProducts
  UPDATE_CART: 'stores/{storeId}/cart/products/{cartProductId}', //Update cart
  ADD_TO_WISHLIST: 'stores/{storeId}/wishlist/customers/{customerId}', //addToWishlist
  REMOVE_FROM_WISHLIST: 'stores/{storeId}/wishlist/products/{productId}/variant/{variantId}', //removeFromWishlist
  LIKE_CLICK: 'stores/{storeId}/reviews/{reviewId}/like', //likeClick
  DELETE_LIKE_CLICK: 'stores/{storeId}/reviews/{reviewId}/unlike', //dislikeClick
  SUBCATEGORIES: '/stores/{storeId}/products/productSummary', //getSubCategories
  TOP_RATED_PRODUCTS: 'stores/{storeId}/product/topRatedProducts/{locationId}', //getTopRatedProducts
  BEST_SELLER_PRODUCTS: 'stores/{storeId}/product/bestSeller/{locationId}', //getBestSellerProducts
  REMOVE_CART_PRODUCT: 'stores/{storeId}/cart/{cartProductId}', //removeCartProducts
  REMOVE_CART_MODIFIER: 'stores/{storeId}/cart/products/{cartProductId}/modifiers/{modifierId}',//removeCartProductModifier
  GET_SINGLE_PRODUCT_DETAILS: 'stores/{storeId}/products/{productId}/summary', //getSingleProductDetails
  // ROLE: 'stores/{storeId}/storefrontroles/', //getRoleId
  GET_ROLE_ID: 'stores/{storeId}/roles/id/{roleName}',
  STORE_INFO: 'storedetails', //getStoreInfo
  ORDERED_PRODUCTS: 'stores/{storeId}/orderedProducts/customers/{customerId}/products/{productId}', //getOrderedProducts
  WAIT_LIST: '/stores/{storeId}/waitList', //createWaitList
  PRODUCTS_WITH_CATEGORIES: 'stores/{storeId}/products/limit',
  GET_ALL_PRODUCTS: 'stores/{storeId}/products/categories',
  PRODUCTS_BY_SEARCH_TEXT: 'stores/{storeId}/products/v6',
  QUICK_ORDER_CATEGORIES: 'stores/{storeId}/categories/quick',
  QUICK_ORDER_PRODUCTS: 'stores/{storeId}/products/quick',
  SINGLE_PRODUCT_DISOUNT: 'stores/{storeId}/discounts/automatic/product/{productId}/variant/{variantId}',
  PRODUCTS_FOR_STOREFRONT_EDITOR: 'stores/{storeId}/products/editor', //getProductsByStore
  BUYX_GETY_DISCOUNT: 'stores/{storeId}/discounts/buyxgety/product/{productId}/variant/{variantId}',
  CATEGORY_NAME_FOR_PRODUCT: 'stores/{storeId}/categories/{categoryId}/categoryname',//getCategoryNameforProduct
  RECENTLY_VIEWED_PRODUCTS: 'stores/{storeId}/products/recentlyviewed', //createRecentlyViewedProducts
  REVIEW_PLUGIN: 'stores/{storeId}/reviews/plugin', // getReviewPluginDetails
  //AUTH -> auth.service.ts

  REGISTER_USER: 'customer/registeruser',//registerUser
  PLUGINS_BY_CODE: '{storeId}/pluginByCodeForStoreadmin',//getPluginsByCode,registeruser
  INDUSRTY_TYPES: 'getAllIndustry',//getAllIndustryType
  SOCIAL_LOGIN: 'sociallogin',//socialsignIn
  LOGIN: 'login',/*signinUser
        //change-password-vii.component.ts -> navigateToSignIn
        //forgot-password-vii.component.ts -> navigateToSignIn
        */
  CUSTOM_REGISTRATION: 'stores/{storeId}/customregistration',//getOneCustomregistration,getAdditionalFields
  CUSTOM_FIELD: 'stores/{storeId}/plugins/customcode',//getOneCustomField
  USER_EMAIL: 'getUserEmail',//getUserEmail
  unsubscribeUserEmail: 'mail/unsubscribe',
  REFERRALCODE: 'referralCode/check',
  //AUTH -> change-password-vii.component.ts

  LOGIN_CHANGE_PASSWORD: '/login',//changePassword
  // SIGNUP: 'signup',/*navigateToSignUp
  //forgot-password-vii.component.ts -> navigateToSignUp
  //sign-in-vii.component.ts -> navigateToSignUp
  // */
  // PRODUCT: 'products',//navigateToProduct

  //DEALS -> deals.component.ts

  GET_ONE_STORE: 'storenames/{storeId}',

  STOREFRONT_ANNOUNCEMENT: 'stores/{storeId}/storefrontAnnouncement',
  // Domain Configuration
  GET_DNS_LIST: 'stores/{storeId}/domain/dnslist',
  GET_DNS_CONFIGURATION: 'stores/{storeId}/domain/{id}/config',
  VERIFY_DOMAIN: 'stores/{storeId}/domain/{id}/verify',
  NEW_CONFIG: 'stores/{storeId}/domain/config/generate',
  DOMAIN_DELETE_WITH_CLOUDFRONTID: 'stores/{storeId}/domain/{id}/{cloudfrontId}',
  DOMAIN_DELETE: 'stores/{storeId}/domain/{id}',
  CHANGE_DOMAIN: 'stores/{storeId}/domain/internal',
  CHECK_ACTIVATION_STATUS: 'stores/{storeId}/domain/checkactive/{id}',
  CHECK_DOMAIN_ALREADY_EXISTS: 'stores/{storeId}/domain/checkactivedomains',

  CREATE_SUBSCRIPTION_INVOICE_PDF: 'invoice/subscription/{id}', //create Subscription Invoice
  CREATE_MOBILE_SUBSCRIPTION_INVOICE_PDF: 'invoice/mobilesubscription/{id}', //create  Mobile Subscription Invoice
  SEND_SUBSCRIPTION_INVOICE_PDF: 'invoice',//send Subscription Invoice
  PLUGINS_CATEGORIES: 'stores/{storeId}/plugins/pluginscategories', //getAllPluginCategories
  PURCHASED_CREDITS: 'stores/{storeId}/credits/purchase',//getAllPurchasedCredits
  AVAILABLE_CREDITS: 'stores/{storeId}/credits',//getAvailableCredits
  FETCH_ONE_CREDIT_PLAN: 'credits/{code}',//getOneCreditPlan
  SAVE_PURCHASED_CREDITS: 'stores/{storeId}/credits/checkout',//savePurchasedCredits

  PLUGIN_DETAILS: 'stores/{storeId}/plugins/{id}/details', //getOnePluginDetails
  // PLUGINS: 'stores/{storeId}/plugins', //getAllPluginsdetails
  PLUGIN_INFO: 'stores/{storeId}/plugins/{id}', //getOnePluginInfo
  PLUGIN_INFO_SUMMARY: 'stores/{storeId}/plugins/{pluginId}/plans/{planId}/summary',
  PLUGIN_EXPIRE: 'stores/{storeId}/plugins/{pluginId}/plans/{planId}', //getExpiredPlugin
  PLUGINS: 'stores/{storeId}/plugins', //updateToggle
  PLUGINS_CATEGORY_ID: 'stores/{storeId}/plugins/category/{categoryId}/list',//getPluginByCategoryId
  // PLUGIN_CATEGORY: 'plugin/plugincategories',//savePluginsCategory //getPluginsCategory
  // UPDATE_PLUGIN_CATEGORY: 'plugin/plugincategories/',//updatePluginsCategory
  DELETE_PLUGIN_CATEGORY: 'plugin/plugincategories/{id}',//deletePluginsCategory
  PLUGINS_PLAN_FEATURES: 'stores/plugins/feature/{pluginId}',//getOnePluginFeature
  PLUGINS_PLAN_DETAILS: 'stores/{storeId}/plugins/{pluginId}/plans',//getPluginPlansByPluginId


  CALCULATE_DELIVERY_METHOD_CHARGE: 'stores/{storeId}/shipping/orders/{orderId}',//calculateDeliveryMethodCharge
  //Orders List Page
  DELETE_ORDER: 'stores/{storeId}/orders/remove',//deleteOrder
  UPDATE_NEW_SHIPPING: 'stores/{storeId}/orders/shipping',//updateNewShipping
  ADMIN_UPDATE_ORDER: 'stores/{storeId}/orders/{orderId}/details',//updateOrder
  UPDATE_TRACK_DETAILS: 'stores/{storeId}/orders/{orderId}/track',
  UPDATE_ORDER_DETAIL: 'stores/{storeId}/orders/{orderId}',//updateOrderDetails
  RESTORE_STOCK: 'stores/{storeId}/orders/{orderId}/stock/restore',//restoreStock
  ORDER_DETAIL: 'stores/{storeId}/orders/{id}',//getOrderDetails
  ORDER_STOCK_UPDATE: 'stores/{storeId}/orders/{orderId}/stock',
  GET_EXPORT_COUNT: 'stores/{storeId}/orders/count',
  GET_APPROVED_RETURN_ORDERS: 'stores/{storeId}/orders/{orderId}/returnRequest/shipping', //return approved orders.
  DELHIVERYRETURNORDERS: 'stores/{storeId}/delhivery/orders/reverse',
  //OrderDetails Digital Product
  DIGITAL_PRODUCT_RENEW_LINK_MAIL: 'stores/{storeId}/orders/{orderId}/link/renew',
  DIGITAL_PRODUCT_DOWNLOAD_LINK_PERMISSION: 'stores/{storeId}/orders/digitalorders/{orderId}',

  GET_ORDER_LOCATION_BASED_INVENTORY_LOCATION: 'stores/{storeId}/locations/users',//getInventoryLocation

  NOTIFICATION: 'stores/{storeId}/notification',//fetchNotifications,enableNotifications
  GETONEMAILTEMPLATE: 'stores/{storeId}/emailmarketing/mail/template/{templateId}/dynamicData',//fetchNotifications,enableNotifications
  UPDATEMAILTEMPLATE: 'stores/{storeId}/emailmarketing/mail/template/{templateId}/update',//fetchEDITEMAILTEMPLATE,TO edit email template
  ADDMAILTEMPLATE: 'stores/{storeId}/emailmarketing/addMailTemplate',//fetchEDITEMAILTEMPLATE,TO edit email template
  SENTTESTMAIL: 'stores/{storeId}/emailmarketing/send/test/mail',//fetchEDITEMAILTEMPLATE,TO edit email template
  REVERTMAILTEMPLATE: 'stores/{storeId}/emailmarketing/mail/template/{templateId}/revert',//fetchEDITEMAILTEMPLATE,TO edit email template
  UPDATE_NOTIFICATIONS: 'stores/{storeId}/notification/{id}',//updateSmsNotifications
  SUPER_UPDATE_NOTIFICATIONS: 'stores/{storeId}/emailmarketing/mail/template/{id}/update',//updateSmsNotifications
  USER_COUNT: 'stores/{storeId}/staff/count', //getAllUserCount
  ADMIN_ROLES: 'stores/{storeId}/roles/admin', //getAdminRoles
  LOGIN_HISTORY: 'stores/{storeId}/staff/{userId}/history', //getLoginDetails
  DEVICE_NAME: 'stores/{storeId}/staff/{userId}/history/device',//getDevicename,
  // BROWSER: 'stores/{storeId}/users/{userId}/browser',//getBrowser
  USERS: 'stores/{storeId}/staff/{uuid}/{isActive}', //getAllUsers
  USER_STATUS: 'stores/{storeId}/staff/{id}/status', //userStatusUpdation
  ROLE_ONE: 'stores/{storeId}/roles/{id}', //updateRole, deleteRole, getOneRole,
  UPDATE_STAFF_INFO: 'stores/{storeId}/staff/{userId}',//updateUserInfo
  USER_ONE: 'stores/{storeId}/staff/{userId}', //getOneUserInfo
  CUSTOMER_ROLE: 'stores/{storeId}/customers/roles', //getCustomerRole
  CUSTOMERS: 'stores/{storeId}/customers', //createCustomer, getCustomers
  CUSTOMER_ONE: 'stores/{storeId}/customers/{customerId}', //deleteCustomer, updateCustomer, getOneCustomer
  GUEST_AS_CUSTOMER: 'stores/{storeId}/customers/{userId}/guest',
  GET_ONE_CUSTOMET_LOYALTY: 'stores/{storeId}/loyalty/histroy/{customerId}',
  GET_ONE_CUSTOMER_REDEEM_POINTS: 'stores/{storeId}/points/customer/{customerId}/redeem',
  GET_ONE_CUSTOMER_REFERRAL_ACTIVITY: 'stores/{storeId}/referral/loyalty/referralhistroy/{customerId}',
  CUSTOMER_BULK_DELETE: 'stores/{storeId}/customers/delete',
  CUSTOMER_STATUS: 'stores/{storeId}/customers/{customerId}/status', //customerStatus
  CUSTOMER_GROUP: 'stores/{storeId}/customers/group', // createCustomerGroup, getCustomerGroup
  CUSTOMER_GROUP_ONE: 'stores/{storeId}/customers/group/{customerGroupId}', //getOneCustomerGroup, deleteCustomerGroup
  CREATE_INVITATION: 'stores/{storeId}/users/invitation',//Create invitation
  GET_ONE_INVITATION: 'stores/{storeId}/users/invitation/token',//Get a invitation
  GET_ALL_INVITATION: 'stores/{storeId}/users/invitation',//Get all invitation
  GET_ONE_STAFF_INVITATION: 'stores/{storeId}/users/invitation/{id}',// Get one staff login invitaion
  CHECK_INVITATION_MAILS: 'stores/{storeId}/users/invitation/check',// check invitation mail already exists
  RESEND_INVITATION: 'stores/{storeId}/users/invitation/{id}',//Resend invitation mail
  DELETE_INVITATION: 'stores/{storeId}/users/invitation/{id}',//Delete invitation
  GET_ALL_INVITATION_REASONS: 'stores/{storeId}/users/invitation/reasons',//Get all invitation reasons
  CUSTOMER_GROUP_CUSTOMERS: 'stores/{storeId}/customers/group/{groupId}/customers',
  GET_ALL_TAG_NAMES: 'stores/{storeId}/marketing/email/tags',
  GET_LOCATION_BASED_PRODUCT: 'stores/{storeId}/products/inventory',
  ADD_PRODUCT_QUANTITY: 'stores/{storeId}/inventory/add',
  SET_PRODUCT_QUANTITY: 'stores/{storeId}/inventory/set',
  GET_ALL_CUSTOMER_BY_STORE: 'stores/{storeId}/customers/v1',
  GET_PRODUCTS_BY_CATEGORY_ENABLED_LOCATION: 'stores/{storeId}/products/category/location',
  GET_ENABLED_CATEGORY_PRODUCTS: 'stores/{storeId}/products/manual',
  GET_IMPORT_EXPORT_LOGS_BY_STORE: 'stores/{storeId}/importexport/logs',
  GET_IMPORT_EXPORT_LOG_DETAILS_BY_STORE: 'stores/{storeId}/importexport/logs/{logId}',
  GET_USER_BASED_INVENTORY_LOCATION: 'stores/{storeId}/locations/users',
  GET_ALL_IMPORT_EXPORT_LOGS: 'importexport/',
  GET_ALL_THEME: 'stores/themes',
  DELETE_THEME: 'stores/themes/{themeId}/delete',
  DELETE_THEME_DETAILS: 'stores/themes/',
  UPDATE_THEME_DETAILS: 'stores/themes/{themeId}',
  SAVE_THEME_DETAILS: 'stores/themeData',
  GET_ONE_THEME_DETAILS: 'stores/themes/',
  ENABLE_THEME: 'stores/themes/{themeId}/enable',
  UPDATE_THEME: 'stores/themes/{themeId}/update',
  BULK_UNSUBSCRIBE: 'stores/{storeId}/customers/unsubscribe',
  BULK_CUSTUMER_TAG_MAPPING: 'stores/{storeId}/marketing/email/tags/bulk',
  BULK_CUSTOMER_TAG_REMOVE: 'stores/{storeId}/marketing/email/tags/{customerids}/{id}',
  LOYALTY_POINTS: 'stores/{storeId}/points/customer/{customerId}/balance',
  //LOGS-> LOG_SERVICE
  ERROR_LOGS: 'errorlog', //getAllErrorLogs
  IMPORT_EXPORT_ERROR_LOGS: '/importExportErrors',//getImportExportErrorLogsForSuperAdmin
  STORE_NAME: '/storenames',//getStoreNames

  //SHARED-> IMAGE_GALLERY_SERVICE
  ZENBASKET_GALLERY: 'zenbasketgallery',//getAllDetails,createAttachments
  DELETE_ZENBASKET_GALLERY: '/zenbasketgallery',//deleteAttachments
  EDIT_ZENBASKET_GALLERY: '/zenbasketgallery',//editAttachments

  //SHARED->SUPER_ADMIN_SHARD_SERVICE
  // MENUS: '/menus/',//getAllMenu

  //STORE SETTINGS ->STORE_SETTING_SERVICE
  THEMES: 'themes/',//getAllThemes
  CREATE_INDUSTRY_TYPE: '/industrytypes',//createIndustryType,getAllIndustryTypes
  UPDATE_INDUSTRY_TYPE: '/industrytypes/',//updateIndustryType,getOneIndustryType,deleteIndustryType
  THEME_IMAGE: '/themeimage/',//getThemeImage
  PLUGIN_CATEGORIES: 'pluginscategories',//getPluginCategory
  SAVE_PLUGIN: '/plugin',//savePluginDetails
  UPDATE_PLUGIN: 'plugin/',//updatePluginDetails,deletePlugins
  UPDATE_PLUGIN_SORT: 'plugin/sort', // update plugin sort
  // PLUGIN: '/plugin/',//getOnePluginDetails
  PROPERTIES: 'properties',//getAllProperties,createProperty
  GETALLPROPERTYVALUES: 'properties/getallproperties',
  JOB_SETTINGS: 'jobs',//getJobSettings
  JOB_STATUS: 'jobs/{id}',//getjobStatus
  JOB_EXPRESSION: 'jobs/updateschedule',
  GET_JOBEXPRESSION: 'jobs/describe',
  UPDATE_JOB: 'jobs/toggle',
  // UPDATE_TOGGLE: 'jobsettings/jobstatus',//updateToggle
  PROPERTY: 'properties/',//deleteProperty,getOneProperty,updatePropertyDetails
  PLUGIN_CATEGORY: 'plugin/plugincategories',//savePluginsCategory
  UPDATE_PLUGIN_CATEGORY: 'plugin/plugincategories/',//deletePluginsCategory,updatePluginsCategory
  UPDATE_PLUGIN_CATEGORY_SORT: 'plugin/sort-category',//sortCategory
  DELETE_PROPERTY: 'properties/value/',//deletePropertyValue,getOnePropertyValue
  STORE_NAMES: 'storenames/',//getAllStores
  PROPERTIES_STORES: 'properties/stores/',//checkDuplicate
  CHECK_DUPLICATES: '/common/checkduplicates',//checkDuplicate
  STORENAMES: 'storenames',//getAllStore, SUBSCRIPTION_SERVICE-> getClientName
  STORE: 'stores/',//getOneStore,SUBSCRIPTION_SERVICE -> getStoreDetails,updateStoreDetails
  // PLUGINS: 'plugin',//getAllPlugin
  // MENU_CATEGORIES: '/menucategory',//getAllMenuCategories
  SORE_MENU: '/sortMenu',//sortMenuData
  MENU_POSITION: 'menu/position/',//getMenuPosition
  MENU_CATEGORY: 'menucategory/',//deleteMenuCategory,getOneMenuCategory,updateMenuCategory
  CREATE_MENU_CATEGORY: '/menucategory',//createMenuCategory
  INDUSTRIES: '/getAllIndustry',//getAllIndustryType
  UPDATE_INDUSTRY_TYPES: '/addUpdateIndustryTypes',//updateIndustryTypes
  CHECK_PEROPERTY_CODE: 'checkPropertyCode',//checkPropertyCode
  STORES: '/stores/',//getEmailMarketingConstants
  PROPERTY_VALUES: '/propertyvalues',//getEmailMarketingConstants
  // NOTIFICATIONS: '/emailmarketing/notification',//fetchNotifications,enableNotifications
  // UPDATE_NOTIFICATIONS: '/emailmarketing/notification/',//updateNotifications
  THEME: '/getAllTheme',//getAllTheme
  // DELETE_THEME: '/deleteTheme',//deleteTheme
  // ENABLE_THEME: '/enableTheme',//enableTheme
  IMPORT_EXPORT_LOGS: '/getAllImportExportLogs',//getAllImportExportLogs
  //SUBSCRIPTIONS-> SUBSCRIPTION_SERVICE
  // STORE_DETAILS: '/storedetails',//getStoreDetails
  // CURRENCIES: 'store/getAllCurrencies',//getCurrencies
  UPDATE_STORE_DETAILS: '/storedetails/',//updateStoreDetails,addStoreDetails
  FEATURIES: '/feature',//getFeature,addFeature
  UPDATE_FEATURE: '/feature/',//updateFeature,deleteFeature
  // REPORTS: 'report/',//getReport
  // PLANS: '/plan',//addPlan,getAllPlans
  ADD_PLAN_FEATURE: '/planFeature/',//addPlanFeature,updatePlanFeature
  // COMPLETED_PAYMENTS: '/completedPayments/',//getCompletedPayments
  // PAST_DUE_PAYMENTS: '/pastDuePayments/',//getpastDuePayments
  // UPCOMING_PAYMENTS: '/upcomingPayments/',//getUpcomingPayments
  // PLAN: '/plan/',//getOnePlan,updatePlan,deletePlan
  UPDATE_SUBSCRIPTION_PLAN: '/subscriptionPlan',//updateSubscriptionPlan
  COMPANY_DETAILS: '/companydetails',//getCompanyDetails
  SUBSCRIPTION_MEMBER: '/subscriptionMember',//getActivePlan
  REPORTS: 'subscription/report/{from}/{to}',//getReport
  PLAN: 'plan',//addPlan,getAllPlans
  COMPLETED_PAYMENTS: 'subscription/payment/completed/{days}',
  PAST_DUE_PAYMENTS: 'subscription/payment/pastDue/{days}',//getpastDuePayments
  UPCOMING_PAYMENTS: 'subscription/payment/upcoming/{days}',//getUpcomingPayments
  ONE_PLAN: 'plan/{planId}',
  SUBSCRIPTION_PLAN: 'subscriptionPlan',
  PLAN_FEATURE: 'planFeature/{planId}',
  FEATURE: 'feature',
  FEATURE_PLUGIN: 'plugin/feature',
  ONE_FEATURE: 'feature/{featureId}',
  DELETE_FEATURE_CHECK: 'feature/check',
  DELETE_STORE: 'stores/{storeId}',
  check_LimitFunction: 'common/checklimitfunction/{functionName}',
  check_DynamicFunction: 'common/checkdynamicfunction/{functionName}',
  UPDATE_TEST_ACCOUNT: 'stores/test',

  // shared-theme => shared.service.ts
  FAQ: 'stores/{storeId}/faq',
  SORT_FAQ: 'stores/{storeId}/faq/sortfaq',
  FAQ_DETAILS: 'stores/{storeId}/faq/{id}',

  CATEGORIES_NAME: 'stores/{storeId}/categories/name', //getAllCategoriesName
  BRANDS_NAME: 'stores/{storeId}/brands/name', //getAllBrands
  PRODUCTS_NAME: 'stores/{storeId}/products/name', //getAllProductsName
  VARIANTS_NAME: 'stores/{storeId}/products/variant/name', //getAllProductsName
  CUSTOMER_NAME: 'stores/{storeId}/customers/name', //getAllCustomerName
  CUSTOMER_GROUP_NAME: 'stores/{storeId}/customers/group/name', //getAllCustomerName
  ROLE_NAME: 'stores/{storeId}/customers/roles', //getAllCustomerName

  // Product Service - Add Product
  ADD_PRODUCT_DETAILS: 'stores/{storeId}/products/catelog/{id}', //updateProducts and getoneproduct
  GET_PRODUCT_ATTACHMENT: 'stores/{storeId}/products/catelog/{id}/attachment', //get attachement
  CREATE_PRODUCT_DETAILS: 'stores/{storeId}/products/catelog',//add product
  GET_VARIANT_DETAILS: 'stores/{storeId}/products/catelog/variantcombination',//get variants details
  QUICK_UPDATE_PRODUCT_DETAILS: 'stores/{storeId}/products/productDataFormation/{id}', //quickupdateProducts

  //Related Product
  GET_RELATED_PRODUCT_DETAILS: 'stores/{storeId}/relatedproducts/{code}/products/{productId}',
  // DELETE_RELATED_PRODUCT: 'stores/{storeId}/products/{productId}/relatedproducts/{relatedProductId}/{relatedProductName}',
  DELETE_RELATED_PRODUCT: 'stores/{storeId}/relatedproducts/{id}/{code}/products/{productId}',
  SORT_RELATED_PRODUCT: 'stores/{storeId}/relatedproducts/sort',
  GET_NON_RELATED_PRODUCT: 'stores/{storeId}/relatedProducts/{code}/products',
  ADD_RELATED_PRODUCT: 'stores/{storeId}/relatedproducts',
  LINK_BACK_RELATED_PRODUCT: 'stores/{storeId}/relatedproducts/{id}/{code}/products/{productId}',
  BULK_DELETE_RELATED_PRODUCT: 'stores/{storeId}/relatedproducts/{code}/products/{productId}',

  GET_PLUGIN_CATEGORY_CODE: 'stores/{storeId}/plugins/codes',// plugin category
  //checkout

  CALCULATE_SUMMARY: 'stores/{storeId}/checkout', // checkout summary
  CALCULATE_SUMMARY1: 'stores/{storeId}/productsubscription/checkout/{clientId}', // checkout summary
  CALCULATE_SUMMARY_FOR_DIGITAL_PRODUCT: 'stores/{storeId}/checkout/digitalproduct', // checkout summary

  UPDATE_USER: 'stores/{storeId}/users/{id}/userinfo', // update user
  UPDATE_ADDRESS: 'stores/{storeId}/user/{id}/address', // update address
  AUTHORIZE_PAYMENT: 'stores/{storeId}/payment/authorize', // authorize payment
  AUTHORIZE_PAYMENT_ORDER: 'stores/{storeId}/payment/paymentorder',//authorize paypal order
  AUTHORIZE_PAYMENT_ORDER_SUBSCRIPTION: 'stores/{storeId}/payment/payment-order-subscription',//for subscription razorpay payment
  AUTHORIZE_CHECKOUTDOTCOM_ORDER: 'stores/{storeId}/payment/checkoutDotComPayment',//authorize for checkoutDotCom payment
  PAYMENT_LOG: 'stores/{storeId}/paymentLogs/payment',//getAllPaymentList
  REFUND_LOG: 'stores/{storeId}/paymentLogs/refund', // getAllRefundList
  UNFULFILLED_ORDERS: 'stores/{storeId}/order-pre-process',
  RETRY_ORDERS: 'stores/{storeId}/order-pre-process/place-order',
  DELETE_UNFULFILLED_ORDERS: 'stores/{storeId}/order-pre-process/{id}',
  VERIFY_PAYMENT_ORDER: 'stores/{storeId}/order-pre-process/verify-payment-order',
  CREATE_FAILURE_TRANSACTION_LOG: 'stores/{storeId}/payment/transaction',//create faliure transaction log

  PARENT_CATEGORY_DETAILS: 'stores/{storeId}/categories/parentcategories', //getParentCategoryDetails
  ADD_CATEGORY: 'stores/{storeId}/categories',//addCategory
  TRANSACTION: 'stores/{storeId}/payment/transaction',//getAllTransactions
  ERROR_EMAIL_NOTIFICATION: 'stores/{storeId}/errornotification',//errorEmailNotification

  GET_CREDENTIALS: 'stores/{storeId}/facebook/credentials',//getAppId
  ACCESS_TOKEN: 'stores/{storeId}/facebook/accesstoken',//Check for the availability of access token
  GET_PRODUCT_DETAILS: 'stores/{storeId}/facebook/products',//getProductsList
  SYNC_ALL_PRODUCTS: 'stores/{storeId}/facebook/sync',//sync all products
  SYNC_PRODUCT: 'stores/{storeId}/facebook/products/{id}/variants/{variantId}/sync',//sync a particular product
  PROCESSING_PRODUCT: 'stores/{storeId}/facebook/products/{id}/variants/{variantId}/refresh',//refresh a particular product
  ASYNC_PRODUCT: 'stores/{storeId}/facebook/products/{id}/variants/{variantId}/async',//async a particular product
  REFRESH_ALL_PRODUCTS: 'stores/{storeId}/facebook/refresh',//refresh all products
  GET_ALL_TAX_BY_NAME: 'stores/{storeId}/taxes/name',
  GET_ALL_RIBBIONS_BY_NAME: 'stores/{storeId}/ribbons/name',
  GET_ALL_BRANDS_BY_NAME: 'stores/{storeId}/brands/name', //getAllBrands
  GET_ALL_OPTION_BY_NAME: 'stores/{storeId}/optionsvariants/options/name', //option
  GET_ALL_LOCATION_BY_NAME: 'stores/{storeId}/locations/name', //option
  GET_SPECIFICATION: 'stores/{storeId}/products/specification/{prodId}',
  UPDATE_PRODCUT_DETAILS: 'stores/{storeId}/products/specification/{id}', //createSpecification
  ORDER_MAILL: 'stores/{storeId}/orders/mail',
  GET_LOW_STOCK_DETAILS: 'stores/{storeId}/products/lowstockvalue',//get low stock details

  ENQUIRY_DETAILS: 'enquiries',
  FETCH_ONE_ENQUIRY_DETAILS: 'enquiries/{id}',

  MAIL_CONFIG_VERIFY: 'stores/{storeId}/mailconfig/verify', //verifyMailConfig ->settings.service.ts
  MAIL_CONFIG_RESET: 'stores/{storeId}/mailconfig/reset', //verifyMailConfig ->settings.service.ts
  MAIL_CONFIG: 'stores/{storeId}/mailconfig', //saveMailConfig,updateMailConfig,getMailConfig ->settings.service.ts'
  /*
  * Below routes are used for modifiers
  */
  getProductModifiers: 'stores/{storeId}/products/{productId}/{variantId}/modifiers',
  /*
    * Below routes are used to update modifiers
    */
  updateProductModifiers: 'stores/{storeId}/orders/{orderId}/modifier',
  GET_ALL_MODIFIER: 'stores/{storeId}/modifiers/set',//get all modifiers

  RELATED_PRODUCTS_LIST: 'stores/{storeId}/products/relatedproduct/{productId}', //getRelatedProducts
  GET_STORE_SETTINGS: 'stores/{storeId}/storePlugin',

  /*
  * Below routes are used to Saved Filters
  */
  GET_SAVEDFILTER_BY_CODE: 'stores/{storeId}/savedFilter', //getSavedFilterByCode
  CREATE_SAVEDFILTER_BY_CODE: 'stores/{storeId}/savedFilter', //CREATE SavedFilterByCode
  // UPDATE_SAVEDFILTER_BY_CODE: 'stores/{storeId}/savedFilter', //UPDATE SavedFilterByCode
  DELETE_SAVEDFILTER_BY_CODE: 'stores/{storeId}/savedFilter/{filterId}', //DELETE SavedFilterByCode
  GET_MAIL_LOGS: 'mail/logs/store/{storeId}',
  GET_ONE_MAIL_LOG: 'mail/logs/{id}',
  // GET_MAIL_LOGS: 'stores/{storeId}/common/maillog',
  GET_ONE_PURCHASE_GIFT_CARD: 'stores/{storeId}/purchasedgiftcards/{purchaseGiftId}',
  GET_ONE_PURCHASE_GIFT_CARD_ORDERS: 'stores/{storeId}/purchasedgiftcards/orders/{purchaseGiftId}',


  // GET_ONE_PURCHASE_GIFT_CARD: 'stores/{storeId}/customers/{purchaseGiftId}',
  ADD_GIFT_CARD: 'stores/{storeId}/giftcards', //addGiftCard
  GET_ONE_GIFT_CARD: 'stores/{storeId}/giftcards/{id}',
  GET_ALL_GIFT_CARD: 'stores/{storeId}/giftcards', //getAllGiftCard
  UPDATE_GIFT_CARD: 'stores/{storeId}/giftcards/{id}', //updateGiftCard
  DELETE_GIFT_CARD: 'stores/{storeId}/giftcards/{id}', //deleteGiftCard
  getPurchasedGiftCardDetailsForUser: 'stores/{storeId}/purchasedgiftcards/users/{userId}',

  // Customer subscription
  GET_ALL_CUSTOMER_SUBSCRIPTIONS: 'stores/{storeId}/productsubscription/plans/{customerId}',
  GET_ALL_CUSTOMER_SUBSCRIPTION: 'stores/{storeId}/productsubscription/plan',
  // GET_PLACE_ORDER: 'stores/{storeId}/orders/subscription/placeorder',
  GET_PLACE_ORDER: 'stores/{storeId}/productsubscription/order',
  GET_ALL_UPCOMING_ORDER: 'stores/{storeId}/productsubscription/orders/upcoming',
  SKIP_ORDER: 'stores/{storeId}/productsubscription/orders/skip',
  GET_ALL_SUBSCRIPTION_ORDERS: 'stores/{storeId}/productsubscription/orders/bundle/{id}',
  GET_ONE_SUBSCRIBED_PLAN: 'stores/{storeId}/productsubscription/orders/plan/{subId}',
  GET_SUBSCRIPTION_SETTING: 'stores/{storeId}/subscription/settings',
  GET_SUBSCRIPTION_PLUGIN_DETAILS: 'stores/{storeId}/productsubscription/plugindetails',
  UPDATE_SUBSCRIPTION_SETTING: 'stores/{storeId}/subscription/settings',
  GET_ALL_SINGLE_PRODUCT: 'stores/{storeId}/productsubscription/orders/single/products',
  GET_ONE_SINGLE_PRODUCT: 'stores/{storeId}/productsubscription/orders/{subId}/single/products',
  GET_ALL_SINGLE_SUBSCRIPTION_ORDERS: 'stores/{storeId}/productsubscription/orders/{subId}/single/orders',
  GET_ALL_SINGLE_UPCOMING_ORDER: 'stores/{storeId}/productsubscription/orders/single/upcoming',
  GET_SUBSCRIPTION_FREQUENCY: 'stores/{storeId}/productsubscription/frequency',

  GET_DISCOUNT_TYPE: 'stores/{storeId}/discounts/types',
  S3_BUCKET_IMAGE_LIST: 'stores/{storeId}/zenimages',
  S3_BUCKET_IMAGE_LIST_DELETE: 'stores/{storeId}/zenimages/{directoryDetails}',
  S3_BUCKET_IMAGE_RENAME: 'stores/{storeId}/zenimages/rename',
  //subscription products
  GET_ALL_SUBSCRIPTION: 'stores/{storeId}/productsubscription',
  GET_PRODUCTS_LIST: 'stores/{storeId}/products/subscription',
  GET_ONE_PRODUCT: 'stores/{storeId}/products/{productId}/subscription',
  CREATE_SUBSCRIPTION: 'stores/{storeId}/productsubscription',
  GET_ONE_SUBSCRIPTION: 'stores/{storeId}/productsubscription/{id}',
  GET_ONE_SUBSCRIPTION_DETAIL: 'stores/{storeId}/productsubscription/{id}',
  UPDATE_PRODUCT_SUBSCRIPTION: 'stores/{storeId}/productsubscription/{id}',
  RESCHEDULE_ORDER: 'stores/{storeId}/productsubscription/orders/{orderId}/reschedule',
  SUBSCRIPTION_ORDER_SHIPPING: 'stores/{storeId}/productsubscription/orders/{orderId}/shipping/method',
  SUBSCRIPTION_ORDER_ADDRESS: 'stores/{storeId}/productsubscription/orders/{orderId}/shipping/address',

  /**
   * Below route used for singup discount
   */
  REQUEST_COUPON: 'discounts/signup',//Get all signup discount,createSignupDiscount
  UPDATE_ENABLE_STATUS: 'discounts/signup/{id}/status',//enableChange
  DELETE_SIGNUP_DISCOUNT: 'discounts/signup/{id}',
  // SAVE_SIGNUP_DISCOUNT: 'discounts/signupDiscount',
  UPDATE_SIGNUP_DISCOUNT: 'discounts/signup/{id}/discount',
  GET_DISCOUNT_DATA: 'discounts/signup/{id}',//getSingleDiscountData
  CHECK_EXISITING_PROMOCODE: 'discounts/signup/existence',
  CHECK_DISCOUNT_DATA: 'discounts/signup/exists',
  USER_PURCHASED_GIFT_CARDS: 'stores/{storeId}/purchasedgiftcards/customer/{customerId}',
  PURCHASED_GIFT_CARD_LOGS: 'stores/{storeId}/purchasedgiftcards/{id}/logs',
  APPLIED_GIFT_CARDS_ORDERS: 'stores/{storeId}/purchasedgiftcards/{purchasedGiftCardId}/orders',
  limit: 'stores/{storeId}/common/check-limit',
  S3_BUCKET_IMAGE_LIST_BULKDELETE: 'stores/{storeId}/zenimages/delete',
  //Notification Configuration
  GET_ALL_TEMPLATES: 'mail/template',
  UPDATE_TEMPLATE: 'mail/template/customizeTemplate/{id}',
  REVERT_TEMPLATE: 'mail/customTemplate/{id}',
  GETONEDEFAULTMAILTEMPLATESTOREID: 'mail/template/{id}/store/{storeId}',//get mail for particular store for superadmin
  UPDATEMAILDEFAULTTEMPLATE: 'mail/template/{id}',//fetchEDITEMAILTEMPLATE,TO edit email template
  UPDATEMAILDEFAULTTEMPLATEDETAILS: 'mail/templateDetails/{id}',//fetchEDITEMAILTEMPLATE,TO edit email template
  ADDDEFAULTMAILTEMPLATE: 'mail/template',//fetchEDITEMAILTEMPLATE,TO edit email template

  //Email  Marketing
  CONTACT_GROUP: 'stores/{storeId}/marketing/email/contactgroups',
  GET_ONE_CONTACT_GROUP: 'stores/{storeId}/marketing/email/contactgroups/{id}',
  GET_CUSTOMERS_FOR_CONTACT_GROUP: 'stores/{storeId}/marketing/email/contactgroups/customers/{id}',
  GET_ONE_EMAIL_TRACKING: 'stores/{storeId}/marketing/email/tracking/{id}',
  EMAIL_TRACKING_DETAILS: 'stores/{storeId}/common/tracking/{customerId}/{campaignId}',
  GET_ALL_DEFAULT_MARKETING_TEMPLATE: 'stores/{storeId}/marketing/email/template/list/{type}',
  UPDATE_DEFAULT_MARKETING_TEMPLATE: 'stores/{storeId}/marketing/email/template/default/{id}',
  DELETE_DEFAULT_MARKETING_TEMPLATE: 'stores/{storeId}/marketing/email/template/default/{id}',

  //constant contact
  GET_ALL_LIST: 'stores/{storeId}/constantcontact/getAllList',
  //created a group of list with all the contacts
  ADD_CONTACTS_TO_LIST: 'stores/{storeId}/constantcontact/addContactsToList',
  //delete list
  DELETE_LIST: 'stores/{storeId}/constantcontact/deleteList/{list_id}',
  //contact list
  GET_CONTACTS_FROM_LIST: 'stores/{storeId}/constantcontact/getOneList/contacts',
  //update list
  UPDATE_CONTACT_LIST: 'stores/{storeId}/constantcontact/addOrUpdateBulkContactToList/{listId}',
  //createContact
  SYNC_ONE_CONTACT: 'stores/{storeId}/constantcontact/syncOneContact',
  //getContact
  GET_ALL_CONTACTS: 'stores/{storeId}/constantcontact/getAllContacts/{isGetContactCollections}',
  //sync all contacts
  SYNC_ALL_CONTACTS: 'stores/{storeId}/constantcontact/syncAllContacts',
  //getallcustomer from store
  GET_ALL_CUSTOMER_FROM_STORE: 'stores/{storeId}/constantcontact/getAllCustomerFromStore',
  //
  //Segment

  GET_ALL_SEGMENT_RULES: 'marketing/email/segmentrules',
  GET_ALL_SEGMENT_CUSTOMER: 'stores/{storeId}/marketing/email/segment/',
  GET_ALL_TAGES: 'stores/{storeId}/marketing/email/tags',
  CREATE_SEGMENT: 'stores/{storeId}/marketing/email/segment/add',
  GET_ALL_SEGMENT: 'stores/{storeId}/marketing/email/segment/all',
  DELETE_SEGMENT: 'stores/{storeId}/marketing/email/segment/{id}',
  GET_ONE_SEGMENT: 'stores/{storeId}/marketing/email/segment/{id}',
  DUPLICATE_SEGMENT: 'stores/{storeId}/marketing/email/segment/{id}',
  GET_ALL_PRODUCTS_BY_NAME: 'stores/{storeId}/products/name',
  GET_CUSTOMERS_FOR_SEGMENT: 'stores/{storeId}/marketing/email/segment/customers/{segmentids}',
  GET_ALL_SEGMENTS: 'stores/{storeId}/marketing/email/segment',
  // 'stores/:storeId/marketing/email/tags'
  GET_ALL_TAG: 'stores/{storeId}/marketing/email/tags/v1',
  UPDATE_EMAILMARKETTING_TAG: 'stores/{storeId}/marketing/email/tags/{tagId}',
  ADD_EMAILMARKETTING_TAG: 'stores/{storeId}/marketing/email/tags ',
  TAG_VALIDATION_CHECK: 'stores/{userId}/common/checkduplicates',
  DELETE_TAG: 'stores/{storeId}/marketing/email/tags/{tagId}',
  //Campaign
  GET_ALL_CAMPAIGN: 'stores/{storeId}/marketing/email/campaign',
  DELETE_CAMPAIGN: 'stores/{storeId}/marketing/email/campaign/{campaignId}',
  GET_ALL_TEMPLATE: 'stores/{storeId}/marketing/email/template/{templateType}/all',
  CREATE_CAMPAIGN: 'stores/{storeId}/marketing/email/campaign',
  UPDATE_CAMPAIGN: 'stores/{storeId}/marketing/email/campaign/{id}',
  GET_ONE_CAMPAIGN: 'stores/{storeId}/marketing/email/campaign/{campaignId}',
  SEND_CAMPAIGN: 'stores/{storeId}/marketing/email/campaign/{campaignId}/{templateId}/mail',
  SEND_TEST_CAMPAIGN: 'stores/{storeId}/marketing/email/campaign/{templateId}/testmail',
  GET_CAMPAIGN_TEMPLATE_MAPPING: 'stores/{storeId}/marketing/email/campaign/template/{templateId}',
  GET_FILTER_CUSTOMER: 'stores/{storeId}/marketing/email/tracking/list/{campaignId}',
  GET_ALL_FILTER_CUSTOMER: 'stores/{storeId}/marketing/email/tracking/one/campaign/{campaignId}',
  // Sms Campaign
  GET_ALL_SMS_CAMPAIGN: 'stores/{storeId}/marketing/sms/campaign',
  DELETE_SMS_CAMPAIGN: 'stores/{storeId}/marketing/sms/campaign/{id}',
  GET_ALL_SMS_TEMPLATES: 'stores/{storeId}/marketing/sms/campaign/template',
  GET_ALL_DEFAULT_SMS_TEMPLATES: 'marketing/sms/campaign/template',
  UPDATE_DEFAULT_SMS_TEMPLATES: 'marketing/sms/campaign/template/{id}',
  ACTIVE_DEFAULT_SMS_TEMPLATES: 'marketing/sms/campaign/default/active',
  DELETE_DEFAULT_SMS_TEMPLATES: 'marketing/sms/campaign/template/{id}',
  GET_ONE_DEFAULT_SMS_TEMPLATES: 'marketing/sms/campaign/template/{id}',
  CREATE_DEFAULT_SMS_TEMPLATES: 'marketing/sms/campaign/template',
  GET_ONE_SMS_DETAILS: 'stores/{storeId}/marketing/sms/campaign/{id}',
  CREATE_SMS_CAMPAIGN: 'stores/{storeId}/marketing/sms/campaign',
  UPDATE_SMS_CAMPAIGN: 'stores/{storeId}/marketing/sms/campaign',
  GET_FILTER_CUSTOMER_TRACKING: 'stores/{storeId}/marketing/sms/tracking/customer/{campaignId}',
  GET_ONE_CAMPAIGN_DETAILS: 'stores/{storeId}/marketing/sms/tracking/{campaignId}',
  GET_ONE_CUSTOMER_ORDER_DETAILS: 'stores/{storeId}/marketing/sms/tracking/{campaignId}/customer/{customerId}',
  SEND_SMS_CAMPAIGN: 'stores/{storeId}/marketing/sms/campaign/send',
  SEND_TEST_SMS: 'stores/{storeId}/marketing/sms/campaign/testsms',
  //custom mail templates for email marketing
  GET_ALL_CUSTOM_TEMPLATE: 'stores/{storeId}/marketing/email/template/list/{type}',
  DELETE_CUSTOM_TEMPLATE: 'stores/{storeId}/marketing/email/template/{id}',
  GETONE_CUSTOM_TEMPLATE: 'stores/{storeId}/marketing/email/template/{id}',
  GET_ONE_MAIL_TEMPLATE: 'stores/marketing/email/template/{templateId}',
  UPDATE_CUSTOM_TEMPLATE: 'stores/{storeId}/marketing/email/template/{templateId}/update',
  ADD_CUSTOM_TEMPLATE: 'stores/{storeId}/marketing/email/template/create',
  // cart subscription
  GET_CART_SUBSCRIPTION_COUNT: 'stores/{storeId}/cart/count/{customerId}',
  GET_CART_SUBSCRIPTION_DETAILS: 'stores/{storeId}/cart/{customerId}/productsubscription',
  UPDATE_CART_SUBSCRIPTION_DETAILS: 'stores/{storeId}/cart/{cartId}/productsubscription/{subscriptionId}',
  ADD_TO_ORDER: 'stores/{storeId}/cart/{customerId}/productsubscription',
  GET_CART_SUBSCRIPTION: 'stores/{storeId}/cart/{customerId}/productsubscription/{productId}',

  //Shippo Shipment
  GET_SHIPPO_ORDERS: 'stores/{storeId}/shippo/orders',
  TRACK_SHIPPO_ORDER: 'stores/{storeId}/shippo/orders/{orderId}/shipment/track',
  CREATE_SHIPPO_TRANSACTION: 'stores/{storeId}/shippo/orders/{orderId}/transaction',
  CREATE_SHIPPO_SHIPMENT: 'stores/{storeId}/shippo/orders/{orderId}/shipment',

  //fedex
  GET_FEDEX_ORDERS: 'stores/{storeId}/fedex/orders',
  VALIDATE_ADDRESS: 'stores/{storeId}/fedex/orders/{orderId}/address/validate',
  SHIPPING_CARRIERS: 'stores/{storeId}/fedex/orders/{orderId}/services',
  SHIPMENT: 'stores/{storeId}/fedex/orders/{orderId}/shipment',
  TRACK_FEDEX_ORDER: 'stores/{storeId}/fedex/orders/{orderId}/shipment/track/{trackingId}',
  CANCEL_FEDEX_ORDER: 'stores/{storeId}/fedex/orders/{orderId}/shipment/cancel',

  //UPS Shipment
  GET_UPS_LOCATION_DETAILS: 'stores/{storeId}/ups/orders/{orderId}',
  GET_UPS_RATINGS: 'stores/{storeId}/ups/orders/{orderId}/rating',
  GET_UPS_ORDERS: 'stores/{storeId}/ups/orders',
  CANCEL_UPS_ORDER: 'stores/{storeId}/ups/orders/{shipmentIdentificationNumber}/shipment/cancel',
  CREATE_UPS_TRANSACTION: 'stores/{storeId}/ups/orders/{orderId}/shiping',
  TRACK_UPS_ORDER: 'stores/{storeId}/ups/orders/{orderId}/tracking',

  //subscription product
  GET_SUBSCRIPTION_DETAIL: 'stores/{storeId}/product/subscription/check/{productId}',

  //MailChimp
  GET_MAILCHIMP_AUDIENCE: 'stores/{storeId}/mailchimp',//getMailChimpAudience,createAudience
  // CREATE_MAILCHIMP_AUDIENCE: 'stores/{storeId}/mailchimp',
  GET_ONE_MAILCHIMP_AUDIENCE: 'stores/{storeId}/mailchimp/{audienceId}',//getAudienceInfo,updateAudience,deleteAudience
  // DELETE_MAILCHIMP_AUDIENCE: 'stores/{storeId}/mailchimp/{audienceId}',
  // UPDATE_MAILCHIMP_AUDIENCE: 'stores/{storeId}/mailchimp/{audienceId}',
  GET_ALL_MAILCHIMP_CUSTOMERS: 'stores/{storeId}/mailchimp/customers',
  GET_MAILCHIMP_CONTACTS: 'stores/{storeId}/mailchimp/{audienceId}/contacts',//createContacts,getContacts
  // ADD_MAILCHIMP_CUSTOMERS: 'stores/{storeId}/mailchimp/{audienceId}/contacts',
  UPDATE_MAILCHIMP_CUSTOMERS: 'stores/{storeId}/mailchimp/{audienceId}/contacts/{contactId}',//deleteCustomer,updateCustomer
  // DELETE_MAILCHIMP_CUSTOMERS: 'stores/{storeId}/mailchimp/{audienceId}/contacts/{contactId}',
  //Credit plans
  GET_CREDIT_PLANS: 'credits/plans',
  DELETE_CREDIT_PLAN: 'credits/{id}',
  CHECK_PLAN_EXISTENCE: 'credits/existence',
  GET_ONE_CREDIT_PLAN: 'credits/{code}',
  CREATE_CREDIT_PLAN: 'credits',
  UPDATE_CREDIT_PLAN: 'credits/{code}',
  CREDIT_LOG: 'stores/{storeId}/credits/logs',
  CREDIT_TYPE: 'stores/{storeId}/credits/type',
  STOCK_CHECKING: 'stores/{storeId}/products/{productId}/variants/{variantId}/inventory/check',

  //Blog Management
  GET_BLOG_LIST: 'stores/{storeId}/marketing/blogs/client/{clientId}',
  UPDATE_BLOG_LIST: 'stores/{storeId}/marketing/blogs/{blogId}',
  GET_BLOG_AUTHOR_LIST: 'stores/{storeId}/marketing/blogs/{clientId}/author',
  DELETE_BLOG_LIST: 'stores/{storeId}/marketing/blogs',
  DELETE_COMMENT_LIST: 'stores/{storeId}/marketing/blogs/comments',
  DELETE_BLOG_MAPPED: 'stores/{storeId}/marketing/blogs',
  DELETE_BLOG_CATEGORY: 'stores/{storeId}/marketing/blogCategories',
  GET_ALL_CATEGORY_LIST: 'stores/{storeId}/marketing/blogCategories',
  GET_ALL_CATEGORY_LIST_FOR_STOREFRONT: 'stores/{storeId}/marketing/blogCategories/v1/category',
  GET_BLOG_CATEGORY_LIST: 'stores/{storeId}/marketing/blogCategories/v1',
  CREATE_BLOG_CATEGORY: 'stores/{storeId}/marketing/blogCategories',
  UPDATE_BLOG_CATEGORY: 'stores/{storeId}/marketing/blogCategories/{categoryId}',
  GET_ONE_BLOG_CATEGORY: 'stores/{storeId}/marketing/blogCategories/{categoryId}',
  UPDATE_COMMENT_STATUS: 'stores/{storeId}/marketing/blogs/comments/{id}',
  GET_ALL_COMMENT: 'stores/{storeId}/marketing/blogs/comments',
  GET_ALL_BLOGS: 'stores/{storeId}/marketing/blogs/translation',

  //digital product
  ADD_DIGITAL_PRODUCT_DETAILS: 'stores/{storeId}/digitalproducts/',      // save a digital product
  GETONE_DIGITAL_PRODUCT_DETAILS: 'stores/{storeId}/digitalproducts/{id}',  // get/update a digital product
  REMOVE_DIGITAL_PRODUCT_FILE: 'stores/{storeId}/digitalproducts/remove',   //remove a digital product file
  GET_AWS_KEY: 'stores/{storeId}/zenimages/accesskey',                 //get aws keys
  DOWNLOAD_DIGITAL_PRODUCT_FILE: 'stores/{storeId}/orders/{orderId}/download', //download digital product
  UPDATE_FILE_DOWNLOAD_COUNT: 'stores/{storeId}/orders/{orderId}/count',
  GET_AWS_SIZE: 'stores/{storeId}/zenimages/storage',                 //get aws size
  CHECK_AWS_SIZE: 'stores/{storeId}/common/storageavailability',      //check aws size
  COPY_OR_UPLOAD_IMAGE: 'stores/{storeId}/zenimages/copyorupload',
  // Url payment
  PLACE_ORDER2: 'stores/{storeId}/orders/subscription/urlcheckout/placeorder', //url placeOrder
  GET_SUBSCRIPTION_ADDRESS: 'subscription/payment/{id}', //subscription location
  GET_PAYMENT_DETAILS_SUBSCRIPTION: 'stores/{storeId}/payment/paymentDetails', // subscription details
  URL_CALCULATE_SUMMARY: 'stores/{storeId}/checkout/{clientId}/urlcheckout', // checkout summary
  URL_AUTHORIZE_PAYMENT: 'stores/{storeId}/payment/authorize/urlcheckout', // url authorize payment
  URL_AUTHORIZE_PAYMENT_ORDER: 'stores/{storeId}/payment/urlcheckout/paymentorder',//url authorize paypal order
  URL_AUTHORIZE_CHECKOUTDOTCOM_ORDER: 'stores/{storeId}/payment/checkoutDotComPayment/urlcheckout', //url authorize for checkoutDotCom payment
  URL_SQUARE_PAYMENT: 'stores/{storeId}/payment/square/urlcheckout', //url authorize for square payment
  URL_RAZORPAY_ORDER: 'stores/{storeId}/payment/razorpay/urlcheckout', //url authorize for razorpay payment
  URL_AUTHORIZEDOTNET_PAYMENT: 'stores/{storeId}/payment/authorizeDotNetPayment/urlcheckout', //url authorize for authorizedotnet payment
  URL_DELETE_CARD: 'stores/{storeId}/payment/urlcheckout/card/{id}',
  GET_PURCHASED_GIFTCARD_DETAILS: 'stores/{storeId}/purchasedgiftcards/urlcheckout/users/{userId}',

  //ORDER LIST PAGE => SUBSCRIPTION ORDER STOCK orders-list.component.ts
  // GET_CHECK_COMMON_STOCK: 'stores/{storeId}/orders/{orderId}/stock',

  // Saved for later
  GET_DISCOUNT_LOGS: 'stores/{storeId}/discounts/log',
  Bulk_Delete_Discount: 'stores/{storeId}/discounts/delete',//discount bulk delete
  ADD_TO_SAVED_FOR_LATER: 'stores/{storeId}/savedForLater',
  GET_SAVE_FOR_LATER_PRODUCTS: 'stores/{storeId}/savedForLater/{customerId}',
  REMOVE_SAVE_FOR_LATER_PRODUCTS: 'stores/{storeId}/savedForLater/{cartId}/{variantId}',
  MOVE_TO_CART: 'stores/{storeId}/savedForLater',
  //Option Sort
  UPDATE_OPTIONVALUE_SORT_ORDER: 'stores/{storeId}/optionsvariants/option/{id}/optionvalues/sort',
  UPDATE_OPTION_SORT_ORDER: 'stores/{storeId}/optionsvariants/option/sort',
  GET_ALL_CATEGORY_BLOGS: 'stores/{storeId}/marketing/blogCategories/blog',
  CREATE_BLOG: 'stores/{storeId}/marketing/blogs',
  LIST_ALL_BLOG: 'stores/{storeId}/marketing/blogs/list',
  GET_ONE_BLOG_DETAIL: 'stores/{storeId}/marketing/blogs/{blogId}/details',
  POST_COMMAND: 'stores/{storeId}/marketing/blogs/comments',
  GET_ONE_BLOGCOMMENT: 'stores/{storeId}/marketing/blogs/comments/v1/{id}',
  UPDATE_BLOG: 'stores/{storeId}/marketing/blogs/{blogId}',
  GET_ONE_BLOG: 'stores/{storeId}/marketing/blogs/{id}',

  // WhatsApp Marketing
  WHATSAPP_ACCESS_TOKEN: 'stores/{storeId}/marketing/whatsapp/token',//Check for the availability of access token
  GET_APP_ID: 'stores/{storeId}/marketing/whatsapp/config',
  GET_WHATSAPP_TEMPLATE: 'stores/{storeId}/marketing/whatsapp/',
  CREATE_TEMPLATE: 'stores/{storeId}/marketing/whatsapp/',
  GET_ONE_TEMPLATE: 'stores/{storeId}/marketing/whatsapp/{id}',
  UPDATE_WHATSAPP_TEMPLATE: 'stores/{storeId}/marketing/whatsapp/',
  SEND_WHATSAPP_MESSAGE: 'stores/{storeId}/marketing/whatsapp/messages',
  DELETE_WHATSAPP_MESSAGE: 'stores/{storeId}/marketing/whatsapp/{id}',
  GET_ALL_MESSAGE_LOGS: 'stores/{storeId}/marketing/whatsapp/logs',


  //PRE ORDER
  GET_ALL_PRE_ORDER_PRODUCTS: 'stores/{storeId}/preorder/products',
  GET_ALL_PREORDER_CUSTOMER: 'stores/{storeId}/preorder/customers',
  GET_ONE_PRE_ORDER_PRODUCTS: 'stores/{storeId}/preorder/{id}',
  QUICK_UPDATE_PRE_ORDER_PRODUCTS: 'stores/{storeId}/preorder/{id}',
  GET_ONE_PRE_ORDER_PRODUCT_QUANTITY: 'stores/{storeId}/preorder/quantity',
  GET_ORDER_STATUS: 'stores/{storeId}/preorder/status',

  //facebook pixel
  GET_ALL_CAMPAIGNS: 'stores/{storeId}/facebookAds/campaigns',
  GET_ALL_ADS: 'stores/{storeId}/facebookAds/ads',
  GET_CREDENTIAL: 'stores/{storeId}/facebookAds/credentials',
  GET_FB_PIXEL_VALUES: 'stores/{storeId}/facebookAds/values',
  GET_ACCESS_TOKEN: 'stores/{storeId}/facebookAds/accesstoken',
  DELETE_FACEBOOK_CAMPAIGN: 'stores/{storeId}/facebookAds/campaigns/{campaignId}',
  EDIT_FACEBOOK_CAMPAIGN: 'stores/{storeId}/facebookAds/campaigns/{campaignId}',
  ADD_FACEBOOK_CAMPAIGN: 'stores/{storeId}/facebookAds/campaigns',
  UPDATE_FACEBOOK_CAMPAIGN: 'stores/{storeId}/facebookAds/campaigns/{campaignId}',
  GET_ONE_AD: 'stores/{storeId}/facebookAds/ads/{adId}',
  GET_ALL_IMAGES: 'stores/{storeId}/facebookAds/images',
  GET_ONE_ADSET: 'stores/{storeId}/facebookAds/adSets/{adSetId}',
  CREATE_ADSET: 'stores/{storeId}/facebookAds/adSets',
  GET_ALL_ADSETS: 'stores/{storeId}/facebookAds/adSets',
  UPDATE_ADSET: 'stores/{storeId}/facebookAds/adSets/{adSetId}',
  DELETE_ADSET: 'stores/{storeId}/facebookAds/adSets/{adSetId}',
  CREATE_ADS: 'stores/{storeId}/facebookAds/ads',
  UPLOAD_IMAGE: 'stores/{storeId}/facebookAds/images',
  DELETE_AD: 'stores/{storeId}/facebookAds/ads/{adId}',
  GET_ONE_IMAGE: 'stores/{storeId}/facebookAds/images/{imageId}',
  GET_ONE_VIDEO: 'stores/{storeId}/facebookAds/videos/{videoId}',
  UPLOAD_VIDEO: 'stores/{storeId}/facebookAds/videos',
  GET_ALL_VIDEOS: 'stores/{storeId}/facebookAds/videos',


  //TimerStoreAdmin:
  GET_TIMER_DETAILS: 'stores/{storeId}/salestimer/timer',//getAllTimerDetails,
  GET_TIMER_TEMPLATES_STOREADMIN: 'stores/{storeId}/salestimer/template',
  SAVE_TIMER_DISCOUNT: 'stores/{storeId}/salestimer',//createTimerDiscount
  DELETE_COUNT_DOWN_TIMER: 'stores/{storeId}/salestimer/{id}',
  BULK_DELETE_COUNT_DOWN_TIMER: 'stores/{storeId}/salestimer',

  GET_ONE_TIMER_DETAILS: 'stores/{storeId}/salestimer/{id}',//getOneTimerDetails,updateTimerDiscount
  // UPDATE_COUNT_DOWN_TIMER: 'stores/{storeId}/timerdiscount/{id}',
  CHECK_EXISTENCE: 'stores/{storeId}/salestimer/exists',
  UPDATE_TIMERSTATUS: 'stores/{storeId}/salestimer/{id}/status',
  GET_TIMER_LOGS: 'stores/{storeId}/salestimer/logs',
  DELETE_TIMER_LOG: 'stores/{storeId}/salestimer/logs/{id}',
  BULK_DELETE_TIMER_LOG: 'stores/{storeId}/salestimer/logs',
  GET_ALL_SALES_COUNTDOWN_TIMER_DISCOUNT: 'stores/{storeId}/salestimer/discounts',
  GET_ONE_PRODUCT_TIME_BASED_OFFERS: 'stores/{storeId}/salestimer/products/{productId}/variant/{variantId}',

  //Timer SuperAdmin
  GET_TIMER_TEMPLATES: 'timer',
  // CHANGE_TIMER_TEMPLATES: 'timer',

  // Loyalty Points
  GET_ALL_STORE_EARN_POINTS_SETTINGS: 'stores/{storeId}/points/earn/settings',
  GET_ALL_POINTS_TYPE: 'pointsType',
  CREATE_STORE_EARN_POINTS: 'stores/{storeId}/points/storeEarn/settings',
  UPDATE_STORE_EARN_POINTS: 'stores/{storeId}/points/earn/settings/{id}',
  GET_ALL_STORE_REDEEM_POINTS_SETTINGS: 'stores/{storeId}/points/redeem/settings',
  UPDATE_STORE_REDEEM_POINTS_SETTINGS: 'stores/{storeId}/points/redeem/settings/{id}',
  ADD_STORE_REDEEM_POINTS_SETTINGS: 'stores/{storeId}/points/redeem/settings',
  GET_ONE_STORE_REDEEM_POINTS_SETTINGS: 'stores/{storeId}/points/redeem/settings/{id}',
  REDEEM_POINTS: 'stores/{storeId}/points/redeem/settings',
  REDEEM_CUSTOMER_POINTS: 'stores/{storeId}/points/customer/{customerId}/redeem',
  GET_ONE_REDEEM_POINTS: 'stores/{storeId}/points/redeem/settings/{id}',
  GET_ONE_REFFERAL_SETTINGS: 'stores/{storeId}/referral',
  UPDATE_REFERRAL_POINTS_SETTINGS: 'stores/{storeId}/referral',
  DELETE_STORE_EARN_POINT_SETTING: 'stores/{storeId}/points/storeEarn/setting/{id}',
  DELETE_STORE_REDEEM_POINT_SETTING: 'stores/{storeId}/points/storeRedeem/setting/{id}',
  //SuperAdmin Settings
  // GET_ALL_SUPER_ADMIN_SETTINGS: 'superadminsettings',

  // Loyalty Points - Super Admin
  GET_LOYALTY_POINT: 'pointsType',
  UPDATE_LOYALTY_POINT: 'pointsType/{id}',
  GET_ONE_LOYALTY_POINT: 'pointsType/{id}',
  GET_EARN_SETTINGS: 'stores/{storeId}/points/earn/settings',
  GET_EXPIRY_POINT: 'stores/{storeId}/points/pointsExpirySettings',
  UPDATE_EXPIRY_POINT: 'stores/{storeId}/points/pointsExpirySettings/{id}',

  GET_CUSTOMER_EARN_POINTS: 'stores/{storeId}/points/customer/{customerId}/earn',
  GET_CUSTOMER_REDEEM_POINTS: 'stores/{storeId}/points/customer/{customerId}/redeem',

  GET_POINTS_LOG: 'stores/{storeId}/points/loyalty/pointsLog/{customerId}',
  GET_ONE_STORE_EARN_POINTS_SETTINGS: 'stores/{storeId}/points/earn/settings/{id}',

  GET_REFERRAL: 'stores/{storeId}/referral',

  FREQUENTLY_BOUGHT_TOGETHER: 'stores/{storeId}/fbt/{productId}',
  FREQUENTLY_SAVE: 'stores/{storeId}/fbt',
  FREQUENTLY_UPDATE: 'stores/{storeId}/fbt/{fbtId}',
  DEFAULT_LOCATION: 'stores/{storeId}/locations/defaultId',
  CREATE_SIZE_CHART: 'sizechart/',
  GET_ALL_SIZE_CHART_TEMPLATES: 'sizechart/',
  GET_ONE_SIZE_CHART_TEMPLATES: 'sizechart/',
  DELETE_SIZE_CHART_TEMPLATE: 'sizechart/',
  UPDATE_SIZE_CHART_TEMPLATE: 'sizechart/',
  TEMPLATE_CHECK: 'sizechart/v4/',
  //Sizechart
  GET_ALL_CHART_TEMPLATES: 'sizechart/',
  ADD_SIZE_CHART: 'stores/{storeId}/sizechart/',
  GET_ALL_SIZE_CHART: 'stores/{storeId}/sizechart/',
  GET_ONE_SIZE_CHART: 'stores/{storeId}/sizechart/{id}',
  DELETE_SIZE_CHART: 'stores/{storeId}/sizechart/{id}',
  UPDATE_SIZE_CHART: 'stores/{storeId}/sizechart/{id}',
  GET_NON_MAPPED_PRODUCT: 'stores/{storeId}/sizechart/association',
  // DELETE_MAPPING: 'stores/{storeId}/products/sizechart/v3/{templateId}',
  // GET_MAPPED_PRODUCT: 'stores/{storeId}/products/sizechart/v3/{templateId}',
  GET_CUSTOM_TEMPLATE: 'stores/{storeId}/sizechart/custom',
  GET_PRODUCT_SIZE_CHART: 'stores/{storeId}/sizechart/product/{productId}',
  GET_SIZE_CHART_PLUGIN: 'stores/{storeId}/sizechart/plugindetails',

  //Badges
  GET_BADGE_TYPES: 'imageTemplates',
  BADGE_CRUD: 'badges',
  GET_ONE_BADGE: 'badges/{badgeId}',
  GET_ALL_BADGES: 'stores/{storeId}/badges', //getAllBadges
  GET_ALL_TRANSLATE_BADGE: 'stores/{storeId}/badges/translate',
  GET_ALL_DEFAULT_BADGES: 'badges',
  DELETE_BADGE: 'stores/{storeId}/badges/{id}',
  UPDATE_BADGE_DETAILS: 'stores/{storeId}/badges/{id}',
  BULK_DELETE_BADGE: 'stores/{storeId}/badges/delete',
  GET_ALL_CATEGORIES_BY_BADGES: 'stores/{storeId}/badges/categories',
  GET_ALL_PRODUCTS_BY_BADGES: 'stores/{storeId}/badges/products',
  GET_ONE_BADGE_STORE: 'stores/{storeId}/badges/{badgeId}',
  GET_MAPPED_PRODUCT_CATEGORY: 'stores/{storeId}/badges/remove/{badgeId}/{selectedIds}',
  FOR_BADGES_TRANSLATE: 'stores/{storeId}/badges/translate/{badgeId}',
  GET_PLUGIN_FEATURE_DETAILS: 'stores/{storeId}/plugins/pluginfeatures',
  GET_PLUIGIN_DETAILS: 'stores/{storeId}/productsubscription/checkPluginDetails',
  //export store theme details
  EXPORT_STORE_THEME: 'stores/{storeId}/themes/export',
  //SuperAdmin Settings
  GET_ALL_SUPER_ADMIN_SETTINGS: 'superadminsettings',
  CREATE_BADGE: 'stores/{storeId}/badges/',
  GET_ONE_SUPER_ADMIN_SETTINGS: 'superadminsettings/{code}',
  //Super Admin Plugin Answer Encription
  ENCRYPT_PLUGIN_DATA: 'plugin/encryptValues',
  //Super Admin Payment Password Encription
  ENCRYPT_PAYMENT_DATA: 'superadmin/encryptOrderDetails',
  GET_RECAPTCHA_CREDENTIAL: 'customer/credential',
  RECAPTCHA: 'customer/recaptchaEnterprise',
  ///ZenBasket User signup
  ZENBASKET_USER_SIGNUP: 'customer/zenbasket/register',
  ZENUSER_UPDATE: 'stores/{storeId}/customers/shopapp/{id}',

  //Payable Plugin
  GET_ONE_PLAN_DETAILS: 'stores/{storeId}/plugins/plans/{planId}',
  SAVE_PURCHASED_PLUGIN: 'stores/{storeId}/plugins/plans/checkout',

  //Plugin settings

  //plugin plan.
  PLUGIN_PLANS: 'stores/plugins/planType',
  UPDATE_PLUGIN_PLAN: 'stores/plugins/planType/{planTypeId}',
  PLUGIN_PLAN_EXISTENCE: 'stores/plugins/planType/existence',
  GET_PLUGIN_PLAN_BY_PLAN_TYPE: 'stores/plugins/planFeatures/planType/{planTypeId}',

  //plugin features.
  GET_ONE_PLUGIN_FEATURES: 'stores/plugins/feature/{pluginId}',
  ADD_UPDATE_PLUGIN_FEATURES: 'stores/plugins/feature',
  GET_ONE_PLUGIN_PLAN_CONFIGURATION: 'stores/plugins/planFeatures/{pluginId}',

  GET_ALL_PLUGIN_PLANS_CONFIGURATION: 'stores/plugins/planFeatures',
  ADD_PLUGIN_PLAN_CONFIGURATION: 'stores/plugins/planFeatures',
  UPDATE_PLUGIN_PLAN_CONFIGURATION: 'stores/plugins/planFeatures',
  DELETE_PLUGIN_PLAN_CONFIGURATION: 'stores/plugins/planFeatures/{pluginId}',
  PLUGIN_PLAN_CONFIGURATION_EXISTENCE: 'stores/plugins/planFeatures/existence',
  GET_ALL_PLUGIN_DETAIL: 'stores/plugins/name',
  STORE_POLICIES_STOREFRONT: 'stores/{storeId}/policy/availablepolicies',
  //Get Count
  GET_TABLE_COUNT: 'stores/{storeId}/common/counts',

  BULK_DELETE_CART_PRODUCTS: 'stores/{storeId}/cart/delete',
  GET_ALL_PAYOUT_REQUEST: 'payoutlog',
  GET_ALL_PAYOUT_STATUS: 'payoutlog/status',
  UPDATE_PAYOUT_REQUEST: 'payoutlog/{id}',

  //Affiliate Marketing
  AFFILIATE_SIGNUP: 'affiliateuser',
  AFFILIATE_CHECK_MAIL: 'affiliateuser/mail',
  AFFILIATE_SIGNIN: 'affiliateMarketing/login',
  AFFILIATE_SIGNOUT: 'affiliateMarketing/logout',
  AFFILIATE_VERIFY_USER: 'affiliateMarketing/verifyUser',
  AFFILIATE_USER_DETAILS: 'affiliateMarketing/getCurrentUser',
  AFFILIATE_VERIFY_PASSWORD_TOKEN: 'affiliateMarketing/verify',
  AFFILIATE_FORGOT_PASSWORD: 'affiliateMarketing/forgotPassword',
  AFFILIATE_RESET_PASSWORD: 'affiliateMarketing/configurePassword',
  AFFILIATE_CHECK_PASSWORD_EXISTS: 'affiliateMarketing/checkPassword',
  AFFILIATE_REFERRAL_CODE: 'referralCode',
  AFFILIATE_REFERRAL_CODE_UPDATION: 'referralCode/{id}',
  AFFILIATE_REFERRAL_CUSTOMER: 'affiliateuser/customer/{id}',
  AFFILIATE_REFERRAL_COMMISSION: 'affiliateuser/commission',
  AFFILIATE_GET_SETTINGS: 'affiliateuser/globalsetting',
  AFFILIATE_UPDATE_SETTINGS: 'affiliateuser/globalsetting/{id}',
  GET_ALL_ACCOUNT_DETAILS: 'accountDetails/',
  CREATE_ACCOUNT_DETAILS: 'accountDetails',
  AFFILIATE_ACCOUNT_DETAILS: 'accountDetails/{id}',
  AFFILIATE_CHNAGE_PASSWORD: 'affiliateMarketing/changePassword',
  AFFILIATE_USER_DASHBOARD_DETAILS: 'affiliateuser/getDashboardDetails',
  AFFILIATE_PAYOUT_LOG_DETAILS: 'Payoutlog/status/{userId}',
  AFFILIATE_USER_UPDATED_DETAILS: 'affiliateuser/{id}',
  GLOBAL_SETTINGS: 'affiliateuser/globalSetting',
  AFFILIATE_USER_BANK_ACC_DETAILS: 'accountDetails/active/{id}',
  SHOPAPP_USERLIST: 'store/{storeId}/shopapp/users',
  SHOPAPP_ADD_CUSTOMERS: 'store/{storeId}/shopapp/', //create shoppapp Customer -superadmin
  SHOPAPP_USER_STATUS: 'stores/{storeId}/customers/{customerId}/status',
  SHOPAPP_UPDATE_CUSTOMERS: 'store/{storeId}/shopapp/{id}',
  SHOPAPP_EMAIL_VERIFICATION: 'store/{storeId}/shopapp/{id}/mail/verification',
  SHOP_APP_ORDER_LIST: 'store/{storeId}/shopapp/orderlist/{id}',
  GET_DASHBOARD: 'stores/{storeId}/loyalty',
  GET_POINTS_HISTORY: 'stores/{storeId}/points/pointsLog',
  GET_REFERRAL_HISTORY: 'stores/{storeId}/referral/history',
  GET_LOYALTY_SETTINGS: 'stores/{storeId}/loyalty/settings',
  GET_DASHBOARD_STOREFRONT: 'stores/{storeId}/loyalty/{customerId}',
  GET_CUSTOMER_REFERRAL_HISTORY: 'stores/{storeId}/referral/loyalty/referralhistroy/{customerId}',
  GET_ALL_LIVE_SALE_TEMPLATES: 'liveNotification/templates/',
  GET_ONE_LIVE_SALE_NOTIFICATION: 'stores/{storeId}/liveNotification/liveSale/',
  CREATE_LIVE_SALE_NOTIFICATION: 'stores/{storeId}/liveNotification/liveSale/',
  UPDATE_LIVE_SALE_NOTIFICATION: 'stores/{storeId}/liveNotification/liveSale/',
  GET_ORDER_DETAILS_LIVE_SALE_NOTIFICATION: 'stores/{storeId}/liveNotification/liveSale/orderList',
  CHECK_LIVE_NOTIFICATION: 'stores/{storeId}/liveNotification/liveSale/checkLiveNotification',
  GET_ALL_PRODUCT_DETAILS: 'stores/{storeId}/liveNotification/liveSale/productList',
  CREATE_CARTANDWISHLIST_NOTIFICATION: 'stores/{storeId}/liveNotification/cartAndWishlist/',
  GET_ONE_CARTANDWISHLIST_NOTIFICATION: 'stores/{storeId}/liveNotification/cartAndWishlist/',
  UPDATE_CARTANDWISHLIST_NOTIFICATION: 'stores/{storeId}/liveNotification/cartAndWishlist/',
  CREATE_SOLD_COUNT: 'stores/{storeId}/liveNotification/soldCount/',
  GET_ONE_SOLD_COUNT_FOR_STORE_FRONT: 'stores/{storeId}/liveNotification/soldCount/store',
  GET_SOLD_COUNT_FOR_STORE_FRONT: 'stores/{storeId}/liveNotification/soldCount/count',
  GET_ONLINE_VISITORS: 'stores/{storeId}/liveNotification/onlinevisitors',
  CREATE_ONLINE_VISITORS: 'stores/{storeId}/liveNotification/onlinevisitors',
  GET_NOTIFICATION_PLUGIN_DETAILS: 'stores/{storeId}/liveNotification/liveSale/plugindetails',
  GET_ALL_LIVE_NOTIFICATION: 'stores/{storeId}/liveNotification/liveSale/live-notification',
  GET_PAYOUT_LOG: 'payoutlog',
  GET_STORE_LIMTS: 'common/limit',
  GET_MOBILE_PAYMENT_DATA: 'subscription/calculateForMobile/{storeId}',
  MOBILE_SUBSCRIPTION: 'subscription/mobileAppSubscription',
  UPDATE_MOBILE_THEME: 'stores/{storeId}/pages/mobile/pagesetup',
  GET_AFFILIATE_USER_LOGIN_HISTORY: 'affiliateuser/{id}/history',
  DEACTIVATE_AFFILIATE_ACCOUNT: 'affiliateuser/suspend/{id}',
  GET_ONE_PLUGIN_FEATURE: 'stores/{storeId}/plugins/features',
  GET_ONE_PRODUCT_NOTIFICATION: 'stores/{storeId}/liveNotification/cartAndWishlist/{productId}',
  GET_ONE_PRODUCT_WISHLIST_COUNT: 'stores/{storeId}/liveNotification/cartAndWishlist/{productId}/count',
  GET_ONE_CARTANDWISHLIST_NOTIFICATION_MSG: 'stores/{storeId}/liveNotification/cartAndWishlist/productCount',
  GET_ONLINE_VISITORS_COUNT: 'stores/{storeId}/liveNotification/onlinevisitors/visitorscount',
  GET_PAYMENT_DATA: 'subscription/calculate',
  UPDATE_UPCOMING_PLAN: 'subscription/request ',
  STORE_PLANS: 'subscription/request/{id}',
  DELETE_REQUEST: 'subscription/request/',
  SIGNUP_OTP_VERIFICATION: 'customer/{storeId}/{countryCode}/otp/{userCountryCode}/{phone}',
  FORMAT_AND_UPDATE_MOBILE_PAGES: 'stores/theme/mobile',
  //Translate
  GET_ALL_LANGUAGE: 'stores/{storeId}/translate/getallLanguages',
  GET_ALL_STORELANGUAGE: 'stores/{storeId}/translate/getAllStoreLanguages',
  ADD_STORE_LANGUAGE: 'stores/{storeId}/translate/addStoreLanguages',
  UPDATE_STORE_LANGUAGE: 'stores/{storeId}/translate/updatestorelanguage/{langCode}',
  CHANGE_STORE_LANGUAGE: 'stores/{storeId}/translate/updateStoreLanguages',
  GET_LANGUAGE: 'stores/{storeId}/translate/getLanguage',
  GET_STORELANGUAGE: 'stores/{storeId}/translate/getStoreLanguage',
  GET_ALL_TRANSLATE_HEADER_MENU: 'stores/{storeId}/translate/allheadermenu',

  // discount translation
  GET_ALL_DISCOUNT_TRANSLATE: "stores/{storeId}/discounts/name",
  GET_ONE_DISCOUNT_TRANSLATE: "stores/{storeId}/discounts/translate/{id}",
  // shipping translation
  GET_ALL_SHIPPING_TRANSLATE: "stores/{storeId}/shipping/name",
  GET_ONE_SHIPPING_TRANSLATE: "stores/{storeId}/shipping/translate/{id}",

  // pickup translation
  GET_ALL_PICKUP_TRANSLATE: "stores/{storeId}/shipping/pickup/name",
  GET_ONE_PICKUP_TRANSLATE: "stores/{storeId}/shipping/pickup/translate/{id}",
  GET_LANGUAGE_DATA: 'stores/{storeId}/translate/autoTranslate',
  //UPDATE LOYALTY TRANSLATION
  UPDATE_TRANSLATION_CONTENT: 'stores/{storeId}/translate/{pageName}',

  //translate category
  GET_TRANSLATE_CATEGORY: 'stores/{storeId}/categories/translate/{categoryId}',
  UPDATE_TRANSLATE_CATEGORY: 'stores/{storeId}/categories/translate',

  //translate Product
  GET_ALL_PRODUCTS_TRANSLATE: 'stores/{storeId}/products/catelog/translate',
  GET_TRANSLATE_PRODUCT: 'stores/{storeId}/products/catelog/translate/{productId}',
  UPDATE_TRANSLATE_PRODUCT: 'stores/{storeId}/products/catelog/translate',

  //translate maillogs
  GET_LANGUAGES: 'translate/getLanguage',

  //superadmin signin
  SUPERADMIN_VERIFY_TOKEN: 'master/verifytoken', //verifyToken
  SUPERADMIN_RESET_PASSWORD: 'master/configurepassword',//reset password
  SUPERADMIN_CHANGE_PASSWORD: 'master/changepassword', //changePassword


  //translate Menu in superadmin
  GET_ALL_TRANSLATION_HEADER_MENU: 'translate/header',
  CREATE_TRANSLATION_HEADER_MENU: 'translate/headermenu',
  UPDATE_TRANSLATION_HEADER_MENU: 'translate/headermenu/{id}',
  DELETE_TRANSLATION_HEADER_MENU: 'translate/deleteheadermenu/{id}',
  GET_ONE_TRANSLATION_HEADER_MENU: 'translate/headermenu/{id}',
  ENABLE_DISABLE_TRANSLATION_HEADER_MENU: 'translate/headertoggle/{id}',

  // Add Language
  GET_ALL_LANGUAGE_FOR_SUPERADMIN: 'translate/getallLanguages',
  CREATE_LANGUAGE: 'translate/createlanguage',
  UPDATE_LANGUAGE: 'translate/updatelanguage/{id}',
  // Vama Shipment
  GET_VAMA_SHIP_ESTIMATE_DETAILS: 'stores/{storeId}/vamaship/orders/{id}/coverage',
  CREATE_VAMA_SHIP_BOOKMENT: 'stores/{storeId}/vamaship/orders/{id}/shipping',
  GET_VAMA_SHIP_ORDERS: 'stores/{storeId}/vamaship/orders',
  VAMA_SHIP_TRACKING: 'stores/{storeId}/vamaship/orders/{id}/tracking/{trackingId}',
  VAMA_SHIP_DELETE_ORDER: 'stores/{storeId}/vamaship/orders/{id}/cancel',
  GET_FEATURE_FBT: 'stores/{storeId}/fbt/checkFeatureForFbt',
  GET_LOYALTY_PLUGIN: 'stores/{storeId}/loyalty/plugin',


  SEO_UPDATION: 'stores/{storeId}/seo'
};
