<!-- Footer with solid background color -->
<div *ngIf="footerBackgroundDetails?.backgroundColorType; else elseBackground">
    <!-- style="clip-path: polygon(0 34%, 100% 15%, 100% 100%, 0 100%);" -->
    <ng-container [ngTemplateOutlet]="getTemplateName(footerBackgroundDetails?.backgroundColorType)"
        [ngTemplateOutletContext]="{footerDetails: footerDetails, footerBackgroundDetails: footerBackgroundDetails}"></ng-container>
</div>

<ng-template #elseBackground>
    <div class="large-new" style="background-color:#373737" fxLayout="column">
        <ng-container [ngTemplateOutlet]="footerTemplate"
            [ngTemplateOutletContext]="{footerDetails: footerDetails}"></ng-container>
    </div>
</ng-template>

<ng-template #solid let-footerDetails="footerDetails" let-footerBackgroundDetails="footerBackgroundDetails">
    <div class="large-new"
        [ngStyle]="{'background-color': footerBackgroundDetails?.backgroundColor ? footerBackgroundDetails?.backgroundColor : '#373737'}"
        fxLayout="column">
        <ng-container [ngTemplateOutlet]="footerTemplate"
            [ngTemplateOutletContext]="{footerDetails: footerDetails}"></ng-container>
    </div>
</ng-template>

<ng-template #gradient let-footerDetails="footerDetails" let-footerBackgroundDetails="footerBackgroundDetails">
    <div class="large-new" fxLayout="column"
        [ngStyle]="{'background-image': 'linear-gradient(to right,'+footerBackgroundDetails?.gradientColor1+','+footerBackgroundDetails?.gradientColor2+')'}">
        <ng-container [ngTemplateOutlet]="footerTemplate"
            [ngTemplateOutletContext]="{footerDetails: footerDetails}"></ng-container>
    </div>
</ng-template>

<ng-template #footerTemplate let-footerDetails="footerDetails">
    <div fxLayout="column" fxLayoutAlign="end none">
        <div fxLayout="row wrap" fxLayoutGap="5%" fxLayoutAlign="center start" fxLayoutAlign.lt-sm="start start"
            class="main-footer" [ngStyle]="{'margin.px': footerDetails?.margin ? footerDetails?.margin : '20'}">
            <ng-container *ngIf="footerDetails?.footerContentAlignment">
                <ng-container *ngFor="let footerSection of footerDetails?.footerContentAlignment">
                    <ng-container [ngTemplateOutlet]="getTemplateName(footerSection?.templateName)"
                        [ngTemplateOutletContext]="{footerDetails: footerDetails}"></ng-container>
                </ng-container>
            </ng-container>
            <!-- <ng-template #elseBlock>
                <ng-container *ngFor="let footerCont of footerAlignment">
                    <ng-container [ngTemplateOutlet]="getTemplateName(footerCont?.templateName)"
                        [ngTemplateOutletContext]="{footerDetails: footerDetails}"></ng-container>
                </ng-container>
            </ng-template> -->
        </div>
        <div *ngIf="footerDetails?.isDisplayDisclaimer" class="footer-disclaimer"
            [ngStyle]="{'font-size.px': footerDetails?.disclaimerTextSize ? footerDetails?.disclaimerTextSize : '15', 'color': footerDetails?.disclaimerTextColor ? footerDetails?.disclaimerTextColor : '#ffffff', 'font-family': footerDetails?.disclaimerTextFontFamily ? footerDetails?.disclaimerTextFontFamily : 'Montserrat', 'font-weight': footerDetails?.disclaimerTextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.disclaimerTextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.disclaimerTextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}"
            fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center center">
            {{footerDetails?.disclaimerText | convertLanguage:language}}
        </div>
    </div>



</ng-template>
<ng-template #about>
    <div style="margin-right: 4px;" *ngIf="footerDetails?.isDisplayAbout">
        <div class="Heading"
            [ngStyle]="{'font-size.px': footerDetails?.titleTextSize ? footerDetails?.titleTextSize : '17', 'color': footerDetails?.titleTextColor ? footerDetails?.titleTextColor : '#ffffff', 'font-family': footerDetails?.textFontFamily ? footerDetails?.textFontFamily : 'Montserrat', 'font-weight': footerDetails?.textFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.textFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.textFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
            {{footerDetails?.aboutTitle | convertLanguage:language}}</div>
        <div class="aboutus-text"
            [ngStyle]="{'font-size.px': footerDetails?.subtextSize ? footerDetails?.subtextSize : '15', 'color': footerDetails?.subtextColor ? footerDetails?.subtextColor : '#ffffff', 'font-family': footerDetails?.subtextFontFamily ? footerDetails?.subtextFontFamily : 'Montserrat', 'font-weight': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.subtextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
            {{footerDetails?.aboutText | convertLanguage:language}}</div>
    </div>
</ng-template>
<ng-template #otherMenus>
    <div *ngIf="customMenuHeading.length">
        <div class="Heading"
            [ngStyle]="{'font-size.px': footerDetails?.titleTextSize ? footerDetails?.titleTextSize : '17', 'color': footerDetails?.titleTextColor ? footerDetails?.titleTextColor : '#ffffff', 'font-family': footerDetails?.textFontFamily ? footerDetails?.textFontFamily : 'Montserrat', 'font-weight': footerDetails?.textFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.textFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.textFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
            {{footerDetails?.otherMenusTitle | convertLanguage:language}}</div>
        <ng-container *ngFor="let header of customMenuHeading">
            <div class="footer-menu"
                [ngStyle]="{'font-size.px': footerDetails?.subtextSize ? footerDetails?.subtextSize : '15', 'color': footerDetails?.subtextColor ? footerDetails?.subtextColor : '#ffffff', 'font-family': footerDetails?.subtextFontFamily ? footerDetails?.subtextFontFamily : 'Montserrat', 'font-weight': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.subtextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}"
                (click)="navigate(header?.menuCustomLink)" [attr.data-control]="'navigateTo' + header?.menuName">
                {{header?.translatedMenuName?.[language] ?? header?.menuName}}</div>
        </ng-container>
    </div>
</ng-template>
<ng-template #information>
    <div *ngIf="menuHeading.length">
        <div class="Heading"
            [ngStyle]="{'font-size.px': footerDetails?.titleTextSize ? footerDetails?.titleTextSize : '17', 'color': footerDetails?.titleTextColor ? footerDetails?.titleTextColor : '#ffffff', 'font-family': footerDetails?.textFontFamily ? footerDetails?.textFontFamily : 'Montserrat', 'font-weight': footerDetails?.textFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.textFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.textFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
            {{footerDetails?.informationTitle | convertLanguage:language}}</div>
        <ng-container *ngFor="let header of menuHeading">
            <div class="footer-menu" *ngIf="header?.menuCustomLink==='aboutus' || header?.menuCustomLink==='store/faq'"
                [ngStyle]="{'font-size.px': footerDetails?.subtextSize ? footerDetails?.subtextSize : '15', 'color': footerDetails?.subtextColor ? footerDetails?.subtextColor : '#ffffff', 'font-family': footerDetails?.subtextFontFamily ? footerDetails?.subtextFontFamily : 'Montserrat', 'font-weight': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.subtextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}"
                (click)="navigate(header?.menuCustomLink)" [attr.data-control]="'navigateTo' + header?.menuName">
                {{ getTranslatedTitle(header?.menuName)}}</div>
            <!-- <div *ngFor=" let menu of header?.subMenu">
                <a class="service-list Heading"
                    [ngStyle]="{'font-size.px': footerDetails?.subtextSize ? footerDetails?.subtextSize : '15', 'color': footerDetails?.subtextColor ? footerDetails?.subtextColor : '#ffffff', 'font-family': footerDetails?.subtextFontFamily ? footerDetails?.subtextFontFamily : 'Montserrat', 'font-weight': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.subtextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}"
                    (click)="navigate(menu?.menuCustomLink)" [attr.data-control]="'navigateTo' + menu?.menuName"
                    routerLink={{menu?.menuCustomLink}}>{{menu?.menuName}}</a><br />
            </div> -->
        </ng-container>
        <ng-container *ngFor="let policy of storePolicies">
            <div class="footer-menu"
                [ngStyle]="{'font-size.px': footerDetails?.subtextSize ? footerDetails?.subtextSize : '15', 'color': footerDetails?.subtextColor ? footerDetails?.subtextColor : '#ffffff', 'font-family': footerDetails?.subtextFontFamily ? footerDetails?.subtextFontFamily : 'Montserrat', 'font-weight': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.subtextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}"
                [attr.data-control]="'navigateTo' + policy?.policyName" (click)="navigateTo(policy?.id)">
                {{policy?.policyName}}</div>
        </ng-container>
    </div>

</ng-template>
<ng-template #contact>
    <div fxLayout="column">
        <div class=" Heading"
            [ngStyle]="{'font-size.px': footerDetails?.titleTextSize ? footerDetails?.titleTextSize : '17', 'color': footerDetails?.titleTextColor ? footerDetails?.titleTextColor : '#ffffff', 'font-family': footerDetails?.textFontFamily ? footerDetails?.textFontFamily : 'Montserrat', 'font-weight': footerDetails?.textFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.textFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.textFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
            {{footerDetails?.contactTitle | convertLanguage:language}}</div>
        <div fxLayout="column" class="address-align">
            <ng-container *ngFor="let location of locationDetail">
                <div fxLayout="column"
                    [ngStyle]="{'font-size.px': footerDetails?.subtextSize ? footerDetails?.subtextSize : '15', 'color': footerDetails?.subtextColor ? footerDetails?.subtextColor : '#ffffff', 'font-family': footerDetails?.subtextFontFamily ? footerDetails?.subtextFontFamily : 'Montserrat', 'font-weight': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.subtextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
                    <div *ngIf="location?.address" fxLayout="row" style="margin-bottom: 10px;">
                        <mat-icon class="contact-icon"
                            [ngStyle]="{'height.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'width.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'font-size.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'color': footerDetails?.contactIconColor ? footerDetails?.contactIconColor : '#ffffff'}">place</mat-icon>
                        <div fxLayout="column" style="width:270px"
                            [ngStyle]="{'font-family': footerDetails?.subtextFontFamily ? footerDetails?.subtextFontFamily : 'Montserrat', 'font-weight': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.subtextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.subtextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
                            <span *ngIf="location?.address?.addressLine1" class="contact-font">
                                {{location?.address?.addressLine1}}
                            </span>
                            <span *ngIf="location?.address?.city" class="contact-font">
                                {{location?.address?.stateDetail?.name ? location?.address?.city+',':
                                location?.address?.city}}{{location?.address?.countryDetail?.name ?
                                location?.address?.stateDetail?.name+','
                                : location?.address?.stateDetail?.name}}
                            </span>
                            <span *ngIf="location?.address?.countryDetail?.name" class="contact-font">
                                {{location?.address?.zipCode ? location?.address?.countryDetail?.name+' - ':
                                location?.address?.countryDetail?.name}}{{location?.address?.zipCode ?
                                location?.address?.zipCode:
                                ''}}
                            </span>
                        </div>
                    </div>
                    <span *ngIf="location?.address?.mobileNumber" fxLayout="row" class="contact-font">
                        <mat-icon class="contact-icon"
                            [ngStyle]="{'height.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'width.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'font-size.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'color': footerDetails?.contactIconColor ? footerDetails?.contactIconColor : '#ffffff'}">phone</mat-icon>
                        <span>+{{location?.address?.countryCode}}&nbsp;{{location?.address?.mobileNumber}}</span>
                    </span>
                    <span *ngIf="location?.address?.email" fxLayout="row" class="contact-font">
                        <mat-icon class="contact-icon"
                            [ngStyle]="{'height.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'width.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'font-size.px': footerDetails?.contactIconSize ? footerDetails?.contactIconSize : '15', 'color': footerDetails?.contactIconColor ? footerDetails?.contactIconColor : '#ffffff'}">email</mat-icon>
                        <span>{{location?.address?.email}}</span>
                    </span>
                </div>
            </ng-container>
            <!-- section for displaying social media links -->
            <div fxLayout="row" fxLayoutGap="2%">
                <div *ngIf="storeDetails?.faceBook" (click)="navigate(storeDetails?.faceBook)">
                    <svg class="social-media-icon" xmlns="http://www.w3.org/2000/svg" [attr.fill]="footerDetails?.socialMediaIconColor ?? footerDetails?.subtextColor ?? '#ffffff'" viewBox="0 0 24 24" width="24px" height="24px"><path d="M12,2C6.477,2,2,6.477,2,12c0,5.013,3.693,9.153,8.505,9.876V14.65H8.031v-2.629h2.474v-1.749 c0-2.896,1.411-4.167,3.818-4.167c1.153,0,1.762,0.085,2.051,0.124v2.294h-1.642c-1.022,0-1.379,0.969-1.379,2.061v1.437h2.995 l-0.406,2.629h-2.588v7.247C18.235,21.236,22,17.062,22,12C22,6.477,17.523,2,12,2z"/></svg>
                </div>
                <div *ngIf="storeDetails?.instagram" (click)="navigate(storeDetails?.instagram)">
                    <svg class="social-media-icon" xmlns="http://www.w3.org/2000/svg" [attr.fill]="footerDetails?.socialMediaIconColor ?? footerDetails?.subtextColor ?? '#ffffff'"  viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z"/></svg>
                </div>
                <div *ngIf="storeDetails?.linkedIn" (click)="navigate(storeDetails?.linkedIn)">
                    <svg class="social-media-icon" xmlns="http://www.w3.org/2000/svg" [attr.fill]="footerDetails?.socialMediaIconColor ?? footerDetails?.subtextColor ?? '#ffffff'"  viewBox="0 0 24 24" width="24px" height="24px">    <path d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M9,17H6.477v-7H9 V17z M7.694,8.717c-0.771,0-1.286-0.514-1.286-1.2s0.514-1.2,1.371-1.2c0.771,0,1.286,0.514,1.286,1.2S8.551,8.717,7.694,8.717z M18,17h-2.442v-3.826c0-1.058-0.651-1.302-0.895-1.302s-1.058,0.163-1.058,1.302c0,0.163,0,3.826,0,3.826h-2.523v-7h2.523v0.977 C13.93,10.407,14.581,10,15.802,10C17.023,10,18,10.977,18,13.174V17z"/></svg>
                </div>
                <div *ngIf="storeDetails?.pinterest" (click)="navigate(storeDetails?.pinterest)">
                    <svg class="social-media-icon" xmlns="http://www.w3.org/2000/svg" [attr.fill]="footerDetails?.socialMediaIconColor ?? footerDetails?.subtextColor ?? '#ffffff'"  viewBox="0 0 24 24" width="24px" height="24px">    <path d="M12,2C6.477,2,2,6.477,2,12c0,4.237,2.636,7.855,6.356,9.312c-0.087-0.791-0.167-2.005,0.035-2.868 c0.182-0.78,1.172-4.971,1.172-4.971s-0.299-0.599-0.299-1.484c0-1.391,0.806-2.428,1.809-2.428c0.853,0,1.265,0.641,1.265,1.408 c0,0.858-0.546,2.141-0.828,3.329c-0.236,0.996,0.499,1.807,1.481,1.807c1.777,0,3.143-1.874,3.143-4.579 c0-2.394-1.72-4.068-4.177-4.068c-2.845,0-4.515,2.134-4.515,4.34c0,0.859,0.331,1.781,0.744,2.282 c0.082,0.099,0.093,0.186,0.069,0.287c-0.076,0.316-0.244,0.995-0.277,1.134c-0.043,0.183-0.145,0.222-0.334,0.133 c-1.249-0.582-2.03-2.408-2.03-3.874c0-3.154,2.292-6.052,6.608-6.052c3.469,0,6.165,2.472,6.165,5.776 c0,3.447-2.173,6.22-5.189,6.22c-1.013,0-1.966-0.527-2.292-1.148c0,0-0.502,1.909-0.623,2.378 c-0.226,0.868-0.835,1.958-1.243,2.622C9.975,21.843,10.969,22,12,22c5.522,0,10-4.478,10-10S17.523,2,12,2z"/></svg>
                </div>
                <div *ngIf="storeDetails?.twitter" (click)="navigate(storeDetails?.twitter)">
                    <svg class="social-media-icon" xmlns="http://www.w3.org/2000/svg" [attr.fill]="footerDetails?.socialMediaIconColor ?? footerDetails?.subtextColor ?? '#ffffff'"  viewBox="0 0 24 24" width="24px" height="24px"><path d="M 2.3671875 3 L 9.4628906 13.140625 L 2.7402344 21 L 5.3808594 21 L 10.644531 14.830078 L 14.960938 21 L 21.871094 21 L 14.449219 10.375 L 20.740234 3 L 18.140625 3 L 13.271484 8.6875 L 9.2988281 3 L 2.3671875 3 z M 6.2070312 5 L 8.2558594 5 L 18.033203 19 L 16.001953 19 L 6.2070312 5 z"/></svg>
                </div>
                <div *ngIf="storeDetails?.youtube" (click)="navigate(storeDetails?.youtube)">
                    <svg class="social-media-icon" xmlns="http://www.w3.org/2000/svg" [attr.fill]="footerDetails?.socialMediaIconColor ?? footerDetails?.subtextColor ?? '#ffffff'"  viewBox="0 0 24 24" width="24px" height="24px">    <path d="M21.582,6.186c-0.23-0.86-0.908-1.538-1.768-1.768C18.254,4,12,4,12,4S5.746,4,4.186,4.418 c-0.86,0.23-1.538,0.908-1.768,1.768C2,7.746,2,12,2,12s0,4.254,0.418,5.814c0.23,0.86,0.908,1.538,1.768,1.768 C5.746,20,12,20,12,20s6.254,0,7.814-0.418c0.861-0.23,1.538-0.908,1.768-1.768C22,16.254,22,12,22,12S22,7.746,21.582,6.186z M10,15.464V8.536L16,12L10,15.464z"/></svg>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="footer-copyright"
    [ngStyle]="{'background-color': footerBackgroundDetails?.copyRightBackgroundColor ? footerBackgroundDetails?.copyRightBackgroundColor : '#2c2b2b'}"
    fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center center">
    <footer id="footer" fxFlex.gt-xs="80%" fxFlex.xs="100%">
        <div style="margin-top: -4px; padding-bottom: 0px;" fxLayoutAlign="center center">
            <div fxLayoutAlign.lt-xs="center center"
                [ngStyle]="{'font-size.px': footerDetails?.copyrightTextSize ? footerDetails?.copyrightTextSize : '15', 'color': footerDetails?.copyrightTextColor ? footerDetails?.copyrightTextColor : '#ffffff', 'font-family': footerDetails?.copyrightTextFontFamily ? footerDetails?.copyrightTextFontFamily : 'Montserrat', 'font-weight': footerDetails?.copyrightTextFontStyle?.includes(fontStyleValues?.bold) ? 'bold' : 'normal', 'font-style': footerDetails?.copyrightTextFontStyle?.includes(fontStyleValues?.italic) ? 'italic' : 'normal', 'text-decoration' : footerDetails?.copyrightTextFontStyle?.includes(fontStyleValues?.underline) ? 'underline' : 'none'}">
                {{'SHARED.COPY_RIGHTS' | translate}} {{currentYear}} | {{'SHARED.ALL_RIGHTS_RESERVED' | translate}}.
                <span *ngIf="footerDetails?.showPoweredBy">
                    <span *ngIf="!language || language === 'en'">Powered by</span><a href="https://getzenbasket.com/"
                        target="_blank" rel="noopener noreferrer" data-control="goToZenbasketPage"
                        style="padding-left: 5px;">ZenBasket
                    </a>&nbsp;1.0.212 <span *ngIf="language && language !== 'en'">&nbsp;{{'SHARED.POWERED_BY' |
                        translate}}</span></span>
            </div>
        </div>
    </footer>
</div>