import { Injectable } from '@angular/core';
/**
 * Variable used to handle the unlayer properties.
 */
declare var unlayer;
/**
 * This service is used to create methods unlayer.
 */
@Injectable({
  providedIn: 'root'
})
export class CreateTemplateService {
  /**
   * Component constructor which is used to inject the required services.
   */
  constructor() { }
  /**
   * Method used to load design using unlayer.
   * @param design holds the design details.
   */
  loadUnlayer(design: any) {
    unlayer.loadDesign(design);
  }
  /**
   * Method used to initialize the unlayer.
   * @param data holds the details.
   * @returns the response.
   */
  initializeUnlayer(data?) {
    unlayer.init({
      id: 'editor-container',
      displayMode: 'email',
      appearance: {
        theme: 'light',
        panels: {
          tools: {
            dock: 'right'
          }
        }
      },
      mergeTags: data
    });
    unlayer.setMergeTags(
      data
    );
    return true;
  }

  /**
   * Method used to initialize the unlayer.
   * @param data holds the details.
   * @returns the response.
   */
  initializeUnlayerForEmail(data,designdata?,delimiter?){
    unlayer.init({
      id: 'editor-container',
      displayMode: 'email',
      appearance: {
        theme: 'light',
        panels: {
          tools: {
            dock: 'right'
          }
        }
      },
      mergeTags: data,
      // designTags:{
      //   FirstName:'hiiiii',
      // },
      // designTagsConfig:{
      //     FirstName:'hiiiii',
      //   },
      // designTagsConfig: {
      //   delimiter: delimiter
      // }    
    });
    unlayer.setMergeTags(
      data
    );
    // 
    return true;
  }
}
