import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PaginationService } from '../../services/pagination.service';
import { CommonConstants, PaginatorDetails } from '../../constants/shared-constant';
import { Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { LoadTranslationService } from '@phase-ii/common';
import { MatPaginatorIntl } from '@angular/material/paginator';
@Component({
  selector: 'phase-ii-paginator-vii',
  templateUrl: './paginator-vii.component.html',
  styleUrls: ['./paginator-vii.component.scss']
})
export class PaginatorViiComponent extends CommonConstants implements OnInit, OnChanges, OnDestroy {
  @ViewChild('pagination') pagination: MatPaginator;
  @Input() pageOptions: PaginatorDetails;
  @Input() customPaginationData: any;
  dataArray = [];
  @Output() emitData = new EventEmitter(null);
  @Output() valueChanged = new EventEmitter(null);
  @Output() paginatorData = new EventEmitter(null);
  @Input() isFilterReset: boolean = false;
  @Input() isLocalPagination: boolean = false;
  subscriptionObj: Subscription[] = [];
  constructor(private paginationService: PaginationService,
    private translationService: LoadTranslationService,
    private matPaginatorIntl: MatPaginatorIntl,  // Inject MatPaginatorIntl
  ) {
    super();
  }
  isLoading: boolean;
  /**
   * Variable used to store the translated value of {OF}
   */
  ofTranslateValue: String;
  ngOnInit(): void {

    this.pageOptions = this.pageOptions ? this.pageOptions : {
      pageIndex: (this.customPaginationData?.customPageIndex && this.customPaginationData?.customPageSize) ? Number(this.customPaginationData.customPageIndex / this.customPaginationData?.customPageSize) : 0,
      pageSize: this.customPaginationData?.customPageSize ? Number(this.customPaginationData?.customPageSize) : this.paginator.defaultPageSize,
      previousPageIndex: null,
      length: null
    };
    this.subscriptionObj.push(this.paginationService.listPaginatorData.subscribe((data) => {
      this.dataArray = [];
      if (data) {
        this.pageOptions.length = data.count;
        if (data.count == 0 || this.isFilterReset) {
          this.pageOptions.pageSize = this.paginator.defaultPageSize;
        }
        // if( data.new) {
        //   console.log('new');
        //   this.pageOptions.pageIndex = 0;
        //   // this.pagination.firstPage();
        // }
        // this.dataArray = data.rows;
        // if( this.dataArray &&  this.dataArray.length){
        // this.dataArray = this.dataArray.splice(0, this.pageOptions.pageSize);
        // this.dataArray = data.rows;
        // }
        if (data.new) {
          this.pageOptions.pageIndex = (this.customPaginationData?.customPageIndex && this.customPaginationData?.customPageSize) ? Number(this.customPaginationData.customPageIndex / this.customPaginationData?.customPageSize) : 0;
          if (this.pagination) {
            this.pagination.pageIndex = 0;
          }
        }
        if (!this.isLocalPagination) {
          // console.log('data.rows', data.rows);
          if (data.rows) {
            this.dataArray = data.rows.slice(0, this.pageOptions.pageSize);
            this.emitData.emit(this.dataArray);
          }
        } else {
          if (data.rows) {
            // console.log(data);
            this.dataArray = data.rows;
            // this.cdf.detectChanges();
            if (data.new) {
              this.emitData.emit(this.dataArray.slice(0, this.pageOptions.pageSize));
              // this.pageOptions.pageSize = t
            }
          }
        }
        // } else {
        // }
        // this.emitData.emit(this.dataArray);
        // this.pageOptions.pageSize
      }

    }));
  }
  /**
   *Function used to translate the paginator label
   */
  getPaginatorLabelDetails() {
    this.matPaginatorIntl.itemsPerPageLabel = this.translationService.getTranslation('COMMON.ITEMS_PER_PAGE_LABEL') || 'Items per page';
    this.matPaginatorIntl.nextPageLabel = this.translationService.getTranslation('COMMON.NEXT_PAGE_LABEL') || 'Next';
    this.matPaginatorIntl.previousPageLabel = this.translationService.getTranslation('COMMON.PREVIOUS_PAGE_LABEL') || 'Previous';
    this.matPaginatorIntl.firstPageLabel = this.translationService.getTranslation('COMMON.FIRST_PAGE_LABEL') || 'First page';
    this.matPaginatorIntl.lastPageLabel = this.translationService.getTranslation('COMMON.LAST_PAGE_LABEL') || 'Last page';
    this.ofTranslateValue = this.translationService.getTranslation('COMMON.OF') || 'of';
    //
    this.matPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 ${this.ofTranslateValue} ${length}`;
      }
      const startIndex = page * pageSize;
      const endIndex = Math.min(startIndex + pageSize, length);
      return `${startIndex + 1} – ${endIndex} ${this.ofTranslateValue} ${length}`;
    };
    this.matPaginatorIntl.changes.next();  // Notify that paginator labels have changed
  }
  ngOnChanges(simple: SimpleChanges) {
    this.getPaginatorLabelDetails();
    this.pageOptions = this.pageOptions ? this.pageOptions : {
      pageIndex: (this.customPaginationData?.customPageIndex && this.customPaginationData?.customPageSize) ? Number(this.customPaginationData?.customPageIndex / this.customPaginationData?.customPageSize) : 0,
      pageSize: this.customPaginationData?.customPageSize ? Number(this.customPaginationData?.customPageSize) : this.paginator.defaultPageSize,
      previousPageIndex: null,
      length: null
    };
  }
  onPageChanged(event) {
    // console.log('event', event);
    this.pageOptions.pageSize = event.pageSize;
    this.pageOptions.pageIndex = parseFloat(event?.pageIndex?.toFixed(1));

    // const offset = event.pageIndex * event.pageSize;
    const offset = this.pageOptions.pageIndex * event.pageSize;
    const limit = this.pageOptions.pageSize;
    // console.log('offset',offset, 'limit', limit );

    this.paginatorData.emit(event);
    if (!this.isLocalPagination) {
      this.valueChanged.emit({
        offset: offset,
        limit: limit
      });
      // this.emitData.emit(this.dataArray);
    } else {
      // console.log("local pagination", offset, offset+limit ,this.dataArray);
      // let data = this.dataArray;
      // console.log(this.dataArray.splice(offset, offset+limit));
      this.emitData.emit(this.dataArray.slice(offset, offset + limit));
    }
  }
  ngOnDestroy(): void {
    this.dataArray = [];
    this.pageOptions = {
      pageIndex: 0,
      pageSize: this.paginator.defaultPageSize,
      previousPageIndex: null,
      length: null
    };
    if (this.subscriptionObj) {
      this.subscriptionObj.forEach(element => {
        element.unsubscribe();
      });
    } else {
        return;
    }
  }
}
